import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import axiosInstance from "../utility/axios";
import Pagination from "./Pagination";
import DeleteConfirmation from "./DeleteModal";
import NoDataPresent from "./NoDataPresent";

export default function UnverifiedUsers({ setNotificationData }) {
  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const formatDateAndTime = (utcTimestamp) => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const userOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZone: userTimezone,
    };

    return new Intl.DateTimeFormat("en-US", userOptions).format(
      new Date(utcTimestamp)
    );
  };

  const fetchData = () => {
    axiosInstance
      .get(
        `/user/website/unverified-users?offset=${pagination.offset}&limit=${pagination.limit}`
      )
      .then((res) => {
        if (res.status == 200) {
          setUsers(res.data.data.users);
          setTotalRecords(res.data.data.count);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteUser = (user) => {
    axiosInstance
      .delete(`/user/website/delete-unverified-user/${user.id}`)
      .then((res) => {
        setNotificationData([
          {
            msg: res.data.message,
            status: res.status,
          },
        ]);
        fetchData();
      });
  };

  useEffect(() => {
    fetchData();
  }, [pagination]);

  useEffect(() => {
    if (deleteId) {
      setShowDeleteConfirmation(true);
    }
  }, [deleteId]);

  return (
    <div>
      <DeleteConfirmation
        showModal={showDeleteConfirmation}
        hideModal={() => {
          setShowDeleteConfirmation(false);
          setDeleteId(undefined);
        }}
        confirmModal={() => {
          deleteUser(deleteId);
          setShowDeleteConfirmation(false);
        }}
        message={`Are you sure you want to delete ${deleteId?.name}?`}
      />
      {users.length == 0 ? (
        <NoDataPresent />
      ) : (
        <>
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th className="table-head">#</th>
                <th className="table-head">Name</th>
                <th className="table-head">Email</th>
                <th className="table-head">Country</th>
                <th className="table-head">Organisation</th>
                <th className="table-head">Group Type</th>
                <th className="table-head">CSO</th>
                <th className="table-head">Created At</th>
                <th className="table-head">Delete</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr key={index}>
                  <td>{pagination.offset + index + 1}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>
                    {user.country || "Other - " + user.other_country_name}
                  </td>
                  <td>{user.organisation + " - " + user.organisation_name}</td>
                  <td>{user.group_type}</td>
                  <td>{user.subCommunity || user.other_CSO}</td>
                  <td>{user.createdAt && formatDateAndTime(user.createdAt)}</td>

                  <td>
                    <i
                      className="fa-solid fa-trash-can fa-1x"
                      onClick={() => setDeleteId(user)}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            setPagination={setPagination}
            totalRecords={totalRecords}
          />
        </>
      )}
    </div>
  );
}
