import { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import axiosInstance from "../utility/axios";
import DeleteConfirmation from "../components/DeleteModal";
import Notification from "../components/Notification";
import GroupRequests from "../components/GroupRequests";
import ChatGroups from "../components/ChatGroups";
import GroupSideBar from "../components/GroupSideBar";
import ManageGroupUsers from "../components/ManageGroupUsers";

const ChatBoard = () => {
  const [groupData, setGroupData] = useState([]);
  const [groupRequestData, setGroupRequestData] = useState([]);
  const [editDetails, setEditDetails] = useState({});
  const [showCreateGroupBar, setShowCreateGroupBar] = useState(false);
  const [search, setSearch] = useState("");
  const [searchRequest, setSearchRequest] = useState("");
  const [countries, setCountries] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [selectedTab, setSelectedTab] = useState("groups");
  const [deleteId, setDeleteId] = useState(undefined);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [groupList, setGroupList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(undefined);
  const [selectedGroup, setSelectedGroup] = useState(undefined);

  const listGroups = async () => {
    let query = "";
    if (selectedCountry) query += `?country_id=${selectedCountry.id}`;

    axiosInstance
      .get(`/group-requests/list-groups/${query}`)
      .then((res) => {
        if (res.status === 200) {
          setGroupList(res.data?.data);
        } else {
          setGroupList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    listGroups();
    setSelectedGroup(undefined);
  }, [selectedCountry]);

  const getGroups = async () => {
    let query = `?search=${search}&offset=${pagination.offset}&limit=${pagination.limit}`;

    axiosInstance
      .get(`/chat-group/get/${query}`)
      .then((res) => {
        if (res.status === 200) {
          setGroupData(res.data.chatGroups);
          setTotalRecords(res.data?.count || 0);
        } else {
          console.log("Error");
          setTotalRecords(0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getGroups();
  }, [pagination]);

  const getGroupRequests = async () => {
    if (!selectedGroup?.id) return;
    let query = `?search=${searchRequest}&offset=${pagination.offset}&limit=${pagination.limit}&groupId=${selectedGroup?.id}`;
    axiosInstance
      .get(`/group-requests/get${query}`)
      .then((res) => {
        if (res.status === 200) {
          setGroupRequestData(res.data?.data?.requests);
          setTotalRecords(res.data?.data?.count || 0);
        } else {
          setGroupRequestData([]);
          setTotalRecords(0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getGroupData = (id) => {
    axiosInstance
      .get(`/chat-group/get/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setEditDetails(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteGroup = (id) => {
    axiosInstance
      .delete(`/chat-group/delete/${id}`)
      .then((res) => {
        if (res.status === 200) {
          getGroups();
        }
        setNotificationData([
          {
            msg: res.data.message,
            status: res.status,
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const approveGroupRequest = (id, groupId) => {
    axiosInstance
      .patch(`/group-requests/approve`, {
        id,
        groupId,
      })
      .then((res) => {
        if (res.status === 200) {
          getGroupRequests();
        }
        setNotificationData([
          {
            msg: res.data.message,
            status: res.status,
          },
        ]);
      });
  };

  const rejectGroupRequest = (id, groupId) => {
    axiosInstance
      .patch(`/group-requests/reject`, {
        id,
        groupId,
      })
      .then((res) => {
        if (res.status === 200) {
          getGroupRequests();
        }
        setNotificationData([
          {
            msg: res.data.message,
            status: res.status,
          },
        ]);
      });
  };

  const approveBulkGroupRequests = (selectedRequests, groupId) => {
    axiosInstance
      .patch("/group-requests/approve-bulk", {
        ids: selectedRequests?.map((item) => item["public_user.id"]),
        groupId,
      })
      .then((res) => {
        if (res.status === 200) {
          getGroupRequests();
          setSelectedRows([]);
        }
        setNotificationData([
          {
            msg: res.data.message,
            status: res.status,
          },
        ]);
      })
      .catch((error) => {
        console.error("Error approving group requests:", error);
      });
  };

  const rejectBulkGroupRequests = (selectedRequests, groupId) => {
    axiosInstance
      .patch("/group-requests/reject-bulk", {
        ids: selectedRequests?.map((item) => item["public_user.id"]),
        groupId,
      })
      .then((res) => {
        if (res.status === 200) {
          getGroupRequests();
          setSelectedRows([]);
        }
        setNotificationData([
          {
            msg: res.data.message,
            status: res.status,
          },
        ]);
      })
      .catch((error) => {
        console.error("Error rejecting group requests:", error);
      });
  };

  useEffect(() => {
    getGroupRequests();
  }, [pagination, selectedGroup]);

  useEffect(() => {
    axiosInstance.get("/user/country/list").then((res) => {
      setCountries(res.data || []);
    });
    listGroups();
  }, []);

  useEffect(() => {
    if (selectedTab === "groups") {
      getGroups();
    } else {
      setSelectedRows([]);
      getGroupRequests();
    }
  }, [selectedTab]);

  useEffect(() => {
    if (deleteId) {
      setShowDeleteConfirmation(true);
    }
  }, [deleteId]);

  return (
    <>
      <section className="user-section" id="user-section">
        <div className="user-content">
          <Notification
            notificationData={notificationData}
            setNotificationData={setNotificationData}
          />
          <DeleteConfirmation
            showModal={showDeleteConfirmation}
            hideModal={() => {
              setShowDeleteConfirmation(false);
              setDeleteId(undefined);
            }}
            confirmModal={() => {
              deleteGroup(deleteId?.id);
              setShowDeleteConfirmation(false);
            }}
            message={`Are you sure you want to delete ${deleteId?.title}?`}
          />
          <GroupSideBar
            show={showCreateGroupBar}
            details={editDetails}
            countries={countries}
            refresh={() => {
              getGroups();
              setEditDetails({});
            }}
            handleClose={() => {
              setShowCreateGroupBar(false);
              setEditDetails({});
            }}
          />

          <Tabs
            defaultActiveKey="groups"
            id="uncontrolled-tab-example"
            className="mb-3"
            onSelect={(v) => {
              setSelectedTab(v);
            }}
          >
            <Tab eventKey="groups" title="Groups">
              <ChatGroups
                data={groupData}
                search={search}
                setSearch={setSearch}
                getGroups={getGroups}
                showCreateGroupBar={showCreateGroupBar}
                setShowCreateGroupBar={setShowCreateGroupBar}
                setEditDetails={setEditDetails}
                setDeleteId={setDeleteId}
                getGroupData={getGroupData}
                deleteGroup={deleteGroup}
                pagination={pagination}
                setPagination={setPagination}
                totalRecords={totalRecords}
              />
            </Tab>
            <Tab eventKey="requests" title="Requests">
              <GroupRequests
                data={groupRequestData}
                approveGroupRequest={approveGroupRequest}
                rejectGroupRequest={rejectGroupRequest}
                approveBulkGroupRequests={approveBulkGroupRequests}
                rejectBulkGroupRequests={rejectBulkGroupRequests}
                search={searchRequest}
                setSearch={setSearchRequest}
                getGroupRequests={getGroupRequests}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                setPagination={setPagination}
                pagination={pagination}
                totalRecords={totalRecords}
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
                selectedGroup={selectedGroup}
                setSelectedGroup={setSelectedGroup}
                groupList={groupList}
                countries={countries}
              />
            </Tab>
            <Tab eventKey="manage" title="Manage Groups">
              <ManageGroupUsers
                countries={countries}
                groupList={groupList}
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
                setNotificationData={setNotificationData}
                notificationData={notificationData}
              />
            </Tab>
          </Tabs>
        </div>
      </section>
    </>
  );
};

export default ChatBoard;
