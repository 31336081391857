import { useContext, useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Pagination from "./Pagination";
import axiosInstance from "../utility/axios";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Badge from "react-bootstrap/Badge";
import Offcanvas from "react-bootstrap/Offcanvas";
import { userContext } from "../pages/Context";
import NoDataPresent from "./NoDataPresent";

const MainHubTable = ({
  search,
  setSearch,
  selectedCountry,
  setSelectedCountry,
  countries,
}) => {
  const { user } = useContext(userContext);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [mainHubs, setMainHubs] = useState([]);
  const [selected, setSelected] = useState({});
  const [show, setShow] = useState(false);

  const fetchData = () => {
    let query = `search=${search}&offset=${pagination.offset}&limit=${pagination.limit}`;
    if (user.role < 3 && selectedCountry) {
      query += `&country_id=${selectedCountry.id}`;
    } else if (user.role >= 3) {
      query += `&country_id=${user.countryId}`;
    }
    axiosInstance.get(`/user/hub/main/getall?${query}`).then((res) => {
      if (res.status === 200) {
        setMainHubs(res.data.communities || []);
        setTotalRecords(res.data.count);
      } else {
        setMainHubs([]);
        setTotalRecords(res.data.count);
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    fetchData();
  }, [pagination, selectedCountry]);

  return (
    <>
      {" "}
      <Offcanvas
        show={show}
        onHide={() => {
          setSelected(undefined);
          setShow(false);
        }}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Community</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <InputGroup className="mb-3">
            <p>
              <strong>Name: </strong>
              {selected?.name}
            </p>
          </InputGroup>

          <InputGroup className="mb-3">
            <p>
              <strong>Email: </strong>
              {selected?.email}
            </p>
          </InputGroup>

          <InputGroup className="mb-3">
            <p>
              <strong>Address: </strong>
              {selected?.address}
            </p>
          </InputGroup>
          <InputGroup className="mb-3">
            <p>
              <strong>Phone: </strong>
              {selected?.country_code}
              {"  "}
              {selected?.phone}
            </p>
          </InputGroup>
          {selected?.alternate_phone && (
            <InputGroup className="mb-3">
              <p>
                <strong>Phone: </strong>
                {selected?.country_code}
                {"  "}
                {selected?.alternate_phone}
              </p>
            </InputGroup>
          )}
          <InputGroup className="mb-3">
            <p>
              <strong>Country: </strong>
              {
                countries?.filter(
                  (country) => country.id == selected?.countryId
                )[0]?.name
              }
            </p>
          </InputGroup>
        </Offcanvas.Body>
      </Offcanvas>
      <div
        className="input-group mb-4 table-filters"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div style={{ display: "flex" }}>
          <input
            type="search"
            placeholder="Search by name"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            aria-describedby="button-addon5"
            className="form-control"
            style={{ borderRadius: ".375rem 0px 0px .375rem" }}
          />
          <div className="input-group-append">
            <button
              id="button-addon5"
              type="button"
              onClick={() => fetchData()}
              className="btn btn-primary color3863A0"
              style={{
                borderRadius: "2px",
                height: "100%",
              }}
            >
              <i className="bx bx-search"></i>
            </button>
          </div>
          {user.role < 3 && (
            <InputGroup className="mx-3" style={{ width: "fit-content" }}>
              <DropdownButton
                variant="outline-secondary"
                title={
                  selectedCountry ? selectedCountry["name"] : "Select Country"
                }
                id="input-group-dropdown-1"
                onSelect={(e) => {
                  setSelectedCountry(JSON.parse(e));
                }}
              >
                <Dropdown.Item eventKey={undefined}>Select None</Dropdown.Item>
                {countries.map((country, index) => (
                  <Dropdown.Item eventKey={JSON.stringify(country)} key={index}>
                    {country.name}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </InputGroup>
          )}
        </div>
      </div>
      {mainHubs.length === 0 ? (
        <NoDataPresent />
      ) : (
        <>
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th className="table-head">#</th>
                <th className="table-head">Name</th>
                <th className="table-head">Contact</th>
                <th className="table-head"></th>
                <th className="table-head">View</th>
              </tr>
            </thead>
            <tbody>
              {mainHubs.map((hub, index) => (
                <tr key={index}>
                  <td>{pagination.offset + index + 1}</td>
                  <td>{hub.name}</td>
                  <td>{hub.email}</td>
                  <td>
                    <Badge bg="light" text="dark">
                      {hub.country}
                    </Badge>
                  </td>
                  <td>
                    <i
                      className="fa-solid fa-box-open fa-1x"
                      onClick={() => {
                        setSelected(hub);
                        setShow(true);
                      }}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            setPagination={setPagination}
            totalRecords={totalRecords}
          />
        </>
      )}
    </>
  );
};

export default MainHubTable;
