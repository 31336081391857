import React, { useState, useEffect, useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";
import axiosInstance from "../utility/axios";
import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup";
import Alert from "react-bootstrap/Alert";
import Badge from "react-bootstrap/Badge";
import { userContext } from "../pages/Context";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { signOut } from "firebase/auth";
import { auth } from "../firebase-config";

const docs_types_name = {
  tools: "Tools",
  document: "Document",
  report: "Report",
};

const EditAdvocacy = ({
  form,
  setShow,
  handleSubmit,
  handleChange,
  handleContentChange,
  handleFileChange,
  handleImageChange,
  setShowDocs,
  setEditDocs,
  removeSelectedFile,
  removeSelectedFileFirebase,
  docs_category,
  docs_types,
  msg,
  setMsg,
  setNotificationData,
  updateTable,
  setUploadProgress,
  setFileError,
  fileError,
  uploadProgress,
}) => {
  const { user } = useContext(userContext);
  const [comment, setComment] = useState("");
  const [countries, setCountries] = useState([]);
  const [newsImageTracker, setNewsImageTracker] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState(
    form.countryIds || []
  );
  useEffect(() => {
    axiosInstance.get("/user/country/list").then((res) => {
      setCountries(res.data);
    });
  }, []);

  useEffect(() => {
    if (
      form.countryIds.find((country) => country.id === user.countryId) ||
      !user.countryId
    ) {
      setSelectedCountries(form.countryIds);
    } else {
      setSelectedCountries([
        ...form.countryIds,
        { id: user.countryId, name: user.country },
      ]);
    }
  }, [form.countryIds]);

  const uploadAdapter = (loader) => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("file", file);
            fetch(`${process.env.REACT_APP_API}/docs/image/uploads`, {
              method: "post",
              body: body,
              credentials: "include",
              // mode: "no-cors"
            })
              .then((res) => res.json())
              .then((res) => {
                setNewsImageTracker([...newsImageTracker, res.url]);
                resolve({
                  default: `${process.env.REACT_APP_BUCKET_URL}${res.url}`,
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  };
  const uploadPlugin = (editor) => {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  };

  const handleComment = async (status) => {
    // status = 1 rejected, status = 3 approved, status = 2 No update [General comment]
    let isApproved = 2;
    if (status === "approve") {
      isApproved = 3;
    } else if (status === "reject") {
      isApproved = 1;
    }
    await axiosInstance
      .patch(`/docs/approve/${form.id}`, {
        status: isApproved,
        comment,
      })
      .then((res) => {
        setNotificationData([
          {
            msg: res.data.message,
            status: res.status,
          },
        ]);
        setShowDocs(false);
        setEditDocs("");
        setMsg({
          message: [],
          variant: "danger",
        });
        updateTable();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDropdownSelect = (e, selectedCountry) => {
    if (user.countryId !== selectedCountry.id) {
      setSelectedCountries((prevSelected) => {
        // Check if the country is already selected
        const isSelected = prevSelected.filter(
          (country) => country.id === selectedCountry.id
        );
        // If the country is already selected, remove it from the selected list
        if (isSelected.length) {
          return prevSelected.filter(
            (country) => country.id !== selectedCountry.id
          );
        } else {
          // If the country is not selected, add it to the selected list
          return [...prevSelected, selectedCountry];
        }
      });
    }
  };

  const uploadFileToFirebase = (e, uploadUrl) => {
    return new Promise(async (resolve, reject) => {
      const file = e.target.files[0];
      try {
        const storage = getStorage();
        const storageRef = ref(storage, uploadUrl);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
            switch (snapshot.state) {
              case "paused":
                // console.log("Upload is paused");
                break;
              case "running":
                // console.log("Upload is running");
                break;
            }
          },
          (error) => {
            // console.log("Upload error", error);
            reject(error);
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              // console.log("File available at", downloadURL);
              resolve(downloadURL); // Resolve the promise with the downloadURL
            } catch (error) {
              // console.log("Error getting download URL", error);
              reject(error);
            }
          }
        );
      } catch (error) {
        // console.log("Video upload error", error);
        reject(error);
      }
      signOut(auth);
    });
  };

  useEffect(() => {
    handleChange({
      target: { name: "countryIds", value: selectedCountries },
    });
  }, [selectedCountries]);

  useEffect(() => {
    newsImageTracker.forEach((imageURL, idx) => {
      if (form.content.search(imageURL) === -1) {
        setNewsImageTracker([
          ...newsImageTracker.filter((url) => url !== imageURL),
        ]);
        axiosInstance
          .post(`/docs/image/remove`, {
            filePath: imageURL,
          })
          .then((response) => {})
          .catch((err) => {});
      }
    });
  }, [form.content]);

  return (
    <div className="BlogsContainer">
      <div className="resource-top mx-4">
        <Col className="blog-heading pt-2 pb-2" xs={8}>
          <h1 className="heading">Edit Form</h1>
        </Col>
        <Col className="button-grp">
          <button className="preview-button" onClick={() => setShow(true)}>
            <img
              loading="lazy"
              src="https://cdn-icons-png.flaticon.com/512/6329/6329893.png"
              alt="Preview Icon"
              className="preview-icon"
            />
            Preview
          </button>
          <button className="save-button" onClick={handleSubmit}>
            Save
          </button>
          <button
            style={{ backgroundColor: "grey" }}
            className="save-button"
            onClick={() => {
              setShowDocs(false);
              setEditDocs("");
              if (form.File) {
                removeSelectedFileFirebase("File");
              }
              setMsg({
                message: [],
                variant: "danger",
              });
            }}
          >
            Back
          </button>
        </Col>
      </div>
      <div className="resource-inputs pt-4">
        <Col md={12} lg={8}>
          <div className="p-3 pt-0">
            <Row>
              <Container className="rounded editor-container p-4 m-1  ">
                <h2 className="sub-heading">Title</h2>

                <input
                  type="text"
                  className="form-control"
                  id="blogTitle"
                  name="title"
                  placeholder=""
                  value={form.title}
                  onChange={handleChange}
                  disabled={
                    user.role > 3 &&
                    (form.publishStage === 2 ||
                      (form.authorId && user.id !== form.authorId))
                  }
                />
              </Container>
            </Row>

            <Row>
              <Container className="rounded editor-container p-4 m-1">
                <Row>
                  <Col className="left-container text-center" xs={3}>
                    <p>Upload File</p>
                  </Col>
                  <Col className="text-center ">
                    <Row>
                      <div className="image-uploader">
                        <label htmlFor="File" className="upload-label">
                          <div className="upload-icon">
                            <img
                              loading="lazy"
                              src="https://cdn-icons-png.flaticon.com/512/569/569800.png"
                              alt="Upload Icon"
                            />
                          </div>
                          <span className="upload-text">
                            {form.File || form.FileId
                              ? typeof form.File === "string"
                                ? `${form.FileName}`
                                : form.File.name
                              : "Choose from files"}
                          </span>{" "}
                          {(form.File || form.FileId) && (
                            <button
                              style={{
                                padding: "0px .5em",
                                marginLeft: "1em",
                              }}
                              className="remove-button"
                              type="button"
                              onClick={() => removeSelectedFileFirebase("File")}
                              disabled={
                                user.role > 3 &&
                                (form.publishStage === 2 ||
                                  (form.authorId && user.id !== form.authorId))
                              }
                            >
                              X
                            </button>
                          )}
                        </label>

                        <input
                          type="file"
                          id="File"
                          accept="application/pdf"
                          multiple={false}
                          name="File"
                          style={{ display: "none" }}
                          onChange={(e) => {
                            handleFileChange(e, uploadFileToFirebase);
                          }}
                          disabled={
                            user.role > 3 &&
                            (form.publishStage === 2 ||
                              (form.authorId && user.id !== form.authorId))
                          }
                        />
                      </div>
                    </Row>
                    <Row className="progress-container">
                      <div className="progress-bar">
                        <div
                          className="progress-fill"
                          style={{ width: `${uploadProgress}%` }}
                        ></div>
                      </div>
                      <div className="progress-text">
                        {uploadProgress !== 0 &&
                          `${uploadProgress.toFixed(2)}%`}
                      </div>
                      <div>
                        {fileError.File && (
                          <Alert variant="danger">{fileError.File}</Alert>
                        )}
                      </div>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </Row>
            <Row>
              <Container className="rounded editor-container p-4 m-1">
                <Row>
                  <Col className="left-container text-center" xs={3}>
                    <p>Upload Banner Image</p>
                  </Col>
                  <Col className="text-center ">
                    <div className="image-uploader">
                      <label htmlFor="bannerImage" className="upload-label">
                        <div className="upload-icon">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/512/569/569800.png"
                            alt="Upload Icon"
                          />
                        </div>
                        <span className="upload-text">
                          {form.bannerImage || form.bannerImageName
                            ? typeof form.bannerImage === "string"
                              ? form.bannerImageName
                              : form.bannerImage.name
                            : "Choose from files"}
                        </span>{" "}
                        {form.bannerImage && (
                          <button
                            style={{
                              padding: "0px .5em",
                              marginLeft: "1em",
                            }}
                            className="remove-button"
                            type="button"
                            onClick={() => removeSelectedFile("bannerImage")}
                          >
                            X
                          </button>
                        )}
                      </label>

                      <input
                        type="file"
                        id="bannerImage"
                        accept=".jpg,.jpeg,.png"
                        multiple={false}
                        name="bannerImage"
                        style={{ display: "none" }}
                        onChange={handleImageChange}
                        disabled={
                          user.role > 3 &&
                          (form.publishStage === 2 ||
                            (form.authorId && user.id !== form.authorId))
                        }
                      />
                    </div>
                    {fileError && fileError?.bannerImage && (
                      <Alert variant="danger">{fileError?.bannerImage}</Alert>
                    )}
                  </Col>
                </Row>
              </Container>
            </Row>
            <Row>
              <Container className="rounded editor-container p-4 m-1  ">
                <h2 className="sub-heading">authors</h2>
                <input
                  type="text"
                  className="form-control"
                  id="authorName"
                  name="authorName"
                  placeholder=""
                  value={form.authorName}
                  onChange={handleChange}
                  disabled={
                    user.role > 3 &&
                    (form.publishStage === 2 ||
                      (form.authorId && user.id !== form.authorId))
                  }
                />
              </Container>
            </Row>
            <Row>
              <Container className="rounded editor-container p-4 m-1">
                <h2 className="sub-heading">Description</h2>
                <CKEditor
                  editor={Editor}
                  data={form.description}
                  onReady={(editor) => {
                    uploadPlugin(editor);
                  }}
                  onChange={handleContentChange}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                  disabled={
                    user.role > 3 &&
                    (form.publishStage === 2 ||
                      (form.authorId && user.id !== form.authorId))
                  }
                />
              </Container>
            </Row>
          </div>
        </Col>
        <Col>
          <Container className="pt-4 editor-container">
            <div className="p-1">
              <h2 className="sub-heading">Country</h2>

              {user.role > 3 &&
              (form.publishStage === 2 ||
                (form.authorId && user.id !== form.authorId)) ? (
                <>
                  {selectedCountries.map((country) => (
                    <Badge
                      bg="dark"
                      text="light"
                      className="p-2 m-1"
                      key={country.id}
                    >
                      {country.name}
                    </Badge>
                  ))}
                </>
              ) : (
                <DropdownButton
                  variant="outline-secondary"
                  title={"Select Country"}
                  id="input-group-dropdown-1"
                >
                  <Form.Check
                    type="checkbox"
                    label="Select All"
                    style={{ margin: "0px 20px" }}
                    checked={selectedCountries.length === countries.length}
                    onChange={() => {
                      setSelectedCountries(
                        selectedCountries.length === countries.length
                          ? []
                          : countries
                      );
                    }}
                  />
                  {countries.map((country, index) => (
                    <Form.Check
                      key={index}
                      type="checkbox"
                      label={country.name}
                      style={{ margin: "0px 20px" }}
                      checked={selectedCountries.some(
                        (selected) => selected?.id === country?.id
                      )}
                      onChange={(e) => {
                        handleDropdownSelect(e, country);
                      }}
                    />
                  ))}
                </DropdownButton>
              )}
            </div>
            <div className="p-1">
              <h2 className="sub-heading">Category</h2>
              <InputGroup className="" style={{ width: "fit-content" }}>
                <DropdownButton
                  variant="outline-secondary"
                  title={form.category ? form.category : "Select Category"}
                  id="input-group-dropdown-1"
                  onSelect={(e) => {
                    handleChange({ target: { name: "category", value: e } });
                  }}
                  disabled={
                    user.role > 3 &&
                    (form.publishStage === 2 ||
                      (form.authorId && user.id !== form.authorId))
                  }
                >
                  {docs_category.map((docsCategory, index) => (
                    <Dropdown.Item eventKey={docsCategory} key={index}>
                      {docsCategory}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </InputGroup>
            </div>
            {form.docs_type !== "advocacy" && (
              <div className="p-1">
                <h2 className="sub-heading">Resource Type</h2>
                <InputGroup className="" style={{ width: "fit-content" }}>
                  <DropdownButton
                    variant="outline-secondary"
                    title={
                      form.docs_type
                        ? docs_types_name[form.docs_type]
                        : "Resource Type"
                    }
                    id="input-group-dropdown-1"
                    onSelect={(e) => {
                      handleChange({
                        target: { name: "docs_type", value: e },
                      });
                    }}
                    disabled={
                      user.role > 3 &&
                      (form.publishStage === 2 ||
                        (form.authorId && user.id !== form.authorId))
                    }
                  >
                    {docs_types.map((docsType, index) => (
                      <Dropdown.Item eventKey={docsType} key={index}>
                        {docs_types_name[docsType]}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </InputGroup>
              </div>
            )}
            <div className="p-1">
              <h2 className="sub-heading">Post Date</h2>
              <input
                type="date"
                className="form-control"
                id="blogDate"
                name="date"
                placeholder=""
                // min={new Date().toISOString().split("T")[0]}
                value={form.date}
                onChange={handleChange}
                disabled={
                  user.role > 3 &&
                  (form.publishStage === 2 ||
                    (form.authorId && user.id !== form.authorId))
                }
              />
            </div>
            <div>
              {msg.message.length > 0 &&
                msg.message.map((m, index) => (
                  <Alert key={index} variant={msg.variant}>
                    {m}
                  </Alert>
                ))}
            </div>
            <div
              style={{
                display: form.publishStage === 2 ? "flex" : "none",
              }}
            >
              <Card border="primary" style={{ margin: "auto" }}>
                <Card.Header>
                  {user.id === form.authorId
                    ? "Please Wait!!!"
                    : `Do you Approve this ${form.docs_type}?`}
                </Card.Header>
                <Card.Body>
                  <Card.Text as="div">
                    {user.id === form.authorId ? (
                      `${form.docs_type} is pending for approval`
                    ) : (
                      <>
                        <textarea
                          placeholder="Comment"
                          style={{ width: "100%" }}
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        ></textarea>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "15px",
                          }}
                        >
                          <button
                            className="btn btn-success"
                            type=""
                            onClick={() => handleComment("approve")}
                          >
                            Approve
                          </button>
                          <button
                            className="btn btn-danger"
                            type=""
                            onClick={() => handleComment("reject")}
                          >
                            Reject
                          </button>
                        </div>{" "}
                      </>
                    )}
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </Container>
        </Col>
      </div>
    </div>
  );
};

export default EditAdvocacy;
