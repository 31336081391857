import React, { useState, useEffect } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";
import Notification from "./Notification";
import { SyncLoader } from "react-spinners";
import { nanoid } from "nanoid";
import Compressor from "compressorjs";

import axiosInstance from "../utility/axios";
import "./GalleryFileUpload.css";

const GalleryFileUpload = ({ refresh }) => {
  const [selectedfile, SetSelectedFile] = useState([]);
  const [Files, SetFiles] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [imageUploading, setImageUploading] = useState(false);
  const [eventTitle, setEventTitle] = useState("");
  const [eventDate, setEventDate] = useState(new Date());

  useEffect(() => {
    axiosInstance.get("/user/country/list").then((res) => {
      setCountries(res.data);
    });
  }, []);

  const filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const handleTitleChange = (e, id) => {
    const result = selectedfile.map((data) => {
      if (data.id !== id) {
        return data;
      } else {
        return {
          ...data,
          title: e.target.value,
        };
      }
    });

    SetSelectedFile([...result]);
  };

  const InputChange = async (e) => {
    // --For Multiple File Input
    let images = [];
    for (let i = 0; i < e.target.files.length; i++) {
      images.push(e.target.files[i]);
      let reader = new FileReader();
      let file = e.target.files[i];
      try {
        const compressedFileData = await new Promise(
          (resolve, reject) =>
            new Compressor(file, {
              quality: 0.6,
              success: (res) => {
                resolve(res);
              },
              error: (err) => {
                console.log(err);
                reject();
              },
            })
        );
        reader.onloadend = () => {
          SetSelectedFile((preValue) => {
            return [
              ...preValue,
              {
                id: nanoid(),
                filename: compressedFileData.name,
                filetype: compressedFileData.type,
                fileimage: reader.result,
                fileObj: compressedFileData,
                datetime:
                  compressedFileData?.lastModifiedDate?.toLocaleString(
                    "en-IN"
                  ) || new Date(),
                filesize: filesizes(compressedFileData.size),
                title: "",
              },
            ];
          });
        };
      } catch (error) {
        console.error("Error compressing file:", error);
      }
      if (e.target.files[i]) {
        reader.readAsDataURL(file);
      }
    }
  };

  const handleDropdownSelect = (e, selectedCountry) => {
    setSelectedCountries((prevSelected) => {
      // Check if the country is already selected
      const isSelected = prevSelected.filter(
        (country) => country.id === selectedCountry.id
      );
      // If the country is already selected, remove it from the selected list
      if (isSelected.length) {
        return prevSelected.filter(
          (country) => country.id !== selectedCountry.id
        );
      } else {
        // If the country is not selected, add it to the selected list
        return [...prevSelected, selectedCountry];
      }
    });
  };

  const DeleteSelectFile = (id) => {
    if (window.confirm("Are you sure you want to delete this Image?")) {
      const result = selectedfile.filter((data) => data.id !== id);
      SetSelectedFile(result);
    } else {
      // alert('No');
    }
  };

  const FileUploadSubmit = async (e) => {
    setImageUploading(true);
    window.scrollTo(0, 0);
    document.body.classList.toggle("stop-scrolling");

    e.preventDefault();
    const formData = new FormData();
    selectedfile.forEach((file, index) => {
      const fileData = {
        title: file.title,
        eventTitle: eventTitle,
        eventDate: eventDate,
        imageId: file.id,
        imageName: file.filename,
        countryIds: selectedCountries.map((data) => data.id),
      };
      formData.append(`metadata`, JSON.stringify(fileData));
      formData.append(`files`, file.fileObj);
    });

    axiosInstance
      .post("/gallery/upload/images", formData)
      .then((res) => {
        if (res.status === 200) {
          setNotificationData(
            res.data
              .split("\n")
              .filter((item) => Boolean(item))
              .map((item) => {
                return {
                  msg: item,
                  status: res.status,
                };
              })
          );
        } else {
          setNotificationData([
            {
              msg: res.data.message,
              status: res.status,
            },
          ]);
        }
        refresh();
        setImageUploading(false);
        document.body.classList.toggle("stop-scrolling");
      })
      .catch((err) => {
        setImageUploading(false);
        document.body.classList.toggle("stop-scrolling");
      });

    // form reset on submit
    e.target.reset();
    if (selectedfile.length > 0) {
      for (let index = 0; index < selectedfile.length; index++) {
        SetFiles((preValue) => {
          return [...preValue, selectedfile[index]];
        });
      }
      SetSelectedFile([]);
    } else {
      alert("Please select file");
    }
  };

  const DeleteFile = async (id) => {
    if (window.confirm("Are you sure you want to delete this Image?")) {
      const result = Files.filter((data) => data.id !== id);
      SetFiles(result);
    } else {
      // alert('No');
    }
  };

  return (
    <div id="gallery-file-upload">
      <SyncLoader
        className="backdrop-loader"
        color="#36d7b7"
        size={20}
        loading={imageUploading}
      />

      <Notification
        notificationData={notificationData}
        setNotificationData={setNotificationData}
      />
      <div className="fileupload-view">
        <div className="row justify-content-center m-0">
          <div className="col-md-12">
            <div className="card mt-5">
              <div className="card-body">
                <div className="kb-data-box">
                  <div className="kb-modal-data-title">
                    <div className="kb-data-title">
                      <h6>Multiple File Upload With Preview</h6>
                    </div>
                  </div>
                  <form onSubmit={FileUploadSubmit}>
                    <div className="kb-file-upload">
                      <div className="file-upload-box">
                        <input
                          type="file"
                          id="fileupload"
                          className="file-upload-input"
                          onChange={InputChange}
                          multiple
                          accept=".jpg, .jpeg, .png"
                        />
                        <span>
                          Drag and drop or{" "}
                          <span className="file-link">Choose your files</span>
                        </span>
                      </div>
                    </div>
                    <div className="kb-attach-box mb-3">
                      {selectedfile.map((data, index) => {
                        const {
                          id,
                          filename,
                          filetype,
                          fileimage,
                          datetime,
                          filesize,
                        } = data;
                        return (
                          <div className="file-atc-box" key={id}>
                            {filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ? (
                              <div className="file-image">
                                {" "}
                                <img loading="lazy" src={fileimage} alt="" />
                              </div>
                            ) : (
                              <div className="file-image">
                                <i className="far fa-file-alt"></i>
                              </div>
                            )}
                            <div className="file-detail">
                              <input
                                type="text"
                                name=""
                                value={data.title}
                                placeholder="Title"
                                onChange={(e) => handleTitleChange(e, id)}
                                style={{
                                  height: "1.5em",
                                  width: "70%",
                                  fontSize: "small",
                                }}
                              />
                              <h6>{filename}</h6>
                              <p></p>
                              <p>
                                <span>Size : {filesize}</span>{" "}
                                <span className="ml-2">
                                  Modified Time : {datetime}
                                </span>
                              </p>
                              <div className="file-actions">
                                <button
                                  type="button"
                                  className="file-action-btn"
                                  onClick={() => DeleteSelectFile(id)}
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="kb-buttons-box">
                      <InputGroup style={{ width: "fit-content" }}>
                        <DropdownButton
                          variant="outline-secondary"
                          title={"Select Country"}
                          id="input-group-dropdown-1"
                          style={{
                            padding: "13px 30px",
                          }}
                        >
                          <Form.Check
                            type="checkbox"
                            label="Select All"
                            style={{ margin: "0px 20px" }}
                            checked={
                              selectedCountries.length === countries.length
                            }
                            onChange={() => {
                              setSelectedCountries(
                                selectedCountries.length === countries.length
                                  ? []
                                  : countries
                              );
                            }}
                          />
                          {countries.map((country, index) => (
                            <Form.Check
                              type="checkbox"
                              label={country.name}
                              style={{ margin: "0px 20px" }}
                              checked={selectedCountries.some(
                                (selected) => selected?.id === country?.id
                              )}
                              onChange={(e) => {
                                handleDropdownSelect(e, country);
                              }}
                              key={index}
                            />
                          ))}
                        </DropdownButton>
                      </InputGroup>
                      <input
                        type="text"
                        name=""
                        value={eventTitle}
                        onChange={(e) => setEventTitle(e.target.value)}
                        placeholder="Event Title"
                        style={{
                          width: "20em",
                          padding: "13px 30px",
                        }}
                      />
                      <input
                        style={{
                          padding: "13px 30px",
                        }}
                        type="date"
                        name=""
                        onChange={(e) => setEventDate(e.target.value)}
                        max={new Date().toISOString().split("T")[0]}
                      />{" "}
                      <button
                        type="submit"
                        className="btn btn-primary form-submit"
                      >
                        Upload
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GalleryFileUpload;
