import { useState, useEffect } from "react";
import "./Pagination.css";
const Pagination = ({ setPagination, totalRecords, doNotShowItemsPerPage }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    setPagination({
      offset: (currentPage - 1) * itemsPerPage,
      limit: itemsPerPage,
    });
  }, [currentPage, itemsPerPage]);

  const handleNextPage = () => {
    const maxPage = Math.ceil(totalRecords / itemsPerPage);
    if (currentPage + 1 <= maxPage) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage - 1 > 0) setCurrentPage(currentPage - 1);
  };

  return (
    <ul className="pagination">
      {!doNotShowItemsPerPage && (
        <>
          <p style={{ margin: "0px", marginRight: "1em" }}>Items per page:</p>
          <select
            className="dropdown-page"
            id="dropdown-page"
            value={itemsPerPage}
            onChange={(e) => {
              setItemsPerPage(Number(e.target.value));
              setCurrentPage(1);
            }}
          >
            {[10, 25, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </>
      )}
      <p style={{ margin: "0em 1em" }}>
        {itemsPerPage * (currentPage - 1) +
          1 +
          " - " +
          (totalRecords < itemsPerPage * (currentPage - 1) + itemsPerPage
            ? totalRecords
            : itemsPerPage * (currentPage - 1) + itemsPerPage) +
          " of " +
          totalRecords}
      </p>
      <li
        className="page-item"
        onClick={() => handlePrevPage()}
        // data-disabled={!canPreviousPage}
      >
        <a className="page-link">{"<"}</a>
      </li>
      <li
        className="page-item"
        onClick={() => handleNextPage()}
        // data-disabled={!canNextPage}
      >
        <a className="page-link">{">"}</a>
      </li>{" "}
    </ul>
  );
};

export default Pagination;
