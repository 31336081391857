import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDEZo30bAf2XEzeEsnRMV1DCW7gfdAsg3s",
  authDomain: "ovochub.firebaseapp.com",
  projectId: "ovochub",
  storageBucket: "ovochub.appspot.com",
  messagingSenderId: "13781609999",
  appId: "1:13781609999:web:f1704137f76351d8ab7d7a",
  measurementId: "G-WRTY6TJXZD",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
