import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Pagination from "./Pagination";
import Table from "react-bootstrap/Table";
import axiosInstance from "../utility/axios";
import { formatDate } from "../utility/utils";
import { useLoading } from "../utility/LoadingContext";
import Notification from "./Notification";

const CampaignUserData = ({ showCampaignData, setShowCampaignData }) => {
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [usersList, setUsersList] = useState([]);
  const { setLoading } = useLoading();
  const [notificationData, setNotificationData] = useState([]);

  const downloadList = async () => {
    setLoading(true);
    await axiosInstance
      .get(`/campaign/public/downloadList/${showCampaignData.id}`, {
        responseType: "blob",
      })
      .then((res) => {
        if (res.status === 200) {
          const url = URL.createObjectURL(res.data);
          const a = document.createElement("a");
          a.href = url;
          a.download = "User List.pdf";
          a.click();
          URL.revokeObjectURL(url);
        }
        setNotificationData([
          {
            msg: res?.statusText,
            status: res.status,
          },
        ]);
      });
    setLoading(false);
  };
  const fetchCampaignUserData = () => {
    if (showCampaignData.show)
      axiosInstance
        .get(
          `/campaign/sign/data/${showCampaignData.id}?offset=${pagination.offset}&limit=${pagination.limit}`
        )
        .then((res) => {
          if (res.status === 200) {
            setTotalRecords(res.data.count);
            setUsersList(res.data.users);
          }
        });
  };

  useEffect(() => {
    fetchCampaignUserData();
  }, []);

  useEffect(() => {
    fetchCampaignUserData();
  }, [pagination]);

  return (
    <>
      <Notification
        notificationData={notificationData}
        setNotificationData={setNotificationData}
      />
      <Modal
        show={showCampaignData.show}
        onHide={() =>
          setShowCampaignData({
            show: false,
            id: undefined,
            info: undefined,
          })
        }
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{showCampaignData?.info?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-4" style={{ textAlign: "center" }}>
              <h4>Target: {showCampaignData?.info?.target}</h4>
            </div>
            <div className="col-4" style={{ textAlign: "center" }}>
              <h4>Achieved: {showCampaignData?.info?.count}</h4>
            </div>
            <div
              className="col-4"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <button
                style={{ marginBottom: "0.5rem", padding: "0px 10px" }}
                onClick={() => {
                  downloadList();
                }}
              >
                Download
              </button>
            </div>
          </div>
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th className="table-head">#</th>
                <th className="table-head">Name</th>
                <th className="table-head">Email</th>
                <th className="table-head">Country</th>
                <th className="table-head">Signing Date</th>
              </tr>
            </thead>
            <tbody>
              {usersList?.map((u, i) => (
                <tr key={i}>
                  <td>{pagination.offset + i + 1}</td>
                  <td>{u.name}</td>
                  <td>{u.email}</td>
                  <td>{u.countryName || "Other"}</td>
                  <td>{formatDate(u.date)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            setPagination={setPagination}
            totalRecords={totalRecords}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CampaignUserData;
