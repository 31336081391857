import "bootstrap/dist/css/bootstrap.min.css";
import axiosInstance from "../utility/axios";
import { useEffect, useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import DropdownButton from "react-bootstrap/DropdownButton";
import Badge from "react-bootstrap/Badge";
import Dropdown from "react-bootstrap/Dropdown";
import VideoPreview from "../components/VideoPreview";
import Table from "react-bootstrap/Table";
import Pagination from "../components/Pagination";
import EditVideos from "../components/EditVideos";
import DeleteConfirmation from "../components/DeleteModal";
import DeactivateConfirmation from "../components/DeleteModal";
import Notification from "../components/Notification";
import { getStorage, ref, deleteObject } from "firebase/storage";
import Compressor from "compressorjs";
import { userContext } from "./Context";
import NoDataPresent from "../components/NoDataPresent";
import { auth } from "../firebase-config";
import { signInWithCustomToken, signOut } from "firebase/auth";

const video_category = ["Knowledge", "Global", "Local", "General"];
const Videos = () => {
  const { user } = useContext(userContext);
  const [fileError, setFileError] = useState("");
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [videosList, setVideosList] = useState([]);
  const [state, setState] = useState();
  const [editVideos, setEditVideos] = useState("");
  const [showVideos, setShowVideos] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [itemId, setItemId] = useState(-1);
  const [form, setForm] = useState({
    id: "",
    title: "",
    date: "",
    comments: [],
    description: "",
    videoFile: "",
    videoFileURL: "",
    videoFileName: "",
    videoFileExt: "",
    videoFileId: "",
    countryIds: [],
    category: "",
    publishStage: "",
    isExternal: false,
    thumbnail: "",
  });
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState(undefined);
  const [countries, setCountries] = useState([]);
  const [category, setCategory] = useState(undefined);

  const [msg, setMsg] = useState({
    message: [],
    variant: "danger",
  });

  const [notificationData, setNotificationData] = useState([]);
  const [showDeactivateConfirmation, setShowDeactivateConfirmation] =
    useState(false);
  const [deactivateId, setDeactivateId] = useState(undefined);

  useEffect(() => {
    axiosInstance.get("/user/country/list").then((res) => {
      setCountries(res.data);
    });
  }, []);

  const handleChange = (e) => {
    if (e.target.name === "isExternal") {
      if (e.target.value) {
        setForm({ ...form, content: "", isExternal: true });
      } else {
        setForm({ ...form, newsLink: "", isExternal: false });
      }
    } else {
      setForm({ ...form, [e.target.name]: e.target.value });
    }
  };
  const handleVideoChange = async (e, uploadVideoToFirebase) => {
    const file = e.target.files[0];
    if (file && file.type.match("video.*") && file.size <= 10 * 1024 * 1024) {
      const videoFileName = file.name;
      const videoFileId = `${Date.now()}_${file.name}`;
      const uploadUrl = `public/videos/${videoFileId}`;
      const token = await axiosInstance.post("/site/generate-signed-url");

      if (token.status !== 200) {
        setNotificationData([
          {
            msg: "Video upload failed",
            status: 500,
          },
        ]);
        return;
      }

      const user = await signInWithCustomToken(auth, token.data.url);

      const videoFileExt = videoFileName.substring(
        videoFileName.lastIndexOf(".")
      );
      const videoUrl = await uploadVideoToFirebase(e, uploadUrl);

      setForm((form) => {
        return {
          ...form,
          [e.target.name]: file,
          videoFileURL: videoUrl,
          videoFileId,
          videoFileName,
          videoFileExt,
        };
      });
      setFileError({ ...fileError, [e.target.name]: "" });
    } else {
      setFileError({
        ...fileError,
        [e.target.name]: "Video can have max size of 10MB",
      });
    }
  };

  const removeSelectedFile = async (name) => {
    const fileInput = document.getElementById(name);
    fileInput.value = "";
    if ((form[name] && typeof form[name] !== "string") || form.videoFileId) {
      const storage = getStorage();
      const storageRef = ref(storage, `public/videos/${form.videoFileId}`);
      const token = await axiosInstance.post("/site/generate-signed-url");

      if (token.status !== 200) {
        setNotificationData([
          {
            msg: "Video upload failed",
            status: 500,
          },
        ]);
        return;
      }

      const user = await signInWithCustomToken(auth, token.data.url);

      try {
        await deleteObject(storageRef);
        // console.log("File deleted from Firebase Storage");
      } catch (error) {
        console.error("Error deleting file from Firebase Storage:", error);
      }
      signOut(auth);
    }

    setUploadProgress(0);
    setFileError("");
    setNotificationData([
      {
        msg: "Video file removed",
        status: 200,
      },
    ]);
    setForm({
      ...form,
      [name]: "",
      videoFileId: "",
      videoFileURL: "",
      videoFileName: "",
      videoFileExt: "",
    });
    setFileError({ ...fileError, [name]: "" });
  };

  const updateTable = () => {
    let query = `search=${search}&offset=${pagination.offset}&limit=${pagination.limit}`;
    if (user.role < 3 && selectedCountry) {
      query += `&country_id=${selectedCountry.id}`;
    } else if (user.role >= 3) {
      query += `&country_id=${user.countryId}`;
    }
    if (category) query += `&category=${category}`;
    axiosInstance
      .get(`/videos/get/all?${query}`)
      .then((res) => {
        if (res.data.videos?.length && typeof res.data.videos === "object") {
          setVideosList([...res.data.videos]);
          setTotalRecords(res.data.count || 0);
        } else {
          setVideosList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type.match("image.*") && file.size <= 5000000) {
        setForm({ ...form, [e.target.name]: file });
        setFileError({ ...fileError, [e.target.name]: "" });
        try {
          const compressedBlob = await new Promise((resolve, reject) => {
            new Compressor(file, {
              quality: 0.6,
              success(result) {
                resolve(result);
              },
              error(err) {
                reject(err);
              },
            });
          });

          const compressedFile = new File([compressedBlob], file.name, {
            type: file.type,
          });
          setForm({ ...form, [e.target.name]: compressedFile });
        } catch (error) {
          console.log(error.message);
        }
      } else {
        setFileError({
          ...fileError,
          [e.target.name]: "File can have max size of 5MB",
        });
      }
    }
  };

  useEffect(() => {
    updateTable();
  }, [selectedCountry, category, pagination]);

  const handleDelete = (id) => {
    axiosInstance
      .delete(`/videos/draft/${id}?indicator=2`)
      .then(async (response) => {
        if (response.status === 200) {
          updateTable();
        } else {
          // TODO: alert
        }
        setNotificationData([
          {
            msg: response.data.message,
            status: response.status,
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
    setShowModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("title", form.title);
    formData.append("date", form.date);
    formData.append("videoFile", form.videoFile);
    formData.append("videoFileId", form.videoFileId);
    formData.append("videoFileName", form.videoFileName);
    formData.append("videoFileURL", form.videoFileURL);
    formData.append("videoFileExt", form.videoFileExt);
    formData.append("description", form.description);
    formData.append("category", form.category);
    formData.append(
      "countryIds",
      JSON.stringify(form.countryIds.map((country) => country.id))
    );
    formData.append("isExternal", form.isExternal);
    formData.append("thumbnail", form.thumbnail);
    // update
    if (form.id) {
      axiosInstance
        .patch(`/videos/update/${form.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setEditVideos("");
            updateTable();
            setShowVideos(false);
            setMsg({
              message: [],
              variant: "danger",
            });
          } else {
            if (response.data.message === "Validation error") {
              setMsg({
                message: response.data.error?.details?.map(
                  (err) => err.message
                ),
                variant: "danger",
              });
            } else if (response.data.message === "DB Error") {
              setMsg({ message: [...response.data.error], variant: "danger" });
            } else {
              setMsg({ message: [response.data.message], variant: "danger" });
            }
          }
          setNotificationData([
            {
              msg: response.data.message,
              status: response.status,
            },
          ]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    //create
    else {
      axiosInstance
        .post(`/videos/create`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setEditVideos("");
            setShowVideos(false);
            updateTable();
            setMsg({
              message: [],
              variant: "danger",
            });
          } else {
            if (response.data.message === "Validation error") {
              setMsg({
                message: response.data.error?.details?.map(
                  (err) => err.message
                ),
                variant: "danger",
              });
            } else if (response.data.message === "DB Error") {
              setMsg({ message: [...response.data.error], variant: "danger" });
            } else {
              setMsg({ message: [response.data.message], variant: "danger" });
            }
          }
          setNotificationData([
            {
              msg: response.data.message,
              status: response.status,
            },
          ]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const PreviewModal = () => {
    return (
      <Modal show={show} onHide={() => setShow(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Video Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal_body">
          <VideoPreview form={form} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const fetchData = (id) => {
    axiosInstance
      .get(`/videos/get/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setForm({
            id: res.data.video.id,
            title: res.data.video.title,
            date: res.data.video.date,
            description: res.data.video.description,
            videoFile: "",
            videoFileURL: res.data.video.videoFileURL,
            videoFileExt: res.data.video.videoFileExt,
            videoFileId: res.data.video.videoFileId,
            videoFileName: res.data.video.videoFileName,
            isExternal: res.data.video.isExternal,
            countryIds: res.data.video.countries,
            comments: res.data.video.comments,
            category: res.data.video.category,
            authorId: res.data.video.authorId,
            publishStage: res.data.video.publishStage,
            thumbnail: res.data.video.thumbnail,
          });
        } else {
          setEditVideos("");
          setUploadProgress(0);
          setFileError("");
          setForm({
            id: "",
            title: "",
            date: "",
            comments: [],
            description: "",
            videoFile: "",
            videoFileURL: "",
            videoFileExt: "",
            videoFileId: "",
            videoFileName: "",
            isExternal: false,
            countryIds: [],
            caregory: "",
            publishStage: undefined,
            thumbnail: "",
          });
          setShowVideos(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (editVideos) fetchData(editVideos);
  }, [showVideos]);

  useEffect(() => {
    updateTable();
    return () => {
      setState({});
    };
  }, []);

  const deactivateVideo = (id, indicator) => {
    axiosInstance
      .delete(`/videos/draft/${id}?indicator=${indicator}`)
      .then((res) => {
        setNotificationData([
          {
            msg: res.data.message,
            status: res.status,
          },
        ]);
        updateTable();
      });
  };

  const showPreview = (id) => {
    fetchData(id);
    setShow(true);
  };
  useEffect(() => {
    if (deactivateId) {
      setShowDeactivateConfirmation(true);
    }
  }, [deactivateId]);

  return (
    <>
      <div className="user-content mt-4">
        <DeactivateConfirmation
          showModal={showDeactivateConfirmation}
          hideModal={() => {
            setShowDeactivateConfirmation(false);
            setDeactivateId(undefined);
          }}
          confirmModal={() => {
            deactivateVideo(
              deactivateId?.id,
              deactivateId?.active_indicator === 1 ? 0 : 1
            );
            setDeactivateId(undefined);
            setShowDeactivateConfirmation(false);
          }}
          message={`Are you sure you want to ${
            deactivateId?.active_indicator === 1 ? "Deactivate" : "Activate"
          } ${deactivateId?.title}?`}
          btnMsg={
            deactivateId?.active_indicator === 1 ? "Deactivate" : "Activate"
          }
        />
        <DeleteConfirmation
          showModal={showModal}
          hideModal={() => setShowModal(false)}
          confirmModal={() => {
            handleDelete(itemId.id);
            setShowModal(false);
          }}
          message={`Are you sure you want to delete ${itemId?.title}?`}
        />
        <PreviewModal />
        <Notification
          notificationData={notificationData}
          setNotificationData={setNotificationData}
        />
        {showVideos ? (
          <EditVideos
            form={form}
            setForm={setForm}
            setShow={setShow}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            handleVideoChange={handleVideoChange}
            setShowVideos={setShowVideos}
            setEditVideos={setEditVideos}
            handleImageChange={handleImageChange}
            removeSelectedFile={removeSelectedFile}
            video_category={video_category}
            fileError={fileError}
            msg={msg}
            setMsg={setMsg}
            setNotificationData={setNotificationData}
            updateTable={updateTable}
            uploadProgress={uploadProgress}
            setUploadProgress={setUploadProgress}
          />
        ) : (
          <>
            <div
              className="input-group mb-4 table-filters"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div style={{ display: "flex" }}>
                <input
                  type="search"
                  placeholder="Search by name"
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  aria-describedby="button-addon5"
                  className="form-control"
                  style={{ borderRadius: ".375rem 0px 0px .375rem" }}
                />
                <div className="input-group-append">
                  <button
                    id="button-addon5"
                    type="button"
                    onClick={updateTable}
                    className="btn btn-primary color3863A0"
                    style={{
                      borderRadius: "2px",
                      height: "100%",
                    }}
                  >
                    <i className="bx bx-search"></i>
                  </button>
                </div>
                {user.role < 3 && (
                  <InputGroup className="mx-3" style={{ width: "fit-content" }}>
                    <DropdownButton
                      variant="outline-secondary"
                      title={
                        selectedCountry
                          ? selectedCountry["name"]
                          : "Select Country"
                      }
                      id="input-group-dropdown-1"
                      onSelect={(e) => {
                        setSelectedCountry(JSON.parse(e));
                      }}
                    >
                      <Dropdown.Item eventKey={undefined}>
                        Select None
                      </Dropdown.Item>
                      {countries.map((country, index) => (
                        <Dropdown.Item
                          eventKey={JSON.stringify(country)}
                          key={index}
                        >
                          {country.name}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </InputGroup>
                )}
                <InputGroup className="mx-3" style={{ width: "fit-content" }}>
                  <DropdownButton
                    variant="outline-secondary"
                    title={category ? category : "Select Category"}
                    id="input-group-dropdown-1"
                    onSelect={(e) => {
                      setCategory(e);
                    }}
                  >
                    <Dropdown.Item eventKey={undefined}>
                      Select None
                    </Dropdown.Item>
                    {video_category.map((videoCategory, index) => (
                      <Dropdown.Item eventKey={videoCategory} key={index}>
                        {videoCategory}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </InputGroup>
              </div>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  setUploadProgress(0);
                  setFileError("");
                  setShowVideos(true);
                  setForm({
                    id: undefined,
                    title: "",
                    date: "",
                    comments: [],
                    description: "",
                    videoFile: "",
                    videoFileURL: "",
                    videoFileExt: "",
                    videoFileId: "",
                    isExternal: false,
                    countryIds: [],
                    caregory: "",
                    publishStage: undefined,
                    thumbnail: "",
                  });
                }}
              >
                Create Video
              </button>
            </div>
            {videosList.length === 0 ? (
              <NoDataPresent />
            ) : (
              <>
                <Table striped bordered responsive>
                  <thead>
                    <tr>
                      <th className="table-head">#</th>
                      <th className="table-head">Name</th>
                      <th className="table-head">Created By</th>
                      <th className="table-head"></th>
                      <th className="table-head">View</th>
                      <th className="table-head">Edit</th>
                      <th className="table-head">Draft</th>
                      <th className="table-head">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {videosList.map((video, index) => (
                      <tr key={index}>
                        <td>{pagination.offset + index + 1}</td>
                        <td>{video.title}</td>
                        <td>{video.createdBy}</td>
                        <td>
                          <Badge bg="light" text="dark">
                            {video.category}
                          </Badge>
                        </td>
                        <td>
                          <i
                            className="fa-solid fa-box-open fa-1x"
                            onClick={() => showPreview(video.id)}
                          ></i>
                        </td>
                        <td>
                          <i
                            className="fa-solid fa-pen-to-square fa-1x"
                            onClick={() => {
                              setShowVideos(true);
                              setEditVideos(video.id);
                            }}
                          ></i>
                        </td>
                        <td>
                          <i
                            className={`fa-solid fa-lock${
                              video.active_indicator === 1 ? "-open" : ""
                            } fa-1x`}
                            onClick={() => setDeactivateId(video)}
                          ></i>
                        </td>
                        <td>
                          <i
                            className="fa-solid fa-trash-can fa-1x"
                            onClick={() => {
                              setItemId(video);
                              setShowModal(true);
                            }}
                          ></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination
                  setPagination={setPagination}
                  totalRecords={totalRecords}
                />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Videos;
