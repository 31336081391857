import { React, useState, useEffect, useContext } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { Tabs, Tab } from "react-bootstrap";
import axiosInstance from "../utility/axios";
import Notification from "../components/Notification";
import { userContext } from "../pages/Context";
import { useLanguage } from "../utility/LanguageContext";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const faqStyles = {
  container: {
    backgroundColor: "#f4f4f4",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    marginBottom: "10px",
  },
  questionContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  question: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#333",
  },
  buttons: {
    display: "flex",
    gap: "10px",
  },
  button: {
    backgroundColor: "#333",
    padding: "3px 9px",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  answer: {
    fontSize: "16px",
    color: "#555",
  },
};

const Content = ({ lang }) => {
  const [notificationData, setNotificationData] = useState([]);
  const [FAQs, setFAQs] = useState({
    en: [],
    bangla: [],
    nap: [],
    thai: [],
  });
  const [editMode, setEditMode] = useState({});
  const [form, setForm] = useState({
    question: "",
    answer: "",
  });
  const [showForm, setShowForm] = useState(false);
  const [showButton, setShowButton] = useState(true);

  const handleEditClick = (lang, index) => {
    setEditMode((prev) => ({
      ...prev,
      [lang]: {
        ...prev[lang],
        [index]: !prev[lang]?.[index],
      },
    }));
  };

  const handleQuestionChange = (lang, index, e) => {
    setFAQs((prevFAQs) => {
      const updatedFAQs = { ...prevFAQs };
      if (updatedFAQs[lang] && updatedFAQs[lang][index]) {
        updatedFAQs[lang][index].question = e.target.value;
      }
      return updatedFAQs;
    });
  };

  const handleAnswerChange = (event, lang, index, editor) => {
    setFAQs((prevFAQs) => {
      const updatedFAQs = { ...prevFAQs };
      if (updatedFAQs[lang] && updatedFAQs[lang][index]) {
        updatedFAQs[lang][index].answer = editor.getData();
      }
      return updatedFAQs;
    });
  };

  const handleCreateFAQ = (lang) => {
    const question = form.question;
    const answer = form.answer;
    axiosInstance
      .post(`/siteData/createFAQ`, { lang, question, answer })
      .then((response) => {
        if (response.status === 200) {
          getFAQ();
          setShowForm(false);
        } else {
          // TODO
        }
        setNotificationData([
          {
            msg: response.data.message,
            status: response.status,
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpdateFAQ = (lang, index) => {
    const value = FAQs[lang][index];
    axiosInstance
      .post(`/siteData/updateFAQ`, {
        lang: lang,
        id: index,
        value: value,
      })
      .then((response) => {
        if (response.status === 200) {
          getFAQ();
          setEditMode((prev) => ({
            ...prev,
            [lang]: {
              ...prev[lang],
              [index]: false,
            },
          }));
        } else {
          // TODO
        }
        setNotificationData([
          {
            msg: response.data.message,
            status: response.status,
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteFAQ = (lang, index) => {
    const value = FAQs[lang][index];
    axiosInstance
      .post(`/siteData/deleteFAQ`, {
        lang: lang,
        id: index,
      })
      .then((response) => {
        if (response.status === 200) {
          getFAQ();
        } else {
          // TODO
        }
        setNotificationData([
          {
            msg: response.data.message,
            status: response.status,
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFAQ = () => {
    axiosInstance
      .get(`/siteData/getFAQ`)
      .then((response) => {
        if (response.status === 200) {
          setFAQs({ ...response.data?.response });
        } else {
          // TODO
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFormChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormAnswerChange = (event, editor) => {
    setForm({
      ...form,
      answer: editor.getData(),
    });
  };

  useEffect(() => {
    if (form.question && form.answer) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, [form]);

  useEffect(() => {
    setForm({
      question: "",
      answer: "",
    });
  }, [showForm]);

  useEffect(() => {
    getFAQ();
  }, []);

  return (
    <>
      <Notification
        notificationData={notificationData}
        setNotificationData={setNotificationData}
      />
      <Modal show={showForm} onHide={() => setShowForm(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create FAQ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Question</Form.Label>
              <Form.Control
                type="text"
                name="question"
                value={form.question}
                onChange={handleFormChange}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Description</Form.Label>
              <CKEditor
                editor={Editor}
                data={form.answer}
                onChange={(event, editor) => {
                  handleFormAnswerChange(event, editor);
                }}
                onReady={(editor) => {
                  editor.ui.view.toolbar.items.get(
                    11
                  ).buttonView.isVisible = false;
                  // editor.ui.view.toolbar.items.get(16).buttonView.isVisible = false;
                  editor.ui.view.toolbar.items.get(31).isVisible = false;
                  editor.ui.view.toolbar.items.get(
                    32
                  ).buttonView.isVisible = false;
                  editor.ui.view.toolbar.items.get(
                    33
                  ).buttonView.isVisible = false;
                  editor.ui.view.toolbar.items.get(
                    34
                  ).buttonView.isVisible = false;
                  editor.ui.view.toolbar.items.get(
                    35
                  ).buttonView.isVisible = false;
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowForm(false)}>
            Close
          </Button>
          {showButton ? (
            <Button onClick={() => handleCreateFAQ(lang)}>Create</Button>
          ) : (
            <Button disabled>Create</Button>
          )}
        </Modal.Footer>
      </Modal>
      {lang && (
        <main className="about-us-dashboard">
          <div
            className="something-do mt-0"
            style={{ height: "300px", display: "flex", alignItems: "center" }}
          >
            <div className="container">
              <div className="amazing-do">
                <h2>FAQ's</h2>
              </div>
            </div>
          </div>
          <div className="container mb-0 pb-1">
            <div className="about-caption-top" style={{ textAlign: "justify" }}>
              <div
                className="mb-3"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <div className="actions edit-btn mt-2">
                  <button
                    id="edit"
                    className="lx-btn"
                    onClick={() => setShowForm(true)}
                    style={{ backgroundColor: "green" }}
                  >
                    <i
                      className="fa-solid fa-plus 2x"
                      style={{ fontSize: "20px" }}
                    ></i>
                    &nbsp;&nbsp;Add
                  </button>
                </div>
              </div>
              <div style={faqStyles.container}>
                {FAQs?.[lang]?.length > 0 &&
                  FAQs[lang].map((item, index) => (
                    <div key={index} className="mb-4">
                      {editMode[lang]?.[index] ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                          }}
                        >
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Control
                              type="text"
                              placeholder="Enter Question"
                              value={item.question}
                              onChange={(e) =>
                                handleQuestionChange(lang, index, e)
                              }
                            />
                          </Form.Group>
                          <div style={faqStyles.buttons}>
                            <button
                              onClick={() => handleEditClick(lang, index)}
                              style={faqStyles.button}
                            >
                              Preview
                            </button>
                            <button
                              onClick={() => handleUpdateFAQ(lang, index)}
                              style={faqStyles.button}
                            >
                              Save
                            </button>
                            <button
                              onClick={() => handleDeleteFAQ(lang, index)}
                              style={faqStyles.button}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div style={faqStyles.questionContainer}>
                            <div style={faqStyles.question}>
                              {index + 1 + ". " + item.question}
                            </div>
                            <div style={faqStyles.buttons}>
                              <button
                                onClick={() => handleEditClick(lang, index)}
                                style={faqStyles.button}
                              >
                                Edit
                              </button>
                              <button
                                onClick={() => handleUpdateFAQ(lang, index)}
                                style={faqStyles.button}
                              >
                                Save
                              </button>
                              <button
                                onClick={() => handleDeleteFAQ(lang, index)}
                                style={faqStyles.button}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </>
                      )}

                      {editMode[lang]?.[index] ? (
                        <CKEditor
                          editor={Editor}
                          data={item.answer}
                          onChange={(event, editor) => {
                            handleAnswerChange(event, lang, index, editor);
                          }}
                          onReady={(editor) => {
                            editor.ui.view.toolbar.items.get(
                              11
                            ).buttonView.isVisible = false;
                            // editor.ui.view.toolbar.items.get(16).buttonView.isVisible = false;
                            editor.ui.view.toolbar.items.get(
                              31
                            ).isVisible = false;
                            editor.ui.view.toolbar.items.get(
                              32
                            ).buttonView.isVisible = false;
                            editor.ui.view.toolbar.items.get(
                              33
                            ).buttonView.isVisible = false;
                            editor.ui.view.toolbar.items.get(
                              34
                            ).buttonView.isVisible = false;
                            editor.ui.view.toolbar.items.get(
                              35
                            ).buttonView.isVisible = false;
                          }}
                        />
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.answer,
                          }}
                          className="ck-content"
                        ></div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
};

const FAQ = () => {
  const { user } = useContext(userContext);
  const { countryMap } = useLanguage();
  const [lang, setLang] = useState(undefined);
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    axiosInstance.get("/user/country/list").then((res) => {
      setCountries(res.data);
    });
  }, []);
  useEffect(() => {
    setLang(user.role < 3 ? "en" : countryMap[user?.countryId]);
  }, [user, countryMap]);

  return (
    <section className="user-section" id="user-section">
      <div className="user-content">
        <Tabs
          defaultActiveKey={user.role < 3 ? "en" : countryMap[user.countryId]}
          id="about-tabs"
          className="mb-3"
          onSelect={(k) => setLang(k)}
        >
          {countries?.map((country, index) => {
            if (user.role < 3 || user.countryId === country.id) {
              return (
                <Tab
                  eventKey={countryMap[country.id]}
                  title={country.name}
                  key={index}
                >
                  <Content lang={lang} />
                </Tab>
              );
            }
          })}
        </Tabs>
      </div>
    </section>
  );
};

export default FAQ;
