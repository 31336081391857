import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Pagination from "./Pagination";
import Table from "react-bootstrap/Table";
import axiosInstance from "../utility/axios";
import { formatDate } from "../utility/utils";

const EventUserData = ({ showEventData, setShowEventData }) => {
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [usersList, setUsersList] = useState([]);

  const fetchEventUserData = () => {
    if (showEventData.show)
      axiosInstance
        .get(
          `/event/sign/data/${showEventData.id}?offset=${pagination.offset}&limit=${pagination.limit}`
        )
        .then((res) => {
          if (res.status === 200) {
            setTotalRecords(res.data.count);
            setUsersList(res.data.users);
          }
        });
  };

  useEffect(() => {
    fetchEventUserData();
  }, []);

  useEffect(() => {
    fetchEventUserData();
  }, [pagination]);

  return (
    <Modal
      show={showEventData.show}
      onHide={() =>
        setShowEventData({
          show: false,
          id: undefined,
          info: undefined,
        })
      }
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{showEventData?.info?.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th className="table-head">#</th>
              <th className="table-head">Name</th>
              <th className="table-head">Email</th>
              <th className="table-head">Country</th>
              <th className="table-head">Registration Date</th>
              <th className="table-head">Interest</th>
            </tr>
          </thead>
          <tbody>
            {usersList?.map((u, i) => (
              <tr key={i}>
                <td>{pagination.offset + i + 1}</td>
                <td>{u.name}</td>
                <td>{u.email}</td>
                <td>{u.countryName || "Other"}</td>
                <td>{u.date && formatDate(u.date)}</td>
                <td>{u.YesMaybe}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination setPagination={setPagination} totalRecords={totalRecords} />
      </Modal.Body>
    </Modal>
  );
};

export default EventUserData;
