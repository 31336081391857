import { React } from "react";
import Bacs from "./Bacs";
import Docs from "./Docs";
import Courses from "./Courses";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const LearningNResource = () => {
  return (
    <section className="user-section" id="user-section">
      <div className="user-content">
        <Tabs defaultActiveKey="courses" id="about-tabs" className="mb-3">
          <Tab eventKey="courses" title="Online Courses">
            <Courses />
          </Tab>
          <Tab eventKey="bacs" title="Blogs/Articles/Case Stories">
            <Bacs />
          </Tab>
          <Tab
            eventKey="dtr"
            title="Research & Advocacy Documents/Tools/Reports"
          >
            <Docs />
          </Tab>
        </Tabs>
      </div>
    </section>
  );
};

export default LearningNResource;
