import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import Notification from "../components/Notification";
import axiosInstance from "../utility/axios";
import { userContext } from "./Context";
import Pagination from "../components/Pagination";
import TrashEventWebPreview from "../components/TrashEventWebPreview";
import DeleteConfirmation from "../components/DeleteModal";
import NoDataPresent from "../components/NoDataPresent";
import TrashNote from "../components/TrashNote";

export default function TrashEventWebinar() {
  const [notificationData, setNotificationData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [allEventWebinars, setAllEventWebinars] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const [eventWebinar, setEventWebinar] = useState({});
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
  });
  const user = useContext(userContext);

  const formatDateAndTime = (dateString) => {
    const dateObject = new Date(dateString);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const month = months[dateObject.getUTCMonth()];
    const day = dateObject.getUTCDate();
    const year = dateObject.getUTCFullYear();

    // const hours = dateObject.getUTCHours();
    // const minutes = dateObject.getUTCMinutes();
    // const ampm = hours >= 12 ? "PM" : "AM";
    // const formattedHours = hours % 12 || 12;

    // return `${month} ${day}th ${year}, ${formattedHours}:${
    //   minutes < 10 ? "0" : ""
    // }${minutes} ${ampm}`;
    const options = {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      year: "numeric",
      month: "long",
      day: "numeric",
      hour12: true,
      hour: "numeric",
      minute: "2-digit",
    };
    const formattedHours = dateObject.toLocaleString(undefined, options);
    // return `${month} ${day}th ${year}, ${formattedHours}`;
    return `${formattedHours}`;
  };

  const deleteEventWebinar = async (id) => {
    axiosInstance
      .delete(`/trash/delete/event-webinar/${id}`)
      .then((res) => {
        setNotificationData([
          {
            msg: res.data.message,
            status: res.status,
          },
        ]);
        getAllEventWebinars();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const restoreEventWebinar = async (id) => {
    axiosInstance
      .get(`/trash/restore/event-webinar/${id}?indicator=0`)
      .then((res) => {
        if (res.status == 200) {
          setNotificationData([
            {
              msg: "Resource is being restored as draft.You can publish it live from the respective section",
              status: res.status,
            },
          ]);
        } else {
          setNotificationData([
            {
              msg: res.data.message,
              status: res.status,
            },
          ]);
        }

        getAllEventWebinars();
      });
  };

  const getAllEventWebinars = () => {
    let query = `search=&offset=${pagination.offset}&limit=${pagination.limit}`;
    if (user.role < 3 /*&& selectedCountry*/) {
      //   query += `&country_id=${selectedCountry.id}`;
    } else if (user.role >= 3) {
      query += `&country_id=${user.countryId}`;
    }
    axiosInstance
      .get(`/trash/get/trash-event-webinar?${query}`)
      .then((res) => {
        if (res.status == 200) {
          setAllEventWebinars(res.data.events);
          setTotalRecords(res.data.count || 0);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllEventWebinars();
  }, []);

  return (
    <div className="event_wrapper">
      <Notification
        notificationData={notificationData}
        setNotificationData={setNotificationData}
      />
      <DeleteConfirmation
        showModal={showModal}
        hideModal={() => {
          setShowModal(false);
        }}
        confirmModal={() => {
          deleteEventWebinar(eventWebinar.id);
          setShowModal(false);
        }}
        message={`Are you sure you want to delete ${eventWebinar?.title}?`}
      />
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal_body">
          <TrashEventWebPreview
            eventWebinar={eventWebinar}
            formatDateAndTime={formatDateAndTime}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <TrashNote />
      <h2>Deleted Events / webinars</h2>
      {totalRecords > 0 ? (
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th className="table-head">#</th>
              <th className="table-head">Title</th>
              <th className="table-head">Type</th>
              <th className="table-head">Start</th>
              <th className="table-head">End</th>
              <th className="table-head">View</th>
              <th className="table-head">Deleted By</th>
              <th className="table-head">Restore</th>
              <th className="table-head">Delete</th>
            </tr>
          </thead>
          <tbody>
            {allEventWebinars?.map((event, index) => {
              return (
                <tr key={index}>
                  <td>{pagination.offset + index + 1}</td>
                  <td>{event.title}</td>
                  <td>{event.calendar_type}</td>
                  <td>{formatDateAndTime(event.start)}</td>
                  <td>{formatDateAndTime(event.end)}</td>
                  <td>
                    <i
                      className="fa-solid fa-box-open fa-1x"
                      onClick={() => {
                        setShow(true);
                        setEventWebinar(event);
                      }}
                    ></i>
                  </td>
                  <td>{event.modifiedBy}</td>
                  <td>
                    {" "}
                    <i
                      class="fa-solid fa-arrow-rotate-left fa-xl"
                      onClick={() => {
                        restoreEventWebinar(event.id);
                      }}
                    ></i>
                  </td>
                  <td>
                    {" "}
                    <i
                      className="fa-solid fa-trash-can fa-1x"
                      onClick={() => {
                        setShowModal(true);
                        setEventWebinar(event);
                      }}
                    ></i>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <NoDataPresent />
      )}
      <Pagination setPagination={setPagination} totalRecords={totalRecords} />
    </div>
  );
}
