import {
  createContext,
  useContext,
  useState,
  useRef,
  useEffect,
  useMemo,
} from "react";

import { SyncLoader } from "react-spinners";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import InputGroup from "react-bootstrap/InputGroup";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import DataGrid from "react-data-grid";
import Alert from "react-bootstrap/Alert";
import axiosInstance from "../utility/axios";
import { ROLES } from "../utility/constants";

const FilterContext = createContext(undefined);

const options = {
  credits: false,
  colors: [
    "#01BAF2",
    "#71BF45",
    "#FAA74B",
    "#B37CD2",
    "#FF4F81",
    "#66CCCC",
    "#FFD700",
    "#8B4513",
    "#008080",
    "#FF6347",
    "#7B68EE",
    "#32CD32",
    "#CD5C5C",
    "#4169E1",
    "#FFA500",
  ],
  chart: {
    type: "pie",
    height: 500,
    width: null,
    borderRadius: "1em",
  },
  title: { text: "" },
  accessibility: {
    point: {
      valueSuffix: "%",
    },
  },
  tooltip: {
    pointFormat: "{point.name}: <b>{point.y}</b>",
  },
  legend: {
    layout: "vertical",
    align: "left",
    verticalAlign: "middle",
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: true,
        format: "{point.name}: {y}",
        crop: false,
        overflow: "allow",
        distance: 10,
      },
      showInLegend: false,
      size: 80,
      shadow: false,
    },
  },
  series: [
    {
      data: [],
    },
  ],
};

const DateFormatter = (inputDate) => {
  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, "0");
  const day = String(inputDate.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};
const ExportButton = ({ onExport, children }) => {
  const [exporting, setExporting] = useState(false);
  return (
    <button
      type="button"
      disabled={exporting}
      className="grid-export-btn"
      onClick={async () => {
        setExporting(true);
        await onExport();
        setExporting(false);
      }}
    >
      <i className="fa-solid fa-file-export"></i>
      {exporting ? "Exporting" : children}
    </button>
  );
};

const FilterRenderer = ({ tabIndex, column, children }) => {
  const filters = useContext(FilterContext);
  return (
    <>
      <div className="cell-head-name">{column.name}</div>
      {filters.enabled && <div>{children({ tabIndex, filters })}</div>}
    </>
  );
};

const selectStopPropagation = (event) => {
  if (["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"].includes(event.key)) {
    event.stopPropagation();
  }
};

const DataGridComponent = ({ data, heading }) => {
  const [filters, setFilters] = useState(() => ({
    "Country.name": "All",
    role: "All",
    "SubCommunity.name": "All",
    group_type: "All",
    isEmailVerified: "All",
    active_indicator: "All",
    enabled: true,
  }));
  const [countries, setCountries] = useState([]);
  const [subCommunities, setSubCommunities] = useState([]);

  useEffect(() => {
    axiosInstance.get("/user/country/list").then((res) => {
      setCountries(res.data);
    });
    axiosInstance.get("/user/hub/list").then((res) => {
      setSubCommunities(res.data);
    });
  }, []);

  const filteredRows = useMemo(() => {
    return data.rows.filter((r) => {
      return (
        (filters["Country.name"] !== "All"
          ? r["Country.name"] === filters["Country.name"]
          : true) &&
        (filters.role !== "All" ? r.role === filters.role : true) &&
        (filters["SubCommunity.name"] !== "All"
          ? r["SubCommunity.name"] === filters["SubCommunity.name"]
          : true) &&
        (filters.group_type !== "All"
          ? r.group_type === filters.group_type
          : true) &&
        (filters.isEmailVerified !== "All"
          ? r.isEmailVerified === filters.isEmailVerified
          : true) &&
        (filters.active_indicator !== "All"
          ? r.active_indicator === filters.active_indicator
          : true)
      );
    });
  }, [data.rows, filters]);

  const summaryRows = useMemo(() => {
    return [
      {
        id: "total",
        totalUsers: filteredRows.length,
        totalCount: (column) => {
          return filteredRows.reduce((acc, curr) => {
            return acc + curr[column];
          }, 0);
        },
      },
    ];
  }, [data.rows, filteredRows]);

  const gridElement = (
    <FilterContext.Provider value={filters}>
      <DataGrid
        rowKeyGetter={(row) => row.id}
        headerRowHeight={filters.enabled ? 70 : undefined}
        defaultColumnOptions={{
          resizable: true,
        }}
        columns={data.columns.map((c, index) => {
          if (index === 0) {
            return {
              ...c,
              renderSummaryCell({ row }) {
                return <strong>Total Users: {row.totalUsers}</strong>;
              },
            };
          } else if (c.children) {
            return {
              ...c,
              children: c.children.map((cc) => {
                return {
                  ...cc,
                  renderSummaryCell({ row, column }) {
                    return <strong>{row.totalCount(column.key)}</strong>;
                  },
                };
              }),
            };
          } else if (c.render_total) {
            return {
              ...c,
              renderSummaryCell({ row, column }) {
                return <strong>{row.totalCount(column.key)}</strong>;
              },
            };
          } else if (c.allow_filter) {
            return {
              ...c,
              headerCellClass: "filter-cell",
              renderHeaderCell: (p) => (
                <FilterRenderer {...p}>
                  {({ filters, ...rest }) => {
                    if (p.column.key === "Country.name") {
                      return (
                        <select
                          {...rest}
                          value={filters["Country.name"]}
                          className="filter-input-table"
                          onChange={(e) =>
                            setFilters({
                              ...filters,
                              "Country.name": e.target.value,
                            })
                          }
                          onKeyDown={selectStopPropagation}
                        >
                          <option value="All">All</option>
                          {countries.map((c) => (
                            <option value={c.name}>{c.name}</option>
                          ))}
                        </select>
                      );
                    } else if (p.column.key === "role") {
                      return (
                        <select
                          {...rest}
                          value={filters["role"]}
                          className="filter-input-table"
                          onChange={(e) =>
                            setFilters({
                              ...filters,
                              role: e.target.value,
                            })
                          }
                          onKeyDown={selectStopPropagation}
                        >
                          <option value="All">All</option>
                          {Object.values(ROLES).map((r) => (
                            <option value={r}>{r}</option>
                          ))}
                        </select>
                      );
                    } else if (p.column.key === "SubCommunity.name") {
                      return (
                        <select
                          {...rest}
                          value={filters["SubCommunity.name"]}
                          className="filter-input-table"
                          onChange={(e) =>
                            setFilters({
                              ...filters,
                              "SubCommunity.name": e.target.value,
                            })
                          }
                          onKeyDown={selectStopPropagation}
                        >
                          <option value="All">All</option>
                          {subCommunities.map((s) => (
                            <option value={s.name}>{s.name}</option>
                          ))}
                        </select>
                      );
                    } else if (p.column.key === "group_type") {
                      return (
                        <select
                          {...rest}
                          value={filters["group_type"]}
                          className="filter-input-table"
                          onChange={(e) =>
                            setFilters({
                              ...filters,
                              group_type: e.target.value,
                            })
                          }
                          onKeyDown={selectStopPropagation}
                        >
                          <option value="All">All</option>
                          <option value="youth-led">youth-led</option>
                          <option value="women-led">women-led</option>
                        </select>
                      );
                    } else if (p.column.key === "isEmailVerified") {
                      return (
                        <select
                          {...rest}
                          value={filters["isEmailVerified"]}
                          className="filter-input-table"
                          onChange={(e) =>
                            setFilters({
                              ...filters,
                              isEmailVerified: e.target.value,
                            })
                          }
                          onKeyDown={selectStopPropagation}
                        >
                          <option value="All">All</option>
                          <option value="Not Verified">Not Verified</option>
                          <option value="Verified">Verified</option>
                        </select>
                      );
                    } else if (p.column.key === "active_indicator") {
                      return (
                        <select
                          {...rest}
                          value={filters["active_indicator"]}
                          className="filter-input-table"
                          onChange={(e) =>
                            setFilters({
                              ...filters,
                              active_indicator: e.target.value,
                            })
                          }
                          onKeyDown={selectStopPropagation}
                        >
                          <option value="All">All</option>
                          <option value="Blocked">Blocked</option>
                          <option value="Active">Active</option>
                        </select>
                      );
                    }
                  }}
                </FilterRenderer>
              ),
            };
          } else {
            return c;
          }
        })}
        rows={filteredRows}
        // bottomSummaryRows={summaryRows}
        topSummaryRows={summaryRows}
        style={{ resize: "both" }}
        className={
          filters.enabled
            ? `filter-container-table rdg-light fill-grid ${data.id}`
            : `rdg-light fill-grid ${data.id}`
        }
      />
    </FilterContext.Provider>
  );
  return (
    <div className="data-grid-container">
      <div className="grid-head">
        <h6>{heading}</h6>
      </div>

      {gridElement}
    </div>
  );
};

const GeneralAnalytics = () => {
  const targetBox = useRef(null);
  const [datePicker, setDatePicker] = useState([
    {
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [filterBy, setFilterBy] = useState("None");
  const [gridData, setGridData] = useState({
    overviewGrid: {
      rows: [],
      columns: [],
    },
    dashboardUserGrid: {
      rows: [],
      columns: [],
    },
    websiteUserGrid: {
      rows: [],
      columns: [],
    },
  });
  const [loader, setloader] = useState(false);
  const [pieData, setPieData] = useState([]);
  const [pieDataOptions, setPieDataOptions] = useState({
    ...options,
  });
  const [countries, setCountries] = useState([]);

  const getTableData = async () => {
    const query = `?start_date=${DateFormatter(
      datePicker[0].startDate
    )}&end_date=${DateFormatter(datePicker[0].endDate)}`;
    await axiosInstance
      .get(`/analytics/tabular${query}`)
      .then((res) => {
        setGridData({ ...gridData, ...res.data?.gridData });
        setPieData([...res.data?.pieData]);
      })
      .catch((err) => {
        console.log(err);
      });

    await axiosInstance.get("/user/country/list").then((res) => {
      setCountries(res.data);
      setFilterBy(res.data ? Number(res.data[0]?.id) : "None");
    });
  };

  const downloadExcel = async () => {
    setloader(true);
    const query = `?start_date=${DateFormatter(
      datePicker[0].startDate
    )}&end_date=${DateFormatter(datePicker[0].endDate)}`;
    await axiosInstance
      .get(`/analytics/export/tabular${query}`, {
        responseType: "blob",
      })
      .then((res) => {
        if (res.status === 200) {
          const url = URL.createObjectURL(res.data);
          const a = document.createElement("a");
          a.href = url;
          a.download = `summary_${DateFormatter(
            datePicker[0].startDate
          )}_to_${DateFormatter(datePicker[0].endDate)}.xlsx`;
          a.click();
          URL.revokeObjectURL(url);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setloader(false);
  };

  useEffect(() => {
    getTableData();
  }, []);

  const updatePie = () => {
    setPieDataOptions({
      ...pieDataOptions,
      chart: {
        type: "pie",
        height: 350,
        // width: 300,
        borderRadius: "1em",
        margin: [0, 0, 0, 0],
        spacingTop: 20,
        spacingBottom: 20,
        spacingLeft: 20,
        spacingRight: 20,
      },

      plotOptions: {
        pie: {
          size: "80%",
          dataLabels: {
            enabled: true,
          },
        },
      },
      series: [
        {
          name: "Country Data",
          colorByPoint: true,
          data: pieData
            ?.map((v) => {
              if (v.id === filterBy) {
                return Object.entries(v)
                  .map(([key, value]) => {
                    if (key === "id") return;
                    return { name: key, y: Number(value) };
                  })
                  .filter(Boolean);
              }
            })
            .filter(Boolean)
            .flat(),
        },
      ],
    });
  };
  useEffect(() => {
    updatePie();
  }, [filterBy, pieData]);

  return (
    <section className="user-section" id="user-section">
      <SyncLoader
        className="backdrop-loader"
        color="#36d7b7"
        size={20}
        loading={loader}
      />
      <div className="user-content">
        <h2 className="dashboard_title">Analytics</h2>
        <div className="filter-inputs">
          <div id="date-picker">
            <OverlayTrigger
              placement="bottom"
              trigger="click"
              rootClose
              overlay={
                <Tooltip id="overlay-date" style={{}}>
                  <DateRangePicker
                    onChange={(item) => setDatePicker([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={1}
                    ranges={datePicker}
                    direction="vertical"
                    scroll={{ enabled: true }}
                  />
                  <button
                    type="button"
                    className="btn btn-success"
                    style={{
                      margin: ".5em",
                      color: "white",
                      background: "rgb(62, 207, 142)",
                      border: "none",
                    }}
                    onClick={() => {
                      document.body.click();
                      getTableData();
                    }}
                  >
                    Apply
                  </button>
                </Tooltip>
              }
            >
              <i
                className="bx bxs-calendar bx-md"
                ref={targetBox}
                style={{ color: "lavender", cursor: "pointer" }}
              ></i>
            </OverlayTrigger>
          </div>
          <div className="grid-export">
            <ExportButton onExport={() => downloadExcel()}>Export</ExportButton>
          </div>
        </div>
        <Alert variant="info" style={{ marginTop: "1em" }}>
          <p>Please Note:</p>
          <ul>
            <li>Published: Live on website</li>
            <li>Review: Pending for Approval</li>
            <li>Draft: Requested for changes</li>
          </ul>
        </Alert>
        <h4>Overview</h4>
        <div>
          <div>
            <InputGroup className="mx-3" style={{ width: "fit-content" }}>
              <DropdownButton
                variant="outline-secondary"
                title={countries.find((c) => c.id === filterBy)?.name}
                id="input-group-dropdown-1"
                onSelect={(e) => {
                  setFilterBy(Number(e));
                }}
              >
                {countries.map((country) => (
                  <Dropdown.Item eventKey={country.id}>
                    {country.name}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </InputGroup>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              padding: "1em",
            }}
          >
            <HighchartsReact
              id="chart-container"
              highcharts={Highcharts}
              options={pieDataOptions}
              style={{
                overflow: "visible",
              }}
            />
          </div>
        </div>
        <h4>Dashboard Users</h4>
        <div>
          <DataGridComponent data={gridData.dashboardUserGrid} heading="" />
        </div>

        <h4>Website Users</h4>
        <div>
          <DataGridComponent data={gridData.websiteUserGrid} heading="" />
        </div>
      </div>
    </section>
  );
};

export default GeneralAnalytics;
