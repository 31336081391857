import React, { useEffect, useState } from "react";
import "./contact.css";
import axiosInstance from "../utility/axios";
import Notification from "../components/Notification";

const Contact = () => {
  const [data, setData] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [Edit, setEdit] = useState({
    isEdit: false,
    isNew: false,
    index: undefined,
  });
  const [newCard, setNewCard] = useState({
    title: "",
    subtitle: "",
    address: "",
    phone: "",
    email: "",
    link: "",
  });
  const [updateData, setUpdateData] = useState({
    title: "",
    subtitle: "",
    address: "",
    phone: "",
    email: "",
    link: "",
  });

  const deleteContact = async (index) => {
    await axiosInstance
      .delete(`/siteData/deleteContact/${index}`)
      .then((res) => {
        if (res) {
          setNotificationData([
            {
              msg: res.data.message,
              status: res.status,
            },
          ]);
          getContacts();
        }
      });
  };

  const Update = async () => {
    await axiosInstance
      .patch(`/siteData/updateContacts`, {
        index: Edit.index,
        data: updateData,
      })
      .then((response) => {
        if (response.status === 200) {
          getContacts();
          setNotificationData([
            {
              msg: response.data.message,
              status: response.status,
            },
          ]);
        } else {
          // console.log("some error happed at line 19")
        }
      })
      .catch((err) => {
        console.log(err);
      });
    getContacts();
    setEdit({
      isEdit: false,
      isNew: false,
      index: undefined,
    });
  };

  const handleChangeNew = (name, value) => {
    setNewCard({
      ...newCard,
      [name]: value,
    });
  };
  const submitHandler = async () => {
    await axiosInstance
      .post(`/siteData/AddContacts`, {
        data: newCard,
      })
      .then((response) => {
        if (response.status === 200) {
          getContacts();
          setNotificationData([
            {
              msg: response.data.message,
              status: response.status,
            },
          ]);
        } else {
          // console.log("some error happed at line 19")
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setEdit({
      isEdit: false,
      isNew: false,
      index: undefined,
    });
  };

  const getContacts = async () => {
    await axiosInstance.get(`/siteData/getContacts`).then((response) => {
      if (response.status == 200) {
        setData(response.data.response.cards);
      }
    });
  };

  const editCard = (index) => {
    setEdit({
      isEdit: true,
      isNew: false,
      index,
    });
    setUpdateData({
      title: data[index]?.title,
      subtitle: data[index]?.subtitle,
      address: data[index]?.address,
      phone: data[index]?.phone,
      email: data[index]?.email,
      link: data[index]?.link,
    });
  };

  const handleChangeUpdate = (name, value) => {
    setUpdateData({
      ...updateData,
      [name]: value,
    });
  };

  useEffect(() => {
    getContacts();
  }, []);

  return (
    <div className="contactUs_wrapper">
      <Notification
        notificationData={notificationData}
        setNotificationData={setNotificationData}
      />
      <div className="contact_title">
        <h1>Contact page</h1>
      </div>
      <div className="row">
        {!Edit.isNew && (
          <div className="col-lg-4">
            <div
              className="add_wrapper"
              onClick={() => {
                setNewCard({
                  title: "",
                  address: "",
                  phone: "",
                  email: "",
                  link: "",
                });
                setEdit({ isEdit: false, isNew: true, index: undefined });
              }}
            >
              <div className="card_title">
                <h2>Add Contact</h2>
              </div>
              <div className="add_card">
                <i className="fa-solid fa-square-plus icon-size"></i>
              </div>
            </div>
          </div>
        )}
        {Edit.isNew && (
          <div className="col-lg-4">
            <div className="cards">
              <div className="card_title">
                <input
                  placeholder="Write Title"
                  value={newCard.title}
                  name="title"
                  type="text"
                  onChange={(e) => {
                    handleChangeNew(e.target.name, e.target.value);
                  }}
                ></input>
              </div>
              <div className="card_content">
                <div className="subtitle">
                  <input
                    placeholder="Sub Title"
                    value={newCard.subtitle}
                    name="subtitle"
                    type="text"
                    onChange={(e) => {
                      handleChangeNew(e.target.name, e.target.value);
                    }}
                  ></input>
                </div>
                <div className="address">
                  <input
                    placeholder="Address"
                    value={newCard.address}
                    name="address"
                    type="text"
                    onChange={(e) => {
                      handleChangeNew(e.target.name, e.target.value);
                    }}
                  ></input>
                </div>
                <div className="phone">
                  <input
                    placeholder="Phone Number"
                    value={newCard.phone}
                    name="phone"
                    type="tel"
                    onChange={(e) => {
                      handleChangeNew(e.target.name, e.target.value);
                    }}
                  ></input>
                </div>
                <div className="email">
                  <input
                    placeholder="Email"
                    value={newCard.email}
                    name="email"
                    type="email"
                    onChange={(e) => {
                      handleChangeNew(e.target.name, e.target.value);
                    }}
                  ></input>
                </div>
                <div className="link">
                  <input
                    placeholder="Link"
                    value={newCard.link}
                    name="link"
                    type="text"
                    onChange={(e) => {
                      handleChangeNew(e.target.name, e.target.value);
                    }}
                  ></input>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    onClick={() => {
                      setEdit({
                        isEdit: false,
                        isNew: false,
                        index: undefined,
                      });
                    }}
                    style={{
                      borderRadius: "0px",
                      backgroundColor: "rgb(210, 0, 0)",
                      padding: "5px 20px",
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      submitHandler();
                    }}
                    style={{
                      borderRadius: "0px",
                      backgroundColor: "#005ab8",
                      padding: "5px 20px",
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {data.map((item, index) => {
          return (
            <div className="col-lg-4" key={index}>
              <div className="cards">
                {Edit.index == index ? (
                  <>
                    <div className="card_title">
                      <input
                        placeholder="Write Title"
                        value={updateData.title}
                        name="title"
                        type="text"
                        onChange={(e) => {
                          handleChangeUpdate(e.target.name, e.target.value);
                        }}
                      ></input>
                    </div>
                    <div className="subtitle">
                      <input
                        placeholder="Sub Title"
                        value={updateData.subtitle}
                        name="subtitle"
                        type="text"
                        onChange={(e) => {
                          handleChangeUpdate(e.target.name, e.target.value);
                        }}
                      ></input>
                    </div>
                    <div className="card_content">
                      <div className="address">
                        <input
                          placeholder="Address"
                          value={updateData.address}
                          name="address"
                          type="text"
                          onChange={(e) => {
                            handleChangeUpdate(e.target.name, e.target.value);
                          }}
                        ></input>
                      </div>
                      <div className="phone">
                        <input
                          placeholder="Phone Number"
                          value={updateData.phone}
                          name="phone"
                          type="tel"
                          onChange={(e) => {
                            handleChangeUpdate(e.target.name, e.target.value);
                          }}
                        ></input>
                      </div>
                      <div className="email">
                        <input
                          placeholder="Email"
                          value={updateData.email}
                          name="email"
                          type="email"
                          onChange={(e) => {
                            handleChangeUpdate(e.target.name, e.target.value);
                          }}
                        ></input>
                      </div>
                      <div className="link">
                        <input
                          placeholder="Link"
                          value={updateData.link}
                          name="link"
                          type="text"
                          onChange={(e) => {
                            handleChangeUpdate(e.target.name, e.target.value);
                          }}
                        ></input>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="card_title">
                      <h2>{item.title}</h2>
                    </div>
                    <div className="subtitle">
                      <h2>{item.subtitle}</h2>
                    </div>
                    <h6></h6>
                    <div className="card_content">
                      <div className="address">
                        <i className="fa-solid fa-location-dot contact-card-location me-2"></i>
                        <p>{item.address}</p>
                      </div>
                      <div className="phone">
                        <i className="fa-solid fa-square-phone contact-card-extra me-2"></i>
                        <p>{item.phone}</p>
                      </div>
                      <div className="email">
                        <i className="fa-solid fa-envelope contact-card-extra me-2"></i>
                        <p>{item.email}</p>
                      </div>
                      <div className="link">
                        <i className="fa-solid fa-link me-2"></i>
                        <p>{item.link}</p>
                      </div>
                    </div>
                  </>
                )}
                <div className="saveEdit-btn">
                  <button
                    className="edit-btn"
                    onClick={() => {
                      editCard(index);
                    }}
                  >
                    Edit
                  </button>
                  <button
                    disabled={!Edit.isEdit}
                    style={Edit.isEdit ? {} : { cursor: "not-allowed" }}
                    onClick={() => {
                      Update();
                    }}
                    className="save-btn"
                  >
                    Save
                  </button>
                  <button
                    onClick={() => {
                      deleteContact(index);
                    }}
                    className="del-btn"
                  >
                    <i className="fa-solid fa-trash-can"></i>
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Contact;
