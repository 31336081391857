import { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import PrivateRoute from "./utility/privateRoute";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Context from "./pages/Context";
import { app } from "./firebase-config";
// import SignUp from "./pages/SignUp";
import { useLoading } from "./utility/LoadingContext";
import { LanguageProvider } from "./utility/LanguageContext";
import Loader from "./components/Loader";
import "./App.css";

const App = () => {
  const { loading } = useLoading();
  return (
    <div>
      {loading && <Loader />}
      <Suspense fallback={<Loader />}>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            {/* <Route path="/signup" element={<SignUp />} /> */}
            <Route
              path="/home/*"
              element={
                <Context>
                  <PrivateRoute>
                    <LanguageProvider>
                      <Home />
                    </LanguageProvider>
                  </PrivateRoute>
                </Context>
              }
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Router>
      </Suspense>
    </div>
  );
};

export default App;
