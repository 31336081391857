import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import "./Notification.css";

const Notification = ({ notificationData, setNotificationData }) => {
  return (
    <ToastContainer
      position="top-end"
      className="p-3"
      style={{
        zIndex: 100,
        position: "fixed",
        color: "white",
        marginTop: "3em",
      }}
    >
      {(notificationData || []).map((item, index) => {
        return (
          <Toast
            key={index}
            onClose={() => {
              setNotificationData([]);
            }}
            bg={item.status === 200 ? "primary" : "danger"}
            delay={5000}
            autohide
          >
            <Toast.Body>
              <strong className="me-auto">{item.msg}</strong>
            </Toast.Body>
          </Toast>
        );
      })}
    </ToastContainer>
  );
};

export default Notification;
