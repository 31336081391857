import React from "react";

export default function TrashEventWebPreview({
  eventWebinar,
  formatDateAndTime,
}) {
  return (
    <div className="Preview_wrapper" id="preview">
      <div className="img_wrapper">
        <img
          src={
            eventWebinar?.bannerImageId &&
            `${process.env.REACT_APP_BUCKET_URL}public/events/${eventWebinar.bannerImageId}${eventWebinar.bannerImageExt}`
          }
          style={{
            maxHeight: "300px",
          }}
        ></img>
      </div>
      <div className="title_event_preview">{eventWebinar.title}</div>
      <div className="posted-date" style={{ marginBottom: "1rem" }}>
        <i className="fa-solid fa-calendar-days"></i>
        <span
          style={{
            padding: "0px 15px",
            fontSize: " 14px",
            color: "#999",
            lineHeight: "20px",
          }}
        >
          Posted By: <span> {eventWebinar.createdBy}</span> | Calendar Type:{" "}
          <span>{eventWebinar.calendar_type}</span>
        </span>
      </div>
      <div className="description_wrapper">
        <div
          dangerouslySetInnerHTML={{ __html: eventWebinar.description }}
          className="ck-content"
          style={{ overflow: "auto", padding: "0px 15px" }}
        ></div>
      </div>
      <div className="right-event-detail">
        <div className="event-date-r">
          <div>
            <img
              loading="lazy"
              src="/img/new-calender.png"
              alt="Event Calendar"
            />
          </div>
          <div>
            <p>
              Start Date & Time
              <span>{formatDateAndTime(eventWebinar.start)}</span>
            </p>
          </div>
        </div>
        <div className="event-date-r">
          <div>
            <img
              loading="lazy"
              src="/img/new-calender.png"
              alt="Event Calendar"
            />
          </div>
          <div>
            <p>
              End Date & Time
              <span>{formatDateAndTime(eventWebinar.end)}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
