import { React, useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import axiosInstance from "../utility/axios";
import Form from "react-bootstrap/Form";
import { Modal } from "react-bootstrap";

const MyIssues = ({
  data,
  ctx,
  getMyIssues,
  search,
  setSearch,
  setNotificationData,
  formatDate,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [showButton, setShowButton] = useState(true);
  const [assignTo, setAssignTo] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    axiosInstance
      .post("/issues/createIssue", {
        userId: ctx.user.id,
        userName: ctx.user.username,
        title: title,
        description: description,
        isClosed: false,
        assignTo: assignTo,
      })
      .then((response) => {
        if (response.status === 200) {
          getMyIssues();
          setShowForm(false);
        }
        setNotificationData([
          {
            msg: response.data.message,
            status: response.status,
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (title && description) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, [title, description]);

  return (
    <>
      <Modal show={showForm} onHide={() => setShowForm(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create Issue</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Title"
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control
                placeholder="Write description here"
                onChange={(e) => setDescription(e.target.value)}
                as="textarea"
                rows={3}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Label>Assign to</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setAssignTo(e.target.value)}
              >
                <option>Select</option>
                <option value="1">Developer Admin</option>
                <option value="2">Super Admin</option>
                <option value="3">Admin</option>
                <option value="4">Moderator</option>
                <option value="5">Content Creator</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowForm(false)}>
            Close
          </Button>
          {showButton ? (
            <Button onClick={handleSubmit}>Create</Button>
          ) : (
            <Button disabled>Create</Button>
          )}
        </Modal.Footer>
      </Modal>
      <div className="mt-4">
        <Row className="mb-4">
          <div className="search-box">
            <Col className="btn-flex">
              <input
                type="search"
                placeholder="Search by name"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                aria-describedby="button-addon5"
                className="form-control"
                style={{ borderRadius: ".375rem 0px 0px .375rem" }}
              />
              <div className="input-group-append">
                <button
                  id="button-addon5"
                  type="button"
                  onClick={() => getMyIssues()}
                  className="btn btn-primary color3863A0"
                  style={{
                    borderRadius: "2px",
                    height: "100%",
                  }}
                >
                  <i className="bx bx-search"></i>
                </button>
              </div>
            </Col>
            <Col xs={2} className="btn-flex">
              <Button variant="success" onClick={() => setShowForm(true)}>
                New issue
              </Button>
            </Col>
          </div>
        </Row>
        {data.map((item, index) => (
          <Link
            to={`/home/issues/${item.id}`}
            key={index}
            className="link-style"
          >
            <Row className="issue_row pt-3 pb-2 mx-2" key={item.id}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <div className="issue-heading">
                    {!item.isClosed ? (
                      <svg
                        className="octicon octicon-issue-opened open"
                        viewBox="0 0 16 16"
                        version="1.1"
                        width="16"
                        height="16"
                        aria-hidden="true"
                      >
                        <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"></path>
                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0ZM1.5 8a6.5 6.5 0 1 0 13 0 6.5 6.5 0 0 0-13 0Z"></path>
                      </svg>
                    ) : (
                      <svg
                        className="octicon octicon-issue-closed closed"
                        viewBox="0 0 16 16"
                        version="1.1"
                        width="16"
                        height="16"
                        aria-hidden="true"
                      >
                        <path d="M11.28 6.78a.75.75 0 0 0-1.06-1.06L7.25 8.69 5.78 7.22a.75.75 0 0 0-1.06 1.06l2 2a.75.75 0 0 0 1.06 0l3.5-3.5Z"></path>
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0Zm-1.5 0a6.5 6.5 0 1 0-13 0 6.5 6.5 0 0 0 13 0Z"></path>
                      </svg>
                    )}

                    <h4>{item.title}</h4>
                  </div>
                  <div className="sub_text">
                    created by user: {item.userName}
                  </div>
                </div>
                <div className="sub_text">{formatDate(item.createdAt)}</div>
              </div>
            </Row>
          </Link>
        ))}
      </div>
    </>
  );
};

export default MyIssues;
