import "./UserSideBar.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Alert from "react-bootstrap/Alert";
import React, { useContext, useEffect, useState } from "react";
import axiosInstance from "../utility/axios";
import { userContext } from "../pages/Context";

const HubSideBar = ({
  editDetails,
  show,
  handleClose,
  countries,
  refresh,
  mode,
  setNotificationData,
}) => {
  const { user } = useContext(userContext);
  const [community, setCommunity] = useState({
    address: "",
    phone: "",
    alternate_phone: "",
    countryId: undefined,
    communityId: undefined,
    email: "",
    name: "",
  });

  const [mainCommunities, setMainCommunities] = useState([]);

  const [msg, setMsg] = useState({
    message: [],
    variant: "danger",
  });

  useEffect(() => {
    if (show && !editDetails?.user) {
      setCommunity({
        ...community,
        countryId: user.countryId ? user.countryId : undefined,
        communityId: user.communityId ? user.communityId : undefined,
      });
    }
  }, [show]);
  useEffect(() => {
    if (editDetails?.community) setCommunity(editDetails?.community);
  }, [editDetails]);

  useEffect(() => {
    axiosInstance
      .get(`/user/hub/main/list?country_id=${community.countryId}`)
      .then((res) => {
        if (res.status === 200) {
          setMainCommunities(res.data);
        }
      });
  }, [community.countryId]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setCommunity({ ...community, [id]: value });
  };

  const handleSubmit = () => {
    if (!community.id) {
      axiosInstance.post("/user/hub/create", community).then((res) => {
        if (res.status === 200) {
          handleClose();
          refresh();
          setMsg({
            message: [],
            variant: "danger",
          });
          setCommunity({
            address: "",
            phone: "",
            alternate_phone: "",
            countryId: undefined,
            communityId: undefined,
            email: "",
            name: "",
          });
        } else {
          if (res.data.message === "Validation error")
            setMsg({
              message: res.data.error?.details?.map((e) => e.message),
              variant: "danger",
            });
          else if (res.data.message === "DB Error")
            setMsg({ message: [...res.data.error], variant: "danger" });
          else setMsg({ message: [res.data.message], variant: "danger" });
        }
        setNotificationData([
          {
            msg: res.data.message,
            status: res.status,
          },
        ]);
      });
    } else {
      axiosInstance
        .patch(`/user/hub/update/${community.id}`, community)
        .then((res) => {
          if (res.status === 200) {
            handleClose();
            refresh();
            setMsg({
              message: [],
              variant: "danger",
            });
            setCommunity({
              address: "",
              phone: "",
              alternate_phone: "",
              countryId: undefined,
              communityId: undefined,
              email: "",
              name: "",
            });
          } else {
            if (res.data.message === "Validation error")
              setMsg({
                message: res.data.error?.details?.map((e) => e.message),
                variant: "danger",
              });
            else if (res.data.message === "DB Error")
              setMsg({ message: [...res.data.error], variant: "danger" });
            else setMsg({ message: [res.data.message], variant: "danger" });
          }
          setNotificationData([
            {
              msg: res.data.message,
              status: res.status,
            },
          ]);
        });
    }
  };

  return (
    <Offcanvas
      show={show}
      onHide={() => {
        setCommunity({
          address: "",
          phone: "",
          alternate_phone: "",
          countryId: undefined,
          communityId: undefined,
          email: "",
          name: "",
        });
        handleClose();
        setMsg({
          message: [],
          variant: "danger",
        });
      }}
      placement="end"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Community Form</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <InputGroup className="mb-3">
          <Form.Control
            placeholder="name"
            aria-label="name"
            aria-describedby="name"
            id="name"
            value={community.name}
            onChange={handleChange}
            disabled={mode === "preview"}
          />
        </InputGroup>

        <InputGroup className="mb-3">
          <Form.Control
            placeholder="Email"
            aria-label="Email"
            aria-describedby="email"
            id="email"
            value={community.email}
            onChange={handleChange}
            disabled={mode === "preview"}
          />
        </InputGroup>

        <InputGroup className="mb-3">
          <Form.Control
            as="textarea"
            placeholder="Address"
            aria-label="Address"
            aria-describedby="Address"
            id="address"
            value={community.address}
            onChange={handleChange}
            disabled={mode === "preview"}
          />
        </InputGroup>

        <InputGroup className="mb-3">
          <DropdownButton
            variant="outline-secondary"
            title={community.country_code || "Country Code"}
            id="input-group-dropdown-1"
            onSelect={(e) =>
              handleChange({ target: { id: "country_code", value: e } })
            }
            disabled={mode === "preview"}
          >
            <Dropdown.Item eventKey={"+91"}>+91</Dropdown.Item>
            <Dropdown.Item eventKey={"+66"}>+66</Dropdown.Item>
            <Dropdown.Item eventKey={"+880"}>+880</Dropdown.Item>
            <Dropdown.Item eventKey={"+977"}>+977</Dropdown.Item>
          </DropdownButton>
          <Form.Control
            placeholder="Phone"
            aria-label="Phone"
            aria-describedby="Phone"
            id="phone"
            value={community.phone}
            onChange={handleChange}
            disabled={mode === "preview"}
          />
        </InputGroup>

        <InputGroup className="mb-3">
          <Form.Control
            placeholder="Alternate Phone"
            aria-label="Alternate Phone"
            aria-describedby="Alternate Phone"
            id="alternate_phone"
            value={community.alternate_phone || ""}
            onChange={handleChange}
            disabled={mode === "preview"}
          />
        </InputGroup>

        <InputGroup className="mb-3">
          <DropdownButton
            variant="outline-secondary"
            title={
              user.role > 2
                ? user.country
                : community.countryId
                ? countries.filter(
                    (country) => country.id == community.countryId
                  )[0]?.name
                : "Select Country"
            }
            id="input-group-dropdown-1"
            onSelect={(e) => {
              handleChange({ target: { id: "countryId", value: e } });
            }}
            disabled={mode === "preview"}
          >
            {user.role > 2 ? (
              ""
            ) : (
              <>
                <Dropdown.Item eventKey={undefined}>Select None</Dropdown.Item>
                {countries.map((country, index) => (
                  <Dropdown.Item eventKey={country.id} key={index}>
                    {country.name}
                  </Dropdown.Item>
                ))}
              </>
            )}
          </DropdownButton>
          <DropdownButton
            variant="outline-secondary"
            title={
              (community.communityId
                ? mainCommunities.filter(
                    (c) => c.id == community.communityId
                  )[0]?.name
                : "Select Main CSO") || "Select Main CSO"
            }
            id="input-group-dropdown-1"
            onSelect={(e) => {
              handleChange({ target: { id: "communityId", value: e } });
            }}
            disabled={mode === "preview"}
          >
            {user?.communityId
              ? ""
              : mainCommunities.map((c, index) => (
                  <Dropdown.Item eventKey={c.id} key={index}>
                    {c.name}
                  </Dropdown.Item>
                ))}
          </DropdownButton>
        </InputGroup>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <button
            className="btn btn-primary mb-3"
            onClick={handleSubmit}
            disabled={mode === "preview"}
          >
            Save
          </button>
        </div>

        {msg.message.length > 0 &&
          msg.message.map((m, index) => (
            <Alert key={index} variant={msg.variant}>
              {m}
            </Alert>
          ))}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default HubSideBar;
