import React, { useContext, useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { userContext } from "../pages/Context";
import axiosInstance from "../utility/axios";
import { useLanguage } from "../utility/LanguageContext";
import TopImages from "../components/TopImages";
import Notification from "../components/Notification";
import "./TopImage.css";

function TopImage() {
  const { user } = useContext(userContext);
  const [countries, setCountries] = useState([]);
  const { countryMap } = useLanguage();
  const [selectedTab, setSelectedTab] = useState("en");
  const [notificationData, setNotificationData] = useState([]);

  useEffect(() => {
    axiosInstance.get("/user/country/list").then((res) => {
      setCountries(res.data);
    });
  }, []);

  return (
    <section className="user-section" id="user-section">
      <Notification
        notificationData={notificationData}
        setNotificationData={setNotificationData}
      />
      <div className="user-content">
        <Tabs
          defaultActiveKey={user.role < 3 ? "en" : countryMap[user.countryId]}
          id="uncontrolled-tab-example"
          onSelect={(k) => setSelectedTab(k)}
          className="mb-3"
        >
          {countries?.map((country, index) => {
            if (user.role < 3 || user.countryId === country.id) {
              return (
                <Tab
                  eventKey={countryMap[country.id]}
                  title={country.name}
                  key={index}
                >
                  <TopImages
                    countryCode={selectedTab}
                    setNotificationData={setNotificationData}
                  />
                </Tab>
              );
            }
          })}
        </Tabs>
      </div>
    </section>
  );
}
export default TopImage;
