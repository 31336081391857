import React, { useState, useEffect, useContext } from "react";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Pagination from "./Pagination";
import axiosInstance from "../utility/axios";
import DeleteConfirmation from "./DeleteModal";
import Offcanvas from "react-bootstrap/Offcanvas";
import { userContext } from "../pages/Context";
import { ROLES } from "../utility/constants";
import NoDataPresent from "./NoDataPresent";

const ManageGroupUsers = ({
  selectedCountry,
  setSelectedCountry,
  groupList,
  setNotificationData,
  notificationData,
}) => {
  const { user } = useContext(userContext);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedGroup, setSelectedGroup] = useState(undefined);
  const [search, setSearch] = useState("");
  const [isModerator, setIsModerator] = useState(0);
  const [countries, setCountries] = useState([]);
  const [data, setData] = useState([]);
  const [deleteId, setDeleteId] = useState(undefined);
  const [showModal, setShowModal] = useState(false);
  const [showCanvas, setShowCanvas] = useState(false);
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [msg, setMsg] = useState("");
  const [createdByMe, setCreatedByMe] = useState(false);

  const fetchData = () => {
    if (selectedGroup?.id) {
      let query = `?search=${search}&groupId=${selectedGroup?.id}&isModerator=${isModerator}&offset=${pagination.offset}&limit=${pagination.limit}`;
      axiosInstance.get(`/group-requests/group-users${query}`).then((res) => {
        setMsg(res.data.message);
        setCreatedByMe(Boolean(res.data.createdByMe));
        if (res.status === 200) {
          setData(res.data?.rows);
          setTotalRecords(res.data?.count);
        } else {
          setData([]);
          setTotalRecords(0);
        }
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedGroup, pagination, isModerator]);

  useEffect(() => {
    axiosInstance.get("/user/country/list").then((res) => {
      setCountries(res.data || []);
    });
  }, []);

  const fetchUsersList = () => {
    axiosInstance.get(`/user/list?search=${search}&limit=${20}`).then((res) => {
      if (res.status === 200) {
        setUserList(res.data.users);
      } else {
        setUserList([]);
      }
    });
  };

  useEffect(() => {
    if (showCanvas) fetchUsersList();
  }, [showCanvas]);

  const deleteUserFromGroup = () => {
    axiosInstance
      .delete(
        `/group-requests/delete-user/${deleteId?.groupId}/${deleteId?.id}`
      )
      .then((res) => {
        setNotificationData([
          {
            msg: res.data.message,
            status: res.status,
          },
        ]);
        fetchData();
      });
  };

  const deleteModerator = () => {
    axiosInstance
      .delete(
        `/group-requests/remove-moderator/${deleteId?.groupId}/${deleteId?.id}`
      )
      .then((res) => {
        setNotificationData([
          {
            msg: res.data.message,
            status: res.status,
          },
        ]);
        fetchData();
      });
  };

  const addModerator = async () => {
    await axiosInstance
      .post(`/group-requests/add-moderator`, {
        userId: selectedUser?.id,
        groupId: selectedGroup?.id,
      })
      .then((res) => {
        setNotificationData([
          {
            msg: res.data.message,
            status: res.status,
          },
        ]);
        fetchData();
      });
    setShowCanvas(false);
  };

  const DropdownSearch = React.forwardRef((props, ref) => {
    const { children, style, className, "aria-labelledby": labeledBy } = props;
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
          style={{ position: "sticky", top: "0px" }}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child?.props?.children.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  });

  return (
    <>
      <Offcanvas
        show={showCanvas}
        onHide={() => {
          setShowCanvas(false);
          setSelectedUser(null);
        }}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Moderator</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <InputGroup style={{ width: "fit-content" }}>
            <Dropdown
              onSelect={(e) => {
                setSelectedUser(JSON.parse(e));
              }}
            >
              <Dropdown.Toggle
                variant="outline-secondary"
                id="input-group-dropdown-1"
              >
                {selectedUser ? selectedUser.name : "Select User"}
              </Dropdown.Toggle>
              <Dropdown.Menu as={DropdownSearch}>
                <Dropdown.Item eventKey={undefined}>Select None</Dropdown.Item>
                {userList.map((user, index) => (
                  <Dropdown.Item eventKey={JSON.stringify(user)} key={index}>
                    {user.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </InputGroup>
          <div className="mt-4">
            <h4>Name: {selectedUser?.name}</h4>
            <h4>Email: {selectedUser?.email}</h4>
            <h4>Role: {ROLES[selectedUser?.role]}</h4>
            <h4>Country: {selectedUser?.country}</h4>
            <h4>Main CSO: {selectedUser?.community}</h4>
            <h4>Registered CSO: {selectedUser?.subCommunity}</h4>
          </div>
          <button
            type="button"
            className="btn btn-secondary float-end"
            onClick={() => addModerator()}
            disabled={!selectedUser}
          >
            Add
          </button>
        </Offcanvas.Body>
      </Offcanvas>
      <DeleteConfirmation
        showModal={showModal}
        hideModal={() => {
          setShowModal(false);
          setDeleteId(undefined);
        }}
        confirmModal={() => {
          if (isModerator) {
            deleteModerator();
          } else {
            deleteUserFromGroup();
          }
          setShowModal(false);
        }}
        message={`Are you sure you want to delete ${deleteId?.name}?`}
      />
      <div
        className="mb-4 table-filters"
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        {" "}
        <div style={{ display: "flex" }}>
          {" "}
          <div style={{ display: "flex", marginRight: "20px" }}>
            <input
              type="search"
              placeholder="Search by name"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              aria-describedby="button-addon5"
              className="form-control"
              style={{ borderRadius: ".375rem 0px 0px .375rem" }}
            />
            <div className="input-group-append">
              <button
                id="button-addon5"
                type="button"
                onClick={fetchData}
                className="btn btn-primary color3863A0"
                style={{
                  borderRadius: "2px",
                  height: "100%",
                }}
              >
                <i className="bx bx-search"></i>
              </button>
            </div>
          </div>
          {user.role < 3 && (
            <InputGroup className="mx-3" style={{ width: "fit-content" }}>
              <DropdownButton
                variant="outline-secondary"
                title={
                  selectedCountry ? selectedCountry["name"] : "Select Country"
                }
                id="input-group-dropdown-1"
                onSelect={(e) => {
                  setSelectedCountry(JSON.parse(e));
                }}
              >
                <Dropdown.Item eventKey={undefined}>Select None</Dropdown.Item>
                {countries.map((country, index) => (
                  <Dropdown.Item eventKey={JSON.stringify(country)} key={index}>
                    {country.name}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </InputGroup>
          )}
          <InputGroup style={{ width: "fit-content" }}>
            <Dropdown
              onSelect={(e) => {
                setSelectedGroup(JSON.parse(e));
              }}
            >
              <Dropdown.Toggle
                variant="outline-secondary"
                id="input-group-dropdown-1"
              >
                {selectedGroup ? selectedGroup.title : "Select Group"}
              </Dropdown.Toggle>
              <Dropdown.Menu as={DropdownSearch}>
                <Dropdown.Item eventKey={undefined}>Select None</Dropdown.Item>
                {groupList.map((group, index) => (
                  <Dropdown.Item eventKey={JSON.stringify(group)} key={index}>
                    {group.title}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </InputGroup>
        </div>
        <InputGroup style={{ width: "fit-content" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1em",
            }}
          >
            <input
              type="checkbox"
              name=""
              value={isModerator}
              id="isModerator"
              onChange={(e) => setIsModerator(e.target.checked ? 1 : 0)}
            />{" "}
            <label htmlFor="isModerator" className="m-0">
              Show Moderators
            </label>
          </div>
        </InputGroup>
      </div>{" "}
      {!!isModerator && (
        <div className="justify-content-end d-flex mb-3">
          <button
            className="btn btn-secondary"
            type="button"
            onClick={() => setShowCanvas(true)}
            disabled={!selectedGroup?.id}
          >
            Add Moderator
          </button>
        </div>
      )}
      {createdByMe && "This Group is created by you."}
      <br />
      {msg}
      {data.length === 0 ? (
        <NoDataPresent />
      ) : (
        <>
          {" "}
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th className="table-head">#</th>
                <th className="table-head">Name</th>
                <th className="table-head">Email</th>
                <th className="table-head">Revoke Access</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{pagination.offset + index + 1}</td>
                  <td>
                    {isModerator
                      ? item.User?.username
                      : item.public_user?.firstName +
                        " " +
                        item.public_user?.lastName}
                  </td>
                  <td>
                    {isModerator ? item.User?.email : item.public_user?.email}
                  </td>
                  <td>
                    {createdByMe && (
                      <i
                        className="fa-solid fa-trash-can fa-1x"
                        onClick={() => {
                          setDeleteId({
                            groupId: item.chatGroupId,
                            id: isModerator ? item.UserId : item.publicUserId,
                            name: isModerator
                              ? item.User?.username
                              : item.public_user?.firstName,
                          });
                          setShowModal(true);
                        }}
                      ></i>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            setPagination={setPagination}
            totalRecords={totalRecords}
          />
        </>
      )}
    </>
  );
};

export default ManageGroupUsers;
