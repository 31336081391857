import { useState, useEffect, useContext } from "react";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Table from "react-bootstrap/Table";
import Pagination from "../components/Pagination";
import axiosInstance from "../utility/axios";
import Notification from "../components/Notification";
import { useLoading } from "../utility/LoadingContext";
import { formatDate } from "../utility/utils";
import { userContext } from "./Context";
import NoDataPresent from "../components/NoDataPresent";

const Newsletter = () => {
  const { user } = useContext(userContext);
  const [selectedCountry, setSelectedCountry] = useState(undefined);
  const [countries, setCountries] = useState([]);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [newsletters, setNewsletters] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const { setLoading } = useLoading();

  const fetchData = () => {
    let query = `?offset=${pagination.offset}&limit=${pagination.limit}`;
    if (user.role < 3 && selectedCountry) {
      query += `&country_id=${selectedCountry.id}`;
    } else if (user.role >= 3) {
      query += `&country_id=${user.countryId}`;
    }
    axiosInstance.get(`/newsletter${query}`).then((res) => {
      if (res.status === 200) {
        setNewsletters(res.data.newsletters);
        setTotalRecords(res.data.count);
      } else {
        setNewsletters([]);
        setTotalRecords(0);
      }
    });
  };

  const exportData = async () => {
    if (user.role < 3 && !selectedCountry?.id) {
      setNotificationData([
        {
          msg: "Please select country to export data",
          status: 400,
        },
      ]);
    } else {
      setLoading(true);
      setNotificationData([
        {
          msg: "Please Note this might take a while",
          status: 200,
        },
      ]);
      const country_id = user.role < 3 ? selectedCountry?.id : user.countryId;
      await axiosInstance
        .get(`/newsletter/export?country_id=${country_id}`, {
          responseType: "blob",
        })
        .then((res) => {
          if (res.status === 200) {
            const url = URL.createObjectURL(res.data);
            const a = document.createElement("a");
            a.href = url;
            a.download = "user_emails.csv";
            a.click();
            URL.revokeObjectURL(url);
            setNotificationData([
              {
                msg: "Export Successful",
                status: 200,
              },
            ]);
          } else {
            setNotificationData([
              {
                msg: res.data.message,
                status: res.status,
              },
            ]);
          }
        });

      setLoading(false);
    }
  };

  useEffect(() => {
    axiosInstance.get("/user/country/list").then((res) => {
      setCountries(res.data);
    });
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [pagination, selectedCountry]);

  return (
    <>
      <Notification
        notificationData={notificationData}
        setNotificationData={setNotificationData}
      />
      <section className="user-section" id="user-section">
        <div className="user-content">
          <div style={{ display: "flex" }}>
            {user.role < 3 && (
              <DropdownButton
                className="me-3"
                variant="outline-secondary"
                title={
                  selectedCountry ? selectedCountry["name"] : "Select Country"
                }
                id="input-group-dropdown-1"
                onSelect={(e) => {
                  setSelectedCountry(JSON.parse(e));
                }}
              >
                <Dropdown.Item eventKey={undefined}>Select None</Dropdown.Item>
                {countries.map((country, index) => (
                  <Dropdown.Item eventKey={JSON.stringify(country)} key={index}>
                    {country.name}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            )}
            <button
              type="button"
              className="btn btn-secondary"
              onClick={exportData}
            >
              Export Data
            </button>
          </div>

          {newsletters.length === 0 ? (
            <NoDataPresent />
          ) : (
            <>
              <Table striped bordered responsive className="mt-2">
                <thead>
                  <tr>
                    <th className="table-head">#</th>
                    <th className="table-head">Email</th>
                    <th className="table-head">Country</th>
                    <th className="table-head">Date of Subscription</th>
                  </tr>
                </thead>
                <tbody>
                  {newsletters.map((newsletter, index) => (
                    <tr key={index}>
                      <td>{pagination.offset + index + 1}</td>
                      <td>{newsletter.email}</td>
                      <td>{newsletter.countryName}</td>
                      <td>{formatDate(newsletter.date)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Pagination
                setPagination={setPagination}
                totalRecords={totalRecords}
              />
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Newsletter;
