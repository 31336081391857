import { React } from "react";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import Pagination from "./Pagination";
import NoDataPresent from "./NoDataPresent";

const ChatGroups = ({
  data,
  showCreateGroupBar,
  setShowCreateGroupBar,
  setEditDetails,
  search,
  setSearch,
  getGroups,
  setDeleteId,
  getGroupData,
  pagination,
  setPagination,
  totalRecords,
}) => {
  const handlerRightBar = () => {
    setShowCreateGroupBar(!showCreateGroupBar);
    setEditDetails({});
  };

  return (
    <>
      <div
        className="input-group mb-4 table-filters"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div style={{ display: "flex" }}>
          <input
            type="search"
            placeholder="Search by name"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            aria-describedby="button-addon5"
            className="form-control"
            style={{ borderRadius: ".375rem 0px 0px .375rem" }}
          />
          <div className="input-group-append">
            <button
              id="button-addon5"
              type="button"
              onClick={getGroups}
              className="btn btn-primary color3863A0"
              style={{
                borderRadius: "2px",
                height: "100%",
              }}
            >
              <i className="bx bx-search"></i>
            </button>
          </div>
        </div>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={handlerRightBar}
        >
          Create Group
        </button>
      </div>
      {data.length === 0 ? (
        <NoDataPresent />
      ) : (
        <>
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th className="table-head">#</th>
                <th className="table-head">Group Name</th>
                <th className="table-head">Country</th>
                <th className="table-head">Date</th>
                <th className="table-head">Edit</th>
                <th className="table-head">Delete</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{pagination.offset + index + 1}</td>
                  <td>{item.title}</td>
                  <td>
                    {item.countries.map((country) => (
                      <Badge bg="light" text="dark" key={country.id + "cid"}>
                        {country.name}
                      </Badge>
                    ))}
                  </td>
                  <td>
                    {new Date(item.createdAt).toLocaleDateString("en-US", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })}
                  </td>
                  <td>
                    <i
                      className="fa-solid fa-pen-to-square fa-1x"
                      onClick={() => {
                        getGroupData(item.id);
                        setShowCreateGroupBar(true);
                      }}
                    ></i>
                  </td>
                  <td>
                    <i
                      className="fa-solid fa-trash-can fa-1x"
                      onClick={() => setDeleteId(item)}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            setPagination={setPagination}
            totalRecords={totalRecords}
          />
        </>
      )}
    </>
  );
};

export default ChatGroups;
