import React from "react";
import Badge from "react-bootstrap/Badge";
import "./StatusCard.css";

const StatusCard = (props) => {
  return (
    <div className="status-card">
      <div className="status-card__info">
        <span>
          {" "}
          <Badge bg="info" text="light">
            {props.count}
          </Badge>
        </span>{" "}
        <span>{props.title}</span>
      </div>
    </div>
  );
};

export default StatusCard;
