import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import "./IssuePage.css";
import axiosInstance from "../utility/axios";
import { useContext } from "react";
import { userContext } from "./Context";
import { useParams } from "react-router-dom";
import { formatDate, formatDateAndTime } from "../utility/utils";

const IssuePage = () => {
  const [issueData, setIssueData] = useState({});
  const [showButton, setShowButton] = useState(false);
  const [comment, setComment] = useState("");
  const { id } = useParams();
  const ctx = useContext(userContext);

  const getIssue = () => {
    axiosInstance
      .get(`/issues/getIssue/${id}`)
      .then((response) => {
        if (response.status === 200) {
          setIssueData(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axiosInstance
      .post("/issues/createIssueComment", {
        description: comment,
        isAdmin: issueData.userId === ctx.user.id ? true : false,
        userId: ctx.user.id,
        userName: ctx.user.username,
        issueId: issueData.id,
      })
      .then((response) => {
        if (response.status === 200) {
          setComment("");
          getIssue();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleStatus = (e) => {
    e.preventDefault();
    if (comment.length > 0) {
      handleSubmit(e);
    }
    axiosInstance
      .patch(`/issues/updateIssue/${id}`, {
        ...issueData,
        isClosed: true,
      })
      .then((response) => {
        if (response.status === 200) {
          getIssue();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    comment.length > 0 ? setShowButton(true) : setShowButton(false);
  }, [comment]);

  useEffect(() => {
    getIssue();
  }, []);

  return (
    <section className="user-section" id="user-section">
      <div className="user-content">
        <div className="issue-page-single">
          <Container className="issue-page-single-container mt-4">
            <div className="d-flex justify-content-between">
              <h2>{issueData.title}</h2>
              <Link to="/home/issues">
                <Button className="back-btn">Back</Button>
              </Link>
            </div>
            <Row>
              <div className="sub-heading-text-button">
                {!issueData.isClosed ? (
                  <div className="p-3 pt-2 pb-2 open-btn rounded-pill">
                    Open
                  </div>
                ) : (
                  <div className="p-3 pt-2 pb-2 closed-btn rounded-pill">
                    Closed
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div className="sub_text_single">
                    created by user: {issueData.userName}
                  </div>
                </div>
                <div className="sub_text_single">
                  {issueData.createdAt && formatDate(issueData.createdAt)}
                </div>
              </div>
            </Row>
            <Row className="mt-2">
              <Container className="comment-box mx-0 mt-4 pt-0">
                <div className="comment-user-name p-3">
                  <div>
                    <span style={{ color: "#666e77", fontSize: "14px" }}>
                      Open by
                    </span>{" "}
                    {issueData.userName}
                  </div>
                  <div
                    className="author-name-bubble rounded-pill p-2 pt-1 pb-1"
                    style={{
                      color: "var(--bs-link-color)",
                    }}
                  >
                    Description
                  </div>
                </div>
                <div className="p-3 px-4">{issueData.description}</div>
              </Container>
            </Row>
            {issueData.comments &&
              issueData.comments.map((comment) => (
                <Row className="mt-2" key={comment.id}>
                  <Container className="comment-box mx-0 mt-4 pt-0">
                    <div className="comment-user-name p-3">
                      <div>{comment.userName}</div>
                      {comment.isAdmin && (
                        <div className="author-name-bubble rounded-pill p-2 pt-1 pb-1">
                          Author
                        </div>
                      )}
                    </div>
                    <div className="p-3 px-4">{comment.description}</div>
                    <div
                      style={{
                        fontSize: "12px",
                        display: "flex",
                        margin: "5px",
                        justifyContent: "flex-end",
                      }}
                    >
                      {comment.createdAt &&
                        formatDateAndTime(comment.createdAt)}
                    </div>
                  </Container>
                </Row>
              ))}

            <Row className="mt-4">
              {!issueData.isClosed && (
                <>
                  <hr className="mt-5 mb-0 horizontal-line" />
                  <textarea
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    className="comment-box comment-textarea mx-0 mt-4 p-2 px-3 pe-3"
                  ></textarea>
                  <div className="mt-3">
                    {showButton ? (
                      <>
                        <Button variant="success" onClick={handleSubmit}>
                          Comment
                        </Button>
                        <Button
                          className="mx-3"
                          variant="secondary"
                          onClick={handleStatus}
                        >
                          Close Issue with Comment
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button variant="success" disabled>
                          Comment
                        </Button>
                        <Button
                          className="mx-3"
                          variant="secondary"
                          onClick={handleStatus}
                        >
                          Close Issue
                        </Button>
                      </>
                    )}
                  </div>
                </>
              )}
            </Row>
          </Container>
        </div>
      </div>
    </section>
  );
};

export default IssuePage;
