import React, { useEffect, useState } from "react";
import { Alert, Row, Table } from "react-bootstrap";
import axiosInstance from "../utility/axios";
import Compressor from "compressorjs";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { useLoading } from "../utility/LoadingContext";
import { signInWithCustomToken, signOut } from "firebase/auth";
import { auth } from "../firebase-config";

const ENV = process.env.NODE_ENV === "development" ? "_test" : "";

export default function TopImages({ countryCode, setNotificationData }) {
  const [navbarData, setNavbarData] = useState();
  const [fileError, setFileError] = useState("");
  const { setLoading } = useLoading();
  const [uploadImageProgress, setUploadImageProgress] = useState(0);

  useEffect(() => {
    getNavbarData();
  }, [countryCode]);

  const getNavbarData = async () => {
    await axiosInstance
      .get(`/siteData/getTopImage/${countryCode}`)
      .then((response) => {
        if (response.status === 200) {
          setNavbarData(response.data.response);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const saveSectionImage = (image, title) => {
    axiosInstance
      .post(`/siteData/topImage`, {
        countryCode,
        title,
        image,
      })
      .then((response) => {
        if (response.status === 200) {
          getNavbarData(countryCode);
        }
        setNotificationData([
          {
            msg: response.data.message,
            status: response.status,
          },
        ]);
      })
      .catch((err) => {
        console.log(err, "ERROR");
      });
  };
  const uploadFileToFirebase = (file, uploadUrl, setUploadProgress) => {
    return new Promise(async (resolve, reject) => {
      try {
        const storage = getStorage();
        const storageRef = ref(storage, uploadUrl);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
            switch (snapshot.state) {
              case "paused":
                break;
              case "running":
                break;
            }
          },
          (error) => {
            console.log("Upload error", error);
            reject(error);
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              // console.log("File available at", downloadURL);
              resolve(downloadURL); // Resolve the promise with the downloadURL
            } catch (error) {
              // console.log("Error getting download URL", error);
              reject(error);
            }
          }
        );
      } catch (error) {
        reject(error);
      }
      signOut(auth);
    });
  };
  const handleImageChange = async (e, title) => {
    setLoading(true);
    const file = e.target.files[0];
    if (file) {
      if (file.type.match("image.*") && file.size <= 5000000) {
        setFileError({ ...fileError, [e.target.name]: "" });
        try {
          const fileName = file.name;
          const uploadUrl = `public/home/topImages/${countryCode}${title}${ENV}_img`;
          const token = await axiosInstance.post("/site/generate-signed-url");

          if (token.status !== 200) {
            setNotificationData([
              {
                msg: "Image upload failed",
                status: 500,
              },
            ]);
            return;
          }

          const user = await signInWithCustomToken(auth, token.data.url);

          const compressedBlob = await new Promise((resolve, reject) => {
            new Compressor(file, {
              quality: 0.6,
              success(result) {
                resolve(result);
              },
              error(err) {
                reject(err);
              },
            });
          });

          const compressedFile = new File([compressedBlob], file.name, {
            type: file.type,
          });
          const fileURL = await uploadFileToFirebase(
            compressedFile,
            uploadUrl,
            setUploadImageProgress
          );
          const image = {
            name: fileName,
            url: fileURL,
          };

          saveSectionImage(image, title);
        } catch (error) {
          console.error("Error uploading image:", error);
        }
      } else {
        // setFileError({
        //   ...fileError,
        //   [e.target.name]: "File can have max size of 5MB",
        // });
        setNotificationData([
          {
            msg: "Selected file is not an image or it is larger than 5MB",
            status: 400,
          },
        ]);
      }
    }
    setLoading(false);
  };
  return (
    <div>
      <Table striped bordered responsive>
        <thead>
          <tr>
            <td className="table-head">PAGE</td>
            <td className="table-head">IMAGE</td>
            <td className="table-head"></td>
          </tr>
        </thead>
        <tbody>
          {navbarData?.jsonData[countryCode].map((item, index) => {
            return (
              <>
                {item.child.length > 0 ? (
                  ""
                ) : item.id == "home" ||
                  item.id == "signin" ||
                  item.id == "signup" ||
                  item.id == "terms_condition" ||
                  item.id == "privacy_policy" ? null : (
                  <tr key={index}>
                    <td className="table-data">
                      <div className="table-content">{item.title}</div>
                    </td>
                    <td className="table-data">
                      <div className="table-content">
                        <img
                          alt="image"
                          src={navbarData?.imageUrls[item.id]}
                          className="top-image"
                        ></img>
                      </div>
                    </td>
                    <td className="add_image table-data ">
                      <div className="table-content">
                        <button>Change</button>
                        <input
                          accept=".jpg,.jpeg,.png"
                          multiple={false}
                          name="topImage"
                          type="file"
                          onChange={(e) => {
                            handleImageChange(e, item.id);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                )}
                {item.child.length > 0 &&
                  item.child.map((childData, pos) => {
                    return childData.id == "online_streaming" ||
                      childData.id == "facebook" ||
                      childData.id == "other_ovoc_media" ? null : (
                      <tr key={pos}>
                        <td className="table-data">
                          <div className="table-content">{childData.title}</div>
                        </td>
                        <td className="table-data">
                          <div className="table-content">
                            <img
                              alt="image"
                              src={navbarData?.imageUrls[childData.id]}
                              className="top-image"
                            ></img>
                          </div>
                        </td>
                        <td className="add_image table-data">
                          <div className="table-content">
                            <button>Change</button>
                            <input
                              accept=".jpg,.jpeg,.png"
                              multiple={false}
                              name="topImage"
                              type="file"
                              onChange={(e) => {
                                handleImageChange(e, childData.id);
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}
