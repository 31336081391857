import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";
import StatusCard from "./StatusCard";
import { useEffect } from "react";
import { useState } from "react";
import NoDataPresent from "./NoDataPresent";

const counter_skeleton = {
  blog: { title: "Blogs", count: 0 },
  article: { title: "Articles", count: 0 },
  case_study: { title: "Case Stories", count: 0 },
  campaign: { title: "Campaigns", count: 0 },
  event: { title: "Events", count: 0 },
  webinar: { title: "Webinars", count: 0 },
  news: { title: "News", count: 0 },
  video: { title: "Videos", count: 0 },
  course: { title: "Courses", count: 0 },
  tools: { title: "Tools", count: 0 },
  document: { title: "Document", count: 0 },
  report: { title: "Report", count: 0 },
  advocacy: { title: "Advocacy", count: 0 },
};

const MyBoard = ({ data, publishStage, setPreviewData, setDeleteId }) => {
  const [statusCards, setStatusCards] = useState(counter_skeleton);
  useEffect(() => {
    let updatedStatusCards = Object.assign({}, counter_skeleton);

    data.forEach((item) => {
      const { resource_type } = item;

      if (updatedStatusCards.hasOwnProperty(resource_type)) {
        updatedStatusCards[resource_type] = {
          ...updatedStatusCards[resource_type],
          count: updatedStatusCards[resource_type].count + 1,
        };
      }
    });
    setStatusCards(updatedStatusCards);
  }, [data]);

  return (
    <>
      {data.length === 0 ? (
        <NoDataPresent />
      ) : (
        <div>
          <div className="d-flex gap-2 flex-wrap mb-4">
            {Object.values(statusCards).map(
              (item, index) =>
                item.count > 0 && (
                  <StatusCard
                    key={index}
                    icon={item.icon}
                    count={item.count}
                    title={item.title}
                  />
                )
            )}
          </div>
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th className="table-head">#</th>
                <th className="table-head">Title</th>
                <th className="table-head">Resource Type</th>
                <th className="table-head">Current Status</th>
                <th className="table-head">Date</th>
                <th className="table-head">Preview</th>
                <th className="table-head">Delete</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.title}</td>
                  <td>{item.resource_type}</td>
                  <td>
                    <Badge bg="light" text="dark">
                      {publishStage[item.status]}
                    </Badge>
                    {item.status === 1 && item.comment ? (
                      <p>
                        <b>Reason for Rejection: </b> {item.comment}
                      </p>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>{item.date}</td>
                  <td>
                    <i
                      className="bx bx-spreadsheet bx-flip-horizontal bx-sm"
                      onClick={() => {
                        setPreviewData({
                          show: "fetch",
                          id: item.id,
                          resource_type: item.resource_type,
                        });
                      }}
                      style={{ cursor: "pointer" }}
                    ></i>
                  </td>
                  <td>
                    <i
                      className="bx bxs-trash bx-sm"
                      onClick={() => setDeleteId(item)}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </>
  );
};

export default MyBoard;
