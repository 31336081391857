import axiosInstance from "../utility/axios";
import { useEffect, useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import DropdownButton from "react-bootstrap/DropdownButton";
import Badge from "react-bootstrap/Badge";
import Dropdown from "react-bootstrap/Dropdown";
import BacsPreview from "../components/BacsPreview";
import Table from "react-bootstrap/Table";
import Pagination from "../components/Pagination";
import EditBacs from "../components/EditBacs";
import DeleteConfirmation from "../components/DeleteModal";
import DeactivateConfirmation from "../components/DeleteModal";
import Notification from "../components/Notification";
import Compressor from "compressorjs";
import "./Bacs.css";
import { userContext } from "./Context";
import NoDataPresent from "../components/NoDataPresent";

const publishStage = {
  1: "Changes Required",
  2: "In Review",
  3: "published",
};
const bacs_category = ["Knowledge", "Information", "Global", "General"];
const bacs_types = ["blog", "article", "case_study"];

const bacs_types_name = {
  blog: "Blog",
  article: "Article",
  case_study: "Case Story",
};

const Bacs = () => {
  const { user } = useContext(userContext);
  const [fileError, setFileError] = useState("");
  const [bacsImageTracker, setBacsImageTracker] = useState([]);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [bacsList, setBacsList] = useState([]);
  const [state, setState] = useState();
  const [editBacs, setEditBacs] = useState("");
  const [showBacs, setShowBacs] = useState(false);
  const [itemId, setItemId] = useState(-1);
  const [tags, setTags] = useState([]);
  const [addTag, setAddTag] = useState(false);
  const [tagMsg, setTagMsg] = useState("");
  const [form, setForm] = useState({
    id: "",
    title: "",
    date: "",
    tag: [],
    comments: [],
    bannerImage: "",
    bannerImageURL: "",
    bannerImageExt: "",
    bannerImageId: "",
    content: "",
    countryIds: [],
    category: "",
    bacs_type: "",
    publishStage: undefined,
    authorName: "",
    authorDescription: "",
    authorImage: "",
  });

  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState(undefined);
  const [selectedStage, setSelectedStage] = useState(undefined);
  const [countries, setCountries] = useState([]);
  const [category, setCategory] = useState(undefined);
  const [bacs_type, setBacsType] = useState(undefined);
  const [msg, setMsg] = useState({
    message: [],
    variant: "danger",
  });
  const [notificationData, setNotificationData] = useState([]);
  const [showDeactivateConfirmation, setShowDeactivateConfirmation] =
    useState(false);
  const [deactivateId, setDeactivateId] = useState(undefined);

  useEffect(() => {
    axiosInstance.get("/user/country/list").then((res) => {
      setCountries(res.data);
    });
  }, []);

  useEffect(() => {
    bacsImageTracker.forEach((imageURL, idx) => {
      if (form.content.search(imageURL) === -1) {
        setBacsImageTracker([
          ...bacsImageTracker.filter((url) => url !== imageURL),
        ]);
        axiosInstance
          .post(`/bacs/image/remove`, {
            filePath: imageURL,
          })
          .then((response) => {})
          .catch((err) => {});
      }
    });
  }, [form.content]);

  const uploadAdapter = (loader) => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("file", file);
            fetch(`${process.env.REACT_APP_API}/bacs/image/uploads`, {
              method: "post",
              body: body,
              credentials: "include",
              // mode: "no-cors"
            })
              .then((res) => res.json())
              .then((res) => {
                setBacsImageTracker([...bacsImageTracker, res.url]);
                resolve({
                  default: `${process.env.REACT_APP_BUCKET_URL}${res.url}`,
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  };

  const uploadPlugin = (editor) => {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleTagChange = (e) => {
    let currID = parseInt(e.target.value);
    if (form.tag.some((t) => t === currID)) {
      setForm((prevForm) => ({
        ...prevForm,
        tag: form.tag.filter((t) => t !== currID),
      }));
    } else {
      setForm((prevForm) => ({
        ...prevForm,

        tag: [...form.tag, currID],
      }));
    }
  };

  const createTag = (tagName) => {
    axiosInstance
      .post(`/bacs/create/tags`, { name: tagName })
      .then((response) => {
        if (response.status === 200) {
          updateTags();
          setAddTag(false);
          setTagMsg(response.data?.message);
        } else {
          setTagMsg("Unable to create tag");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removeTag = (id) => {
    axiosInstance
      .delete(`/bacs/delete/tags/${id}`)
      .then((response) => {
        if (response.status === 200) {
          updateTags();
          setTagMsg(response.data?.message);
        } else {
          setTagMsg("Unable to delete tag");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file && file.type.match("image.*") && file.size <= 5000000) {
      setForm({ ...form, [e.target.name]: file });
      setFileError({ ...fileError, [e.target.name]: "" });
      try {
        const compressedBlob = await new Promise((resolve, reject) => {
          new Compressor(file, {
            quality: 0.6,
            success(result) {
              resolve(result);
            },
            error(err) {
              reject(err);
            },
          });
        });

        const compressedFile = new File([compressedBlob], file.name, {
          type: file.type,
        });
        setForm({ ...form, [e.target.name]: compressedFile });
      } catch (error) {
        console.log(error.message);
      }
    } else {
      setFileError({
        ...fileError,
        [e.target.name]: "File can have max size of 5MB",
      });
    }
  };

  const handleContentChange = (event, editor) => {
    setForm((prevForm) => ({ ...prevForm, content: editor.getData() }));
  };

  const removeSelectedFile = (name) => {
    const fileInput = document.getElementById(name);
    fileInput.value = "";

    setForm({ ...form, [name]: "" });
    setFileError({ ...fileError, [name]: "" });
  };

  const updateTable = () => {
    let query = `search=${search}&offset=${pagination.offset}&limit=${pagination.limit}`;
    if (user.role < 3 && selectedCountry) {
      query += `&country_id=${selectedCountry.id}`;
    } else if (user.role >= 3) {
      query += `&country_id=${user.countryId}`;
    }
    if (selectedStage) query += `&stage_id=${selectedStage}`;
    if (category) query += `&category=${category}`;
    if (bacs_type) query += `&bacs_type=${bacs_type}`;
    axiosInstance
      .get(`/bacs/get/all?${query}`)
      .then((res) => {
        if (res.data.bacss.length && typeof res.data.bacss === "object") {
          setBacsList([...res.data.bacss]);
          setTotalRecords(res.data.count || 0);
        } else {
          setBacsList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    updateTable();
  }, [selectedCountry, selectedStage, category, pagination, bacs_type]);

  const updateTags = () => {
    axiosInstance
      .get(`/bacs/get/tags`)
      .then((response) => {
        if (response.status === 200) {
          setTags([...response.data?.response]);
        } else {
          // TODO
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = (id) => {
    axiosInstance
      .delete(`/bacs/draft/${id}?indicator=2`)
      .then((response) => {
        if (response.status === 200) {
          updateTable();
          setBacsImageTracker([]);
        } else {
          // TODO: alert
        }
        setNotificationData([
          {
            msg: response.data.message,
            status: response.status,
          },
        ]);
      })
      .catch((err) => {});
    setShowModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("title", form.title);
    formData.append("date", form.date);
    formData.append("tagIds", [...form.tag]);
    formData.append("bannerImage", form.bannerImage);
    formData.append("content", form.content);
    formData.append("category", form.category);
    formData.append("authorName", form.authorName);
    formData.append("authorDescription", form.authorDescription);
    formData.append("authorImage", form.authorImage);
    formData.append(
      "countryIds",
      JSON.stringify(form.countryIds.map((country) => country.id))
    );
    formData.append("bacs_type", form.bacs_type);

    // update
    if (form.id) {
      formData.append("bannerImageId", form.bannerImageId);
      axiosInstance
        .patch(`/bacs/update/${form.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setEditBacs("");
            updateTable();
            setShowBacs(false);
            setBacsImageTracker([]);
            setMsg({
              message: [],
              variant: "danger",
            });
          } else {
            if (response.data.message === "Validation error")
              setMsg({
                message: response.data.error?.details?.map((e) => e.message),
                variant: "danger",
              });
            else if (response.data.message === "DB Error")
              setMsg({ message: [...response.data.error], variant: "danger" });
            else
              setMsg({ message: [response.data.message], variant: "danger" });
          }
          setNotificationData([
            {
              msg: response.data.message,
              status: response.status,
            },
          ]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    //create
    else {
      axiosInstance
        .post(`/bacs/create`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setEditBacs("");
            setShowBacs(false);
            updateTable();
            setBacsImageTracker([]);
            setMsg({
              message: [],
              variant: "danger",
            });
          } else {
            if (response.data.message === "Validation error")
              setMsg({
                message: response.data.error?.details?.map((e) => e.message),
                variant: "danger",
              });
            else if (response.data.message === "DB Error")
              setMsg({ message: [...response.data.error], variant: "danger" });
            else
              setMsg({ message: [response.data.message], variant: "danger" });
          }
          setNotificationData([
            {
              msg: response.data.message,
              status: response.status,
            },
          ]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const PreviewModal = () => {
    return (
      <Modal show={show} onHide={() => setShow(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Resource Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal_body">
          <BacsPreview form={form} tags={tags} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const fetchData = (id) => {
    axiosInstance
      .get(`/bacs/get/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setForm({
            id: res.data.bacs.id,
            title: res.data.bacs.title,
            date: res.data.bacs.date,
            tag: res.data.bacs.tags?.map((tag) => tag.id),
            ptags: res.data.bacs.tags,
            bannerImage: "",
            bannerImageURL: res.data.bacs.bannerImageURL,
            bannerImageExt: res.data.bacs.bannerImageExt,
            bannerImageName: res.data.bacs.bannerImageName,
            bannerImageId: res.data.bacs.bannerImageId,
            content: res.data.bacs.content,
            countryIds: res.data.bacs.countries,
            comments: res.data.bacs.comments,
            category: res.data.bacs.category,
            authorId: res.data.bacs.authorId,
            authorName: res.data.bacs.authorName,
            authorImage: res.data.bacs.authorImage,
            authorDescription: res.data.bacs.authorDescription,
            publishStage: res.data.bacs.publishStage,
            bacs_type: res.data.bacs.bacs_type,
          });
        } else {
          setEditBacs("");
          setForm({
            id: "",
            title: "",
            date: "",
            tag: [],
            comments: [],
            bannerImage: "",
            bannerImageURL: "",
            bannerImageExt: "",
            bannerImageId: "",
            content: "",
            countryIds: [],
            category: "",
            bacs_type: "",
            publishStage: undefined,
            authorName: "",
            authorDescription: "",
            authorImage: "",
          });
          setShowBacs(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (editBacs) fetchData(editBacs);
  }, [showBacs]);

  useEffect(() => {
    updateTable();
    updateTags();
    return () => {
      setState({});
    };
  }, []);

  const showPreview = (id) => {
    fetchData(id);
    setShow(true);
  };

  const deactivateBacs = (id, indicator) => {
    axiosInstance
      .delete(`/bacs/draft/${id}?indicator=${indicator}`)
      .then((res) => {
        if (res.status === 200) {
          updateTable();
        }
        setNotificationData([
          {
            msg: res.data.message,
            status: res.status,
          },
        ]);
      });
  };

  useEffect(() => {
    if (deactivateId) {
      setShowDeactivateConfirmation(true);
    }
  }, [deactivateId]);

  return (
    <>
      <DeactivateConfirmation
        showModal={showDeactivateConfirmation}
        hideModal={() => {
          setShowDeactivateConfirmation(false);
          setDeactivateId(undefined);
        }}
        confirmModal={() => {
          deactivateBacs(
            deactivateId?.id,
            deactivateId?.active_indicator === 1 ? 0 : 1
          );
          setDeactivateId(undefined);
          setShowDeactivateConfirmation(false);
        }}
        message={`Are you sure you want to ${
          deactivateId?.active_indicator === 1 ? "Deactivate" : "Activate"
        } ${deactivateId?.title}?`}
        btnMsg={
          deactivateId?.active_indicator === 1 ? "Deactivate" : "Activate"
        }
      />
      <DeleteConfirmation
        showModal={showModal}
        hideModal={() => {
          setShowModal(false);
        }}
        confirmModal={() => {
          handleDelete(itemId.id);
          setShowModal(false);
        }}
        message={`Are you sure you want to delete ${itemId?.title}?`}
      />
      <PreviewModal />
      <Notification
        notificationData={notificationData}
        setNotificationData={setNotificationData}
      />
      {showBacs ? (
        <EditBacs
          form={form}
          setShow={setShow}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          handleContentChange={handleContentChange}
          handleImageChange={handleImageChange}
          setShowBacs={setShowBacs}
          setEditBacs={setEditBacs}
          uploadPlugin={uploadPlugin}
          removeSelectedFile={removeSelectedFile}
          tags={tags}
          handleTagChange={handleTagChange}
          removeTag={removeTag}
          addTag={addTag}
          setAddTag={setAddTag}
          createTag={createTag}
          tagMsg={tagMsg}
          setTagMsg={setTagMsg}
          bacs_category={bacs_category}
          bacs_types={bacs_types}
          msg={msg}
          setMsg={setMsg}
          setNotificationData={setNotificationData}
          updateTable={updateTable}
          setFileError={setFileError}
          fileError={fileError}
        />
      ) : (
        <>
          <div
            className="input-group mb-4 table-filters"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div style={{ display: "flex", flexWrap: "wrap", gap: "1em" }}>
              <div style={{ display: "flex" }}>
                <input
                  type="search"
                  placeholder="Search by name"
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  aria-describedby="button-addon5"
                  className="form-control"
                  style={{ borderRadius: ".375rem 0px 0px .375rem" }}
                />
                <div className="input-group-append">
                  <button
                    id="button-addon5"
                    type="button"
                    onClick={updateTable}
                    className="btn btn-primary color3863A0"
                    style={{
                      borderRadius: "2px",
                      height: "100%",
                    }}
                  >
                    <i className="bx bx-search"></i>
                  </button>
                </div>
              </div>
              {user.role < 3 && (
                <InputGroup className="mx-3" style={{ width: "fit-content" }}>
                  <DropdownButton
                    variant="outline-secondary"
                    title={
                      selectedCountry
                        ? selectedCountry["name"]
                        : "Select Country"
                    }
                    id="input-group-dropdown-1"
                    onSelect={(e) => {
                      setSelectedCountry(JSON.parse(e));
                    }}
                  >
                    <Dropdown.Item eventKey={undefined}>
                      Select None
                    </Dropdown.Item>
                    {countries.map((country, index) => (
                      <Dropdown.Item
                        eventKey={JSON.stringify(country)}
                        key={index}
                      >
                        {country.name}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </InputGroup>
              )}
              <InputGroup className="mx-3" style={{ width: "fit-content" }}>
                <DropdownButton
                  variant="outline-secondary"
                  title={
                    selectedStage ? publishStage[selectedStage] : "Select Stage"
                  }
                  id="input-group-dropdown-1"
                  onSelect={(e) => {
                    setSelectedStage(e);
                  }}
                >
                  <Dropdown.Item eventKey={undefined}>
                    Select None
                  </Dropdown.Item>
                  {Object.keys(publishStage).map((stageId, index) => (
                    <Dropdown.Item eventKey={stageId} key={index}>
                      {publishStage[stageId]}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </InputGroup>
              <InputGroup className="mx-3" style={{ width: "fit-content" }}>
                <DropdownButton
                  variant="outline-secondary"
                  title={category ? category : "Select Category"}
                  id="input-group-dropdown-1"
                  onSelect={(e) => {
                    setCategory(e);
                  }}
                >
                  <Dropdown.Item eventKey={undefined}>
                    Select None
                  </Dropdown.Item>
                  {bacs_category.map((bacsCategory, index) => (
                    <Dropdown.Item eventKey={bacsCategory} key={index}>
                      {bacsCategory}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </InputGroup>
              <InputGroup className="mx-3" style={{ width: "fit-content" }}>
                <DropdownButton
                  variant="outline-secondary"
                  title={
                    bacs_type ? bacs_types_name[bacs_type] : "Resource Type"
                  }
                  id="input-group-dropdown-1"
                  onSelect={(e) => {
                    setBacsType(e);
                  }}
                >
                  <Dropdown.Item eventKey={undefined}>
                    Select None
                  </Dropdown.Item>
                  {bacs_types.map((bacsType, index) => (
                    <Dropdown.Item eventKey={bacsType} key={index}>
                      {bacs_types_name[bacsType]}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </InputGroup>
            </div>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                setShowBacs(true);
                setForm({
                  id: undefined,
                  title: "",
                  date: "",
                  tag: [],
                  comments: [],
                  bannerImage: "",
                  bannerImageURL: "",
                  bannerImageExt: "",
                  bannerImageId: "",
                  content: "",
                  bacs_type: "",
                  countryIds: [],
                  category: "",
                  publishStage: undefined,
                  authorName: "",
                  authorDescription: "",
                  authorImage: "",
                });
              }}
            >
              Create New
            </button>
          </div>
          {bacsList.length === 0 ? (
            <NoDataPresent />
          ) : (
            <>
              <Table striped bordered responsive>
                <thead>
                  <tr>
                    <th className="table-head">#</th>
                    <th className="table-head">Name</th>
                    <th className="table-head">Created By</th>
                    <th className="table-head"></th>
                    <th className="table-head">Type</th>
                    <th className="table-head">Published</th>
                    <th className="table-head">View</th>
                    <th className="table-head">Edit</th>
                    <th className="table-head">Draft</th>
                    <th className="table-head">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {bacsList.map((bacs, index) => (
                    <tr key={index}>
                      <td>{pagination.offset + index + 1}</td>
                      <td>{bacs.title}</td>
                      <td>{bacs.createdBy}</td>
                      <td>
                        <Badge bg="light" text="dark">
                          {bacs.category}
                        </Badge>
                      </td>
                      <td>{bacs_types_name[bacs.bacs_type]}</td>
                      <td>
                        {publishStage[bacs.publishStage]} <br />
                        {bacs.publishStage === 1 && bacs.comment ? (
                          <p>
                            <b>Reason for Rejection: </b> {bacs.comment}
                          </p>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        <i
                          className="fa-solid fa-box-open fa-1x"
                          onClick={() => showPreview(bacs.id)}
                        ></i>
                      </td>
                      <td>
                        <i
                          className="fa-solid fa-pen-to-square fa-1x"
                          onClick={() => {
                            setShowBacs(true);
                            setEditBacs(bacs.id);
                          }}
                        ></i>
                      </td>
                      <td>
                        <i
                          className={`fa-solid fa-lock${
                            bacs.active_indicator === 1 ? "-open" : ""
                          } fa-1x`}
                          onClick={() => setDeactivateId(bacs)}
                        ></i>
                      </td>
                      {(!bacs.isAuthorPublic && user.id == bacs.createdById) ||
                      user.role < 5 ? (
                        <td>
                          <i
                            className="fa-solid fa-trash-can fa-1x"
                            onClick={() => {
                              setItemId(bacs);
                              setShowModal(true);
                            }}
                          ></i>
                        </td>
                      ) : (
                        <td>
                          <i className="fa-solid fa-ban fa-1x"></i>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Pagination
                setPagination={setPagination}
                totalRecords={totalRecords}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default Bacs;
