import axiosInstance from "../utility/axios";
import { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import DropdownButton from "react-bootstrap/DropdownButton";
import Badge from "react-bootstrap/Badge";
import Dropdown from "react-bootstrap/Dropdown";
import DocsPreview from "../components/DocsPreview";
import Table from "react-bootstrap/Table";
import Pagination from "../components/Pagination";
import EditDocs from "../components/EditDocs";
import DeleteConfirmation from "../components/DeleteModal";
import DeactivateConfirmation from "../components/DeleteModal";
import Notification from "../components/Notification";
import { getStorage, ref, deleteObject } from "firebase/storage";
import Compressor from "compressorjs";
import "./Bacs.css";
import { userContext } from "./Context";
import NoDataPresent from "../components/NoDataPresent";
import { auth } from "../firebase-config";
import { signInWithCustomToken, signOut } from "firebase/auth";
import EditAdvocacy from "../components/EditAdvocacy";

const publishStage = {
  1: "Changes Required",
  2: "In Review",
  3: "published",
};
const docs_category = ["Knowledge", "Information", "Global", "General"];
const docs_types = ["tools", "document", "report"];
const Docs = () => {
  const { user } = useContext(userContext);
  const [fileError, setFileError] = useState("");
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [docsList, setDocsList] = useState([]);
  const [state, setState] = useState();
  const [editDocs, setEditDocs] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showDocs, setShowDocs] = useState(false);
  const [itemId, setItemId] = useState(-1);
  const [form, setForm] = useState({
    id: "",
    title: "",
    date: "",
    comments: [],
    bannerImage: "",
    bannerImageURL: "",
    bannerImageExt: "",
    bannerImageId: "",
    File: "",
    FileName: "",
    FileURL: "",
    FileId: "",
    FileName: "",
    FileExt: "",
    description: "",
    countryIds: [],
    category: "",
    docs_type: "advocacy",
    publishStage: undefined,
    authorName: "",
  });

  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState(undefined);
  const [selectedStage, setSelectedStage] = useState(undefined);
  const [countries, setCountries] = useState([]);
  const [category, setCategory] = useState(undefined);
  const [msg, setMsg] = useState({
    message: [],
    variant: "danger",
  });
  const [notificationData, setNotificationData] = useState([]);
  const [showDeactivateConfirmation, setShowDeactivateConfirmation] =
    useState(false);
  const [deactivateId, setDeactivateId] = useState(undefined);

  useEffect(() => {
    axiosInstance.get("/user/country/list").then((res) => {
      setCountries(res.data);
    });
  }, []);

  const handleFileChange = async (e, uploadFileToFirebase) => {
    const file = e.target.files[0];
    if (
      file &&
      ["application/pdf"].includes(file.type) &&
      file.size <= 15000000
    ) {
      const FileName = file.name;
      const FileId = `${Date.now()}_${file.name}`;
      const uploadUrl = `public/docs/files/${FileId}`;
      const FileExt = FileName.substring(FileName.lastIndexOf("."));
      const token = await axiosInstance.post("/site/generate-signed-url");

      if (token.status !== 200) {
        setNotificationData([
          {
            msg: "Upload failed",
            status: 500,
          },
        ]);
        return;
      }

      const user = await signInWithCustomToken(auth, token.data.url);

      const downloadURL = await uploadFileToFirebase(e, uploadUrl);
      setForm((form) => {
        return {
          ...form,
          [e.target.name]: file,
          FileURL: downloadURL,
          FileId,
          FileName,
          FileExt,
        };
      });
      setFileError({ ...fileError, [e.target.name]: "" });
    } else {
      setFileError({
        ...fileError,
        [e.target.name]: "File can have max size of 15MB",
      });
    }
  };
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file && file.type.match("image.*") && file.size <= 5000000) {
      setForm({ ...form, [e.target.name]: file });
      setFileError({ ...fileError, [e.target.name]: "" });
      try {
        const compressedBlob = await new Promise((resolve, reject) => {
          new Compressor(file, {
            quality: 0.6,
            success(result) {
              resolve(result);
            },
            error(err) {
              reject(err);
            },
          });
        });

        const compressedFile = new File([compressedBlob], file.name, {
          type: file.type,
        });
        setForm({ ...form, [e.target.name]: compressedFile });
      } catch (error) {
        console.log(error.message);
      }
    } else {
      setFileError({
        ...fileError,
        [e.target.name]: "File can have max size of 5MB",
      });
    }
  };

  const handleContentChange = (event, editor) => {
    setForm((prevForm) => ({ ...prevForm, description: editor.getData() }));
  };

  const removeSelectedFile = (name) => {
    const fileInput = document.getElementById(name);
    fileInput.value = "";

    setForm({ ...form, [name]: "" });
    setFileError({ ...fileError, [name]: "" });
  };

  const removeSelectedFileFirebase = async (name) => {
    const fileInput = document.getElementById(name);
    fileInput.value = "";
    if ((form[name] && typeof form[name] !== "string") || form.FileId) {
      const storage = getStorage();
      const storageRef = ref(storage, `public/docs/files/${form.FileId}`);
      const token = await axiosInstance.post("/site/generate-signed-url");

      if (token.status !== 200) {
        setNotificationData([
          {
            msg: "Upload failed",
            status: 500,
          },
        ]);
        return;
      }

      const user = await signInWithCustomToken(auth, token.data.url);

      try {
        await deleteObject(storageRef);
        // console.log("File deleted from Firebase Storage");
      } catch (error) {
        console.error("Error deleting file from Firebase Storage:", error);
      }
      signOut(auth);
    }
    setUploadProgress(0);
    setNotificationData([
      {
        msg: "Document removed",
        status: 200,
      },
    ]);
    setForm({
      ...form,
      [name]: "",
      FileURL: "",
      FileId: "",
      FileName: "",
      FileExt: "",
    });
    setFileError({ ...fileError, [name]: "" });
  };

  const updateTable = () => {
    let query = `search=${search}&offset=${pagination.offset}&limit=${pagination.limit}&docs_type=advocacy`;
    if (user.role < 3 && selectedCountry) {
      query += `&country_id=${selectedCountry.id}`;
    } else if (user.role >= 3) {
      query += `&country_id=${user.countryId}`;
    }
    if (selectedStage) query += `&stage_id=${selectedStage}`;
    if (category) query += `&category=${category}`;
    axiosInstance
      .get(`/docs/get/all?${query}`)
      .then((res) => {
        if (res.data.docss.length && typeof res.data.docss === "object") {
          setDocsList([...res.data.docss]);
          setTotalRecords(res.data.count || 0);
        } else {
          setDocsList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    updateTable();
  }, [selectedCountry, selectedStage, category, pagination]);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleDelete = (id) => {
    axiosInstance
      .delete(`/docs/draft/${id}?indicator=2`)
      .then(async (response) => {
        if (response.status === 200) {
          updateTable();
        } else {
          // TODO: alert
        }
        setNotificationData([
          {
            msg: response.data.message,
            status: response.status,
          },
        ]);
      })
      .catch((err) => {});
    setShowModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (form.FileURL == "") {
    //   setNotificationData([
    //     {
    //       msg: "Upload File",
    //       status: 500,
    //     },
    //   ]);
    //   return;
    // }
    const formData = new FormData();

    formData.append("title", form.title);
    formData.append("date", form.date);
    formData.append("bannerImage", form.bannerImage);
    formData.append("File", form.File);
    formData.append("FileId", form.FileId);
    formData.append("FileName", form.FileName);
    formData.append("FileURL", form.FileURL);
    formData.append("FileExt", form.FileExt);

    formData.append("description", form.description);
    formData.append("category", form.category);
    formData.append("authorName", form.authorName);
    formData.append(
      "countryIds",
      JSON.stringify(form.countryIds.map((country) => country.id))
    );
    formData.append("docs_type", form.docs_type);

    // update
    if (form.id) {
      // formData.append("bannerImageId", form.bannerImageId);
      // formData.append("FileId", form.FileId);
      axiosInstance
        .patch(`/docs/update/${form.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setEditDocs("");
            updateTable();
            setShowDocs(false);
            setMsg({
              message: [],
              variant: "danger",
            });
          } else {
            if (response.data.message === "Validation error")
              setMsg({
                message: response.data.error?.details?.map((e) => e.message),
                variant: "danger",
              });
            else if (response.data.message === "DB Error")
              setMsg({ message: [...response.data.error], variant: "danger" });
            else
              setMsg({ message: [response.data.message], variant: "danger" });
          }
          setNotificationData([
            {
              msg: response.data.message,
              status: response.status,
            },
          ]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    //create
    else {
      axiosInstance
        .post(`/docs/create`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setEditDocs("");
            setShowDocs(false);
            updateTable();
            setMsg({
              message: [],
              variant: "danger",
            });
          } else {
            if (response.data.message === "Validation error")
              setMsg({
                message: response.data.error?.details?.map((e) => e.message),
                variant: "danger",
              });
            else if (response.data.message === "DB Error")
              setMsg({ message: [...response.data.error], variant: "danger" });
            else
              setMsg({ message: [response.data.message], variant: "danger" });
          }
          setNotificationData([
            {
              msg: response.data.message,
              status: response.status,
            },
          ]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const PreviewModal = () => {
    return (
      <Modal show={show} onHide={() => setShow(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Resource Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal_body">
          <DocsPreview form={form} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const fetchData = (id) => {
    axiosInstance
      .get(`/docs/get/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setForm({
            id: res.data.docs.id,
            title: res.data.docs.title,
            date: res.data.docs.date,
            bannerImage: "",
            bannerImageURL: res.data.docs.bannerImageURL,
            bannerImageExt: res.data.docs.bannerImageExt,
            bannerImageId: res.data.docs.bannerImageId,
            File: "",
            FileURL: res.data.docs.FileURL,
            FileId: res.data.docs.FileId,
            FileName: res.data.docs.FileName,
            FileExt: res.data.docs.FileExt,
            description: res.data.docs.description,
            countryIds: res.data.docs.countries,
            comments: res.data.docs.comments,
            category: res.data.docs.category,
            authorId: res.data.docs.authorId,
            authorName: res.data.docs.authorName,
            publishStage: res.data.docs.publishStage,
            docs_type: res.data.docs.docs_type,
          });
        } else {
          setEditDocs("");
          setForm({
            id: "",
            title: "",
            date: "",
            comments: [],
            bannerImage: "",
            bannerImageURL: "",
            bannerImageExt: "",
            bannerImageId: "",
            File: "",
            FileURL: "",
            FileId: "",
            FileName: "",
            FileExt: "",
            description: "",
            countryIds: [],
            category: "",
            docs_type: "advocacy",
            authorName: "",
            publishStage: undefined,
          });
          setShowDocs(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (editDocs) {
      fetchData(editDocs);
    }
  }, [showDocs]);

  useEffect(() => {
    updateTable();
    return () => {
      setState({});
    };
  }, []);

  const showPreview = (id) => {
    fetchData(id);
    setShow(true);
  };

  const deactivateDocs = (id, indicator) => {
    axiosInstance
      .delete(`/docs/draft/${id}?indicator=${indicator}`)
      .then((res) => {
        setNotificationData([
          {
            msg: res.data.message,
            status: res.status,
          },
        ]);
        updateTable();
      });
  };

  useEffect(() => {
    if (deactivateId) {
      setShowDeactivateConfirmation(true);
    }
  }, [deactivateId]);

  return (
    <>
      <DeactivateConfirmation
        showModal={showDeactivateConfirmation}
        hideModal={() => {
          setShowDeactivateConfirmation(false);
          setDeactivateId(undefined);
        }}
        confirmModal={() => {
          deactivateDocs(
            deactivateId?.id,
            deactivateId?.active_indicator === 1 ? 0 : 1
          );
          setDeactivateId(undefined);
          setShowDeactivateConfirmation(false);
        }}
        message={`Are you sure you want to ${
          deactivateId?.active_indicator === 1 ? "Deactivate" : "Activate"
        } ${deactivateId?.title}?`}
        btnMsg={
          deactivateId?.active_indicator === 1 ? "Deactivate" : "Activate"
        }
      />
      <DeleteConfirmation
        showModal={showModal}
        hideModal={() => {
          setShowModal(false);
        }}
        confirmModal={() => {
          handleDelete(itemId.id);
          setShowModal(false);
        }}
        message={`Are you sure you want to delete ${itemId?.title}?`}
      />
      <PreviewModal />
      <Notification
        notificationData={notificationData}
        setNotificationData={setNotificationData}
      />
      {showDocs ? (
        <EditAdvocacy
          form={form}
          setShow={setShow}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          handleContentChange={handleContentChange}
          handleFileChange={handleFileChange}
          handleImageChange={handleImageChange}
          setShowDocs={setShowDocs}
          setEditDocs={setEditDocs}
          removeSelectedFile={removeSelectedFile}
          removeSelectedFileFirebase={removeSelectedFileFirebase}
          docs_category={docs_category}
          docs_types={docs_types}
          msg={msg}
          setMsg={setMsg}
          setNotificationData={setNotificationData}
          updateTable={updateTable}
          setFileError={setFileError}
          fileError={fileError}
          setUploadProgress={setUploadProgress}
          uploadProgress={uploadProgress}
        />
      ) : (
        <>
          <div
            className="input-group mb-4 table-filters"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div style={{ display: "flex", flexWrap: "wrap", gap: "1em" }}>
              <div style={{ display: "flex" }}>
                <input
                  type="search"
                  placeholder="Search by name"
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  aria-describedby="button-addon5"
                  className="form-control"
                  style={{ borderRadius: ".375rem 0px 0px .375rem" }}
                />
                <div className="input-group-append">
                  <button
                    id="button-addon5"
                    type="button"
                    onClick={updateTable}
                    className="btn btn-primary color3863A0"
                    style={{
                      borderRadius: "2px",
                      height: "100%",
                    }}
                  >
                    <i className="bx bx-search"></i>
                  </button>
                </div>
              </div>
              {user.role < 3 && (
                <InputGroup className="mx-3" style={{ width: "fit-content" }}>
                  <DropdownButton
                    variant="outline-secondary"
                    title={
                      selectedCountry
                        ? selectedCountry["name"]
                        : "Select Country"
                    }
                    id="input-group-dropdown-1"
                    onSelect={(e) => {
                      setSelectedCountry(JSON.parse(e));
                    }}
                  >
                    <Dropdown.Item eventKey={undefined}>
                      Select None
                    </Dropdown.Item>
                    {countries.map((country, index) => (
                      <Dropdown.Item
                        eventKey={JSON.stringify(country)}
                        key={index}
                      >
                        {country.name}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </InputGroup>
              )}
              <InputGroup className="mx-3" style={{ width: "fit-content" }}>
                <DropdownButton
                  variant="outline-secondary"
                  title={
                    selectedStage ? publishStage[selectedStage] : "Select Stage"
                  }
                  id="input-group-dropdown-1"
                  onSelect={(e) => {
                    setSelectedStage(e);
                  }}
                >
                  <Dropdown.Item eventKey={undefined}>
                    Select None
                  </Dropdown.Item>
                  {Object.keys(publishStage).map((stageId, index) => (
                    <Dropdown.Item eventKey={stageId} key={index}>
                      {publishStage[stageId]}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </InputGroup>
              <InputGroup className="mx-3" style={{ width: "fit-content" }}>
                <DropdownButton
                  variant="outline-secondary"
                  title={category ? category : "Select Category"}
                  id="input-group-dropdown-1"
                  onSelect={(e) => {
                    setCategory(e);
                  }}
                >
                  <Dropdown.Item eventKey={undefined}>
                    Select None
                  </Dropdown.Item>
                  {docs_category.map((docsCategory, index) => (
                    <Dropdown.Item eventKey={docsCategory} key={index}>
                      {docsCategory}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </InputGroup>
            </div>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                setShowDocs(true);
                setForm({
                  id: undefined,
                  title: "",
                  date: "",
                  comments: [],
                  bannerImage: "",
                  bannerImageURL: "",
                  bannerImageExt: "",
                  bannerImageId: "",
                  File: "",
                  FileURL: "",
                  FileId: "",
                  FileName: "",
                  FileExt: "",
                  description: "",
                  docs_type: "advocacy",
                  countryIds: [],
                  category: "",
                  publishStage: undefined,
                  authorName: "",
                });
                setUploadProgress(0);
              }}
            >
              Create New
            </button>
          </div>
          {docsList.length === 0 ? (
            <NoDataPresent />
          ) : (
            <>
              <Table striped bordered responsive>
                <thead>
                  <tr>
                    <th className="table-head">#</th>
                    <th className="table-head">Name</th>
                    <th className="table-head">Created By</th>
                    <th className="table-head"></th>
                    <th className="table-head">Published</th>
                    <th className="table-head">View</th>
                    <th className="table-head">Edit</th>
                    <th className="table-head">Draft</th>
                    <th className="table-head">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {docsList.map((docs, index) => (
                    <tr key={index}>
                      <td>{pagination.offset + index + 1}</td>
                      <td>{docs.title}</td>
                      <td>{docs.createdBy}</td>
                      <td>
                        <Badge bg="light" text="dark">
                          {docs.country}
                        </Badge>
                        <Badge bg="light" text="dark">
                          {docs.category}
                        </Badge>
                      </td>
                      <td>
                        {publishStage[docs.publishStage]} <br />
                        {docs.publishStage === 1 && docs.comment ? (
                          <p>
                            <b>Reason for Rejection: </b> {docs.comment}
                          </p>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        <i
                          className="fa-solid fa-box-open fa-1x"
                          onClick={() => showPreview(docs.id)}
                        ></i>
                      </td>
                      <td>
                        <i
                          className="fa-solid fa-pen-to-square fa-1x"
                          onClick={() => {
                            setShowDocs(true);
                            setEditDocs(docs.id);
                          }}
                        ></i>
                      </td>
                      <td>
                        <i
                          className={`fa-solid fa-lock${
                            docs.active_indicator === 1 ? "-open" : ""
                          } fa-1x`}
                          onClick={() => setDeactivateId(docs)}
                        ></i>
                      </td>
                      {(!docs.isAuthorPublic && user.id == docs.createdById) ||
                      user.role < 5 ? (
                        <td>
                          <i
                            className="fa-solid fa-trash-can fa-1x"
                            onClick={() => {
                              setItemId(docs);
                              setShowModal(true);
                            }}
                          ></i>
                        </td>
                      ) : (
                        <td>
                          <i className="fa-solid fa-ban fa-1x"></i>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Pagination
                setPagination={setPagination}
                totalRecords={totalRecords}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default Docs;
