import { createContext, useEffect, useState } from 'react';
import axiosInstance from '../utility/axios';

export const userContext = createContext({
  user: {},
  isLoading: true, // Add a loading state
});

export default function Context(props) {
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(true); // Initialize the loading state

  useEffect(() => {
    axiosInstance.get('/user').then((response) => {
      setUser({ ...response.data });
      setIsLoading(false); // Set loading state to false after data is fetched
    });
  }, []);

  return (
    <userContext.Provider value={{ user, isLoading, setUser: setUser }}>
      {props.children}
    </userContext.Provider>
  );
}
