import { React, useState, useEffect, useContext } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { Tabs, Tab } from "react-bootstrap";
import axiosInstance from "../utility/axios";
import Notification from "../components/Notification";
import { userContext } from "../pages/Context";
import { useLanguage } from "../utility/LanguageContext";

const Content = ({ lang }) => {
  const [notificationData, setNotificationData] = useState([]);
  const [isPreview, setIsPreview] = useState(true);
  const [privacy, setPrivacy] = useState({
    en: {
      content: "",
    },
    bangla: {
      content: "",
    },
    nap: {
      content: "",
    },
    thai: {
      content: "",
    },
  });

  const handleChange = (event, editor) => {
    setPrivacy({
      ...privacy,
      [lang]: {
        content: editor.getData(),
      },
    });
  };

  const getPrivacy = () => {
    axiosInstance
      .get(`/siteData/getPrivacy`)
      .then((response) => {
        if (response.status === 200) {
          setPrivacy({ ...response.data?.response });
        } else {
          // TODO
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSave = (key) => {
    axiosInstance
      .post(`/siteData/updatePrivacy`, {
        lang,
        key,
        value: privacy[lang][key],
      })
      .then((response) => {
        if (response.status === 200) {
          getPrivacy();
          setIsPreview(true);
        } else {
          // TODO
        }
        setNotificationData([
          {
            msg: response.data.message,
            status: response.status,
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getPrivacy();
  }, []);

  return (
    <>
      <Notification
        notificationData={notificationData}
        setNotificationData={setNotificationData}
      />
      {lang && (
        <main className="about-us-dashboard">
          <div
            className="something-do mt-0"
            style={{ height: "300px", display: "flex", alignItems: "center" }}
          >
            <div className="container">
              <div className="amazing-do">
                <h2>Privacy Policy and Cookie Notice</h2>
              </div>
            </div>
          </div>
          <div className="container mb-0 pb-1">
            <div className="about-caption-top" style={{ textAlign: "justify" }}>
              <div>
                <div className="actions edit-btn mt-2">
                  <button
                    id="edit"
                    className="lx-btn"
                    onClick={() => setIsPreview(!isPreview)}
                  >
                    {isPreview ? (
                      <i className="fa-solid fa-pen-to-square"></i>
                    ) : (
                      <i className="fa-regular fa-eye"></i>
                    )}
                    &nbsp;&nbsp;{isPreview ? "Edit" : "Preview"}
                  </button>
                  <button
                    id="save"
                    className="lx-btn mx-3"
                    onClick={() => handleSave("content")}
                  >
                    <i className="fas fa-save"></i>
                    &nbsp;&nbsp;Save
                  </button>
                </div>

                {!isPreview ? (
                  <CKEditor
                    editor={Editor}
                    data={privacy[lang]?.content}
                    onChange={(event, editor) => {
                      handleChange(event, editor);
                    }}
                    onReady={(editor) => {
                      editor.ui.view.toolbar.items.get(
                        11
                      ).buttonView.isVisible = false;
                      // editor.ui.view.toolbar.items.get(16).buttonView.isVisible = false;
                      editor.ui.view.toolbar.items.get(31).isVisible = false;
                      editor.ui.view.toolbar.items.get(
                        32
                      ).buttonView.isVisible = false;
                      editor.ui.view.toolbar.items.get(
                        33
                      ).buttonView.isVisible = false;
                      editor.ui.view.toolbar.items.get(
                        34
                      ).buttonView.isVisible = false;
                      editor.ui.view.toolbar.items.get(
                        35
                      ).buttonView.isVisible = false;
                    }}
                  />
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: privacy[lang]?.content,
                    }}
                    className="ck-content"
                  ></div>
                )}
              </div>
            </div>

            <div className="mb-4"></div>
          </div>
        </main>
      )}
    </>
  );
};

const Privacy = () => {
  const { user } = useContext(userContext);
  const { countryMap } = useLanguage();
  const [lang, setLang] = useState(undefined);
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    axiosInstance.get("/user/country/list").then((res) => {
      setCountries(res.data);
    });
  }, []);
  useEffect(() => {
    setLang(user.role < 3 ? "en" : countryMap[user?.countryId]);
  }, [user, countryMap]);

  return (
    <section className="user-section" id="user-section">
      <div className="user-content">
        <Tabs
          defaultActiveKey={user.role < 3 ? "en" : countryMap[user.countryId]}
          id="about-tabs"
          className="mb-3"
          onSelect={(k) => setLang(k)}
        >
          {countries?.map((country, index) => {
            if (user.role < 3 || user.countryId === country.id) {
              return (
                <Tab eventKey={countryMap[country.id]} title={country.name}>
                  <Content lang={lang} />
                </Tab>
              );
            }
          })}
        </Tabs>
      </div>
    </section>
  );
};

export default Privacy;
