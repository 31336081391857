import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import TrashVideos from "./TrashVideos";
import "./Trash.css";
import TrashEventWebinar from "./TrashEventWebinar";
import TrashNews from "./TrashNews";
import TrashCampaigns from "./TrashCampaigns";
import TrashAdvocacy from "./TrashAdvocacy";
import TrashCourse from "./TrashCourse";
import TrashBacs from "./TrashBacs";
import TrashDocs from "./TrashDocs";

export default function Trash() {
  return (
    <section className="user-section" id="user-section">
      <div className="user-content">
        <Tabs defaultActiveKey="media" id="about-tabs" className="mb-3">
          <Tab eventKey="media" title="Media">
            <TrashVideos />
          </Tab>
          <Tab eventKey="ovocInAction" title="OVOC in Action">
            <TrashEventWebinar />
            <TrashNews />
          </Tab>
          <Tab eventKey="advocacy" title="Adovcacy">
            <TrashCampaigns />
            <TrashAdvocacy />
          </Tab>
          <Tab eventKey="learn&resource" title="Learning & Resources">
            <TrashCourse />
            <TrashBacs />
            <TrashDocs />
          </Tab>
        </Tabs>
      </div>
    </section>
  );
}
