import Table from "react-bootstrap/Table";
import Pagination from "./Pagination";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Badge from "react-bootstrap/Badge";
import { Modal, Button } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import UserSideBar from "../components/UserSideBar";
import DeleteConfirmation from "./DeleteModal";
import DeactivateConfirmation from "./DeleteModal";
import "./UserTable.css";
import axiosInstance from "../utility/axios";
import { userContext } from "../pages/Context";
import NoDataPresent from "./NoDataPresent";

const GmailModal = ({ setOpenMailModal, openMailModal }) => {
  const [mail, setMail] = useState("");
  const [showBtns, setShowBtns] = useState(true);
  const [error, setError] = useState({
    message: "",
    show: false,
    type: "info",
  });
  const sendMail = () => {
    axiosInstance
      .post("/user/password-reset-mail", {
        email: mail,
      })
      .then((res) => {
        if (res.status === 200) {
          setShowBtns(false);
          setError({
            message: res.data.message,
            type: "info",
            show: true,
          });
        } else {
          setError({
            message: res.data.message,
            type: "danger",
            show: true,
          });
        }
      });
  };
  return (
    <Modal
      show={openMailModal}
      onHide={() => {
        setOpenMailModal(false);
        setMail("");
        setShowBtns(true);
        setError({
          message: "",
          show: false,
          type: "info",
        });
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Resend Password Mail</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <input
          type="email"
          name="email"
          value={mail}
          placeholder="Enter Email"
          onChange={(e) => setMail(e.target.value)}
        />
        {error.show && (
          <div className={`alert alert-${error.type}`}>{error.message}</div>
        )}
      </Modal.Body>
      {showBtns && (
        <Modal.Footer>
          <Button
            variant="default"
            onClick={() => {
              setOpenMailModal(false);
              setMail("");
              setShowBtns(true);
              setError({
                message: "",
                show: false,
                type: "info",
              });
            }}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={sendMail}>
            Send Mail
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

const UserTable = ({
  tab,
  users,
  getUserData,
  deleteUser,
  search,
  setSearch,
  selectedCountry,
  selectedCommunity,
  selectedRole,
  setSelectedCountry,
  setSelectedCommunity,
  setSelectedRole,
  setEditDetails,
  getUsers,
  pagination,
  setPagination,
  totalRecords,
  countries,
  communities,
  roles,
  setNotificationData,
  subCommunities,
}) => {
  const { user } = useContext(userContext);
  const [showCreateUserBar, setShowCreateUserBar] = useState({
    show: false,
    mode: "",
  });
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState(undefined);
  const [showDeactivateConfirmation, setShowDeactivateConfirmation] =
    useState(false);
  const [deactivateId, setDeactivateId] = useState(undefined);
  const [openMailModal, setOpenMailModal] = useState(false);

  const handlerRightBar = (mode) => {
    setShowCreateUserBar({
      show: !showCreateUserBar.show,
      mode,
    });
    setEditDetails({});
  };

  const deactivateUser = (id) => {
    const active_indicator = deactivateId.active_indicator;
    if (active_indicator === 1) {
      axiosInstance.patch(`/user/deactivate/${id}`).then((res) => {
        setNotificationData([
          {
            msg: res.data.message,
            status: res.status,
          },
        ]);
        getUsers();
      });
    } else {
      axiosInstance.patch(`/user/activate/${id}`).then((res) => {
        setNotificationData([
          {
            msg: res.data.message,
            status: res.status,
          },
        ]);
        getUsers();
      });
    }
  };

  const DropdownSearch = React.forwardRef((props, ref) => {
    const { children, style, className, "aria-labelledby": labeledBy } = props;
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
          style={{ position: "sticky", top: "0px" }}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child?.props?.children.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  });

  useEffect(() => {
    if (deleteId) {
      setShowDeleteConfirmation(true);
    }
  }, [deleteId]);

  useEffect(() => {
    if (deactivateId) {
      setShowDeactivateConfirmation(true);
    }
  }, [deactivateId]);
  return (
    <>
      <GmailModal
        openMailModal={openMailModal}
        setOpenMailModal={setOpenMailModal}
      />
      <DeactivateConfirmation
        showModal={showDeactivateConfirmation}
        hideModal={() => {
          setShowDeactivateConfirmation(false);
          setDeactivateId(undefined);
        }}
        confirmModal={() => {
          deactivateUser(deactivateId?.id);
          setShowDeactivateConfirmation(false);
        }}
        message={`Are you sure you want to ${
          deactivateId?.active_indicator === 1 ? "Deactivate" : "Activate"
        } ${deactivateId?.name}?`}
        btnMsg={
          deactivateId?.active_indicator === 1 ? "Deactivate" : "Activate"
        }
      />
      <DeleteConfirmation
        showModal={showDeleteConfirmation}
        hideModal={() => {
          setShowDeleteConfirmation(false);
          setDeleteId(undefined);
        }}
        confirmModal={() => {
          deleteUser(deleteId?.id);
          setShowDeleteConfirmation(false);
        }}
        message={`Are you sure you want to delete ${deleteId?.name}?`}
      />
      <UserSideBar
        show={showCreateUserBar.show}
        mode={showCreateUserBar.mode}
        currUser={user}
        countries={countries}
        communities={communities}
        roles={roles}
        refresh={() => {
          getUsers();
          setEditDetails({});
        }}
        handleClose={() => {
          setShowCreateUserBar({
            show: false,
            mode: "",
          });
          setEditDetails({});
        }}
        setNotificationData={setNotificationData}
      />
      <div
        className="input-group mb-4 table-filters"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div style={{ display: "flex", flexWrap: "wrap", gap: "1em" }}>
          <div style={{ display: "flex" }}>
            <input
              type="search"
              placeholder="Search by name"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              aria-describedby="button-addon5"
              className="form-control"
              style={{ borderRadius: ".375rem 0px 0px .375rem" }}
            />
            <div className="input-group-append">
              <button
                id="button-addon5"
                type="button"
                onClick={() => getUsers()}
                className="btn btn-primary color3863A0"
                style={{
                  borderRadius: "2px",
                  height: "100%",
                }}
              >
                <i className="bx bx-search"></i>
              </button>
            </div>
          </div>
          {user.role < 3 && (
            <InputGroup className="mx-3" style={{ width: "fit-content" }}>
              <DropdownButton
                variant="outline-secondary"
                title={
                  selectedCountry ? selectedCountry["name"] : "Select Country"
                }
                id="input-group-dropdown-1"
                onSelect={(e) => {
                  setSelectedCountry(JSON.parse(e));
                }}
              >
                <Dropdown.Item eventKey={undefined}>Select None</Dropdown.Item>
                {countries.map((country, index) => (
                  <Dropdown.Item eventKey={JSON.stringify(country)} key={index}>
                    {country.name}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </InputGroup>
          )}
          <InputGroup style={{ width: "fit-content" }}>
            <Dropdown
              onSelect={(e) => {
                setSelectedCommunity(JSON.parse(e));
              }}
            >
              <Dropdown.Toggle
                variant="outline-secondary"
                id="input-group-dropdown-1"
              >
                {selectedCommunity ? selectedCommunity["name"] : "Select CSO"}
              </Dropdown.Toggle>
              <Dropdown.Menu as={DropdownSearch}>
                <Dropdown.Item eventKey={undefined}>Select None</Dropdown.Item>
                {subCommunities.map((community, index) => (
                  <Dropdown.Item
                    eventKey={JSON.stringify(community)}
                    key={index}
                  >
                    {community.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </InputGroup>
          <InputGroup className="mx-3" style={{ width: "fit-content" }}>
            <DropdownButton
              variant="outline-secondary"
              title={selectedRole ? selectedRole["name"] : "Select Role"}
              id="input-group-dropdown-1"
              onSelect={(e) => {
                setSelectedRole(JSON.parse(e));
              }}
            >
              <Dropdown.Item eventKey={undefined}>Select None</Dropdown.Item>
              {roles.map((role, index) => (
                <Dropdown.Item eventKey={JSON.stringify(role)} key={index}>
                  {role.name}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </InputGroup>
        </div>
        <button
          type="button"
          className="btn btn-info"
          onClick={() => setOpenMailModal(true)}
        >
          Reset Password
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => handlerRightBar("edit")}
        >
          Add New
        </button>
      </div>
      {users.length === 0 ? (
        <NoDataPresent />
      ) : (
        <>
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th className="table-head">#</th>
                <th className="table-head">Name</th>
                <th className="table-head">Email</th>
                <th className="table-head"></th>
                <th className="table-head">View</th>
                <th className="table-head">Edit</th>
                <th className="table-head">Deactivate/Activate</th>
                <th className="table-head">Delete</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr key={index}>
                  <td>
                    <i
                      style={{ color: user.isActive ? "lightgreen" : "red" }}
                      className="bx bxs-circle"
                    ></i>{" "}
                    {pagination.offset + index + 1}
                  </td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>
                    <Badge bg="light" text="dark">
                      {user.role}
                    </Badge>
                    <Badge bg="light" text="dark">
                      {user.country}
                    </Badge>
                    <Badge bg="light" text="dark">
                      {user.community}
                    </Badge>
                    <Badge bg="light" text="dark">
                      {user.subCommunity}
                    </Badge>
                  </td>
                  <td>
                    <i
                      className="fa-solid fa-box-open fa-1x"
                      onClick={() => getUserData(user.id, "preview")}
                    ></i>
                  </td>
                  <td>
                    <i
                      className="fa-solid fa-pen-to-square fa-1x"
                      onClick={() => getUserData(user.id, "edit")}
                    ></i>
                  </td>
                  {user.active_indicator === 0 ? (
                    <td>
                      <i
                        className="fa-solid fa-lock fa-1x"
                        onClick={() => setDeactivateId(user)}
                      ></i>
                    </td>
                  ) : (
                    <td>
                      <i
                        className="fa-solid fa-lock-open fa-1x"
                        onClick={() => setDeactivateId(user)}
                      ></i>
                    </td>
                  )}

                  <td>
                    <i
                      className="fa-solid fa-trash-can fa-1x"
                      onClick={() => setDeleteId(user)}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            setPagination={setPagination}
            totalRecords={totalRecords}
          />
        </>
      )}
    </>
  );
};

export default UserTable;
