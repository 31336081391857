import React, { useEffect, useState } from "react";
import "./Issue.css";
import Container from "react-bootstrap/Container";
import axiosInstance from "../utility/axios";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Notification from "../components/Notification";
import MyIssues from "./MyIssues";
import AssignedIssues from "./AssignedIssues";
import { useContext } from "react";
import { userContext } from "./Context";
import AllIssues from "./AllIssues";
import { formatDate } from "../utility/utils";

const Issue = () => {
  const ctx = useContext(userContext);
  const [selectedTab, setSelectedTab] = useState("myIssues");
  const [myIssues, setMyIssues] = useState([]);
  const [assignedIssues, setAssignedIssues] = useState([]);
  const [allIssues, setAllIssues] = useState([]);
  const [search, setSearch] = useState("");
  const [notificationData, setNotificationData] = useState([]);

  const getMyIssues = () => {
    let query = `search=${search}`;
    axiosInstance
      .get(`/issues/get/MyIssues?${query}`)
      .then((response) => {
        if (response.status === 200) {
          setMyIssues(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAssignedIssues = () => {
    axiosInstance
      .get("/issues/get/AssignedIssues")
      .then((response) => {
        setAssignedIssues(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllIssues = () => {
    axiosInstance
      .get("/issues/get/getAllIssues")
      .then((response) => {
        setAllIssues(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (selectedTab === "myIssues") getMyIssues();
    if (selectedTab === "assignedIssues") getAssignedIssues();
    if (selectedTab === "allIssues") getAllIssues();
  }, [selectedTab]);

  return (
    <>
      <section className="user-section" id="user-section">
        <div className="user-content">
          <div className="issue-page">
            <Notification
              notificationData={notificationData}
              setNotificationData={setNotificationData}
            />
            <Container>
              <Tabs
                defaultActiveKey="myIssues"
                id="uncontrolled-tab-example"
                className="mb-3"
                onSelect={(v) => {
                  setSelectedTab(v);
                }}
              >
                <Tab eventKey="myIssues" title="My Issues">
                  <MyIssues
                    data={myIssues}
                    ctx={ctx}
                    getMyIssues={getMyIssues}
                    search={search}
                    setSearch={setSearch}
                    setNotificationData={setNotificationData}
                    formatDate={formatDate}
                  />
                </Tab>
                <Tab eventKey="assignedIssues" title="Assigned Issues">
                  <AssignedIssues
                    data={assignedIssues}
                    formatDate={formatDate}
                  />
                </Tab>
                {ctx.user.role === 1 ? (
                  <Tab eventKey="allIssues" title="All Issues">
                    <AllIssues data={allIssues} formatDate={formatDate} />
                  </Tab>
                ) : null}
              </Tabs>
            </Container>
          </div>
        </div>
      </section>
    </>
  );
};

export default Issue;
