import React, { useEffect, useState, useContext } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import Pagination from "./Pagination";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import axiosInstance from "../utility/axios";
import Notification from "./Notification";
import { userContext } from "../pages/Context";
import NoDataPresent from "./NoDataPresent";

export default function CCRequest({ countries }) {
  const [requests, setRequests] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [showModal, setShowModel] = useState(false);
  const [publicUser, setPublicUser] = useState({});
  const { user } = useContext(userContext);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [search, setSearch] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);

  const fetchData = () => {
    let query = `?search=${search}&offset=${pagination.offset}&limit=${pagination.limit}`;
    if (user.role < 3 && selectedCountry) {
      query += `&country_id=${selectedCountry?.id}`;
    } else if (user.role >= 3) {
      query += `&country_id=${user.countryId}`;
    }
    axiosInstance.get(`/user/website/new-cc-request${query}`).then((res) => {
      if (res.status === 200) {
        setRequests(res.data?.user?.rows);
        setTotalRecords(res.data?.user?.count);
      } else {
        setRequests([]);
        setNotificationData([
          {
            msg: res.data.message,
            status: res.status,
          },
        ]);
        setTotalRecords(0);
      }
    });
  };
  const approveReject = (isApproved, id, email, username, country) => {
    axiosInstance
      .post("/user/website/approve-new-cc", {
        approve: isApproved,
        userId: id,
        email,
        username,
        country,
      })
      .then((res) => {
        setNotificationData([
          {
            msg: res.data.message,
            status: res.status,
          },
        ]);
        fetchData();
      });
  };

  const getPublicUserDetails = (email) => {
    axiosInstance
      .get(`/user/website/get-user-details/${email}`)
      .then((response) => {
        if (response.status === 200) {
          setPublicUser({ ...response.data });
          setShowModel(true);
        } else {
          setNotificationData([
            {
              msg: response?.data?.message,
              status: response.status,
            },
          ]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const formatDateAndTime = (dateString) => {
    const dateObject = new Date(dateString);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const month = months[dateObject.getUTCMonth()];
    const day = dateObject.getUTCDate();
    const year = dateObject.getUTCFullYear();

    // const hours = dateObject.getUTCHours();
    // const minutes = dateObject.getUTCMinutes();
    // const ampm = hours >= 12 ? "PM" : "AM";
    // const formattedHours = hours % 12 || 12;

    // return `${month} ${day}th ${year}, ${formattedHours}:${
    //   minutes < 10 ? "0" : ""
    // }${minutes} ${ampm}`;
    const options = {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      year: "numeric",
      month: "long",
      day: "numeric",
      hour12: true,
      hour: "numeric",
      minute: "2-digit",
    };
    const formattedHours = dateObject.toLocaleString(undefined, options);
    // return `${month} ${day}th ${year}, ${formattedHours}`;
    return `${formattedHours}`;
  };
  useEffect(() => {
    fetchData();
  }, [pagination, selectedCountry]);

  return (
    <div>
      <Notification
        notificationData={notificationData}
        setNotificationData={setNotificationData}
      />
      <Modal
        show={showModal}
        onHide={() => {
          setShowModel(false);
          setPublicUser({});
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>User Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="user_image_wrapper">
            <img className="user_image" src={publicUser?.userImage} />
          </div>
          <div className="detail_body">
            <div className="user_name">
              <h3 style={{ margin: "0" }}>
                {publicUser?.firstName + " " + publicUser?.lastName}
              </h3>
              <p style={{ margin: "0" }}>{publicUser?.email}</p>
              <p style={{ margin: "0", fontSize: "15px", color: "gray" }}>
                Last logged In
                {" " + formatDateAndTime(publicUser?.last_login_date)}
              </p>
            </div>
            <div className="personal_info_wrapper custom">
              <h5>Personal Details</h5>
              <div className="personal_info row">
                <div className="col-6">Gender: {publicUser?.gender}</div>
                <div className="col-6">Age: {publicUser?.age}</div>
                <div className="col-6">
                  Country:{" "}
                  {publicUser?.other_country
                    ? publicUser?.other_country_name
                    : publicUser?.Country?.name}
                </div>
                {publicUser?.religion && (
                  <div className="col-6">Religion: {publicUser?.religion}</div>
                )}
              </div>
            </div>
            {(publicUser?.subCommunityId || publicUser?.other_CSO) && (
              <div className="cso_wrapper custom">
                <h5>Civil Society Organisation</h5>
                <div className="cso">
                  <h6>{publicUser?.group_type}</h6>
                  {publicUser?.SubCommunity?.name || publicUser?.other_CSO}
                </div>
              </div>
            )}
            {publicUser?.organisation && (
              <div className="cso_wrapper custom">
                <h5>Organisation</h5>
                <div className="cso">
                  <h6>{publicUser?.organisation}</h6>
                  {publicUser?.organisation_name}
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="default"
            onClick={() => {
              setShowModel(false);
              setPublicUser({});
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div
        className="input-group mb-4 table-filters"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div style={{ display: "flex" }}>
          <input
            type="search"
            placeholder="Search by name"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            aria-describedby="button-addon5"
            className="form-control"
            style={{ borderRadius: ".375rem 0px 0px .375rem" }}
          />
          <div className="input-group-append">
            <button
              id="button-addon5"
              type="button"
              onClick={() => fetchData()}
              className="btn btn-primary color3863A0"
              style={{
                borderRadius: "2px",
                height: "100%",
              }}
            >
              <i className="bx bx-search"></i>
            </button>
          </div>
          {user.role < 3 && (
            <InputGroup className="mx-3" style={{ width: "fit-content" }}>
              <DropdownButton
                variant="outline-secondary"
                title={
                  selectedCountry ? selectedCountry["name"] : "Select Country"
                }
                id="input-group-dropdown-1"
                onSelect={(e) => {
                  setSelectedCountry(JSON.parse(e));
                }}
              >
                <Dropdown.Item eventKey={undefined}>Select None</Dropdown.Item>
                {countries.map((country, index) => (
                  <Dropdown.Item eventKey={JSON.stringify(country)} key={index}>
                    {country.name}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </InputGroup>
          )}
        </div>
      </div>

      {requests.length === 0 ? (
        <NoDataPresent />
      ) : (
        <>
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th className="table-head">#</th>
                <th className="table-head">Name</th>
                <th className="table-head">Email</th>
                <th className="table-head">Country</th>
                <th className="table-head">Organisation</th>
                <th className="table-head">Group Type</th>
                <th className="table-head">CSO</th>
                <th className="table-head">Action</th>
              </tr>
            </thead>
            <tbody>
              {requests &&
                requests.map((user, index) => (
                  <tr key={index}>
                    <td>{pagination.offset + index + 1}</td>
                    <td>
                      {user.username}
                      <i
                        className="fa-solid fa-circle-info fa-xl"
                        style={{
                          cursor: "pointer",
                          marginLeft: "1em",
                        }}
                        onClick={() => {
                          getPublicUserDetails(user.email);
                        }}
                      ></i>{" "}
                    </td>

                    <td>{user.email}</td>
                    <td>
                      {user.other_country
                        ? user.other_country_name
                        : user.Country?.name}
                    </td>
                    <td>
                      {user.organisation + " - " + user.organisation_name}
                    </td>
                    <td>{user.group_type}</td>
                    <td>{user.SubCommunity?.name || user.other_CSO}</td>

                    <td>
                      <Button
                        variant="success"
                        // className="rounded-pill"
                        onClick={() => {
                          approveReject(
                            true,
                            user.id,
                            user.email,
                            user.username,
                            user.other_country
                              ? user.other_country_name
                              : user.Country?.name
                          );
                        }}
                      >
                        <i class="fa-regular fa-circle-check fa-xl"></i>
                      </Button>

                      <Button
                        variant="danger"
                        // className="rounded-pill"
                        style={{
                          marginLeft: "1em",
                        }}
                        onClick={() => {
                          approveReject(
                            false,
                            user.id,
                            user.email,
                            user.username,
                            user.other_country
                              ? user.other_country_name
                              : user.Country?.name
                          );
                        }}
                      >
                        <i class="fa-regular fa-circle-xmark fa-xl"></i>{" "}
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <Pagination
            setPagination={setPagination}
            totalRecords={totalRecords}
          />
        </>
      )}
    </div>
  );
}
