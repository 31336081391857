export const formatDate = (inputDate) => {
  if (!isNaN(inputDate)) return;
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: userTimezone,
  };

  const utcDate = new Date(inputDate);
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    utcDate
  );

  return formattedDate;
};

export function extractShortDescription(html, maxLength) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const textContent = doc.body.textContent.trim();

  const sentence = textContent.split(/[.!?]/)[0];

  let shortDescription = "";

  // if (sentence.length < 130) {
  //   shortDescription = sentence + ".";
  // } else {
  shortDescription = textContent.slice(0, 130) + "...";
  // }

  return shortDescription.trim();
}

export const formatDateAndTime = (dateString) => {
  const dateObject = new Date(dateString);

  const formattedDate = dateObject.toLocaleString(undefined, {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  return formattedDate;
};
