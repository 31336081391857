import React from "react";
import { Table } from "react-bootstrap";

export default function NoDataPresent() {
  return (
    <>
      <Table striped bordered responsive>
        <thead>
          <tr>
            <th className="table-head">#</th>
            <th className="table-head">Name</th>
            <th className="table-head">Created By</th>
            <th className="table-head">View</th>
            <th className="table-head">Edit</th>
            <th className="table-head">Draft</th>
            <th className="table-head">Delete</th>
          </tr>
        </thead>
      </Table>
      <div className="noData_wrapper">
        <h2>No Data Present</h2>
      </div>
    </>
  );
}
