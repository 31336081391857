import axiosInstance from "../utility/axios";
import { useEffect, useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import DropdownButton from "react-bootstrap/DropdownButton";
import Badge from "react-bootstrap/Badge";
import Dropdown from "react-bootstrap/Dropdown";
import Table from "react-bootstrap/Table";
import Pagination from "../components/Pagination";
import EditCampaign from "../components/EditCampaign";
import CampaignPreview from "../components/CampaignPreview";
import DeleteConfirmation from "../components/DeleteModal";
import DeactivateConfirmation from "../components/DeleteModal";
import Notification from "../components/Notification";
import Compressor from "compressorjs";
import CampaignUserData from "../components/CampaignUserData";
import { useLoading } from "../utility/LoadingContext";
import { userContext } from "./Context";
import NoDataPresent from "../components/NoDataPresent";

const publishStage = {
  1: "Changes Required",
  2: "review",
  3: "published",
};

const campaign_category = ["Knowledge", "Global", "Local", "General"];

const Campaigns = () => {
  const { user } = useContext(userContext);
  const [fileError, setFileError] = useState("");
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [campaignsList, setCampaignsList] = useState([]);
  const [state, setState] = useState();
  const [editCampaign, setEditCampaign] = useState("");
  const [showCampaign, setShowCampaign] = useState(false);
  const [itemId, setItemId] = useState(-1);
  const [tags, setTags] = useState([]);
  const [addTag, setAddTag] = useState(false);
  const [tagMsg, setTagMsg] = useState("");
  const { setLoading } = useLoading();

  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState(undefined);
  const [selectedStage, setSelectedStage] = useState(undefined);
  const [countries, setCountries] = useState([]);
  const [category, setCategory] = useState(undefined);
  const [msg, setMsg] = useState({
    message: [],
    variant: "danger",
  });
  const [notificationData, setNotificationData] = useState([]);
  const [form, setForm] = useState({
    id: "",
    title: "",
    start: "",
    end: "",
    tag: [],
    comments: [],
    category: "",
    bannerImage: "",
    bannerImageURL: "",
    bannerImageExt: "",
    bannerImageId: "",
    description: "",
    countryIds: [],
    publishStage: undefined,
    count: "",
    host: "",
    email: "",
    contact: "",
    target: 0,
    message: "",
  });
  const [showDeactivateConfirmation, setShowDeactivateConfirmation] =
    useState(false);
  const [deactivateId, setDeactivateId] = useState(undefined);
  const [showCampaignData, setShowCampaignData] = useState({
    id: undefined,
    show: false,
    info: undefined,
  });

  useEffect(() => {
    axiosInstance.get("/user/country/list").then((res) => {
      setCountries(res.data);
    });
  }, []);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleTagChange = (e) => {
    let currID = parseInt(e.target.value);
    if (form.tag.some((t) => t === currID)) {
      setForm((prevForm) => ({
        ...prevForm,
        tag: form.tag.filter((t) => t !== currID),
      }));
    } else {
      setForm((prevForm) => ({
        ...prevForm,

        tag: [...form.tag, currID],
      }));
    }
  };

  const createTag = (tagName) => {
    axiosInstance
      .post(`/campaign/create/tags`, { name: tagName })
      .then((response) => {
        if (response.status === 200) {
          updateTags();
          setAddTag(false);
          setTagMsg(response.data?.message);
        } else {
          setTagMsg("Unable to create tag");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removeTag = (id) => {
    axiosInstance
      .delete(`/campaign/delete/tags/${id}`)
      .then((response) => {
        if (response.status === 200) {
          updateTags();
          setTagMsg(response.data?.message);
        } else {
          setTagMsg("Unable to delete tag");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file && file.type.match("image.*") && file.size <= 5000000) {
      setForm({ ...form, [e.target.name]: file });
      setFileError({ ...fileError, [e.target.name]: "" });
      try {
        const compressedBlob = await new Promise((resolve, reject) => {
          new Compressor(file, {
            quality: 0.6,
            success(result) {
              resolve(result);
            },
            error(err) {
              reject(err);
            },
          });
        });

        const compressedFile = new File([compressedBlob], file.name, {
          type: file.type,
        });
        setForm({ ...form, [e.target.name]: compressedFile });
      } catch (error) {
        console.log(error.message);
      }
    } else {
      setFileError({
        ...fileError,
        [e.target.name]: "File can have max size of 5MB",
      });
    }
  };

  const handleContentChange = (e, editor) => {
    setForm((prev) => ({ ...prev, description: editor.getData() }));
  };

  const removeSelectedFile = (name) => {
    const fileInput = document.getElementById(name);
    fileInput.value = "";

    setForm({ ...form, [name]: "" });
    setFileError({ ...fileError, [name]: "" });
  };

  const updateTable = () => {
    let query = `search=${search}&offset=${pagination.offset}&limit=${pagination.limit}`;
    if (user.role < 3 && selectedCountry) {
      query += `&country_id=${selectedCountry.id}`;
    } else if (user.role >= 3) {
      query += `&country_id=${user.countryId}`;
    }
    if (selectedStage) query += `&stage_id=${selectedStage}`;
    if (category) query += `&category=${category}`;
    axiosInstance
      .get(`/campaign/get?${query}`)
      .then((res) => {
        if (
          res.data.campaigns.length &&
          typeof res.data.campaigns === "object"
        ) {
          setCampaignsList([...res.data.campaigns]);
          setTotalRecords(res.data.count || 0);
        } else {
          setCampaignsList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    updateTable();
  }, [selectedCountry, selectedStage, category]);

  const updateTags = () => {
    axiosInstance
      .get(`/campaign/get/tags/readAll`)
      .then((response) => {
        if (response.status === 200) {
          setTags([...response.data?.response]);
        } else {
          // TODO
        }
      })
      .catch((err) => {
        console.log(err, "getting tags");
      });
  };

  const handleDelete = (id) => {
    axiosInstance
      .delete(`/campaign/draft/${id}?indicator=2`)
      .then((response) => {
        if (response.status === 200) {
          updateTable();
        } else {
          // TODO: alert
        }
        setNotificationData([
          {
            msg: response.data.message,
            status: response.status,
          },
        ]);
      })
      .catch((err) => {});
    setShowModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("title", form.title);
    formData.append("start", form.start);
    formData.append("end", form.end);
    formData.append("category", form.category);
    formData.append("description", form.description);
    formData.append("bannerImage", form.bannerImage);
    formData.append("tagIds", [...form.tag]);
    formData.append("host", form.host);
    formData.append("contact", form.contact);
    formData.append("email", form.email);
    formData.append("target", form.target);
    formData.append("message", form.message);
    formData.append(
      "countryIds",
      JSON.stringify(form.countryIds.map((country) => country.id))
    );

    //create
    if (!form.id) {
      axiosInstance
        .post("/campaign/create", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setEditCampaign("");
            updateTable();
            setShowCampaign(false);
            setMsg({
              message: [],
              variant: "danger",
            });
          } else {
            if (res.data.message === "Validation error")
              setMsg({
                message: res.data.error?.details?.map((e) => e.message),
                variant: "danger",
              });
            else if (res.data.message === "DB Error")
              setMsg({ message: [...res.data.error], variant: "danger" });
            else setMsg({ message: [res.data.message], variant: "danger" });
          }
          setNotificationData([
            {
              msg: res.data.message,
              status: res.status,
            },
          ]);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      //update
      formData.append("bannerImageId", form.bannerImageId);
      axiosInstance
        .patch(`/campaign/edit/${form.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setEditCampaign("");
            updateTable();
            setShowCampaign(false);
            setMsg({
              message: [],
              variant: "danger",
            });
          } else {
            if (res.data.message === "Validation error")
              setMsg({
                message: res.data.error?.details?.map((e) => e.message),
                variant: "danger",
              });
            else if (res.data.message === "DB Error")
              setMsg({ message: [...res.data.error], variant: "danger" });
            else setMsg({ message: [res.data.message], variant: "danger" });
          }
          setNotificationData([
            {
              msg: res.data.message,
              status: res.status,
            },
          ]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const PreviewModal = () => {
    return (
      <Modal show={show} onHide={() => setShow(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Campaign Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal_body">
          <CampaignPreview form={form} tags={tags} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const fetchData = (id) => {
    axiosInstance
      .get(`/campaign/get/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setForm({
            id: res.data.campaign.id,
            title: res.data.campaign.title,
            start: res.data.campaign.start,
            end: res.data.campaign.end,
            tag: res.data.campaign.tags?.map((tag) => tag.id),
            ptags: res.data.campaign.tags,
            bannerImage: "",
            bannerImageURL: res.data.campaign.bannerImageURL,
            bannerImageExt: res.data.campaign.bannerImageExt,
            bannerImageId: res.data.campaign.bannerImageId,
            bannerImageName: res.data.campaign.bannerImageName,
            description: res.data.campaign.description,
            countryIds: res.data.campaign.countries,
            comments: res.data.campaign.comments,
            category: res.data.campaign.category,
            publishStage: res.data.campaign.publishStage,
            count: res.data.campaign.count,
            authorId: res.data.campaign.authorId,
            author: res.data.campaign.author,
            email: res.data.campaign.email,
            host: res.data.campaign.host,
            contact: res.data.campaign.contact,
            target: res.data.campaign.target,
            message: res.data.campaign.message,
          });
        } else {
          setEditCampaign("");
          setForm({
            id: "",
            title: "",
            start: "",
            end: "",
            tag: [],
            bannerImage: "",
            bannerImageURL: "",
            bannerImageExt: "",
            bannerImageId: "",
            description: "",
            countryIds: [],
            comments: [],
            category: "",
            publishStage: undefined,
            email: "",
            host: "",
            contact: "",
            target: 0,
            message: "",
          });
          setShowCampaign(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (editCampaign) {
      fetchData(editCampaign);
    }
  }, [showCampaign]);

  useEffect(() => {
    updateTable();
    updateTags();
    return () => {
      setState({});
    };
  }, []);

  const showPreview = (id) => {
    fetchData(id);
    setShow(true);
  };

  const deactivateCampaign = (id, indicator) => {
    axiosInstance
      .delete(`/campaign/draft/${id}?indicator=${indicator}`)
      .then((res) => {
        setNotificationData([
          {
            msg: res.data.message,
            status: res.status,
          },
        ]);
        updateTable();
      });
  };

  useEffect(() => {
    if (deactivateId) {
      setShowDeactivateConfirmation(true);
    }
  }, [deactivateId]);

  // HTML to PDF convert
  const htmlToPdf = async (id) => {
    setLoading(true);
    await axiosInstance
      .get(`/campaign/get/download-summary/${id}`, {
        responseType: "blob",
      })
      .then((res) => {
        if (res.status === 200) {
          const url = URL.createObjectURL(res.data);
          const a = document.createElement("a");
          a.href = url;
          a.download = "Summary.pdf";
          a.click();
          URL.revokeObjectURL(url);
        }
        setNotificationData([
          {
            msg: res?.statusText,
            status: res.status,
          },
        ]);
      });
    setLoading(false);
  };

  return (
    <>
      <CampaignUserData
        showCampaignData={showCampaignData}
        setShowCampaignData={setShowCampaignData}
      />
      <DeactivateConfirmation
        showModal={showDeactivateConfirmation}
        hideModal={() => {
          setShowDeactivateConfirmation(false);
          setDeactivateId(undefined);
        }}
        confirmModal={() => {
          deactivateCampaign(
            deactivateId?.id,
            deactivateId?.active_indicator === 1 ? 0 : 1
          );
          setDeactivateId(undefined);
          setShowDeactivateConfirmation(false);
        }}
        message={`Are you sure you want to ${
          deactivateId?.active_indicator === 1 ? "Deactivate" : "Activate"
        } ${deactivateId?.title}?`}
        btnMsg={
          deactivateId?.active_indicator === 1 ? "Deactivate" : "Activate"
        }
      />
      <DeleteConfirmation
        showModal={showModal}
        hideModal={() => {
          setShowModal(false);
        }}
        confirmModal={() => {
          handleDelete(itemId.id);
          setShowModal(false);
        }}
        message={`Are you sure you want to delete ${itemId?.title}?`}
      />
      <PreviewModal />
      <Notification
        notificationData={notificationData}
        setNotificationData={setNotificationData}
      />
      {showCampaign ? (
        <EditCampaign
          form={form}
          setForm={setForm}
          setShow={setShow}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          handleContentChange={handleContentChange}
          handleImageChange={handleImageChange}
          setShowCampaign={setShowCampaign}
          setEditCampaign={setEditCampaign}
          removeSelectedFile={removeSelectedFile}
          tags={tags}
          handleTagChange={handleTagChange}
          removeTag={removeTag}
          addTag={addTag}
          setAddTag={setAddTag}
          createTag={createTag}
          tagMsg={tagMsg}
          setTagMsg={setTagMsg}
          campaign_category={campaign_category}
          msg={msg}
          setMsg={setMsg}
          setNotificationData={setNotificationData}
          updateTable={updateTable}
          fileError={fileError}
          setFileError={setFileError}
        />
      ) : (
        <>
          <div
            className="input-group mb-4 table-filters"
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", flexWrap: "wrap", gap: "1em" }}>
              <div style={{ display: "flex" }}>
                <input
                  type="search"
                  placeholder="Search by name"
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  aria-describedby="button-addon5"
                  className="form-control"
                  style={{ borderRadius: ".375rem 0px 0px .375rem" }}
                />
                <div className="input-group-append">
                  <button
                    id="button-addon5"
                    type="button"
                    onClick={updateTable}
                    className="btn btn-primary color3863A0"
                    style={{
                      borderRadius: "2px",
                      height: "100%",
                    }}
                  >
                    <i className="bx bx-search"></i>
                  </button>
                </div>
              </div>
              {user.role < 3 && (
                <InputGroup className="mx-3" style={{ width: "fit-content" }}>
                  <DropdownButton
                    variant="outline-secondary"
                    title={
                      selectedCountry
                        ? selectedCountry["name"]
                        : "Select Country"
                    }
                    id="input-group-dropdown-1"
                    onSelect={(e) => {
                      setSelectedCountry(JSON.parse(e));
                    }}
                  >
                    <Dropdown.Item eventKey={undefined}>
                      Select None
                    </Dropdown.Item>
                    {countries.map((country, index) => (
                      <Dropdown.Item
                        eventKey={JSON.stringify(country)}
                        key={index}
                      >
                        {country.name}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </InputGroup>
              )}
              <InputGroup className="mx-3" style={{ width: "fit-content" }}>
                <DropdownButton
                  variant="outline-secondary"
                  title={
                    selectedStage ? publishStage[selectedStage] : "Select Stage"
                  }
                  id="input-group-dropdown-1"
                  onSelect={(e) => {
                    setSelectedStage(e);
                  }}
                >
                  <Dropdown.Item eventKey={undefined}>
                    Select None
                  </Dropdown.Item>
                  {Object.keys(publishStage).map((stageId, index) => (
                    <Dropdown.Item eventKey={stageId} key={index}>
                      {publishStage[stageId]}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </InputGroup>
              <InputGroup className="mx-3" style={{ width: "fit-content" }}>
                <DropdownButton
                  variant="outline-secondary"
                  title={category ? category : "Select Category"}
                  id="input-group-dropdown-1"
                  onSelect={(e) => {
                    setCategory(e);
                  }}
                >
                  <Dropdown.Item eventKey={undefined}>
                    Select None
                  </Dropdown.Item>
                  {campaign_category.map((campaignCategory, index) => (
                    <Dropdown.Item eventKey={campaignCategory} key={index}>
                      {campaignCategory}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </InputGroup>
            </div>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                setShowCampaign(true);
                setForm({
                  id: undefined,
                  title: "",
                  start: "",
                  end: "",
                  tag: [],
                  bannerImage: "",
                  bannerImageURL: "",
                  bannerImageExt: "",
                  bannerImageId: "",
                  description: "",
                  countryIds: [],
                  comments: [],
                  category: "",
                  publishStage: undefined,
                  host: "",
                  email: "",
                  contact: "",
                  target: 0,
                  message: "",
                });
              }}
            >
              Create Petition
            </button>
          </div>
          {campaignsList.length === 0 ? (
            <NoDataPresent />
          ) : (
            <>
              <Table striped bordered responsive>
                <thead>
                  <tr>
                    <th className="table-head">#</th>
                    <th className="table-head">Name</th>
                    <th className="table-head">Created By</th>
                    <th className="table-head"></th>
                    <th className="table-head">Published</th>
                    <th className="table-head">View</th>
                    <th className="table-head">Edit</th>
                    <th className="table-head">Draft</th>
                    <th className="table-head">Delete</th>
                    <th className="table-head">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {campaignsList.map((campaign, index) => (
                    <tr key={index}>
                      <td>{pagination.offset + index + 1}</td>
                      <td>{campaign.title}</td>
                      <td>{campaign.createdBy}</td>
                      <td>
                        <Badge bg="light" text="dark">
                          {campaign.country}
                        </Badge>
                        <Badge bg="light" text="dark">
                          {campaign.category}
                        </Badge>
                      </td>
                      <td>
                        {publishStage[campaign.publishStage]} <br />
                        {campaign.publishStage === 1 && campaign.comment ? (
                          <p>
                            <b>Reason for Rejection: </b> {campaign.comment}
                          </p>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        <i
                          className="fa-solid fa-box-open fa-1x"
                          onClick={() => showPreview(campaign.id)}
                        ></i>
                      </td>
                      <td>
                        <i
                          className="fa-solid fa-pen-to-square fa-1x"
                          onClick={() => {
                            setShowCampaign(true);
                            setEditCampaign(campaign.id);
                          }}
                        ></i>
                      </td>
                      <td>
                        <i
                          className={`fa-solid fa-lock${
                            campaign.active_indicator === 1 ? "-open" : ""
                          } fa-1x`}
                          onClick={() => setDeactivateId(campaign)}
                        ></i>
                      </td>
                      {/* first check the resource should not be made by public user && check if it is really created by the same cc or the role should be less than CC   */}
                      {(!campaign.isAuthorPublic &&
                        user.id == campaign.createdById) ||
                      user.role < 5 ? (
                        <td>
                          <i
                            className="fa-solid fa-trash-can fa-1x"
                            onClick={() => {
                              setItemId(campaign);
                              setShowModal(true);
                            }}
                          ></i>
                        </td>
                      ) : (
                        <td>
                          <i className="fa-solid fa-ban fa-1x"></i>
                        </td>
                      )}
                      <td>
                        <i
                          className="fa-solid fa-file-invoice fa-1x mx-2"
                          onClick={() =>
                            setShowCampaignData({
                              id: campaign.id,
                              show: true,
                              info: campaign,
                            })
                          }
                        ></i>
                        <i
                          className="fa-solid fa-download fa-1x mx-2"
                          onClick={() => htmlToPdf(campaign.id)}
                        ></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Pagination
                setPagination={setPagination}
                totalRecords={totalRecords}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default Campaigns;
