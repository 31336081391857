import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import GalleryContainer from "./GalleryContainer";
import Videos from "./Videos";
import { React } from "react";

const Media = () => {
  return (
    <>
      <section className="user-section" id="user-section">
        <div className="user-content">
          <Tabs
            defaultActiveKey="Gallery"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="Gallery" title="Gallery">
              <GalleryContainer />
            </Tab>
            <Tab eventKey="videos" title="Videos">
              <Videos />
            </Tab>
          </Tabs>
        </div>
      </section>
    </>
  );
};

export default Media;
