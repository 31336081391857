import React from "react";
import { useContext } from "react";
import { userContext } from "../pages/Context";
import PreviewCard from "./PreviewCard";
import { formatDate, extractShortDescription } from "../utility/utils";
import "./CoursePreview.css";

const CoursePreview = ({ form }) => {
  const ctx = useContext(userContext);
  return (
    <div id="preview">
      <PreviewCard
        title={form.title}
        img={
          typeof form.bannerImageURL === "string" && form.bannerImageURL.length
            ? process.env.REACT_APP_BUCKET_URL + form.bannerImageURL
            : typeof form.bannerImage === "object" && form.bannerImage !== null
            ? URL.createObjectURL(form.bannerImage)
            : "https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png"
        }
        date={formatDate(form.date)}
        showBtn={false}
        description={extractShortDescription(form.description, 50)}
        author={form.instructor}
        link={""}
      />
      <div id="course-preview-section">
        <div className="row smile-photo">
          <div className="col-sm-6">
            <img
              loading="lazy"
              src={
                form.bannerImageURL
                  ? process.env.REACT_APP_BUCKET_URL + form.bannerImageURL
                  : "https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png"
              }
              alt="banner"
            />
          </div>
          <div className="col-sm-6 women-skills">
            <h4>{form.title}</h4>
            <p>
              <span>Instructor :</span> {form.instructor}
            </p>
            <p>
              <span>Duration :</span> {form.duration}
            </p>
            <p>
              <a href="#">
                <span>Category :</span> {form.category}
              </a>
            </p>
            <div className="course-join">
              <a href={form.courseLink} className="btn btn-pink">
                Join now
              </a>
            </div>
          </div>
        </div>
        <div className="marathon-event">
          <h4>About Course</h4>
          <div
            dangerouslySetInnerHTML={{ __html: form.description }}
            className="ck-content"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default CoursePreview;
