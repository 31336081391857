import React, { useState, useMemo, useEffect, useContext } from "react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import _ from "lodash";
import "react-big-calendar/lib/css/react-big-calendar.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Badge from "react-bootstrap/Badge";
import EventSideBar from "../components/EventSideBar";
import axiosInstance from "../utility/axios";
import DeleteConfirmation from "../components/DeleteModal";
import DeactivateConfirmation from "../components/DeleteModal";
import Notification from "../components/Notification";
import "./Calendar.css";
import EventUserData from "../components/EventUserData";
import { userContext } from "./Context";
import Upcoming from "../components/Upcoming";

const localizer = momentLocalizer(moment);

const publishStage = {
  1: "Changes Required",
  2: "review",
  3: "published",
};
const event_category = [
  "Knowledge Session",
  "Workshop",
  "Celebration",
  "General Meetup",
];

const CalendarToolbar = ({ date, view: viewMode, onView, ...props }) => {
  return <div>Whatever you want on toolbar</div>;
};

const CustomPopover = React.forwardRef(
  (
    {
      popper,
      children,
      show: _,
      setShowEventSideBar,
      setEditDetails,
      setDeleteId,
      setDeactivateId,
      setShowEventData,
      ...props
    },
    ref
  ) => {
    useEffect(() => {
      popper.scheduleUpdate();
    }, [children, popper]);

    const {
      id,
      title,
      start,
      end,
      category,
      description,
      bannerImageId,
      bannerImageExt,
      isOffline,
      publishStage,
      place,
      countries,
      comment,
      calendar_type,
      authorId,
      active_indicator,
      website,
      phone,
      email,
      host1Name,
      host1Image,
      host1Role,
      host2Name,
      host2Image,
      host2Role,
      host3Name,
      host3Image,
      host3Role,
      host4Name,
      host4Image,
      host4Role,
    } = children;

    const formatDateAndTime = (dateString) => {
      const dateObject = new Date(dateString);
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const month = months[dateObject.getUTCMonth()];
      const day = dateObject.getUTCDate();
      const year = dateObject.getUTCFullYear();

      // const hours = dateObject.getUTCHours();
      // const minutes = dateObject.getUTCMinutes();
      // const ampm = hours >= 12 ? "PM" : "AM";
      // const formattedHours = hours % 12 || 12;

      // return `${month} ${day}th ${year}, ${formattedHours}:${
      //   minutes < 10 ? "0" : ""
      // }${minutes} ${ampm}`;
      const options = {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        year: "numeric",
        month: "long",
        day: "numeric",
        hour12: true,
        hour: "numeric",
        minute: "2-digit",
      };
      const formattedHours = dateObject.toLocaleString(undefined, options);
      // return `${month} ${day}th ${year}, ${formattedHours}`;
      return `${formattedHours}`;
    };
    const formattedStart = formatDateAndTime(start);
    const formattedEnd = formatDateAndTime(end);

    return (
      <Popover id={id} ref={ref} {...props}>
        <Popover.Header
          as="h3"
          className="d-flex justify-content-between align-items-center"
        >
          {title}{" "}
          <span style={{ gap: "1em", display: "flex", alignItems: "center" }}>
            <i
              className="fa-solid fa-pen-to-square fa-1x"
              onClick={() => {
                setShowEventSideBar(true);
                setEditDetails({
                  event: {
                    id,
                    title,
                    category,
                    start,
                    end,
                    publishStage,
                    bannerImage:
                      bannerImageId &&
                      `${process.env.REACT_APP_BUCKET_URL}public/events/${bannerImageId}${bannerImageExt}`,
                    bannerImageId,
                    description,
                    isOffline,
                    place,
                    authorId,
                    active_indicator,
                    website,
                    phone,
                    email,
                    host1Name,
                    host1Image,
                    host1Role,
                    host2Name,
                    host2Image,
                    host2Role,
                    host3Name,
                    host3Image,
                    host3Role,
                    host4Name,
                    host4Image,
                    host4Role,
                  },
                  countries,
                });
              }}
            ></i>
            <td>
              <i
                className={`fa-solid fa-lock${
                  active_indicator === 1 ? "-open" : ""
                } fa-1x`}
                onClick={() => setDeactivateId(children)}
              ></i>
            </td>
            <i
              className="fa-solid fa-trash-can fa-1x"
              onClick={() => setDeleteId({ id, name: title })}
            ></i>
            <i
              className="fa-solid fa-file-invoice fa-1x mx-2"
              onClick={() =>
                setShowEventData({
                  id: id,
                  show: true,
                  info: children,
                })
              }
            ></i>
          </span>
        </Popover.Header>
        <Popover.Body id="preview">
          {publishStage === 1 && comment ? (
            <p
              style={{
                background: "lightcoral",
                padding: ".5em",
                borderRadius: "1em",
                color: "white",
              }}
            >
              {" "}
              <b> Reason for Rejection:</b> {comment}{" "}
            </p>
          ) : (
            ""
          )}
          {bannerImageId && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                loading="lazy"
                src={
                  process.env.REACT_APP_BUCKET_URL +
                  "public/events/" +
                  bannerImageId +
                  bannerImageExt
                }
                style={{
                  maxWidth: "100%",
                  maxHeight: "200px",
                }}
                alt=""
              />
            </div>
          )}
          <div className="posted-date">
            <span>Category:</span>
            <a href="#" style={{ pointerEvents: "none" }}>
              {category}
            </a>
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: description }}
            className="ck-content"
            style={{ overflow: "auto", padding: "0px 15px" }}
          ></div>
          <div className="event-date-r">
            <div></div>
            <div>
              <p>
                Calendar Type
                <span>{calendar_type.toUpperCase()}</span>
              </p>
            </div>
          </div>
          <div className="">
            <div className="right-event-detail">
              <div className="event-date-r">
                <div>
                  <img
                    loading="lazy"
                    src="/img/new-calender.png"
                    alt="Event Calendar"
                  />
                </div>
                <div>
                  <p>
                    Start Date & Time
                    <span>{formattedStart}</span>
                  </p>
                </div>
              </div>
              <div className="event-date-r">
                <div>
                  <img
                    loading="lazy"
                    src="/img/new-calender.png"
                    alt="Event Calendar"
                  />
                </div>
                <div>
                  <p>
                    End Date & Time
                    <span>{formattedEnd}</span>
                  </p>
                </div>
              </div>
              <div className="event-date-r">
                <div>
                  <img
                    loading="lazy"
                    src="/img/location.png"
                    alt="Event Venue"
                  />
                </div>
                <div>
                  {isOffline ? (
                    <>
                      <p>
                        Event Venue:<span>{place}</span>
                      </p>
                    </>
                  ) : (
                    <>
                      <p>
                        Event Registration Link:{" "}
                        <span>
                          {" "}
                          <a href={place} style={{ pointerEvents: "none" }}>
                            {place}
                          </a>
                        </span>
                      </p>
                    </>
                  )}
                </div>
              </div>
              <div className="may-dum">
                <div className="organizer-event">
                  <h4>Organizer</h4>
                  {phone && (
                    <p>
                      <span>Phone:</span> {phone}
                    </p>
                  )}

                  <p>
                    <span>Email:</span> {email}
                  </p>
                  {website && (
                    <p>
                      <span>Website:</span> {website}
                    </p>
                  )}
                </div>
              </div>
              <div className="jesus-hoiland">
                <h4>Event Host</h4>
                {host1Name && (
                  <div className="event-date-r" id="host-speak">
                    <div>
                      <img
                        loading="lazy"
                        className="host-event"
                        src={
                          typeof host1Image === "string" && host1Image?.length
                            ? process.env.REACT_APP_BUCKET_URL + host1Image
                            : typeof host1Image === "object" &&
                              host1Image !== null
                            ? URL.createObjectURL(host1Image)
                            : "https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png"
                        }
                        alt="Event Host"
                        style={{
                          minWidth: "50px",
                          maxWidth: "50px",
                          aspectRatio: 1,
                          borderRadius: "50%",
                        }}
                      />
                    </div>
                    <div>
                      <p>
                        {" "}
                        <a href="" style={{ pointerEvents: "none" }}>
                          {host1Name}
                        </a>
                        <span>{host1Role}</span>{" "}
                      </p>
                      {/* <span>Host and Speaker</span> */}
                    </div>
                  </div>
                )}
                {host2Name && (
                  <div className="event-date-r" id="host-speak">
                    <div>
                      <img
                        loading="lazy"
                        className="host-event"
                        src={
                          typeof host2Image === "string" && host2Image?.length
                            ? process.env.REACT_APP_BUCKET_URL + host2Image
                            : typeof host2Image === "object" &&
                              host2Image !== null
                            ? URL.createObjectURL(host2Image)
                            : "https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png"
                        }
                        alt="Event Host"
                        style={{
                          minWidth: "50px",
                          maxWidth: "50px",
                          aspectRatio: 1,
                          borderRadius: "50%",
                        }}
                      />
                    </div>
                    <div>
                      <p>
                        {" "}
                        <a href="" style={{ pointerEvents: "none" }}>
                          {host2Name}
                        </a>
                        <span>{host2Role}</span>{" "}
                      </p>
                    </div>
                  </div>
                )}

                {host3Name && (
                  <div className="event-date-r" id="host-speak">
                    <div>
                      <img
                        loading="lazy"
                        className="host-event"
                        src={
                          typeof host3Image === "string" && host3Image?.length
                            ? process.env.REACT_APP_BUCKET_URL + host3Image
                            : typeof host3Image === "object" &&
                              host3Image !== null
                            ? URL.createObjectURL(host3Image)
                            : "https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png"
                        }
                        alt="Event Host"
                        style={{
                          minWidth: "50px",
                          maxWidth: "50px",
                          aspectRatio: 1,
                          borderRadius: "50%",
                        }}
                      />
                    </div>
                    <div>
                      <p>
                        {" "}
                        <a href="" style={{ pointerEvents: "none" }}>
                          {host3Name}
                        </a>
                        <span>{host3Role}</span>{" "}
                      </p>
                    </div>
                  </div>
                )}
                {host4Name && (
                  <div className="event-date-r" id="host-speak">
                    <div>
                      <img
                        loading="lazy"
                        className="host-event"
                        src={
                          typeof host4Image === "string" && host4Image?.length
                            ? process.env.REACT_APP_BUCKET_URL + host4Image
                            : typeof host4Image === "object" &&
                              host4Image !== null
                            ? URL.createObjectURL(host4Image)
                            : "https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png"
                        }
                        alt="Event Host"
                        style={{
                          minWidth: "50px",
                          maxWidth: "50px",
                          aspectRatio: 1,
                          borderRadius: "50%",
                        }}
                      />
                    </div>
                    <div>
                      <p>
                        {" "}
                        <a href="" style={{ pointerEvents: "none" }}>
                          {host4Name}
                        </a>
                        <span>{host4Role}</span>{" "}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Popover.Body>
      </Popover>
    );
  }
);

const EventsCalendar = () => {
  const { user } = useContext(userContext);
  const [myEvents, setEvents] = useState([]);
  const [showEventSideBar, setShowEventSideBar] = useState(false);
  const [editDetails, setEditDetails] = useState({});
  const [filter, setfilter] = useState({
    country_id: undefined,
    category: undefined,
    stage_id: undefined,
    calendar_type: undefined,
    start: moment().startOf("month").toDate(), // Current month's start date
    end: moment().endOf("month").toDate(), // Current month's end date
  });
  const [selectedCountry, setSelectedCountry] = useState(undefined);
  const [countries, setCountries] = useState([]);
  const [selectedStage, setSelectedStage] = useState(undefined);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState(undefined);
  const [notificationData, setNotificationData] = useState([]);
  const [showDeactivateConfirmation, setShowDeactivateConfirmation] =
    useState(false);
  const [deactivateId, setDeactivateId] = useState(undefined);
  const [showEventData, setShowEventData] = useState({
    id: undefined,
    show: false,
    info: undefined,
  });

  // when navigation of calendar changes ie. next, previous, etc
  const handleNavigate = (date, view) => {
    const start = moment(date).startOf(view); // Start date of the range
    const end = moment(date).endOf(view); // End date of the range

    setfilter({
      ...filter,
      start,
      end,
    });
  };

  const getEvents = () => {
    let query = `start=${moment(filter.start).format(
      "YYYY-MM-DD HH:mm:ss"
    )}&end=${moment(filter.end).format("YYYY-MM-DD HH:mm:ss")}`;

    if (user.role < 3 && filter.country_id) {
      query += `&country_id=${filter.country_id}`;
    } else if (user.role >= 3) {
      query += `&country_id=${user.countryId}`;
    }
    if (filter.stage_id) query += `&stage_id=${filter.stage_id}`;
    if (filter.category) query += `&category=${filter.category}`;
    if (filter.calendar_type) query += `&calendar_type=${filter.calendar_type}`;
    axiosInstance
      .get(`/event/get?${query}`)
      .then((res) => {
        if (res.status === 200) {
          const newEvents = res.data?.events?.map((event) => {
            return {
              ...event,
              start: moment(event.start).toDate(),
              end: moment(event.end).toDate(),
            };
          });
          setEvents([...newEvents]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getEvents();
  }, [filter]);

  useEffect(() => {
    getEvents();
    axiosInstance.get("/user/country/list").then((res) => {
      setCountries(res.data);
    });
  }, []);

  const handlerRightBar = () => {
    setShowEventSideBar(!showEventSideBar);
    setEditDetails({});
  };

  const { defaultDate, scrollToTime } = useMemo(
    () => ({
      defaultDate: new Date(),
      scrollToTime: new Date(),
    }),
    []
  );

  const onSave = (data) => {
    setEvents((prev) => [...prev, data]);
    setShowModal(false);
  };

  useEffect(() => {
    if (deleteId) {
      setShowDeleteConfirmation(true);
    }
  }, [deleteId]);

  const deleteEvent = (id) => {
    axiosInstance
      .delete(`/event/draft/${id}?indicator=2`)
      .then((res) => {
        setNotificationData([
          {
            msg: res.data.message,
            status: res.status,
          },
        ]);
        getEvents();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deactivateEvent = (id, indicator) => {
    axiosInstance
      .delete(`/event/draft/${id}?indicator=${indicator}`)
      .then((res) => {
        setNotificationData([
          {
            msg: res.data.message,
            status: res.status,
          },
        ]);
        getEvents();
      });
  };

  useEffect(() => {
    if (deactivateId) {
      setShowDeactivateConfirmation(true);
    }
  }, [deactivateId]);

  return (
    <>
      {" "}
      <EventUserData
        showEventData={showEventData}
        setShowEventData={setShowEventData}
      />
      <DeactivateConfirmation
        showModal={showDeactivateConfirmation}
        hideModal={() => {
          setShowDeactivateConfirmation(false);
          setDeactivateId(undefined);
        }}
        confirmModal={() => {
          deactivateEvent(
            deactivateId?.id,
            deactivateId?.active_indicator === 1 ? 0 : 1
          );
          setDeactivateId(undefined);
          setShowDeactivateConfirmation(false);
        }}
        message={`Are you sure you want to ${
          deactivateId?.active_indicator === 1 ? "Deactivate" : "Activate"
        } ${deactivateId?.title}?`}
        btnMsg={
          deactivateId?.active_indicator === 1 ? "Deactivate" : "Activate"
        }
      />
      <DeleteConfirmation
        showModal={showDeleteConfirmation}
        hideModal={() => {
          setShowDeleteConfirmation(false);
          setDeleteId(undefined);
        }}
        confirmModal={() => {
          deleteEvent(deleteId?.id);
          setShowDeleteConfirmation(false);
        }}
        message={`Are you sure you want to delete ${deleteId?.name}?`}
      />
      <Notification
        notificationData={notificationData}
        setNotificationData={setNotificationData}
      />
      <div
        className="mb-3"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          flexWrap: "wrap",
          gap: "1em",
        }}
      >
        <InputGroup className="mx-3" style={{ width: "fit-content" }}>
          <DropdownButton
            variant="outline-secondary"
            title={
              filter.calendar_type
                ? _.capitalize(filter.calendar_type)
                : "Calendar Type"
            }
            id="input-group-dropdown-1"
            onSelect={(e) => {
              setfilter({
                ...filter,
                calendar_type: e,
              });
            }}
          >
            <Dropdown.Item eventKey={undefined}>Select None</Dropdown.Item>
            <Dropdown.Item eventKey={"event"}>Event</Dropdown.Item>
            <Dropdown.Item eventKey={"webinar"}>Webinar</Dropdown.Item>
          </DropdownButton>
        </InputGroup>
        {user.role < 3 && (
          <InputGroup className="mx-3" style={{ width: "fit-content" }}>
            <DropdownButton
              variant="outline-secondary"
              title={
                selectedCountry ? selectedCountry["name"] : "Select Country"
              }
              id="input-group-dropdown-1"
              onSelect={(e) => {
                setSelectedCountry(JSON.parse(e));
                setfilter({
                  ...filter,
                  country_id: JSON.parse(e) ? JSON.parse(e).id : undefined,
                });
              }}
            >
              <Dropdown.Item eventKey={undefined}>Select None</Dropdown.Item>
              {countries.map((country, index) => (
                <Dropdown.Item eventKey={JSON.stringify(country)} key={index}>
                  {country.name}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </InputGroup>
        )}
        <InputGroup className="mx-3" style={{ width: "fit-content" }}>
          <DropdownButton
            variant="outline-secondary"
            title={selectedStage ? publishStage[selectedStage] : "Select Stage"}
            id="input-group-dropdown-1"
            onSelect={(e) => {
              setSelectedStage(e);
              setfilter({
                ...filter,
                stage_id: e ? e : undefined,
              });
            }}
          >
            <Dropdown.Item eventKey={undefined}>Select None</Dropdown.Item>
            {Object.keys(publishStage).map((stageId, index) => (
              <Dropdown.Item eventKey={stageId} key={index}>
                {publishStage[stageId]}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </InputGroup>
        <InputGroup className="mx-3" style={{ width: "fit-content" }}>
          <DropdownButton
            variant="outline-secondary"
            title={filter.category ? filter.category : "Select Event Category"}
            id="input-group-dropdown-1"
            onSelect={(e) => {
              setfilter({
                ...filter,
                category: e ? e : undefined,
              });
            }}
          >
            <Dropdown.Item eventKey={undefined}>Select None</Dropdown.Item>
            {event_category.map((eventCategory, index) => (
              <Dropdown.Item eventKey={eventCategory} key={index}>
                {eventCategory}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </InputGroup>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={handlerRightBar}
        >
          Create
        </button>
      </div>
      <div className="upcoing_wrapper">
        <div className="upcoming_events">
          <h3>Upcoming Event</h3>
          <Upcoming Type={"Event"} />
        </div>
        <div className="upcoming_webinar">
          <h3>Upcoming Webinar</h3>
          <Upcoming Type={"Webinar"} />
        </div>
        <div className="upcoming_events_webinar">
          <h3>Home Upcoming Events & Webinar</h3>
          <Upcoming Type={"Events_Webinar"} />
        </div>
      </div>
      <div className="mb-3" id="calendar-legend" style={{ gap: "5px" }}>
        <Badge>
          <i className="bx bx-info-circle bx-sm" style={{ color: "red" }}></i>
          <p> Draft</p>
        </Badge>
        <Badge>
          <i
            className="bx bx-info-circle bx-sm"
            style={{ color: "#00a8ff" }}
          ></i>
          <p> In Review</p>
        </Badge>
        <Badge>
          <i className="bx bx-info-circle bx-sm" style={{ color: "lime" }}></i>
          <p>Published</p>
        </Badge>
      </div>
      <EventSideBar
        show={showEventSideBar}
        details={editDetails}
        countries={countries}
        refresh={() => {
          getEvents();
          setEditDetails({});
        }}
        handleClose={() => {
          setShowEventSideBar(false);
          setEditDetails({});
        }}
      />
      <Calendar
        localizer={localizer}
        events={myEvents}
        toolbar={true}
        views={{
          day: true,
          week: true,
          month: true,
        }}
        defaultDate={defaultDate}
        defaultView={Views.MONTH}
        onNavigate={handleNavigate}
        selectable
        scrollToTime={scrollToTime}
        components={{
          event: (component) => {
            const { event } = component;
            const targetId = event.id;
            return (
              <OverlayTrigger
                trigger="click"
                placement="auto"
                overlay={
                  <CustomPopover
                    id="popover-contained"
                    setShowEventSideBar={setShowEventSideBar}
                    setDeleteId={setDeleteId}
                    setEditDetails={setEditDetails}
                    setDeactivateId={setDeactivateId}
                    setShowEventData={setShowEventData}
                  >
                    {event}
                  </CustomPopover>
                }
                rootClose
              >
                {({ ref, ...triggerHandler }) => (
                  <div id={targetId} {...triggerHandler}>
                    <i
                      ref={ref}
                      className="bx bx-info-circle"
                      style={{
                        color:
                          event.publishStage === 2
                            ? "#00a8ff"
                            : event.publishStage === 3
                            ? "lime"
                            : "red",
                        fontSize: "20px",
                        margin: "0px 5px 0px 0px",
                        cursor: "pointer",
                        verticalAlign: "middle",
                      }}
                    ></i>
                    {event?.title}
                  </div>
                )}
              </OverlayTrigger>
            );
          },
          // toolbar: (props) => <CalendarToolbar {...props} />,
        }}
      />
    </>
  );
};

export default EventsCalendar;
