import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { userContext } from "./Context";
import axiosInstance from "../utility/axios";
import Notification from "../components/Notification";
import DocsPreview from "../components/DocsPreview";
import Pagination from "../components/Pagination";
import DeleteConfirmation from "../components/DeleteModal";
import NoDataPresent from "../components/NoDataPresent";
import TrashNote from "../components/TrashNote";

export default function TrashDocs() {
  const [allDocs, setAllDocs] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [docs, setDocs] = useState({});
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
  });
  const [notificationData, setNotificationData] = useState([]);

  const { user } = useContext(userContext);

  const deleteDocs = (id) => {
    axiosInstance
      .delete(`/trash/delete-docs/${id}`)
      .then(async (response) => {
        if (response.status === 200) {
          getAllTrashDocs();
        } else {
          // TODO: alert
        }
        setNotificationData([
          {
            msg: response.data.message,
            status: response.status,
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const restoreDocs = (id) => {
    axiosInstance.get(`/trash/restore-docs/${id}?indicator=0`).then((res) => {
      if (res.status === 200) {
        setNotificationData([
          {
            msg: "Resource is being restored as draft.You can publish it live from the respective section",
            status: res.status,
          },
        ]);
      } else {
        setNotificationData([
          {
            msg: res.data.message,
            status: res.status,
          },
        ]);
      }

      getAllTrashDocs();
    });
  };

  const getAllTrashDocs = () => {
    let query = `search=&offset=${pagination.offset}&limit=${pagination.limit}`;
    if (user.role < 3 /*&& selectedCountry*/) {
      //   query += `&country_id=${selectedCountry.id}`;
    } else if (user.role >= 3) {
      query += `&country_id=${user.countryId}`;
    }
    axiosInstance
      .get(`/trash/get/trash-docs?${query}`)
      .then((res) => {
        setAllDocs(res.data.docss);
        setTotalRecords(res.data.count || 0);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getAllTrashDocs();
  }, [pagination]);

  return (
    <div className="docs_wrapper">
      <Notification
        notificationData={notificationData}
        setNotificationData={setNotificationData}
      />
      <DeleteConfirmation
        showModal={showModal}
        hideModal={() => {
          setShowModal(false);
        }}
        confirmModal={() => {
          deleteDocs(docs.id);
          setShowModal(false);
        }}
        message={`Are you sure you want to delete ${docs?.title}?`}
      />
      <Modal show={show} onHide={() => setShow(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Resource Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal_body">
          <DocsPreview form={docs} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <h2>Delete Documents / Reports / Tools</h2>

      {totalRecords > 0 ? (
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th className="table-head">#</th>
              <th className="table-head">Title</th>
              <th className="table-head">Type</th>
              <th className="table-head">Created From</th>
              <th className="table-head">Deleted By</th>
              <th className="table-head">View</th>
              <th className="table-head">Restore</th>
              <th className="table-head">Delete</th>
            </tr>
          </thead>
          <tbody>
            {allDocs.map((docss, index) => {
              return (
                <tr key={index}>
                  <td>{pagination.offset + index + 1}</td>
                  <td>{docss.title}</td>
                  <td>{docss.docs_type}</td>
                  <td>{docss.isAuthorPublic ? "Website" : "Dashboard"}</td>
                  <td>{docss.modifiedBy}</td>
                  <td>
                    {" "}
                    <i
                      className="fa-solid fa-box-open fa-1x"
                      onClick={() => {
                        setDocs(docss);
                        setShow(true);
                      }}
                    ></i>
                  </td>
                  <td>
                    <i
                      class="fa-solid fa-arrow-rotate-left fa-xl"
                      onClick={() => {
                        restoreDocs(docss.id);
                      }}
                    ></i>
                  </td>
                  <td>
                    <i
                      className="fa-solid fa-trash-can fa-1x"
                      onClick={() => {
                        setShowModal(true);
                        setDocs(docss);
                      }}
                    ></i>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <NoDataPresent />
      )}
      <Pagination setPagination={setPagination} totalRecords={totalRecords} />
    </div>
  );
}
