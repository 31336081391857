import { React, useState, useEffect, useContext } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Alert from "react-bootstrap/Alert";
import axiosInstance from "../utility/axios";
import { ReactSortable } from "react-sortablejs";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Form from "react-bootstrap/Form";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./HomePage.css";
import Notification from "../components/Notification";
import { Player } from "video-react";
import { hexToFilter } from "css-filter-converter";
import { useLoading } from "../utility/LoadingContext";

// import "/css/react-video.css";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import Compressor from "compressorjs";
import DeleteConfirmation from "../components/DeleteModal";
import { useLanguage } from "../utility/LanguageContext";
import { userContext } from "../pages/Context";
import { signInWithCustomToken, signOut } from "firebase/auth";
import { auth } from "../firebase-config";

const ENV = process.env.NODE_ENV === "development" ? "_test" : "";

const countryImages = {
  en: "/img/asia_icon.png",
  bangla: "/img/bangladesh_icon.png",
  nep: "/img/nepal_icon.png",
  thai: "/img/thailand_icon.png",
};

const Content = ({ lang, setNotificationData }) => {
  const [images, setImages] = useState([]);
  const [previewImage, setPreviewImage] = useState([]);
  const [navbar, setNavbar] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [editContent, setEditContent] = useState(false);
  const [content, setContent] = useState({});
  const { setLoading } = useLoading();
  const [sectionImage, setSectionImage] = useState({
    image: "",
    imageName: "",
    imageURL: "",
  });
  const [videoFile, setVideoFile] = useState({
    video: "",
    videoName: "",
    videoURL: "",
  });
  const [uploadImageProgress, setUploadImageProgress] = useState(0);
  const [uploadVideoProgress, setUploadVideoProgress] = useState(0);
  const [fileError, setFileError] = useState("");
  const [showImageDelete, setShowImageDelete] = useState(false);
  const [showVideoDelete, setShowVideoDelete] = useState(false);
  const [theme, setTheme] = useState(undefined);
  const [allowEditTheme, setAllowEditTheme] = useState(false);

  const refreshBannerImages = () => {
    axiosInstance
      .get(`/siteData/getBannerImages?lang=${lang}`)
      .then((response) => {
        if (response.status === 200) {
          setImages([...response.data?.response]);
        } else {
          // TODO: alert
          setImages([]);
        }
      })
      .catch((err) => {
        setImages([]);
        console.log(err);
      });
  };

  const handleImageAdd = async (event) => {
    const confirmUpload = window.confirm(
      "Are you sure you want to upload this file? \nIt will upload to the server and will be visible to the public as banner on https://testinginstance.ovochub.org/"
    );
    const file = event.target.files[0];

    if (confirmUpload) {
      setLoading(true);
      let compressedFile;
      try {
        const compressedBlob = await new Promise((resolve, reject) => {
          new Compressor(file, {
            quality: 0.6,
            success(result) {
              resolve(result);
            },
            error(err) {
              reject(err);
            },
          });
        });

        compressedFile = new File([compressedBlob], file.name, {
          type: file.type,
        });
      } catch (error) {
        console.log(error.message);
      }
      const formData = new FormData();
      formData.append("file", compressedFile);
      formData.append("sortId", images.length + 1);
      await axiosInstance
        .post(`/siteData/addBannerImage/${lang}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            refreshBannerImages();
          }
          setNotificationData([
            {
              msg: response.data.message,
              status: response.status,
            },
          ]);
        })
        .catch((err) => {
          console.log(err);
        });
      setLoading(false);
    }
    event.target.value = null;
  };

  const getData = (lang) => {
    axiosInstance
      .get("/siteData/getNavbar")
      .then((response) => {
        if (response.status === 200) {
          setNavbar(response.data.response[lang]);
          setContent(response.data.response?.content[lang]);
          setSectionImage({
            image: "",
            imageName: response.data.response?.sectionImage[lang]?.name,
            imageURL: response.data.response?.sectionImage[lang]?.url,
          });
          setVideoFile({
            video: "",
            videoName: response.data.response?.videoFile[lang]?.name,
            videoURL: response.data.response?.videoFile[lang]?.url,
          });
          setTheme(response.data.response?.theme[lang]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  const onDragEnd = (result, parentIndex) => {
    if (!result.destination) {
      return;
    }

    const reorderedChildItems = reorder(
      navbar[parentIndex].child,
      result.source.index,
      result.destination.index
    );

    const updatedParentItem = {
      ...navbar[parentIndex],
      child: reorderedChildItems,
    };

    const updatedNavbarCopy = [...navbar];
    updatedNavbarCopy[parentIndex] = updatedParentItem;

    setNavbar(updatedNavbarCopy);
  };

  const handleChoose = (event) => {
    setPreviewImage(event.target.src);
  };

  const saveBannerImages = async () => {
    const formData = new FormData();
    formData.append("data", JSON.stringify(images));
    setLoading(true);
    await axiosInstance
      .patch(`/siteData/updateBannerImages/${lang}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          refreshBannerImages();
        }
        setNotificationData([
          {
            msg: response.data.message,
            status: response.status,
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  const handleDrag = (e) => {
    e.dataTransfer.setData("id", e.target.id);
  };

  const handleTrashDrop = async (evt) => {
    const index = evt.dataTransfer.getData("id");
    const id = images[index].id;
    setLoading(true);
    await axiosInstance
      .delete(`/siteData/deleteBannerImage/${id}/${lang}`)
      .then((response) => {
        if (response.status === 200) {
          refreshBannerImages();
        }
        setNotificationData([
          {
            msg: response.data.message,
            status: response.status,
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  const handleListTitleChange = (e, parentIndex, childIndex) => {
    const updatedNavbar = [...navbar];
    updatedNavbar[parentIndex].child[childIndex].title = e.target.value;
    setNavbar(updatedNavbar);
  };

  const handleTitleChange = (e, parentIndex) => {
    const updatedNavbar = [...navbar];
    updatedNavbar[parentIndex].title = e.target.value;
    setNavbar(updatedNavbar);
  };

  const navbarSubmit = async () => {
    setLoading(true);
    await axiosInstance
      .post(`/siteData/updateNavbar`, { lang, navbar })
      .then((response) => {
        if (response.status === 200) {
          getData(lang);
        }
        setNotificationData([
          {
            msg: response.data.message,
            status: response.status,
          },
        ]);
      })
      .catch((err) => {
        console.log(err, "ERROR");
      });
    setLoading(false);
  };
  const saveSectionImage = async (image) => {
    setLoading(true);
    await axiosInstance
      .patch(`/siteData/updateSectionImage`, {
        lang,
        image,
      })
      .then((response) => {
        if (response.status === 200) {
          getData(lang);
        }
        setNotificationData([
          {
            msg: response.data.message,
            status: response.status,
          },
        ]);
      })
      .catch((err) => {
        console.log(err, "ERROR");
      });
    setLoading(false);
  };

  const saveSectionVideo = async (video) => {
    setLoading(true);
    await axiosInstance
      .patch(`/siteData/updateSectionVideo`, {
        lang,
        video,
      })
      .then((response) => {
        if (response.status === 200) {
          getData(lang);
        }
        setNotificationData([
          {
            msg: response.data.message,
            status: response.status,
          },
        ]);
      })
      .catch((err) => {
        console.log(err, "ERROR");
      });
    setLoading(false);
  };

  const saveTheme = async () => {
    setLoading(true);
    await axiosInstance
      .patch(`/siteData/updateTheme`, {
        lang,
        data: theme,
      })
      .then((response) => {
        if (response.status === 200) {
          getData(lang);
        }
        setNotificationData([
          {
            msg: response.data.message,
            status: response.status,
          },
        ]);
      })
      .catch((err) => {
        console.log(err, "ERROR");
      });
    setLoading(false);
  };

  const saveContent = async () => {
    setLoading(true);
    await axiosInstance
      .post(`/siteData/updateContent`, { lang, content })
      .then((response) => {
        if (response.status === 200) {
          getData(lang);
        }
        setNotificationData([
          {
            msg: response.data.message,
            status: response.status,
          },
        ]);
      })
      .catch((err) => {
        console.log(err, "ERROR");
      });
    setLoading(false);
  };

  const handleChange = (event, editor) => {
    const newContent = { ...content };
    newContent.description = editor.getData();
    setContent(newContent);
  };
  const handleContentTitleChange = (e) => {
    const newContent = { ...content };
    newContent.title = e.target.value;
    setContent(newContent);
  };
  const removeSelectedImage = async (name) => {
    const fileInput = document.getElementById(name);
    const token = await axiosInstance.post("/site/generate-signed-url");

    if (token.status !== 200) {
      setNotificationData([
        {
          msg: "Upload failed",
          status: 500,
        },
      ]);
      return;
    }

    const user = await signInWithCustomToken(auth, token.data.url);
    fileInput.value = "";
    if (
      (sectionImage.image && typeof sectionImage.image !== "string") ||
      sectionImage.imageName
    ) {
      const storage = getStorage();
      const storageRef = ref(storage, `public/home/images/${lang}${ENV}_img`);

      try {
        await deleteObject(storageRef);
        // console.log("File deleted from Firebase Storage");
      } catch (error) {
        console.error("Error deleting file from Firebase Storage:", error);
      }
      signOut(auth);
    }
    setSectionImage({
      image: "",
      imageName: "",
      imageURL: "",
    });
    setFileError({ ...fileError, [name]: "" });
    setUploadImageProgress(0);
  };

  const uploadFileToFirebase = (file, uploadUrl, setUploadProgress) => {
    return new Promise(async (resolve, reject) => {
      try {
        const storage = getStorage();
        const storageRef = ref(storage, uploadUrl);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
            switch (snapshot.state) {
              case "paused":
                break;
              case "running":
                break;
            }
          },
          (error) => {
            console.log("Upload error", error);
            reject(error);
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              // console.log("File available at", downloadURL);
              resolve(downloadURL); // Resolve the promise with the downloadURL
            } catch (error) {
              // console.log("Error getting download URL", error);
              reject(error);
            }
          }
        );
      } catch (error) {
        reject(error);
      }
      signOut(auth);
    });
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type.match("image.*") && file.size <= 5000000) {
        setFileError({ ...fileError, [e.target.name]: "" });
        try {
          const fileName = file.name;
          const uploadUrl = `public/home/images/${lang}${ENV}_img`;
          const token = await axiosInstance.post("/site/generate-signed-url");

          if (token.status !== 200) {
            setNotificationData([
              {
                msg: "Upload failed",
                status: 500,
              },
            ]);
            return;
          }

          const user = await signInWithCustomToken(auth, token.data.url);
          const compressedBlob = await new Promise((resolve, reject) => {
            new Compressor(file, {
              quality: 0.6,
              success(result) {
                resolve(result);
              },
              error(err) {
                reject(err);
              },
            });
          });

          const compressedFile = new File([compressedBlob], file.name, {
            type: file.type,
          });
          const fileURL = await uploadFileToFirebase(
            compressedFile,
            uploadUrl,
            setUploadImageProgress
          );

          setSectionImage({
            image: "",
            imageName: fileName,
            imageURL: fileURL,
          });
          const image = {
            name: fileName,
            url: fileURL,
          };

          saveSectionImage(image);
        } catch (error) {
          console.error("Error uploading image:", error);
        }
      } else {
        setFileError({
          ...fileError,
          [e.target.name]: "File can have max size of 5MB",
        });
      }
    }
  };

  const handleVideoChange = async (e) => {
    const file = e.target.files[0];
    if (file && file.type.match("video.*") && file.size <= 15 * 1024 * 1024) {
      try {
        const fileName = file.name;
        const uploadUrl = `public/home/videos/${lang}${ENV}_vdo`;
        const token = await axiosInstance.post("/site/generate-signed-url");

        if (token.status !== 200) {
          setNotificationData([
            {
              msg: "Video upload failed",
              status: 500,
            },
          ]);
          return;
        }

        const user = await signInWithCustomToken(auth, token.data.url);

        const fileURL = await uploadFileToFirebase(
          file,
          uploadUrl,
          setUploadVideoProgress
        );

        setVideoFile({
          video: "",
          videoName: fileName,
          videoURL: fileURL,
        });
        const video = {
          name: fileName,
          url: fileURL,
        };
        setFileError({ ...fileError, [e.target.name]: "" });
        saveSectionVideo(video);
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    } else {
      setFileError({
        ...fileError,
        [e.target.name]: "Video can have max size of 15MB",
      });
    }
  };

  const removeSelectedFile = async (name) => {
    const fileInput = document.getElementById(name);
    fileInput.value = "";
    const token = await axiosInstance.post("/site/generate-signed-url");

    if (token.status !== 200) {
      setNotificationData([
        {
          msg: "Video upload failed",
          status: 500,
        },
      ]);
      return;
    }

    const user = await signInWithCustomToken(auth, token.data.url);
    if (
      (videoFile.video && typeof videoFile.video !== "string") ||
      videoFile.videoName
    ) {
      const storage = getStorage();
      const storageRef = ref(storage, `public/home/videos/${lang}${ENV}_vdo`);

      try {
        await deleteObject(storageRef);
        // console.log("File deleted from Firebase Storage");
      } catch (error) {
        console.error("Error deleting file from Firebase Storage:", error);
      }
      signOut(auth);
    }
    setVideoFile({
      video: "",
      videoName: "",
      videoURL: "",
    });
    setFileError({
      ...fileError,
      [name]: "",
    });
    setUploadVideoProgress(0);
  };

  useEffect(() => {
    getData(lang);
    refreshBannerImages();
  }, [lang]);

  useEffect(() => {
    refreshBannerImages();
    return () => {
      setImages([]);
    };
  }, []);

  return (
    <>
      <DeleteConfirmation
        showModal={showImageDelete}
        hideModal={() => {
          setShowImageDelete(false);
        }}
        confirmModal={() => {
          removeSelectedImage("bannerImage");
          setShowImageDelete(false);
        }}
        message={`Are you sure you want to delete this image?`}
      />
      <DeleteConfirmation
        showModal={showVideoDelete}
        hideModal={() => {
          setShowVideoDelete(false);
        }}
        confirmModal={() => {
          removeSelectedFile("videoFile");
          setShowVideoDelete(false);
        }}
        message={`Are you sure you want to delete this video?`}
      />
      <div id="edit-carousel" className="home-page-editable">
        <h2>Update Website Theme</h2>
        <div className="actions  mt-2">
          {allowEditTheme ? (
            <button
              id="cancel"
              className="lx-btn"
              onClick={() => {
                setAllowEditTheme(false);
                getData(lang);
              }}
            >
              <i className="fas fa-ban"></i>&nbsp;&nbsp;Cancel
            </button>
          ) : (
            <div className="edit-btn">
              <button
                id="edit"
                className="lx-btn"
                onClick={() => setAllowEditTheme(true)}
              >
                <i className="fa-solid fa-pen-to-square"></i>
                &nbsp;&nbsp;Edit
              </button>
            </div>
          )}
          <button
            id="save"
            className="lx-btn mx-3"
            onClick={() => {
              saveTheme();
              setAllowEditTheme(false);
            }}
          >
            <i className="fas fa-save"></i>&nbsp;&nbsp;Save
          </button>
        </div>
        <Alert variant="info" style={{ marginTop: "1em" }}>
          <p>Note:</p>
          <ul>
            <li>This will change Footer, Home Page banner image background.</li>
            <li>Refresh to cancel</li>
          </ul>
        </Alert>
        <div className="d-flex justify-content-evenly align-items-center">
          <input
            type="color"
            name=""
            value={theme?.general}
            onChange={(e) => {
              setTheme({
                ...theme,
                general: e.target.value,
                langCard: hexToFilter(e.target.value)?.color,
              });
            }}
            disabled={!allowEditTheme}
          />
          <div className="feature">
            <img
              loading="lazy"
              src={countryImages[lang]}
              alt=""
              style={{
                filter: theme?.langCard,
              }}
            />
          </div>
        </div>

        <h2>Update navbar</h2>
        <div className="actions  mt-2">
          {isEdit ? (
            <button
              id="cancel"
              className="lx-btn"
              onClick={() => setIsEdit(false)}
            >
              <i className="fas fa-ban"></i>&nbsp;&nbsp;Cancel
            </button>
          ) : (
            <div className="edit-btn">
              <button
                id="edit"
                className="lx-btn"
                onClick={() => setIsEdit(true)}
              >
                <i className="fa-solid fa-pen-to-square"></i>
                &nbsp;&nbsp;Edit
              </button>
            </div>
          )}
          <button
            id="save"
            className="lx-btn mx-3"
            onClick={() => {
              navbarSubmit();
              setIsEdit(false);
            }}
          >
            <i className="fas fa-save"></i>&nbsp;&nbsp;Save
          </button>
        </div>
        <div className="navbar-slider-container">
          {navbar &&
            navbar.length &&
            navbar.map((item, parentIndex) => (
              <div key={item.id} className="item-container">
                {isEdit ? (
                  <input
                    type="text"
                    value={item.title}
                    onChange={(e) => handleTitleChange(e, parentIndex)}
                    className="title-input"
                  />
                ) : (
                  <h4 className="item-title">{item.title}</h4>
                )}
                {!!item.child.length && (
                  <DragDropContext
                    onDragEnd={(result) => onDragEnd(result, parentIndex)}
                  >
                    <Droppable droppableId={item.id} direction="vertical">
                      {(provided) => (
                        <ul
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className="list-container"
                        >
                          {item.child.map((c, childIndex) => (
                            <Draggable
                              key={c.id}
                              draggableId={c.id}
                              index={childIndex}
                              isDragDisabled={!isEdit}
                            >
                              {(provided) => (
                                <li
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className="list-item"
                                >
                                  {isEdit ? (
                                    <input
                                      type="text"
                                      value={c.title}
                                      onChange={(e) =>
                                        handleListTitleChange(
                                          e,
                                          parentIndex,
                                          childIndex
                                        )
                                      }
                                      className="list-input"
                                    />
                                  ) : (
                                    <span>{c.title}</span>
                                  )}
                                </li>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>
                )}
              </div>
            ))}
        </div>
        <h2>Update banner Images</h2>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <input
            type="file"
            accept=".jpg,.jpeg,.png"
            onChange={handleImageAdd}
          />
          <div
            className="trash"
            onDragOver={(e) => e.preventDefault()}
            onDrop={handleTrashDrop}
          >
            <i className="bx bxs-trash-alt bx-md"></i>
          </div>
          <button onClick={() => saveBannerImages()} className="banner-btn">
            Save Order of Images
          </button>
        </div>

        <div className="banner-images-container">
          <ReactSortable
            list={images}
            setList={setImages}
            id="banner-images"
            animation={200}
            delayOnTouchStart={true}
            delay={2}
          >
            {images.map((item, index) => (
              <li
                key={index}
                draggable="true"
                onClick={handleChoose}
                id={index}
                onDragStart={handleDrag}
              >
                <img
                  loading="lazy"
                  src={process.env.REACT_APP_BUCKET_URL + item.fileURL}
                  alt={`Image ${index}`}
                />
              </li>
            ))}
          </ReactSortable>
          <div className="banner-images-container-preview">
            <img loading="lazy" src={previewImage} alt="" />
          </div>
        </div>
        <Alert variant="info" style={{ marginTop: "1em" }}>
          <p>Note:</p>
          <ul>
            <li>
              Once you upload, Image will be saved automatically and is updated
              on live site. Be sure what you are uploading
            </li>
            <li>Drag image to change order and then Save the Order</li>
            <li>Drag Image to trash icon to delete it</li>
          </ul>
        </Alert>

        <div className="what-we-do">
          <div className="container">
            <h2>Update About and Video</h2>
            <div className="row">
              <div className="actions edit-btn mt-2">
                <button
                  id="edit"
                  className="lx-btn"
                  onClick={() => setEditContent((prev) => !prev)}
                >
                  {!editContent ? (
                    <i className="fa-solid fa-pen-to-square"></i>
                  ) : (
                    <i className="fa-regular fa-eye"></i>
                  )}
                  &nbsp;&nbsp;{editContent ? "Preview" : "Edit"}
                </button>
                <button
                  id="save"
                  className="lx-btn mx-3"
                  onClick={() => {
                    saveContent();
                    setEditContent(false);
                  }}
                >
                  <i className="fas fa-save"></i>&nbsp;&nbsp;Save
                </button>
              </div>
              {!editContent ? (
                <>
                  <div className="col-sm-4 col-xxl-4 col-xl-4 col-lg-4 col-md-12">
                    <h2 className="warld-help mb-4">
                      {content && content?.title}
                    </h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: content && content?.description,
                      }}
                      className="ck-content"
                    ></div>
                    <Link to="" className="btn btn-pink mb-2">
                      About Us
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Enter title"
                      value={content && content?.title}
                      onChange={handleContentTitleChange}
                    />
                  </Form.Group>
                  <CKEditor
                    editor={Editor}
                    data={content && content?.description}
                    onChange={(event, editor) => {
                      handleChange(event, editor);
                    }}
                    onReady={(editor) => {
                      editor.ui.view.toolbar.items.get(
                        11
                      ).buttonView.isVisible = false;
                      // editor.ui.view.toolbar.items.get(16).buttonView.isVisible = false;
                      editor.ui.view.toolbar.items.get(31).isVisible = false;
                      editor.ui.view.toolbar.items.get(
                        32
                      ).buttonView.isVisible = false;
                      editor.ui.view.toolbar.items.get(
                        33
                      ).buttonView.isVisible = false;
                      editor.ui.view.toolbar.items.get(
                        34
                      ).buttonView.isVisible = false;
                      editor.ui.view.toolbar.items.get(
                        35
                      ).buttonView.isVisible = false;
                    }}
                  />
                </>
              )}
              {!editContent ? (
                <>
                  <div className="col-sm-4 col-xxl-4 col-xl-4 col-lg-4 col-md-4 img-vid-cont">
                    <div className="we-do-bx">
                      <img
                        style={{ width: "400px", height: "auto" }}
                        loading="lazy"
                        src={sectionImage && sectionImage?.imageURL}
                        alt="Bangladesh"
                        className="default-image mx-4 mt-4"
                      />
                    </div>
                  </div>
                  <Container
                    className="m-3"
                    style={{ maxWidth: "1000px", height: "auto" }}
                  >
                    {videoFile && videoFile?.videoURL && (
                      <Player>
                        <source src={videoFile?.videoURL} />
                      </Player>
                    )}
                  </Container>
                </>
              ) : (
                <>
                  <Container className="rounded editor-container p-4 mt-4">
                    <Row>
                      <Col className="left-container text-center" xs={3}>
                        <p>Upload Section Image*</p>
                      </Col>
                      <Col className="text-center me-5">
                        <Row>
                          <div className="image-uploader">
                            <label
                              htmlFor="bannerImage"
                              className="upload-label"
                            >
                              <div className="upload-icon">
                                <img
                                  loading="lazy"
                                  src="https://cdn-icons-png.flaticon.com/512/569/569800.png"
                                  alt="Upload Icon"
                                />
                              </div>
                              <span className="upload-text">
                                {sectionImage.image || sectionImage.imageName
                                  ? typeof sectionImage.image === "string"
                                    ? `${sectionImage.imageName}`
                                    : sectionImage.image.name
                                  : "Choose from files"}
                              </span>{" "}
                              {(sectionImage.image ||
                                sectionImage.imageName) && (
                                <button
                                  style={{
                                    padding: "0px .5em",
                                    marginLeft: "1em",
                                  }}
                                  className="remove-button"
                                  type="button"
                                  onClick={() => setShowImageDelete(true)}
                                >
                                  X
                                </button>
                              )}
                            </label>

                            <input
                              type="file"
                              id="bannerImage"
                              accept=".jpg,.jpeg,.png"
                              multiple={false}
                              name="bannerImage"
                              style={{ display: "none" }}
                              onChange={handleImageChange}
                            />
                          </div>
                        </Row>
                        <Row className="progress-container">
                          <div className="progress-bar">
                            <div
                              className="progress-fill"
                              style={{ width: `${uploadImageProgress}%` }}
                            ></div>
                          </div>
                          <div className="progress-text">
                            {uploadImageProgress !== 0 &&
                              `${uploadImageProgress.toFixed(2)}%`}
                          </div>
                          <div>
                            {fileError && fileError?.bannerImage && (
                              <Alert variant="danger">
                                {fileError?.bannerImage}
                              </Alert>
                            )}
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                  <Container className="rounded editor-container p-4 m-1">
                    <Row>
                      <Col className="left-container text-center" xs={3}>
                        <p>Upload Video File*</p>
                      </Col>
                      <Col className="text-center me-5">
                        <Row>
                          <div className="image-uploader">
                            <label htmlFor="videoFile" className="upload-label">
                              <div className="upload-icon">
                                <img
                                  src="https://cdn-icons-png.flaticon.com/512/4210/4210796.png"
                                  alt="Upload Icon"
                                />
                              </div>
                              <span className="upload-text">
                                {videoFile.video || videoFile.videoName
                                  ? typeof videoFile.video === "string"
                                    ? `${videoFile.videoName}`
                                    : videoFile.video.name
                                  : "Choose from files"}
                              </span>{" "}
                              {(videoFile.video || videoFile.videoName) && (
                                <button
                                  style={{
                                    padding: "0px .5em",
                                    marginLeft: "1em",
                                  }}
                                  className="remove-button"
                                  type="button"
                                  onClick={() => setShowVideoDelete(true)}
                                >
                                  X
                                </button>
                              )}
                            </label>

                            <input
                              type="file"
                              id="videoFile"
                              accept="video/*"
                              multiple={false}
                              name="videoFile"
                              style={{ display: "none" }}
                              onChange={handleVideoChange}
                            />
                          </div>
                        </Row>
                        <Row className="progress-container">
                          <div className="progress-bar">
                            <div
                              className="progress-fill"
                              style={{ width: `${uploadVideoProgress}%` }}
                            ></div>
                          </div>
                          <div className="progress-text">
                            {uploadVideoProgress !== 0 &&
                              `${uploadVideoProgress.toFixed(2)}%`}
                          </div>
                          <div>
                            {fileError && fileError?.videoFile && (
                              <Alert variant="danger">
                                {fileError?.videoFile}
                              </Alert>
                            )}
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                  <Alert
                    variant="info"
                    style={{ marginTop: "1em", marginBottom: "2em" }}
                  >
                    <p>Note:</p>
                    <ul>
                      <li>
                        Once you upload the image and video, it will start
                        uploading, you don't need to save it.
                      </li>
                    </ul>
                  </Alert>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const HomePage = () => {
  const { countryMap } = useLanguage();
  const { user } = useContext(userContext);
  const [notificationData, setNotificationData] = useState([]);
  const [lang, setLang] = useState(undefined);
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    axiosInstance.get("/user/country/list").then((res) => {
      setCountries(res.data);
    });
  }, []);

  useEffect(() => {
    setLang(user.role < 3 ? "en" : countryMap[user?.countryId]);
  }, [user, countryMap]);

  return (
    <>
      <Notification
        notificationData={notificationData}
        setNotificationData={setNotificationData}
      />
      <section className="user-section" id="user-section">
        <div className="user-content">
          <Tabs
            defaultActiveKey={user.role < 3 ? "en" : countryMap[user.countryId]}
            id="about-tabs"
            className="mb-3"
            onSelect={(k) => setLang(k)}
          >
            {countries?.map((country, index) => {
              if (user.role < 3 || user.countryId === country.id) {
                return (
                  <Tab eventKey={countryMap[country.id]} title={country.name}>
                    <Content
                      lang={lang}
                      setNotificationData={setNotificationData}
                    />
                  </Tab>
                );
              }
            })}
          </Tabs>
        </div>
      </section>
    </>
  );
};

export default HomePage;
