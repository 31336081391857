import { useState, useEffect, useContext, useRef } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Card from "react-bootstrap/Card";
import Pagination from "../components/Pagination";
import axiosInstance from "../utility/axios";
import { userContext } from "./Context";
import NoDataPresent from "../components/NoDataPresent";
import Notification from "../components/Notification";

const Queries = () => {
  const { user } = useContext(userContext);
  const [selectedCountry, setSelectedCountry] = useState(undefined);
  const [countries, setCountries] = useState([]);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [queries, setQueries] = useState([]);
  const [showPreview, setShowPreview] = useState({
    show: false,
    subject: "",
    message: "",
  });
  const [comment, setComment] = useState("");
  const targetBox = useRef(null);
  const [showCommentBoxArray, setShowCommentBoxArray] = useState(
    new Array(queries.length).fill(false)
  );
  const [notificationData, setNotificationData] = useState([]);

  useEffect(() => {
    targetBox.current = null;
    setShowCommentBoxArray(new Array(queries.length).fill(false));
  }, [queries]);

  const handleToggleCommentBox = (index) => {
    const updatedShowCommentBoxArray = [...showCommentBoxArray];
    updatedShowCommentBoxArray[index] = !updatedShowCommentBoxArray[index];
    setShowCommentBoxArray(updatedShowCommentBoxArray);
  };

  const fetchData = () => {
    let query = `?offset=${pagination.offset}&limit=${pagination.limit}`;
    if (user.role < 3 && selectedCountry) {
      query += `&country_id=${selectedCountry.id}`;
    } else if (user.role >= 3) {
      query += `&country_id=${user.countryId}`;
    }
    axiosInstance.get(`/queries${query}`).then((res) => {
      if (res.status === 200) {
        setQueries(res.data.queries);
        setTotalRecords(res.data.count);
      } else {
        setQueries([]);
        setTotalRecords(0);
      }
    });
  };

  const assignQuery = (id, assign) => {
    axiosInstance
      .patch(`/queries/assign/${id}`, {
        assign,
      })
      .then((res) => {
        if (res.status === 200) {
          fetchData();
        }
        setNotificationData([
          {
            msg: res.data.message,
            status: res.status,
          },
        ]);
      });
  };

  const resolveQuery = (id) => {
    axiosInstance
      .patch(`/queries/resolve/${id}`, {
        comment,
      })
      .then((res) => {
        if (res.status === 200) {
          fetchData();
        }
        setNotificationData([
          {
            msg: res.data.message,
            status: res.status,
          },
        ]);
      });
  };

  useEffect(() => {
    axiosInstance.get("/user/country/list").then((res) => {
      setCountries(res.data);
    });
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [pagination, selectedCountry]);

  return (
    <>
      <Notification
        notificationData={notificationData}
        setNotificationData={setNotificationData}
      />
      <Modal
        show={showPreview.show}
        onHide={() =>
          setShowPreview({
            show: false,
            subject: "",
            message: "",
          })
        }
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{showPreview.subject}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal_body">{showPreview.message}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() =>
              setShowPreview({
                show: false,
                subject: "",
                message: "",
              })
            }
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <section className="user-section" id="user-section">
        <div className="user-content">
          {user.role < 3 && (
            <InputGroup className="mx-3" style={{ width: "fit-content" }}>
              <DropdownButton
                variant="outline-secondary"
                title={
                  selectedCountry ? selectedCountry["name"] : "Select Country"
                }
                id="input-group-dropdown-1"
                onSelect={(e) => {
                  setSelectedCountry(JSON.parse(e));
                }}
              >
                <Dropdown.Item eventKey={undefined}>Select None</Dropdown.Item>
                {countries.map((country, index) => (
                  <Dropdown.Item eventKey={JSON.stringify(country)} key={index}>
                    {country.name}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </InputGroup>
          )}
          {queries.length === 0 ? (
            <NoDataPresent />
          ) : (
            <>
              <Table striped bordered responsive className="mt-2">
                <thead>
                  <tr>
                    <th className="table-head">#</th>
                    <th className="table-head">Name</th>
                    <th className="table-head">Email</th>
                    <th className="table-head">Country</th>
                    <th className="table-head">phone</th>
                    <th className="table-head">Message</th>
                    <th className="table-head">Assigned To</th>
                    <th className="table-head">Mark as Resolved</th>
                    <th className="table-head">Comment</th>
                  </tr>
                </thead>
                <tbody>
                  {queries.map((query, index) => (
                    <tr key={index}>
                      <td>{pagination.offset + index + 1}</td>
                      <td>{query.name}</td>
                      <td>{query.email}</td>
                      <td>{query.countryName}</td>
                      <td>{query.phone}</td>
                      <td>
                        <i
                          className="fa-solid fa-box-open fa-1x"
                          onClick={() =>
                            setShowPreview({
                              show: true,
                              subject: query.subject,
                              message: query.message,
                            })
                          }
                        ></i>
                      </td>
                      <td>
                        {!query.isResolved ? (
                          !query.userId ? (
                            <button
                              type="button"
                              onClick={() => assignQuery(query.id, true)}
                            >
                              Assign Myself
                            </button>
                          ) : query.userId === user.id ? (
                            <button
                              type="button"
                              onClick={() => assignQuery(query.id, false)}
                            >
                              UnAssign
                            </button>
                          ) : (
                            query.userName
                          )
                        ) : (
                          query.userName
                        )}
                      </td>
                      <td>
                        {query.userId === user.id && !query.isResolved && (
                          <OverlayTrigger
                            placement="auto"
                            trigger="click"
                            onToggle={() => {
                              setComment("");
                            }}
                            rootClose
                            overlay={
                              <Tooltip id="overlay-example">
                                <Card
                                  border="primary"
                                  style={{ margin: "auto" }}
                                >
                                  <Card.Header>
                                    Do you want to resolve this query?
                                  </Card.Header>
                                  <Card.Body>
                                    <Card.Text>
                                      <textarea
                                        placeholder="Comment"
                                        style={{ width: "100%" }}
                                        value={comment}
                                        onChange={(e) =>
                                          setComment(e.target.value)
                                        }
                                      ></textarea>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                          gap: "15px",
                                        }}
                                      >
                                        <button
                                          className="btn btn-success"
                                          type=""
                                          onClick={() => resolveQuery(query.id)}
                                        >
                                          Resolve
                                        </button>
                                      </div>{" "}
                                    </Card.Text>
                                  </Card.Body>
                                </Card>
                              </Tooltip>
                            }
                          >
                            <i
                              className="bx bxs-comment-error bx-sm"
                              ref={targetBox}
                              onClick={() => handleToggleCommentBox(index)}
                            ></i>
                          </OverlayTrigger>
                        )}
                      </td>
                      <td>
                        {query.isResolved ? (
                          <b>Resolved: {query.resolvedComment}</b>
                        ) : (
                          <b>Pending</b>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Pagination
                setPagination={setPagination}
                totalRecords={totalRecords}
              />
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Queries;
