import { React, useEffect, useState } from "react";
import { useContext } from "react";
import { userContext } from "./Context";
import Alert from "react-bootstrap/Alert";
import Notification from "../components/Notification";
import "./Settings.css";
import axiosInstance from "../utility/axios";
import Compressor from "compressorjs";

const USER_ROLES = {
  1: "Dev Admin",
  2: "Super Admin",
  3: "Admin",
  4: "Moderator",
  5: "Content Creator",
  6: "User",
};

const Settings = () => {
  const ctx = useContext(userContext);
  const [username, setUsername] = useState(ctx.user.username);
  const [editUserName, setEditUserName] = useState(false);
  const [password, setPassword] = useState({
    old: "",
    new: "",
    confirm: "",
  });
  const [editPassword, setEditPassword] = useState(false);
  const [profileImage, setProfileImage] = useState("");
  const [fileError, setFileError] = useState("");
  const [notificationData, setNotificationData] = useState([]);
  const [allowEditBio, setAllowEditBio] = useState("");
  const [bio, setBio] = useState(ctx.user.bio);
  const [showBioMobile, setShowBioMobile] = useState(false);

  const handleUserName = () => {
    axiosInstance
      .patch(`/site/update/username/${ctx.user.id}`, {
        username: username,
      })
      .then((response) => {
        if (response.status === 200) {
          ctx.setUser({ ...ctx.user, username: username });
        }
        setNotificationData([
          {
            msg: response.data.message,
            status: response.status,
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
    setEditUserName(false);
  };

  const handlePasswordChange = (e) => {
    setPassword({ ...password, [e.target.name]: e.target.value });
  };

  const resetPassword = () => {
    setPassword({ old: "", new: "", confirm: "" });
    setEditPassword(false);
  };
  const handlePasswordSubmit = () => {
    if (password.new !== password.confirm) {
      setNotificationData([
        {
          msg: "New and current password didn't match",
          status: 401,
        },
      ]);
      return;
    }
    axiosInstance
      .patch(`/site/update/password/${ctx.user.id}`, {
        oldPassword: password.old,
        newPassword: password.new,
      })
      .then((response) => {
        if (response.status === 200) {
          setNotificationData([
            {
              msg: response.data.message,
              status: response.status,
            },
          ]);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    resetPassword();
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file && file.type.match("image.*") && file.size <= 5000000) {
      if (window.confirm("Are you sure want to change your profile picture?")) {
        setProfileImage(file);
        setFileError("");
        let compressedFile;
        try {
          const compressedBlob = await new Promise((resolve, reject) => {
            new Compressor(file, {
              quality: 0.6,
              success(result) {
                resolve(result);
              },
              error(err) {
                reject(err);
              },
            });
          });

          compressedFile = new File([compressedBlob], file.name, {
            type: file.type,
          });
          setProfileImage(compressedFile);
        } catch (error) {
          console.log(error.message);
        }
        const formData = new FormData();
        formData.append("userImage", compressedFile);
        axiosInstance
          .patch(`/site/update/userImage/${ctx.user.id}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              setNotificationData([
                {
                  msg: response.data.message,
                  status: response.status,
                },
              ]);
              ctx.setUser({ ...ctx.user, userImageURL: response.data.url });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      setFileError("File can have max size of 5MB");
    }
  };

  const handleBioChange = () => {
    axiosInstance
      .patch(`/site/update/bio/${ctx.user.id}`, {
        bio: bio,
      })
      .then((response) => {
        if (response.status === 200) {
          ctx.setUser({ ...ctx.user, bio: bio });
        }
        setNotificationData([
          {
            msg: response.data.message,
            status: response.status,
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
    setAllowEditBio(false);
  };

  const resetBio = () => {
    setAllowEditBio(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1079) {
        setShowBioMobile(false);
      } else {
        setShowBioMobile(true);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setUsername(ctx.user.username);
  }, [ctx]);

  return (
    <section className="user-section" id="user-section">
      <Notification
        notificationData={notificationData}
        setNotificationData={setNotificationData}
      />
      <div className="user-content settings-container">
        {/* <div className="lx-row row-heading">
              <h1 className="title">Edit your profile</h1>
            </div> */}
        <div
          className="lx-row align-stretch"
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
          }}
        >
          <div className="lx-column column-user-pic">
            <div className="profile-pic bs-md">
              <h1 className="pic-label">Profile picture</h1>
              <div className="pic bs-md">
                <img
                  loading="lazy"
                  src={
                    ctx.user.userImageURL
                      ? process.env.REACT_APP_BUCKET_URL + ctx.user.userImageURL
                      : "https://cdn-icons-png.flaticon.com/512/1326/1326415.png"
                  }
                  alt=""
                  width="4024"
                  height="6048"
                />
              </div>
              <div
                style={{
                  top: "1em",
                  transform: "translate(88px, -40px)",
                }}
              >
                <i
                  className="bx bxs-camera bx-md"
                  style={{ cursor: "pointer" }}
                >
                  <input
                    type="file"
                    name=""
                    value=""
                    style={{
                      height: "2.25rem",
                      width: "2.25rem",
                      opacity: 0,
                      position: "absolute",
                      transform: "translateX(-41px)",
                    }}
                    onChange={handleImageChange}
                    accept="image/*"
                  />
                </i>
              </div>
              {!!fileError && <Alert variant={"danger"}>{fileError}</Alert>}
            </div>
          </div>
          <div className="lx-column">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="fieldset">
                <label htmlFor="user-name">Name</label>
                <div className="input-wrapper-btn">
                  <div className="input-wrapper">
                    <span className="icon">
                      <i className="fas fa-user"></i>
                    </span>
                    <input
                      disabled={!editUserName}
                      type="text"
                      id="user-name"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <div className="actions edit-btn mx-2">
                    <button
                      id="edit"
                      className="lx-btn"
                      onClick={() => setEditUserName(true)}
                    >
                      <i className="fa-solid fa-pen-to-square"></i>
                      &nbsp;&nbsp;Edit
                    </button>
                  </div>
                </div>
                {editUserName && (
                  <div className="actions mt-2">
                    <button
                      id="save"
                      className="lx-btn"
                      onClick={handleUserName}
                    >
                      <i className="fas fa-save"></i>&nbsp;&nbsp;Save
                    </button>
                    <button
                      id="cancel"
                      className="lx-btn mx-3"
                      onClick={() => setEditUserName(false)}
                    >
                      <i className="fas fa-ban"></i>&nbsp;&nbsp;Cancel
                    </button>
                  </div>
                )}
              </div>
              <div className="fieldset">
                <label htmlFor="user-id">Role</label>
                <div className="input-wrapper-btn">
                  <div className="input-wrapper">
                    <span className="icon">
                      <i className="fa-solid fa-circle-user"></i>
                    </span>
                    <input
                      disabled
                      type="text"
                      id="user-id"
                      defaultValue={`${USER_ROLES[ctx.user.role]}`}
                      required
                    />
                  </div>
                </div>
                <div id="user-id-helper" className="helper"></div>
              </div>
              <div className="fieldset">
                <label htmlFor="email">E-mail</label>
                <div className="input-wrapper-btn">
                  <div className="input-wrapper">
                    <div className="input-wrapper">
                      <span className="icon">
                        <i className="fas fa-envelope"></i>
                      </span>
                      <input
                        disabled
                        type="email"
                        id="email"
                        defaultValue={`${ctx.user.email}`}
                      />
                    </div>
                  </div>
                  <div id="user-id-helper" className="helper"></div>
                </div>
              </div>
              <div className="fieldset">
                <label htmlFor="pass">Password</label>
                <div className="input-wrapper-btn">
                  <div className="input-wrapper">
                    <span className="icon">
                      <i className="fas fa-key"></i>
                    </span>
                    {editPassword ? (
                      <input
                        type="password"
                        id="passw"
                        value={password.old}
                        name="old"
                        onChange={handlePasswordChange}
                        placeholder="Enter old password"
                      />
                    ) : (
                      <input
                        disabled
                        type="password"
                        id="pass"
                        value="123456"
                      />
                    )}
                  </div>
                  <div className="actions edit-btn mx-2">
                    <button
                      id="edit"
                      className="lx-btn"
                      onClick={() => setEditPassword(true)}
                    >
                      <i className="fa-solid fa-pen-to-square"></i>
                      &nbsp;&nbsp;Edit
                    </button>
                  </div>
                </div>
                {editPassword && (
                  <>
                    <div className="input-wrapper mt-2">
                      <span className="icon">
                        <i className="fa-solid fa-check"></i>
                      </span>
                      <input
                        type="password"
                        id="passw"
                        value={password.new}
                        name="new"
                        onChange={handlePasswordChange}
                        placeholder="Enter new password"
                      />
                    </div>
                    <div className="input-wrapper mt-2 mb-2">
                      <span className="icon">
                        <i className="fa-solid fa-check-double"></i>
                      </span>
                      <input
                        type="password"
                        id="passw"
                        value={password.confirm}
                        name="confirm"
                        onChange={handlePasswordChange}
                        placeholder="Confirm password"
                      />
                    </div>
                    <div className="actions mt-2">
                      <button
                        id="save"
                        className="lx-btn"
                        onClick={handlePasswordSubmit}
                      >
                        <i className="fas fa-save"></i>&nbsp;&nbsp;Save
                      </button>
                      <button
                        id="cancel"
                        className="lx-btn mx-3"
                        onClick={resetPassword}
                      >
                        <i className="fas fa-ban"></i>&nbsp;&nbsp;Cancel
                      </button>
                    </div>
                  </>
                )}
                <div id="pass-helper" className="helper"></div>
              </div>
              {showBioMobile && (
                <div className="fieldset">
                  <label htmlFor="user-name">Bio</label>
                  <div className="input-wrapper-btn">
                    <div className="input-wrapper">
                      <span className="icon">
                        <i className="fa-solid fa-feather-pointed"></i>
                      </span>
                      <textarea
                        disabled={!allowEditBio}
                        type="text"
                        id="user-bio"
                        value={bio}
                        style={{
                          height: "14em",
                        }}
                        onChange={(e) => setBio(e.target.value)}
                      ></textarea>
                    </div>
                    <div className="actions edit-btn mx-2">
                      <button
                        id="edit"
                        className="lx-btn"
                        onClick={() => setAllowEditBio(true)}
                      >
                        <i className="fa-solid fa-pen-to-square"></i>
                        &nbsp;&nbsp;Edit
                      </button>
                    </div>
                  </div>
                  {allowEditBio && (
                    <div className="actions mt-2">
                      <button
                        id="save"
                        className="lx-btn"
                        onClick={handleBioChange}
                      >
                        <i className="fas fa-save"></i>&nbsp;&nbsp;Save
                      </button>
                      <button
                        id="cancel"
                        className="lx-btn mx-3"
                        onClick={resetBio}
                      >
                        <i className="fas fa-ban"></i>&nbsp;&nbsp;Cancel
                      </button>
                    </div>
                  )}
                </div>
              )}
            </form>
          </div>
        </div>
        <div
          style={{ marginBottom: "5em", padding: "2rem", paddingTop: "0px" }}
        >
          {!showBioMobile && (
            <form
              className=""
              onSubmit={(e) => e.preventDefault()}
              style={{ margin: "0" }}
            >
              <div className="fieldset">
                <label htmlFor="user-name">Bio</label>
                <div className="input-wrapper-btn">
                  <div className="input-wrapper">
                    <span className="icon">
                      <i className="fa-solid fa-feather-pointed"></i>
                    </span>
                    <textarea
                      style={{ width: "60vw", height: "14em" }}
                      disabled={!allowEditBio}
                      type="text"
                      id="user-bio"
                      value={bio}
                      onChange={(e) => setBio(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="actions edit-btn mx-2">
                    <button
                      id="edit"
                      className="lx-btn"
                      onClick={() => setAllowEditBio(true)}
                    >
                      <i className="fa-solid fa-pen-to-square"></i>
                      &nbsp;&nbsp;Edit
                    </button>
                  </div>
                </div>
                {allowEditBio && (
                  <div className="actions mt-2">
                    <button
                      id="save"
                      className="lx-btn"
                      onClick={handleBioChange}
                    >
                      <i className="fas fa-save"></i>&nbsp;&nbsp;Save
                    </button>
                    <button
                      id="cancel"
                      className="lx-btn mx-3"
                      onClick={resetBio}
                    >
                      <i className="fas fa-ban"></i>&nbsp;&nbsp;Cancel
                    </button>
                  </div>
                )}
              </div>
            </form>
          )}
        </div>
      </div>
    </section>
  );
};

export default Settings;
