import { React } from "react";
import Advocacy from "./Advocacy";
import Campaigns from "./Campaigns";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const LearningNResource = () => {
  return (
    <section className="user-section" id="user-section">
      <div className="user-content">
        <Tabs defaultActiveKey="campaign" id="about-tabs" className="mb-3">
          <Tab eventKey="campaign" title="Petition">
            <Campaigns />
          </Tab>
          <Tab eventKey="advocacy" title="Campaign">
            <Advocacy />
          </Tab>
        </Tabs>
      </div>
    </section>
  );
};

export default LearningNResource;
