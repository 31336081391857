import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import Notification from "../components/Notification";
import { userContext } from "./Context";
import axiosInstance from "../utility/axios";
import Pagination from "../components/Pagination";
import NewsPreview from "../components/NewsPreview";
import DeleteConfirmation from "../components/DeleteModal";
import NoDataPresent from "../components/NoDataPresent";
import TrashNote from "../components/TrashNote";

export default function TrashNews() {
  const [notificationData, setNotificationData] = useState([]);
  const [allNews, setAllNews] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [news, setNews] = useState({});
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
  });
  const { user } = useContext(userContext);

  const getNews = () => {
    let query = `search=&offset=${pagination.offset}&limit=${pagination.limit}`;
    if (user.role < 3 /*&& selectedCountry*/) {
      //   query += `&country_id=${selectedCountry.id}`;
    } else if (user.role >= 3) {
      query += `&country_id=${user.countryId}`;
    }
    axiosInstance
      .get(`/trash/get/news?${query}`)
      .then((res) => {
        setAllNews(res.data.news);
        setTotalRecords(res.data.count || 0);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const restoreNews = (id) => {
    axiosInstance.get(`/trash/restore-news/${id}?indicator=0`).then((res) => {
      if (res.status === 200) {
        setNotificationData([
          {
            msg: "Resource is being restored as draft.You can publish it live from the respective section",
            status: res.status,
          },
        ]);
      } else {
        setNotificationData([
          {
            msg: res.data.message,
            status: res.status,
          },
        ]);
      }

      getNews();
    });
  };

  const deleteNews = (id) => {
    axiosInstance
      .delete(`/trash/delete-news/${id}`)
      .then(async (response) => {
        if (response.status === 200) {
          getNews();
        } else {
          // TODO: alert
        }
        setNotificationData([
          {
            msg: response.data.message,
            status: response.status,
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getNews();
  }, [pagination]);
  return (
    <div className="news_wrapper">
      <Notification
        notificationData={notificationData}
        setNotificationData={setNotificationData}
      />
      <DeleteConfirmation
        showModal={showModal}
        hideModal={() => {
          setShowModal(false);
        }}
        confirmModal={() => {
          deleteNews(news.id);
          setShowModal(false);
        }}
        message={`Are you sure you want to delete ${news?.title}?`}
      />
      <Modal show={show} onHide={() => setShow(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>News Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal_body">
          <NewsPreview form={news} tags={[]} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <h2>Deleted News</h2>
      {totalRecords > 0 ? (
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th className="table-head">#</th>
              <th className="table-head">Title</th>
              <th className="table-head">Deleted By</th>
              <th className="table-head">View</th>
              <th className="table-head">Restore</th>
              <th className="table-head">Delete</th>
            </tr>
          </thead>
          <tbody>
            {allNews.map((news, index) => {
              return (
                <tr>
                  <td>{pagination.offset + index + 1}</td>
                  <td>{news.title}</td>
                  <td>{news.modifiedBy}</td>
                  <td>
                    <i
                      className="fa-solid fa-box-open fa-1x"
                      onClick={() => {
                        setNews(news);
                        setShow(true);
                      }}
                    ></i>
                  </td>
                  <td>
                    {" "}
                    <i
                      class="fa-solid fa-arrow-rotate-left fa-xl"
                      onClick={() => {
                        restoreNews(news.id);
                      }}
                    ></i>
                  </td>
                  <td>
                    {" "}
                    <i
                      className="fa-solid fa-trash-can fa-1x"
                      onClick={() => {
                        setShowModal(true);
                        setNews(news);
                      }}
                    ></i>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <NoDataPresent />
      )}
      <Pagination setPagination={setPagination} totalRecords={totalRecords} />
    </div>
  );
}
