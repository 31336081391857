import { useContext, useEffect, useState } from "react";
import axiosInstance from "../utility/axios";
import "./SideNav.css";
import { userContext } from "../pages/Context";
import { Link, useLocation } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  LANGUAGES,
  LANGMAP,
  FRONTEND_BACKEND_LANGUAGE_MAP,
} from "../utility/constants";
import { useLanguage } from "../utility/LanguageContext";

const OVOC_WEBSITE =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3001"
    : "https://ovochub.org";

// Analytics, Workbench, Site, Moderation, Admin

const sidebarItemsV2 = [
  {
    head: "Dashboard",
    link: "/home/dashboard",
    icon: "bx bx-analyse",
    items: [],
    id: "dashboard",
    validator: () => true,
  },
  {
    head: "Analytics",
    link: "/home/analytics",
    icon: "bx bx-bar-chart-alt-2",
    items: [],
    id: "analytics",
    validator: () => true,
  },
  {
    head: "Workbench",
    link: "/home/workbench",
    icon: "bx bx-grid-alt",
    items: [],
    id: "workbench",
    validator: () => true,
  },
  {
    head: "Site",
    items: [
      {
        link: "/home/main",
        name: "Home",
        icon: "bx bxs-home-alt-2",
        id: "home",
        validator: () => true,
      },
      {
        link: "/home/about",
        name: "About Us",
        icon: "bx bx-book",
        id: "about",
        validator: () => true,
      },
      {
        link: "/home/media",
        name: "Media",
        icon: "bx bx-image",
        id: "media",
        validator: () => true,
      },
      {
        link: "/home/ovoc-in-action",
        name: "OVOC in Action",
        icon: "bx bx-sitemap",
        id: "OVOCInAction",
        validator: () => true,
      },
      {
        link: "/home/advocacy",
        name: "Advocacy",
        icon: "bx bxs-megaphone",
        id: "campaigns",
        validator: () => true,
      },
      {
        link: "/home/learning-n-resources",
        name: "Learning & Resources",
        icon: "bx bxs-book-open",
        id: "learningNresource",
        validator: () => true,
      },
      {
        link: "/home/contact",
        name: "Contact Us",
        icon: "bx bxs-contact",
        id: "contact",
        validator: () => true,
      },
      {
        link: "/home/topImage",
        name: "Top Image",
        icon: "bx bxs-chalkboard",
        id: "topImage",
        validator: () => true,
      },
      {
        link: "/home/privacy",
        name: "Privacy",
        icon: "fa-solid fa-shield-halved",
        id: "privacy",
        validator: () => true,
      },
      {
        link: "/home/terms-n-conditions",
        name: "Terms & Contidion",
        icon: "fa-solid fa-file-invoice",
        id: "terms",
        validator: () => true,
      },
      {
        link: "/home/faq",
        name: "FAQ's",
        icon: "fa-regular fa-circle-question",
        id: "faq",
        validator: () => true,
      },
    ],
  },
  {
    head: "Moderation",
    items: [
      {
        link: "/home/chatBoard",
        name: "Chat Board",
        icon: "bx bxs-message-dots",
        id: "chatBoard",
        validator: () => true,
      },
    ],
  },
  {
    head: "Admin",
    items: [
      {
        link: "/home/user",
        name: "User Management",
        icon: "bx bx-user",
        id: "users",
        validator: () => true,
      },
      {
        link: "/home/queries",
        name: "Queries",
        icon: "bx bx-question-mark",
        id: "queries",
        // validator: (user) => !Boolean(user?.subCommunityId),
        validator: () => true,
      },
      {
        link: "/home/newsletter",
        name: "Newsletter",
        icon: "bx bxs-envelope",
        id: "newsletter",
        // validator: (user) => !Boolean(user?.subCommunityId),
        validator: () => true,
      },
      {
        link: "/home/issues",
        name: "Support",
        icon: "bx bx-conversation",
        id: "issues",
        validator: () => true,
      },
      {
        link: "/home/trash",
        name: "Trash",
        icon: "fa-solid fa-trash-can",
        id: "trash",
        validator: () => true,
      },
      {
        link: "/home/settings",
        name: "Settings",
        icon: "bx bx-cog",
        id: "settings",
        validator: () => true,
      },
    ],
  },
];

const sidebarItems = {
  analytics: {
    link: "/home/analytics",
    name: "Analytics",
    icon: "bx bx-analyse",
    child: [],
  },
  dashboard: {
    link: "/home/dashboard",
    name: "Dashboard",
    icon: "bx bx-grid-alt",
    child: [],
  },
  users: {
    link: "/home/user",
    name: "User Management",
    icon: "bx bx-user",
    child: [],
  },
  OVOCInAction: {
    link: "",
    name: "OVOC in Action",
    icon: "",
    child: [
      {
        link: "/home/events-and-webinars",
        name: "Events and Webinars",
        icon: "bx bx-calendar-event",
        child: [],
      },
      {
        link: "/home/news",
        name: "News",
        icon: "bx bx-news",
        child: [],
      },
    ],
  },
  site: {
    link: "/home/site",
    name: "Media",
    icon: "bx bx-sitemap",
    child: [],
  },
  knowledge: {
    link: "/home/knowledge",
    name: "Knowledge",
    icon: "bx bx-book",
    child: [],
  },
  issues: {
    link: "/home/issues",
    name: "Raise Issue",
    icon: "bx bx-conversation",
    child: [],
  },
  campaigns: {
    link: "/home/campaigns",
    name: "Campaigns",
    icon: "bx bxs-megaphone",
    child: [],
  },
  courses: {
    link: "/home/courses",
    name: "Courses",
    icon: "bx bxs-book-content",
    child: [],
  },
  bacs: {
    link: "/home/bacs",
    name: "Resources",
    icon: "bx bxl-blogger",
    child: [],
  },
  chatBoard: {
    link: "/home/chatBoard",
    name: "Chat Board",
    icon: "bx bxs-message-dots",
    child: [],
  },
  settings: {
    link: "/home/settings",
    name: "Settings",
    icon: "bx bx-cog",
    child: [],
  },
};
const getCookie = (name) => {
  const cookieString = document.cookie;
  const cookies = cookieString.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + "=")) {
      return cookie.substring(name.length + 1);
    }
  }
  return null; // Cookie not found
};

const SideNav = () => {
  const ctx = useContext(userContext);
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState("OVOC HUB Platform");
  const { language, setLanguage } = useLanguage();
  const [dft, setDFT] = useState(
    LANGUAGES[language || "en"]?.filter(
      (lang) => getCookie("googtrans") === "/auto/" + lang.code
    ) || []
  );
  const [selectedLang, setSelectedLang] = useState(
    dft.length ? dft[0] : LANGUAGES[language || "en"][0]
  );
  const [navOpen, setNavOpen] = useState({
    isOpen: true,
    triggeredFrom: undefined,
  });
  useEffect(() => {
    setDFT(LANGUAGES[language || "en"]);
    setSelectedLang(LANGMAP[language]);
  }, [language]);

  useEffect(() => {
    if (document.cookie.includes("googtrans")) {
      FRONTEND_BACKEND_LANGUAGE_MAP;
      const value = getCookie("googtrans")?.split("/")[2];
      setSelectedLang(LANGMAP[FRONTEND_BACKEND_LANGUAGE_MAP[value]]);
    }
  }, []);

  useEffect(() => {
    sidebarItemsV2.forEach((bar) => {
      if (bar.link && bar.link === location.pathname) {
        setSelectedItem(bar.head);
      } else {
        bar.items.forEach((item) => {
          if (item.link === location.pathname) setSelectedItem(item.name);
        });
      }
    });
  }, []);

  useEffect(() => {
    const arrows = document.querySelectorAll(".arrow");

    arrows.forEach((arrow) => {
      arrow.addEventListener("click", (e) => {
        const arrowParent =
          e.target.closest(".arrow").parentElement.parentElement.parentElement;
        arrowParent.classList.toggle("showMenu");
      });
    });
  }, []);

  const handleLanguageChange = (lang) => {
    const languageSelect = document.querySelector("select.goog-te-combo");

    if (languageSelect) {
      languageSelect.value = lang.code;
      languageSelect.dispatchEvent(new Event("change"));
      // Solution to Problem in language selection
      if (!languageSelect.value) {
        languageSelect.value = lang.code;
        languageSelect.dispatchEvent(new Event("change"));
      }
      setSelectedLang(lang);
    }
  };

  const handleLogout = async () => {
    await axiosInstance.get("/site/logout").then((res) => {
      if (res.status === 200 || res.status === 401) {
        window.location.reload();
      }
    });
  };

  const handleNavShow = () => {
    const sidebar = document.querySelector(".sidebar");
    sidebar.classList.toggle("close");
    setNavOpen({
      isOpen: !navOpen.isOpen,
      triggeredFrom: "bar",
    });
  };

  useEffect(() => {
    const sidebar = document.querySelector(".sidebar");
    if (
      !sidebar.classList.contains("close") &&
      navOpen.triggeredFrom === "link"
    ) {
      sidebar.classList.add("close");
    } else if (
      sidebar.classList.contains("close") &&
      navOpen.triggeredFrom === "enter-hover"
    ) {
      sidebar.classList.remove("close");
    } else if (
      !sidebar.classList.contains("close") &&
      navOpen.triggeredFrom === "leave-hover"
    ) {
      sidebar.classList.add("close");
    }
  }, [navOpen]);

  return (
    <>
      <div className={`sidebar ${window.innerWidth > 950 ? "" : "close"}`}>
        <div className="logo-details">
          <img loading="lazy" className="logo logo-primary" src="/logo.png" />
        </div>
        <ul
          className="nav-links"
          // onMouseEnter={() => {
          //   setNavOpen({
          //     isOpen: true,
          //     triggeredFrom: "enter-hover",
          //   });
          // }}
          // onMouseLeave={() => {
          //   setNavOpen({
          //     isOpen: false,
          //     triggeredFrom: "leave-hover",
          //   });
          // }}
        >
          {sidebarItemsV2.map((item, index) => {
            if (item.link && item.validator(ctx.user)) {
              if (ctx.user?.routes.length && ctx.user.routes.includes(item.id))
                return (
                  <li key={item.id}>
                    <Link
                      to={item.link}
                      onClick={() => {
                        setSelectedItem(item.head);
                        // setNavOpen({
                        //   isOpen: false,
                        //   triggeredFrom: "link",
                        // });
                      }}
                    >
                      <i className={item.icon + " i-white"}></i>
                      <span className="link_name">{item.head}</span>
                    </Link>
                  </li>
                );
            } else {
              return (
                <div key={item.head}>
                  <div className="sidenav-menu-heading">
                    <span></span>
                    <p>{item.head}</p>
                  </div>

                  {sidebarItemsV2[index].items.map(
                    (section, index) =>
                      ctx.user?.routes.length &&
                      ctx.user.routes.includes(section.id) &&
                      section.validator(ctx.user) && (
                        <li key={section.id}>
                          <Link
                            className="nav-link"
                            to={section.link}
                            onClick={() => {
                              setSelectedItem(section.name);
                              // setNavOpen({
                              //   isOpen: false,
                              //   triggeredFrom: "link",
                              // });
                            }}
                          >
                            <i className={section.icon + " i-white"}></i>
                            <span className="link_name">{section.name}</span>
                          </Link>
                        </li>
                      )
                  )}
                </div>
              );
            }
          })}
          <li>
            <div className="profile-details">
              <div className="profile-content"></div>
              <div
                className="name-job"
                style={{
                  margin: "0em 2em",
                }}
              >
                <div className="profile_name">{ctx.user.username}</div>
                <div className="job">{ctx.user.email}</div>
              </div>
            </div>
          </li>
        </ul>
        <section className="home-section" id="home-section">
          <div className="home-content">
            <i
              className="bx bx-menu"
              style={{ color: "black" }}
              onClick={handleNavShow}
            ></i>
            <span className="text">{selectedItem}</span>
            <span
              style={{
                right: "0px",
                position: "fixed",
                margin: "1em",
                color: "black",
                cursor: "pointer",
                display: "flex",
                gap: "1em",
              }}
            >
              <ul
                id="nav-right-ican"
                className="navbar-nav my-lg-0 navbar-nav-scroll"
                style={{ marginRight: "2em", overflow: "inherit" }}
              >
                <li className="nav-item dropdown dropdown-lang">
                  <a
                    className="nav-link dropdown-toggle "
                    href="#"
                    id="navbarScrollingDropdown"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    translate="no"
                    style={{ padding: "0px" }}
                  >
                    <i className="fa-solid fa-language mx-1"></i>
                    {selectedLang?.name}
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarScrollingDropdown"
                    style={{
                      transform: "translate(0px, 31px)",
                      margin: "100%",
                    }}
                  >
                    {LANGUAGES[language || "en"]
                      ?.filter((lang) => lang.code !== selectedLang?.code)
                      ?.map((lang, index) => (
                        <li
                          key={index}
                          className="dropdown-item"
                          translate="no"
                          onClick={() => {
                            handleLanguageChange(lang);
                          }}
                        >
                          {lang.name}
                        </li>
                      ))}
                  </ul>
                </li>
              </ul>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip style={{ marginTop: "10px" }}>
                    <span
                      style={{
                        padding: "8px",
                        backgroundColor: "black",
                        color: "white",
                        borderRadius: "5px",
                      }}
                    >
                      Browse as a Moderator
                    </span>
                  </Tooltip>
                }
              >
                <a
                  href={OVOC_WEBSITE + "?moderator=true"}
                  target="_blank"
                  style={{ color: "black", marginRight: "1em" }}
                >
                  <i className="fa-solid fa-globe fa-lg"></i>
                </a>
              </OverlayTrigger>
              <i className="bx bx-log-out bx-sm" onClick={handleLogout}></i>
            </span>
          </div>
        </section>
      </div>
    </>
  );
};

export default SideNav;
