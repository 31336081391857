import { useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { userContext } from "./Context";

import SideNav from "../components/SideNav";
import Workbench from "./Workbench";
import UserBoard from "./UserBoard";
import Contact from "./Contact";
import Media from "./Media";
import News from "./News";
import Issue from "./Issue";
import IssuePage from "./IssuePage";
import Settings from "./Settings";
import CampaignNAdvocacy from "./CampaignNAdvocacy";
import Analytics from "./Analytics";
import ChatBoard from "./ChatBoard";
import About from "./About";
import OvocInAction from "./OvocInAction";
import LearningNResource from "./LearningNResource";
import HomePage from "./HomePage";
import Queries from "./Queries";
import Newsletter from "./Newsletter";
import Privacy from "./Privacy";
import Terms from "./Terms";
import FAQ from "./FAQ";
import TopImage from "./TopImage";
import GeneralAnalytics from "../components/GeneralAnalytics";
import Trash from "./Trash";

// const SideNav = lazy(() => import("../components/SideNav"));
// const Workbench = lazy(() => import("./Workbench"));
// const UserBoard = lazy(() => import("./UserBoard"));
// const Contact = lazy(() => import("./Contact"));
// const Media = lazy(() => import("./Media"));
// const News = lazy(() => import("./News"));
// const Issue = lazy(() => import("./Issue"));
// const IssuePage = lazy(() => import("./IssuePage"));
// const Settings = lazy(() => import("./Settings"));
// const Campaigns = lazy(() => import("./Campaigns"));
// const Analytics = lazy(() => import("./Analytics"));
// const ChatBoard = lazy(() => import("./ChatBoard"));
// const About = lazy(() => import("./About"));
// const OvocInAction = lazy(() => import("./OvocInAction"));
// const LearningNResource = lazy(() => import("./LearningNResource"));
// const HomePage = lazy(() => import("./HomePage"));

const routesV2 = {
  dashboard: {
    path: "/dashboard",
    component: <Analytics />,
    validator: () => true,
  },
  analytics: {
    path: "/analytics",
    component: <GeneralAnalytics />,
    validator: () => true,
  },
  about: {
    path: "/about",
    component: <About />,
    validator: () => true,
  },
  users: {
    path: "/user",
    component: <UserBoard />,
    validator: () => true,
  },
  OVOCInAction: {
    path: "/ovoc-in-action",
    component: <OvocInAction />,
    validator: () => true,
  },
  learningNresource: {
    path: "/learning-n-resources",
    component: <LearningNResource />,
    validator: () => true,
  },
  media: {
    path: "/media",
    component: <Media />,
    validator: () => true,
  },
  campaigns: {
    path: "/advocacy",
    component: <CampaignNAdvocacy />,
    validator: () => true,
  },
  issues: {
    path: "/issues",
    component: <Issue />,
    validator: () => true,
  },
  issuePage: {
    path: "/issues/:id",
    component: <IssuePage />,
    validator: () => true,
  },
  news: {
    path: "/news",
    component: <News />,
    validator: () => true,
  },
  chatBoard: {
    path: "/chatBoard",
    component: <ChatBoard />,
    validator: () => true,
  },
  contact: {
    path: "/contact",
    component: <Contact />,
    validator: () => true,
  },
  topImage: {
    path: "/topImage",
    component: <TopImage />,
    validator: () => true,
  },
  privacy: {
    path: "/privacy",
    component: <Privacy />,
    validator: () => true,
  },
  terms: {
    path: "/terms-n-conditions",
    component: <Terms />,
    validator: () => true,
  },
  faq: {
    path: "/faq",
    component: <FAQ />,
    validator: () => true,
  },
  home: {
    path: "/main",
    component: <HomePage />,
    validator: () => true,
  },
  queries: {
    path: "/queries",
    component: <Queries />,
    // validator: (user) => !Boolean(user?.subCommunityId),
    validator: () => true,
  },
  newsletter: {
    path: "/newsletter",
    component: <Newsletter />,
    // validator: (user) => !Boolean(user?.subCommunityId),
    validator: () => true,
  },
  trash: {
    path: "/trash",
    component: <Trash />,
    validator: () => true,
  },
};

const Home = () => {
  const ctx = useContext(userContext);
  return (
    <div style={{ display: "flex" }}>
      <SideNav />
      <Routes>
        {ctx.user && ctx.user.role <= 4 ? (
          <Route path="/" element={<Analytics />} />
        ) : (
          <Route path="/" element={<Workbench />} />
        )}

        <Route path="/workbench" element={<Workbench />} />
        {ctx.user &&
          ctx.user.routes &&
          ctx.user.routes
            .map((route) => {
              if (routesV2[route]?.validator(ctx.user)) {
                return (
                  <Route
                    key={route}
                    path={routesV2[route]?.path}
                    element={routesV2[route]?.component}
                  />
                );
              }
            })
            .filter(Boolean)}
        <Route path="/settings" element={<Settings />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
};

export default Home;
