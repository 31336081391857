import React, { useEffect, useState } from 'react';
import "./Upcoming.css";
import axiosInstance from "../utility/axios";
import { Button } from 'react-bootstrap';

export default function Upcoming(props) {
    const [Edit, setEdit] = useState(false)
    const [data, setData] = useState()
    const [getData, setGetData] = useState()

    const submitHandler=(e)=>{
        setEdit(!Edit)
        axiosInstance.patch(`/siteData/UpdateUpcomingEventWebinar`,{
            type:props.Type,
            data:data,
        }).then((response)=>{
            if(response.status===200){
                getUpcomingTitles()
                console.log("updated successfully")
            }
            else{
                // console.log("some error happed at line 19")
            }
        }).catch((err)=>{
          console.log(err);
        });
    }

    const getUpcomingTitles=()=>{
        axiosInstance.get(`/siteData/getUpcomingTitle`)
      .then((response) => {
        if (response.status === 200) {
            if(props.Type=="Event"){
                setGetData(response.data.response.Event.upcomingTitle)
            }else if(props.Type == "Webinar"){
                setGetData(response.data.response.Webinar.upcomingTitle)
            }else{
              setGetData(response.data.response.Event_Webinar.upcomingTitle)
            }
            
        } else {
          // TODO
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }
    
    useEffect(()=>{
        getUpcomingTitles()
    },[])

  return (
      <div className="row">
        <div className='col-1'></div>
          <div className="col-9">
              {!Edit && <div className="upcomingShow">
                 <div className='upcoming_content'>
                    {getData}
                 </div>
              </div>}
              {Edit && <div className='upcomingEdit'>
                <input placeholder='Write here' type='text' onChange={(e)=>{setData(e.target.value)}}></input>
              </div>}
          </div>
          <div className="col-2" style={{display:'flex',justifyContent:'left',alignItems:'center'}}>
            <Button variant={Edit?"success":"danger"} onClick={()=>{submitHandler()}}>{Edit?"Save":"Edit"}</Button>
          </div>
      </div>
  );
}
