import Table from "react-bootstrap/Table";
import Pagination from "./Pagination";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Badge from "react-bootstrap/Badge";
import React, { useEffect, useState, useContext } from "react";
import DeleteConfirmation from "./DeleteModal";
import HubSideBar from "../components/HubSideBar";
import "./UserTable.css";
import { userContext } from "../pages/Context";
import NoDataPresent from "./NoDataPresent";

const HubTable = ({
  tab,
  hubs,
  getHubData,
  deleteHub,
  search,
  setSearch,
  selectedCountry,
  setSelectedCountry,
  setEditDetails,
  getHubs,
  pagination,
  setPagination,
  totalRecords,
  countries,
  setNotificationData,
}) => {
  const { user } = useContext(userContext);
  const [showCreateHubBar, setShowCreateHubBar] = useState({
    show: false,
    mode: "",
  });
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState(undefined);

  const handlerRightBar = () => {
    setShowCreateHubBar({
      show: !showCreateHubBar.show,
      mode: "",
    });
  };
  useEffect(() => {
    if (deleteId) {
      setShowDeleteConfirmation(true);
    }
  }, [deleteId]);
  return (
    <>
      <DeleteConfirmation
        showModal={showDeleteConfirmation}
        hideModal={() => {
          setShowDeleteConfirmation(false);
          setDeleteId(undefined);
        }}
        confirmModal={() => {
          deleteHub(deleteId?.id);
          setShowDeleteConfirmation(false);
        }}
        message={`Are you sure you want to delete ${deleteId?.name}?`}
      />
      <HubSideBar
        show={showCreateHubBar.show}
        mode={showCreateHubBar.mode}
        countries={countries}
        refresh={() => {
          getHubs();
          setEditDetails({});
        }}
        handleClose={() => {
          setShowCreateHubBar({
            show: false,
            mode: "",
          });
          setEditDetails({});
        }}
        setNotificationData={setNotificationData}
      />
      <div
        className="input-group mb-4 table-filters"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div style={{ display: "flex" }}>
          <input
            type="search"
            placeholder="Search by name"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            aria-describedby="button-addon5"
            className="form-control"
            style={{ borderRadius: ".375rem 0px 0px .375rem" }}
          />
          <div className="input-group-append">
            <button
              id="button-addon5"
              type="button"
              onClick={() => getHubs()}
              className="btn btn-primary color3863A0"
              style={{
                borderRadius: "2px",
                height: "100%",
              }}
            >
              <i className="bx bx-search"></i>
            </button>
          </div>
          {user.role < 3 && (
            <InputGroup className="mx-3" style={{ width: "fit-content" }}>
              <DropdownButton
                variant="outline-secondary"
                title={
                  selectedCountry ? selectedCountry["name"] : "Select Country"
                }
                id="input-group-dropdown-1"
                onSelect={(e) => {
                  setSelectedCountry(JSON.parse(e));
                }}
              >
                <Dropdown.Item eventKey={undefined}>Select None</Dropdown.Item>
                {countries.map((country, index) => (
                  <Dropdown.Item eventKey={JSON.stringify(country)} key={index}>
                    {country.name}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </InputGroup>
          )}
        </div>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={handlerRightBar}
        >
          Add New
        </button>
      </div>
      {hubs.length === 0 ? (
        <NoDataPresent />
      ) : (
        <>
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th className="table-head">#</th>
                <th className="table-head">Name</th>
                <th className="table-head">Contact</th>
                <th className="table-head">Region</th>
                <th className="table-head">Created By</th>
                <th className="table-head">View</th>
                <th className="table-head">Edit</th>
                <th className="table-head">Delete</th>
              </tr>
            </thead>
            <tbody>
              {hubs.map((hub, index) => (
                <tr key={index}>
                  <td>{pagination.offset + index + 1}</td>
                  <td>{hub.name}</td>
                  <td>{hub.email}</td>
                  <td>
                    <Badge bg="light" text="dark">
                      {hub.country}
                    </Badge>
                  </td>
                  <td>{hub.createdBy}</td>
                  <td>
                    <i
                      className="fa-solid fa-box-open fa-1x"
                      onClick={() => getHubData(hub.id, "preview")}
                    ></i>
                  </td>
                  <td>
                    <i
                      className="fa-solid fa-pen-to-square fa-1x"
                      onClick={() => getHubData(hub.id, "edit")}
                    ></i>
                  </td>
                  <td>
                    <i
                      className="fa-solid fa-trash-can fa-1x"
                      onClick={() => setDeleteId(hub)}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            setPagination={setPagination}
            totalRecords={totalRecords}
          />
        </>
      )}
    </>
  );
};

export default HubTable;
