import React from "react";
import LazyLoad from "react-lazyload";
import { formatDate } from "../utility/utils";

const convertToEmbedLink = (url) => {
  const videoId = getVideoIdFromUrl(url);
  if (videoId) {
    const embedLink = `https://www.youtube.com/embed/${videoId}`;
    return embedLink;
  }
  return url;
};

const getVideoIdFromUrl = (url) => {
  const match = url.match(/v=([^&]+)/);
  return match ? match[1] : null;
};

const VideoPreview = ({ form }) => {
  return (
    <div className="blog_container" id="preview">
      <div className="single">
        <div className="content">
          {" "}
          <LazyLoad height={200} offset={100}>
            <iframe
              width="100%"
              height="415"
              src={convertToEmbedLink(form.videoFileURL)}
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </LazyLoad>
          <img
            src={
              typeof form.thumbnail === "string" && form.thumbnail.length
                ? process.env.REACT_APP_BUCKET_URL + form.thumbnail
                : typeof form.thumbnail === "object" && form.thumbnail !== null
                ? URL.createObjectURL(form.thumbnail)
                : "https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png"
            }
            alt={"video preview"}
            title={form.title}
            style={{
              height: "4em",
            }}
          />
          <div className="every-one">
            <h2>{form.title}</h2>
            <div className="posted-date">
              <i className="fa-solid fa-calendar-days"></i>
              <span>Posted On: {formatDate(form.date)}</span>
            </div>
            <div>{form?.description}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPreview;
