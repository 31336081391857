import React, { useState, useEffect, useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";
import axiosInstance from "../utility/axios";
import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup";
import Alert from "react-bootstrap/Alert";
import Badge from "react-bootstrap/Badge";
import { userContext } from "../pages/Context";

const bacs_types_name = {
  blog: "Blog",
  article: "Article",
  case_study: "Case Story",
};

const EditBacs = ({
  form,
  setShow,
  isPublic,
  handleSubmit,
  handleChange,
  handleContentChange,
  handleImageChange,
  setShowBacs,
  setEditBacs,
  uploadPlugin,
  removeSelectedFile,
  tags,
  handleTagChange,
  removeTag,
  addTag,
  setAddTag,
  createTag,
  tagMsg,
  setTagMsg,
  bacs_category,
  bacs_types,
  msg,
  setMsg,
  setNotificationData,
  updateTable,
  fileError,
  setFileError,
}) => {
  const { user } = useContext(userContext);
  const [comment, setComment] = useState("");
  const [countries, setCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState(
    form.countryIds || []
  );
  useEffect(() => {
    axiosInstance.get("/user/country/list").then((res) => {
      setCountries(res.data);
    });
  }, []);

  useEffect(() => {
    if (
      form.countryIds.find((country) => country.id === user.countryId) ||
      !user.countryId
    ) {
      setSelectedCountries(form.countryIds);
    } else {
      setSelectedCountries([
        ...form.countryIds,
        { id: user.countryId, name: user.country },
      ]);
    }
  }, [form.countryIds]);

  const handleComment = async (status) => {
    // status = 1 rejected, status = 3 approved, status = 2 No update [General comment]
    let isApproved = 2;
    if (status === "approve") {
      isApproved = 3;
    } else if (status === "reject") {
      isApproved = 1;
    }
    if (isPublic) {
      await axiosInstance
        .patch(`/website/approve/${form.id}`, {
          status: isApproved,
          comment,
        })
        .then((res) => {
          setNotificationData([
            {
              msg: res.data.message,
              status: res.status,
            },
          ]);
          setShowBacs(false);
          setEditBacs("");
          setMsg({
            message: [],
            variant: "danger",
          });
          updateTable();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await axiosInstance
        .patch(`/bacs/approve/${form.id}`, {
          status: isApproved,
          comment,
        })
        .then((res) => {
          if (res.status === 200) {
            setShowBacs(false);
            setEditBacs("");
            setMsg({
              message: [],
              variant: "danger",
            });
            updateTable();
          } else {
            if (res.data.message === "DB Error")
              setMsg({ message: [...res.data.error], variant: "danger" });
            else setMsg({ message: [res.data.message], variant: "danger" });
          }
          setNotificationData([
            {
              msg: res.data.message,
              status: res.status,
            },
          ]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const DropdownSearch = React.forwardRef((props, ref) => {
    const { children, style, className, "aria-labelledby": labeledBy } = props;
    const [value, setValue] = useState("");
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
          style={{ position: "sticky", top: "0px" }}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter((child) => {
            return (
              !value ||
              child.props?.children?.props?.children[1]
                .toLowerCase()
                .startsWith(value)
            );
          })}
        </ul>
      </div>
    );
  });

  const handleDropdownSelect = (e, selectedCountry) => {
    if (user.countryId !== selectedCountry.id) {
      setSelectedCountries((prevSelected) => {
        // Check if the country is already selected
        const isSelected = prevSelected.filter(
          (country) => country.id === selectedCountry.id
        );
        // If the country is already selected, remove it from the selected list
        if (isSelected.length) {
          return prevSelected.filter(
            (country) => country.id !== selectedCountry.id
          );
        } else {
          // If the country is not selected, add it to the selected list
          return [...prevSelected, selectedCountry];
        }
      });
    }
  };

  useEffect(() => {
    handleChange({
      target: { name: "countryIds", value: selectedCountries },
    });
  }, [selectedCountries]);

  return (
    <div className="BlogsContainer">
      <div className="resource-top mx-4">
        <Col className="blog-heading pt-2 pb-2" xs={8}>
          <h1 className="heading">Edit Form</h1>
        </Col>
        <Col className="button-grp">
          <button className="preview-button" onClick={() => setShow(true)}>
            <img
              loading="lazy"
              src="https://cdn-icons-png.flaticon.com/512/6329/6329893.png"
              alt="Preview Icon"
              className="preview-icon"
            />
            Preview
          </button>
          <button
            className="save-button"
            onClick={(e) => handleSubmit(e, isPublic)}
          >
            Save
          </button>
          <button
            style={{ backgroundColor: "grey" }}
            className="save-button"
            onClick={() => {
              setShowBacs(false);
              setEditBacs("");
              setMsg({
                message: [],
                variant: "danger",
              });
            }}
          >
            Back
          </button>
        </Col>
      </div>
      <div className="resource-inputs pt-4">
        <Col md={12} lg={8}>
          <div className="p-3 pt-0">
            <Row>
              <Container className="rounded editor-container p-4 m-1">
                <h2 className="sub-heading">Title</h2>

                <input
                  type="text"
                  className="form-control"
                  id="blogTitle"
                  name="title"
                  placeholder=""
                  value={form.title}
                  onChange={handleChange}
                  disabled={
                    user.role > 3 &&
                    (form.publishStage === 2 ||
                      (form.authorId && user.id !== form.authorId))
                  }
                />
              </Container>
            </Row>

            <Row>
              <Container className="rounded editor-container p-4 m-1">
                <Row>
                  <Col className="left-container text-center" xs={3}>
                    <p>Upload Banner Image*</p>
                  </Col>
                  <Col className="text-center ">
                    <div className="image-uploader">
                      <label htmlFor="bannerImage" className="upload-label">
                        <div className="upload-icon">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/512/569/569800.png"
                            alt="Upload Icon"
                          />
                        </div>
                        <span className="upload-text">
                          {form.bannerImage || form.bannerImageName
                            ? typeof form.bannerImage === "string"
                              ? form.bannerImageName
                              : form.bannerImage.name
                            : "Choose from files"}
                        </span>{" "}
                        {form.bannerImage && (
                          <button
                            style={{
                              padding: "0px .5em",
                              marginLeft: "1em",
                            }}
                            className="remove-button"
                            type="button"
                            onClick={() => removeSelectedFile("bannerImage")}
                          >
                            X
                          </button>
                        )}
                      </label>

                      <input
                        type="file"
                        id="bannerImage"
                        accept=".jpg,.jpeg,.png"
                        multiple={false}
                        name="bannerImage"
                        style={{ display: "none" }}
                        onChange={handleImageChange}
                        disabled={
                          user.role > 3 &&
                          (form.publishStage === 2 ||
                            (form.authorId && user.id !== form.authorId))
                        }
                      />
                    </div>
                    {fileError && fileError?.bannerImage && (
                      <Alert variant="danger">{fileError?.bannerImage}</Alert>
                    )}
                  </Col>
                </Row>
              </Container>
            </Row>
            <Row className="">
              <Container
                className="rounded editor-container p-4 m-1"
                id="preview"
              >
                <div className="ceo-founder">
                  <div className="row">
                    <div
                      className="col-sm-3 fec-twi-lin"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <img
                        loading="lazy"
                        src={
                          typeof form.authorImage === "string" &&
                          form.authorImage?.length
                            ? process.env.REACT_APP_BUCKET_URL +
                              form.authorImage
                            : typeof form.authorImage === "object" &&
                              form.authorImage !== null
                            ? URL.createObjectURL(form.authorImage)
                            : "https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png"
                        }
                        alt="user img"
                        style={{
                          width: "100%",
                          aspectRatio: "1 / 1",
                          borderRadius: "50%",
                        }}
                      />
                      {form.authorImage && (
                        <button
                          style={{
                            padding: "0px .5em",
                            marginLeft: "1em",
                          }}
                          className="remove-button"
                          type="button"
                          onClick={() => removeSelectedFile("authorImage")}
                        >
                          Remove File
                        </button>
                      )}
                      <input
                        type="file"
                        accept=".jpg,.jpeg,.png"
                        name="authorImage"
                        id="authorImage"
                        value=""
                        placeholder=""
                        onChange={handleImageChange}
                        disabled={
                          user.role > 3 &&
                          (form.publishStage === 2 ||
                            (form.authorId && user.id !== form.authorId))
                        }
                        style={{ display: form.authorImage ? "none" : "block" }}
                      />
                    </div>
                    <div className="col-sm-9 john-doe">
                      <h4>
                        <input
                          type="text"
                          name="authorName"
                          value={form.authorName}
                          placeholder="Author Name"
                          onChange={handleChange}
                          disabled={
                            user.role > 3 &&
                            (form.publishStage === 2 ||
                              (form.authorId && user.id !== form.authorId))
                          }
                        />
                      </h4>
                      <p>
                        <textarea
                          type="text"
                          name="authorDescription"
                          value={form.authorDescription}
                          placeholder="description"
                          style={{
                            minWidth: "100%",
                          }}
                          rows={"5"}
                          onChange={handleChange}
                          disabled={
                            user.role > 3 &&
                            (form.publishStage === 2 ||
                              (form.authorId && user.id !== form.authorId))
                          }
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </Container>
            </Row>
            <Row>
              <Container className="rounded editor-container p-4 m-1">
                <h2 className="sub-heading">Content</h2>
                <CKEditor
                  editor={Editor}
                  data={form.content}
                  onReady={(editor) => {
                    uploadPlugin(editor);
                  }}
                  onChange={handleContentChange}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                  disabled={
                    user.role > 3 &&
                    (form.publishStage === 2 ||
                      (form.authorId && user.id !== form.authorId))
                  }
                />
              </Container>
            </Row>
          </div>
        </Col>
        <Col>
          <Container className="pt-4 editor-container">
            <div className="p-1">
              <h2 className="sub-heading">Country</h2>

              {user.role > 3 &&
              (form.publishStage === 2 ||
                (form.authorId && user.id !== form.authorId)) ? (
                <>
                  {selectedCountries.map((country) => (
                    <Badge
                      bg="dark"
                      text="light"
                      className="p-2 m-1"
                      key={country.id}
                    >
                      {country.name}
                    </Badge>
                  ))}
                </>
              ) : (
                <DropdownButton
                  variant="outline-secondary"
                  title={"Select Country"}
                  id="input-group-dropdown-1"
                >
                  <Form.Check
                    type="checkbox"
                    label="Select All"
                    style={{ margin: "0px 20px" }}
                    checked={selectedCountries.length === countries.length}
                    onChange={() => {
                      setSelectedCountries(
                        selectedCountries.length === countries.length
                          ? []
                          : countries
                      );
                    }}
                  />
                  {countries.map((country, index) => (
                    <Form.Check
                      key={index}
                      type="checkbox"
                      label={country.name}
                      style={{ margin: "0px 20px" }}
                      checked={selectedCountries.some(
                        (selected) => selected?.id === country?.id
                      )}
                      onChange={(e) => {
                        handleDropdownSelect(e, country);
                      }}
                    />
                  ))}
                </DropdownButton>
              )}
            </div>
            <div className="p-1">
              <h2 className="sub-heading">Category</h2>
              <InputGroup className="" style={{ width: "fit-content" }}>
                <DropdownButton
                  variant="outline-secondary"
                  title={form.category ? form.category : "Select Category"}
                  id="input-group-dropdown-1"
                  onSelect={(e) => {
                    handleChange({ target: { name: "category", value: e } });
                  }}
                  disabled={
                    user.role > 3 &&
                    (form.publishStage === 2 ||
                      (form.authorId && user.id !== form.authorId))
                  }
                >
                  {bacs_category.map((bacsCategory, index) => (
                    <Dropdown.Item eventKey={bacsCategory} key={index}>
                      {bacsCategory}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </InputGroup>
            </div>
            <div className="p-1">
              <h2 className="sub-heading">Resource Type</h2>
              <InputGroup className="" style={{ width: "fit-content" }}>
                <DropdownButton
                  variant="outline-secondary"
                  title={
                    form.bacs_type
                      ? bacs_types_name[form.bacs_type]
                      : "Resource Type"
                  }
                  id="input-group-dropdown-1"
                  onSelect={(e) => {
                    handleChange({ target: { name: "bacs_type", value: e } });
                  }}
                  disabled={
                    user.role > 3 &&
                    (form.publishStage === 2 ||
                      (form.authorId && user.id !== form.authorId))
                  }
                >
                  {bacs_types.map((bacsType, index) => (
                    <Dropdown.Item eventKey={bacsType} key={index}>
                      {bacs_types_name[bacsType]}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </InputGroup>
            </div>
            <div className="p-1">
              <h2 className="sub-heading">Post Date</h2>
              <input
                type="date"
                className="form-control"
                id="blogDate"
                name="date"
                placeholder=""
                // min={new Date().toISOString().split("T")[0]}
                value={form.date}
                onChange={handleChange}
                disabled={
                  user.role > 3 &&
                  (form.publishStage === 2 ||
                    (form.authorId && user.id !== form.authorId))
                }
              />
            </div>
            <div className="p-1">
              <h2 className="sub-heading">Tags</h2>
              {user.role > 3 &&
              (form.publishStage === 2 ||
                (form.authorId && user.id !== form.authorId)) ? (
                <>
                  {tags.map((tag) => (
                    <Badge
                      bg="dark"
                      text="light"
                      className="p-2 m-1"
                      key={tag.id}
                    >
                      {tag.name}
                    </Badge>
                  ))}
                </>
              ) : (
                <>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="outline-secondary"
                      id="input-group-dropdown-1"
                    >
                      {"Select Tags"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu as={DropdownSearch}>
                      {tags.map((tag, index) => (
                        <Dropdown.Item key={index}>
                          <div style={{ display: "flex" }}>
                            <input
                              className="me-2"
                              type="checkbox"
                              value={tag.id}
                              onChange={handleTagChange}
                              checked={form.tag?.includes(tag.id)}
                            />
                            {tag.name}
                            <button
                              type="button"
                              style={{
                                padding: "0 .5em",
                                marginLeft: "auto",
                              }}
                              onClick={() => removeTag(tag.id)}
                            >
                              X
                            </button>
                          </div>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  <div>
                    <button
                      className="btn btn-outline-secondary "
                      onClick={() => {
                        setAddTag(!addTag);
                        setTagMsg("");
                      }}
                      disabled={
                        user.role > 3 &&
                        (form.publishStage === 2 ||
                          (form.authorId && user.id !== form.authorId))
                      }
                    >
                      {" "}
                      + Add New Tag
                    </button>
                  </div>
                </>
              )}

              {addTag && (
                <div className="input-group mb-3" style={{ margin: "1em 0" }}>
                  <input
                    type="text"
                    className="form-control"
                    id="tagName"
                    name="tagName"
                    placeholder="Tag Name"
                  />
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    id="button-addon2"
                    onClick={() => {
                      createTag(document.getElementById("tagName").value);
                    }}
                  >
                    Save
                  </button>
                </div>
              )}
              <div className="mt-2">{tagMsg}</div>
            </div>
            <div>
              {msg.message.length > 0 &&
                msg.message.map((m, index) => (
                  <Alert key={index} variant={msg.variant}>
                    {m}
                  </Alert>
                ))}
            </div>
            <div
              style={{
                display: form.publishStage === 2 ? "flex" : "none",
              }}
            >
              {isPublic ? (
                <Card border="primary" style={{ margin: "auto" }}>
                  <Card.Header>{"Do you Approve this Blog?"}</Card.Header>
                  <Card.Body>
                    <Card.Text as="div">
                      <textarea
                        placeholder="Comment"
                        style={{ width: "100%" }}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      ></textarea>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          gap: "15px",
                        }}
                      >
                        <button
                          className="btn btn-success"
                          type=""
                          onClick={() => handleComment("approve")}
                        >
                          Approve
                        </button>
                        <button
                          className="btn btn-danger"
                          type=""
                          onClick={() => handleComment("reject")}
                        >
                          Reject
                        </button>
                      </div>{" "}
                    </Card.Text>
                  </Card.Body>
                </Card>
              ) : (
                <Card border="primary" style={{ margin: "auto" }}>
                  <Card.Header>
                    {user.id === form.authorId
                      ? "Please Wait!!!"
                      : `Do you Approve this ${form.bacs_type}?`}
                  </Card.Header>
                  <Card.Body>
                    <Card.Text as="div">
                      {user.id === form.authorId ? (
                        `${form.bacs_type} is pending for approval`
                      ) : (
                        <>
                          <textarea
                            placeholder="Comment"
                            style={{ width: "100%" }}
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                          ></textarea>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              gap: "15px",
                            }}
                          >
                            <button
                              className="btn btn-success"
                              type=""
                              onClick={() => handleComment("approve")}
                            >
                              Approve
                            </button>
                            <button
                              className="btn btn-danger"
                              type=""
                              onClick={() => handleComment("reject")}
                            >
                              Reject
                            </button>
                          </div>{" "}
                        </>
                      )}
                    </Card.Text>
                  </Card.Body>
                </Card>
              )}
            </div>
          </Container>
        </Col>
      </div>
    </div>
  );
};

export default EditBacs;
