import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { userContext } from '../pages/Context';

const useAuth = (user) => {
  if (!!user.isAuth) return true;
  return false;
}

const PrivateRoute = ({ children }) => {
  const { user, isLoading } = useContext(userContext); // Destructure user and isLoading from context
  if (isLoading) {
    // Handle loading state (e.g., show a loading spinner)
    return <div>Loading...</div>;
  }

  const auth = useAuth(user);
  return auth ? children : <Navigate to="/login" />;
}

export default PrivateRoute;
