import Table from "react-bootstrap/Table";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Card from "react-bootstrap/Card";
import StatusCard from "./StatusCard";
import Notification from "./Notification";
import axiosInstance from "../utility/axios";
import { useState, useEffect, useRef } from "react";
import "./MyWorkflows.css";
import NoDataPresent from "./NoDataPresent";

const counter_skeleton = {
  blog: { title: "Blogs", count: 0 },
  article: { title: "Articles", count: 0 },
  case_study: { title: "Case Stories", count: 0 },
  campaign: { title: "Campaigns", count: 0 },
  event: { title: "Events", count: 0 },
  webinar: { title: "Webinars", count: 0 },
  news: { title: "News", count: 0 },
  video: { title: "Videos", count: 0 },
  course: { title: "Courses", count: 0 },
  tools: { title: "Tools", count: 0 },
  document: { title: "Document", count: 0 },
  report: { title: "Report", count: 0 },
  advocacy: { title: "Advocacy", count: 0 },
};

const RESOURCES_APPROVAL_ENDPOINT = {
  blog: "/bacs/approve/",
  article: "/bacs/approve/",
  case_study: "/bacs/approve/",
  campaign: "/campaign/approve/",
  event: "/event/approve/",
  webinar: "/event/approve/",
  news: "/news/approve/",
  video: "/videos/approve/",
  course: "/course/approve/",
  tools: "/docs/approve/",
  document: "/docs/approve/",
  report: "/docs/approve/",
  advocacy: "/docs/approve/",
};

const MyWorkflows = ({ data, setPreviewData, getWorkflowData }) => {
  const [statusCards, setStatusCards] = useState(counter_skeleton);
  const [showCommentBoxArray, setShowCommentBoxArray] = useState(
    new Array(data.length).fill(false)
  );
  const targetBox = useRef(null);
  const [comment, setComment] = useState("");
  const [notificationData, setNotificationData] = useState([]);

  useEffect(() => {
    let updatedStatusCards = Object.assign({}, counter_skeleton);

    data.forEach((item) => {
      const { resource_type } = item;

      if (updatedStatusCards.hasOwnProperty(resource_type)) {
        updatedStatusCards[resource_type] = {
          ...updatedStatusCards[resource_type],
          count: updatedStatusCards[resource_type].count + 1,
        };
      }
    });
    setStatusCards(updatedStatusCards);
    targetBox.current = null;
    setShowCommentBoxArray(new Array(data.length).fill(false));
  }, [data]);

  const handleComment = async (status, id, resource_type, index) => {
    // status = 1 rejected, status = 3 approved, status = 2 No update [General comment]
    let isApproved = 2;
    if (status === "approve") {
      isApproved = 3;
    } else if (status === "reject") {
      isApproved = 1;
    }
    await axiosInstance
      .patch(`${RESOURCES_APPROVAL_ENDPOINT[resource_type]}${id}`, {
        status: isApproved,
        comment,
      })
      .then((res) => {
        setNotificationData([
          {
            msg: res?.data?.message,
            status: res?.status,
          },
        ]);
        setComment("");
        getWorkflowData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleToggleCommentBox = (index) => {
    const updatedShowCommentBoxArray = [...showCommentBoxArray];
    updatedShowCommentBoxArray[index] = !updatedShowCommentBoxArray[index];
    setShowCommentBoxArray(updatedShowCommentBoxArray);
  };

  return (
    <>
      <Notification
        notificationData={notificationData}
        setNotificationData={setNotificationData}
      />
      <div className="d-flex gap-2 flex-wrap mb-4" id="my-workflows">
        {Object.values(statusCards).map(
          (item, index) =>
            item.count > 0 && (
              <StatusCard
                key={index}
                icon={item.icon}
                count={item.count}
                title={item.title}
              />
            )
        )}
      </div>
      {data.length === 0 ? (
        <NoDataPresent />
      ) : (
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th className="table-head">#</th>
              <th className="table-head">Title</th>
              <th className="table-head">Resource Type</th>
              <th className="table-head">Author</th>
              <th className="table-head">Date</th>
              <th className="table-head">Preview</th>
              <th className="table-head">Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.title}</td>
                <td>{item.resource_type}</td>
                <td>{item.author}</td>
                <td>{item.date}</td>
                <td>
                  <i
                    className="bx bx-spreadsheet bx-flip-horizontal bx-sm"
                    onClick={() => {
                      setPreviewData({
                        show: "fetch",
                        id: item.id,
                        resource_type: item.resource_type,
                      });
                    }}
                    style={{ cursor: "pointer" }}
                  ></i>
                </td>
                <td>
                  <OverlayTrigger
                    placement="auto"
                    trigger="click"
                    onToggle={() => {
                      setComment("");
                    }}
                    rootClose
                    overlay={
                      <Tooltip id="overlay-example">
                        <Card border="primary" style={{ margin: "auto" }}>
                          <Card.Header>
                            Do you Approve this {item.resource_type}?
                          </Card.Header>
                          <Card.Body>
                            <Card.Text>
                              <textarea
                                placeholder="Comment"
                                style={{ width: "100%" }}
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                              ></textarea>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  gap: "15px",
                                }}
                              >
                                <button
                                  className="btn btn-success"
                                  type=""
                                  onClick={() =>
                                    handleComment(
                                      "approve",
                                      item.id,
                                      item.resource_type,
                                      index
                                    )
                                  }
                                >
                                  Approve
                                </button>
                                <button
                                  className="btn btn-danger"
                                  type=""
                                  onClick={() =>
                                    handleComment(
                                      "reject",
                                      item.id,
                                      item.resource_type,
                                      index
                                    )
                                  }
                                >
                                  Reject
                                </button>
                              </div>{" "}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Tooltip>
                    }
                  >
                    <i
                      className="bx bxs-comment-error bx-sm"
                      ref={targetBox}
                      onClick={() => handleToggleCommentBox(index)}
                    ></i>
                  </OverlayTrigger>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default MyWorkflows;
