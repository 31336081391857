import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../utility/axios";
import MFAInputComponent from "../components/MFAInputComponent";
import "./Login.css";

const Login = () => {
  const navigate = useNavigate();
  const [messages, setMessages] = useState({
    success: "",
    error: "",
  });

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [showOTP, setShowOTP] = useState(false);

  const handleGetOTP = (e) => {
    e.preventDefault();
    axiosInstance
      .post("/site/send-otp", {
        email,
      })
      .then((response) => {
        if (response?.status === 200) {
          setMessages({
            success: response.data.message,
            error: "",
          });
        } else {
          setMessages({
            success: "",
            error: response?.data?.message,
          });
        }
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let body = {
      email,
      password,
    };
    if (otp) {
      body = {
        ...body,
        isOTPLogin: true,
        otp,
      };
    }
    axiosInstance.post("/site/login", body).then((response) => {
      if (response?.status === 200) {
        setMessages({
          success: response.data.success,
          error: "",
        });
        navigate("/home");
      } else {
        setMessages({
          success: "",
          error: response?.data?.error,
        });
      }
    });
  };

  return (
    <div id="login-form-id">
      <div className="login-header">
        <img loading="lazy" src="img/logo.png" alt="OVOCHub Logo" />
      </div>
      <div
        className="container login-container"
        style={{
          width: "500px",
        }}
      >
        <div className="login-form">
          <h2 style={{ color: "white" }}>Login</h2>
          <form
            style={{
              width: "300px",
            }}
          >
            <div className="form-group">
              <label htmlFor="email" style={{ color: "white" }}>
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            {!showOTP ? (
              <div className="form-group">
                <label htmlFor="password" style={{ color: "white" }}>
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  onChange={(e) => {
                    setOtp("");
                    setPassword(e.target.value);
                  }}
                  value={password}
                  required
                />
              </div>
            ) : (
              <div className="form-group">
                <label htmlFor="otp" style={{ color: "white" }}>
                  OTP
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    name="otp"
                    id="otp"
                    style={{
                      width: "65%",
                    }}
                    onChange={(e) => {
                      setPassword("");
                      setOtp(e.target.value);
                    }}
                    value={otp}
                    required
                  />
                  <div className="input-group-append">
                    <button
                      style={{
                        backgroundColor: "white",
                        color: "black",
                        height: "100%",
                        borderRadius: "0px 10px 10px 0px",
                      }}
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={handleGetOTP}
                    >
                      Get OTP
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className="mb-3">
              <a
                style={{
                  color: "white",
                  cursor: "pointer",
                  textDecorationLine: "underline",
                }}
                target="__blank"
                onClick={() => setShowOTP((showOTP) => !showOTP)}
              >
                {showOTP ? "Login with Password" : "Forgot Password?"}
              </a>
            </div>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleSubmit}
              style={{
                backgroundColor: "white",
                color: "black",
              }}
            >
              Login
            </button>{" "}
          </form>
        </div>
        {!!messages.success?.length && (
          <div
            className="alert alert-success"
            style={{
              position: "relative",
            }}
          >
            {messages.success}
          </div>
        )}
        {!!messages.error?.length && (
          <div className="alert alert-danger">{messages.error}</div>
        )}
      </div>
    </div>
  );
};

export default Login;
