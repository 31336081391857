import { useState, useEffect, useContext } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import InputGroup from "react-bootstrap/InputGroup";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import axiosInstance from "../utility/axios";
import DeleteConfirmation from "../components/DeleteModal";
import Notification from "../components/Notification";
import MyBoard from "../components/MyBoard";
import MyWorkflows from "../components/MyWorkflows";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./Workbench.css";
import VideosPreview from "../components/VideoPreview";
import BacsPreview from "../components/BacsPreview";
import NewsPreview from "../components/NewsPreview";
import CampaignPreview from "../components/CampaignPreview";
import EventPreview from "../components/EventPreview";
import DocsPreview from "../components/DocsPreview";
import CoursePreview from "../components/CoursePreview";
import { userContext } from "./Context";

const RESOURCES = {
  blog: "Blogs",
  article: "Articles",
  case_study: "Case Stories",
  campaign: "Campaigns",
  event: "Events",
  webinar: "Webinars",
  news: "News",
  video: "Videos",
  tools: "Tools",
  document: "Document",
  report: "Reports",
  course: "Courses",
  advocacy: "Advocacy",
};

const RESOURCES_DELETE_ENDPOINT = {
  blog: "/bacs/delete/",
  article: "/bacs/delete/",
  case_study: "/bacs/delete/",
  campaign: "/campaign/delete/",
  event: "/event/delete/",
  webinar: "/event/delete/",
  news: "/news/delete/",
  video: "/videos/delete/",
  tools: "/docs/delete/",
  document: "/docs/delete/",
  report: "/docs/delete/",
  course: "/course/delete/",
  advocacy: "/docs/delete/",
};

const publishStage = {
  1: "draft/changes required",
  2: "In Review",
  3: "published",
};

const Workbench = () => {
  const { user } = useContext(userContext);
  const [myData, setMyData] = useState([]);
  const [myWorkflow, setMyWorkflow] = useState([]);
  const [filters, setFilters] = useState({
    search: "",
    publishStage: undefined,
    resource: undefined,
    country_id: undefined,
  });
  const [deleteId, setDeleteId] = useState(undefined);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [search, setSearch] = useState("");
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(undefined);
  const [selectedTab, setSelectedTab] = useState("my-board");
  const [previewData, setPreviewData] = useState({
    show: "hide",
    id: "",
    resource_type: "",
  });
  const [bacsTags, setBacsTags] = useState([]);
  const [newsTags, setNewsTags] = useState([]);

  const RESOURCES_GET_PREVIEW = {
    blog: {
      endpoint: "/bacs/get/",
      component: <BacsPreview form={previewData} tags={bacsTags} />,
      parent: "bacs",
    },
    article: {
      endpoint: "/bacs/get/",
      component: <BacsPreview form={previewData} tags={bacsTags} />,
      parent: "bacs",
    },
    case_study: {
      endpoint: "/bacs/get/",
      component: <BacsPreview form={previewData} tags={bacsTags} />,
      parent: "bacs",
    },
    campaign: {
      endpoint: "/campaign/get/",
      component: <CampaignPreview form={previewData} />,
      parent: "campaign",
    },
    event: {
      endpoint: "/event/get/",
      component: <EventPreview form={previewData} />,
      parent: "event",
    },
    webinar: {
      endpoint: "/event/get/",
      component: <EventPreview form={previewData} />,
      parent: "event",
    },
    news: {
      endpoint: "/news/get/",
      component: <NewsPreview form={previewData} tags={newsTags} />,
      parent: "news",
    },
    video: {
      endpoint: "/videos/get/",
      component: <VideosPreview form={previewData} />,
      parent: "video",
    },
    tools: {
      endpoint: "/docs/get/",
      component: <DocsPreview form={previewData} />,
      parent: "docs",
    },
    document: {
      endpoint: "/docs/get/",
      component: <DocsPreview form={previewData} />,
      parent: "docs",
    },
    report: {
      endpoint: "/docs/get/",
      component: <DocsPreview form={previewData} />,
      parent: "docs",
    },
    advocacy: {
      endpoint: "/docs/get/",
      component: <DocsPreview form={previewData} />,
      parent: "docs",
    },
    course: {
      endpoint: "/course/get/",
      component: <CoursePreview form={previewData} />,
      parent: "course",
    },
  };

  const getBoardData = async () => {
    let query = `?search=${filters.search}`;
    if (filters.resource) query += `&resource=${filters.resource}`;
    if (filters.publishStage) query += `&publishStage=${filters.publishStage}`;
    axiosInstance
      .get(`/dashboard/my-board${query}`)
      .then((res) => {
        if (res.status === 200) {
          setMyData(res.data.data);
        } else {
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getWorkflowData = async () => {
    let query = `?search=${filters.search}`;
    if (filters.resource) query += `&resource=${filters.resource}`;
    if (user.role < 3 && filters.country_id) {
      query += `&country_id=${filters.country_id}`;
    } else if (user.role >= 3) {
      query += `&country_id=${user.countryId}`;
    }
    axiosInstance
      .get(`/dashboard/my-workflows${query}`)
      .then((res) => {
        if (res.status === 200) {
          setMyWorkflow(res.data.data);
        } else {
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getItemData = () => {
    axiosInstance
      .get(
        `${RESOURCES_GET_PREVIEW[previewData.resource_type]?.endpoint}${
          previewData.id
        }`
      )
      .then((response) => {
        if (response.status === 200) {
          const data =
            response.data[
              RESOURCES_GET_PREVIEW[previewData.resource_type]?.parent
            ] || {};
          setPreviewData({
            ...previewData,
            show: "show",
            ...data,
            ...(data.tags ? { ptags: data.tags } : {}),
            ...(data.countries ? { countryIds: data.countries } : {}),
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteResource = (id, resource_type) => {
    axiosInstance
      .delete(`${RESOURCES_DELETE_ENDPOINT[resource_type]}${id}`)
      .then((response) => {
        if (response.status === 200) {
          getBoardData();
        }
        setNotificationData([
          {
            msg: response.data.message,
            status: response.status,
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getBoardData();
    getWorkflowData();
    axiosInstance.get("/user/country/list").then((res) => {
      setCountries(res.data);
    });
    axiosInstance
      .get(`/bacs/get/tags`)
      .then((response) => {
        if (response.status === 200) {
          setBacsTags([...response.data?.response]);
        } else {
          // TODO
        }
      })
      .catch((err) => {
        console.log(err);
      });
    axiosInstance
      .get(`/news/get/tags`)
      .then((response) => {
        if (response.status === 200) {
          setNewsTags([...response.data?.response]);
        } else {
          // TODO
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (selectedTab === "my-board") {
      getBoardData();
    } else {
      getWorkflowData();
    }
  }, [filters]);

  useEffect(() => {
    if (deleteId) {
      setShowDeleteConfirmation(true);
    }
  }, [deleteId]);

  useEffect(() => {
    if (previewData.show === "fetch") {
      getItemData();
    }
  }, [previewData]);

  const PreviewModal = () => {
    return (
      <Modal
        show={previewData.show === "show"}
        onHide={() => {
          setPreviewData({
            show: "hide",
            id: "",
            resource_type: "",
          });
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{previewData.resource_type} Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal_body">
          {RESOURCES_GET_PREVIEW[previewData.resource_type]?.component}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() =>
              setPreviewData({
                show: "hide",
                id: "",
                resource_type: "",
              })
            }
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <section className="user-section" id="user-section">
      <div className="user-content">
        <DeleteConfirmation
          showModal={showDeleteConfirmation}
          hideModal={() => {
            setShowDeleteConfirmation(false);
            setDeleteId(undefined);
          }}
          confirmModal={() => {
            deleteResource(deleteId?.id, deleteId.resource_type);
            setShowDeleteConfirmation(false);
          }}
          message={`Are you sure you want to delete ${deleteId?.title}?`}
        />
        <PreviewModal />
        <Notification
          notificationData={notificationData}
          setNotificationData={setNotificationData}
        />
        <div
          className="input-group mb-4 table-filters"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div style={{ display: "flex" }}>
            <input
              type="search"
              placeholder="Search by name"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              aria-describedby="button-addon5"
              className="form-control"
              style={{ borderRadius: ".375rem 0px 0px .375rem" }}
            />
            <div className="input-group-append">
              <button
                id="button-addon5"
                type="button"
                onClick={() => setFilters({ ...filters, search })}
                className="btn btn-primary color3863A0"
                style={{
                  borderRadius: "2px",
                  height: "100%",
                }}
              >
                <i className="bx bx-search"></i>
              </button>
            </div>
            <InputGroup className="mx-3" style={{ width: "fit-content" }}>
              <DropdownButton
                variant="outline-secondary"
                title={
                  filters.resource
                    ? RESOURCES[filters.resource]
                    : "Select Resource Type"
                }
                id="input-group-dropdown-1"
                onSelect={(e) => {
                  setFilters({ ...filters, resource: e });
                }}
              >
                <Dropdown.Item eventKey={undefined}>Select None</Dropdown.Item>
                {Object.keys(RESOURCES).map((resource, index) => (
                  <Dropdown.Item eventKey={resource} key={index}>
                    {RESOURCES[resource]}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </InputGroup>
            {selectedTab === "my-workflows" ? (
              user.role < 3 && (
                <InputGroup className="mx-3" style={{ width: "fit-content" }}>
                  <DropdownButton
                    variant="outline-secondary"
                    title={
                      selectedCountry
                        ? selectedCountry["name"]
                        : "Select Country"
                    }
                    id="input-group-dropdown-1"
                    onSelect={(e) => {
                      setSelectedCountry(JSON.parse(e));
                      setFilters({ ...filters, country_id: JSON.parse(e)?.id });
                    }}
                  >
                    <Dropdown.Item eventKey={undefined}>
                      Select None
                    </Dropdown.Item>
                    {countries.map((country, index) => (
                      <Dropdown.Item
                        eventKey={JSON.stringify(country)}
                        key={index}
                      >
                        {country.name}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </InputGroup>
              )
            ) : (
              <InputGroup className="mx-3" style={{ width: "fit-content" }}>
                <DropdownButton
                  variant="outline-secondary"
                  title={
                    filters.publishStage
                      ? publishStage[filters.publishStage]
                      : "Select Stage"
                  }
                  id="input-group-dropdown-1"
                  onSelect={(e) => {
                    setFilters({ ...filters, publishStage: e });
                  }}
                >
                  <Dropdown.Item eventKey={undefined}>
                    Select None
                  </Dropdown.Item>
                  {Object.keys(publishStage).map((stageId, index) => (
                    <Dropdown.Item eventKey={stageId} key={index}>
                      {publishStage[stageId]}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </InputGroup>
            )}
          </div>
        </div>
        <Tabs
          defaultActiveKey="my-board"
          id="uncontrolled-tab-example"
          className="mb-3"
          onSelect={(v) => {
            setSelectedTab(v);
          }}
        >
          <Tab eventKey="my-board" title="My Board">
            <MyBoard
              data={myData}
              publishStage={publishStage}
              setPreviewData={setPreviewData}
              setDeleteId={setDeleteId}
            />{" "}
          </Tab>
          {user.role < 5 && (
            <Tab eventKey="my-workflows" title="My Workflows">
              <MyWorkflows
                data={myWorkflow}
                setPreviewData={setPreviewData}
                getWorkflowData={getWorkflowData}
              />
            </Tab>
          )}
        </Tabs>
      </div>
    </section>
  );
};

export default Workbench;
