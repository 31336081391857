import Gallery from "react-photo-gallery";
import SelectedImage from "../components/SelectedImage";
import GalleryFileUpload from "../components/GalleryFileUpload";
import { React, useState, useEffect, useCallback } from "react";
import { SyncLoader } from "react-spinners";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Notification from "../components/Notification";
import axiosInstance from "../utility/axios";
import Pagination from "../components/Pagination";
import Form from "react-bootstrap/Form";
import { nanoid } from "nanoid";
import { useContext } from "react";
import { userContext } from "./Context";
import "./Gallery.css";

function columns(containerWidth) {
  let columns = 1;
  if (containerWidth >= 500) columns = 2;
  if (containerWidth >= 900) columns = 3;
  if (containerWidth >= 1200) columns = 4;
  return columns;
}

const debounce = (func, delay, immediate) => {
  let timeoutId; // Holds the reference to the setTimeout timer

  // The debounced function returned by debounce
  return function debouncedFunction() {
    const context = this; // Save the current "this" context
    const args = arguments; // Save the arguments passed to the function

    // Define the function that will be executed after the specified delay
    const executeLater = function () {
      timeoutId = null; // Reset the timeoutId to null, indicating no active timeout

      // If "immediate" is false, invoke the original function with the saved context and arguments
      if (!immediate) {
        func.apply(context, args);
      }
    };

    // Determine whether to call the function immediately or after the delay
    const callNow = immediate && !timeoutId;

    // If there is an active timeout, clear it
    clearTimeout(timeoutId);

    // Set a new timeout with the executeLater function as the callback
    timeoutId = setTimeout(executeLater, delay);

    // If "immediate" is true and there is no active timeout, invoke the original function immediately
    if (callNow) {
      func.apply(context, args);
    }
  };
};

const getRandomSize = () => {
  // Generate random width and height between 400 and 1200
  const width = Math.floor(Math.random() * 800) + 400;
  const height = Math.floor(Math.random() * 600) + 400;
  return { width, height };
};

const dummy_photos = Array.from({ length: 100 }, (_, index) => {
  const { width, height } = getRandomSize();
  return {
    src: `https://picsum.photos/${width}/${height}?image=${index + 10}`,
    width,
    height,
    key: (index + 1).toString(),
    alt: "cannot load image",
    title: `THIS IS MY TITLE ${
      index + 1
    }. It is very big to test. Let's see hehe.`,
  };
});

const loadImages = async (imageData) => {
  const loadedImages = await Promise.all(
    imageData.map(async (img) => {
      return new Promise((resolve, reject) => {
        const dump = new Image();
        dump.src = `${process.env.REACT_APP_BUCKET_URL}${img.URL}`;
        dump.onload = () => {
          resolve({
            id: img.id,
            src: `${process.env.REACT_APP_BUCKET_URL}${img.URL}`,
            path: img.URL,
            height: dump.height,
            width: dump.width,
            key: nanoid(),
            alt: "cannot load image",
            title: img.title,
          });
        };
        dump.onerror = (err) => {
          // to let other image load
          resolve(false);
        };
      });
    })
  );
  return loadedImages;
};

const GalleryContainer = () => {
  const ctx = useContext(userContext);
  const [photos, setPhotos] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [pageNum, setPageNum] = useState({
    offset: 0,
    limit: 10,
  });
  const [loadedAll, setLoadedAll] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedImageIds, setSelectedImageIds] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [loadNext, setLoadNext] = useState(false);
  const [isDraft, setIsDraft] = useState(false);

  const fetchGallery = async () => {
    setLoadNext(true);
    let query = "";
    query += `${selectedCountry?.id}&offset=${pageNum.offset}&limit=${pageNum.limit}`;
    await axiosInstance
      .get(`/gallery/get/images?countryId=${query}&isDraft=${isDraft}`)
      .then(async (res) => {
        if (res.data?.data?.count) {
          const imagesData = res.data?.data?.rows;
          const loadedImages = (await loadImages(imagesData)).filter((obj) =>
            Boolean(obj)
          );

          setPhotos(loadedImages);
          setTotalCount(parseInt(res.data?.data?.count));
        } else {
          setTotalCount(0);
          setPhotos([]);
        }
      });
    setLoadNext(false);
  };

  useEffect(() => {
    setSelectedImageIds([]);
    fetchGallery();
    setSelectAll(false);
  }, [selectedCountry, pageNum, isDraft]);

  useEffect(() => {
    axiosInstance.get("/user/country/list").then((res) => {
      setCountries(res.data);
    });
  }, []);

  // const loadMorePhotos = debounce(() => {
  //   let currentPage = Math.floor(
  //     (Math.max(0, pageNum.offset) + pageNum.limit) / pageNum.limit
  //   );

  //   if (totalCount <= pageNum.limit) {
  //     currentPage = 1;
  //   }
  //   const totalPages = Math.ceil(totalCount / pageNum.limit);
  //   if (currentPage > totalPages) {
  //     setLoadedAll(false);
  //     return;
  //   }
  //   fetchGallery();
  // }, 1000);

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // });

  // const handleScroll = () => {
  //   let scrollY =
  //     window.scrollY ||
  //     window.pageYOffset ||
  //     document.documentElement.scrollTop;
  //   if (window.innerHeight + scrollY >= document.body.offsetHeight - 50) {
  //     loadMorePhotos();
  //   }
  // };

  const toggleSelectAll = () => {
    if (!selectAll) {
      setSelectedImageIds([]);
    } else {
      setSelectedImageIds(
        photos.map((photo) => {
          return { id: photo.id, URL: photo.path };
        })
      );
    }
    setSelectAll(!selectAll);
  };

  const handleDelete = () => {
    const confirmation = window.confirm(
      isDraft
        ? "Are you sure you want to undraft selected images?"
        : "Are you sure you want to delete selected images?"
    );
    if (confirmation) {
      axiosInstance

        .post(`/gallery/delete/images?isDraft=${isDraft}`, {
          selectedImageIds,
        })
        .then((res) => {
          if (res.status === 200) {
            setSelectedImageIds([]);
            setSelectAll(false);
            setIsDraft(false);
            fetchGallery();
          }
          setNotificationData([
            {
              msg: res.data.message,
              status: res.status,
            },
          ]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const imageRenderer = useCallback(
    ({ index, left, top, key, containerHeight, photo }) => (
      <SelectedImage
        selected={selectAll ? true : false}
        key={key}
        id={photo.id}
        margin={"2px"}
        index={index}
        photo={photo}
        setSelectedImageIds={setSelectedImageIds}
        direction={"column"}
        left={left}
        top={top}
      />
    ),
    [selectAll]
  );
  return (
    <>
      <Notification
        notificationData={notificationData}
        setNotificationData={setNotificationData}
      />
      <hr />
      <div>
        <GalleryFileUpload
          refresh={() => {
            fetchGallery();
            setLoadedAll(false);
            setSelectedImageIds([]);
            setSelectAll(false);
          }}
        />
      </div>
      <hr />
      <div style={{ display: "flex" }} className="mb-3">
        <button onClick={toggleSelectAll}>Select All</button>
        <InputGroup className="mx-3" style={{ width: "fit-content" }}>
          <DropdownButton
            variant="outline-secondary"
            title={
              selectedCountry.id !== "All" && selectedCountry !== ""
                ? selectedCountry["name"]
                : "Select Country"
            }
            id="input-group-dropdown-1"
            onSelect={(e) => {
              setSelectedCountry(JSON.parse(e));
            }}
          >
            <Dropdown.Item eventKey={JSON.stringify({ id: "All" })}>
              Select All
            </Dropdown.Item>
            {(countries || []).map((country, index) => (
              <Dropdown.Item eventKey={JSON.stringify(country)} key={index}>
                {country.name}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </InputGroup>
        {(ctx.user.role != 5 || !isDraft) && (
          <button
            className="btn btn-danger"
            onClick={handleDelete}
            disabled={!selectedImageIds.length}
          >
            {isDraft
              ? "Undraft Items "
              : ctx.user.role == 5
              ? "Draft Items "
              : "Delete Selected "}
            {selectedImageIds.length}
          </button>
        )}
      </div>
      <div>
        <Form.Check
          type="checkbox"
          label="Show Draft Images"
          checked={isDraft}
          onChange={() => {
            setIsDraft(!isDraft);
          }}
        />
      </div>

      <Pagination setPagination={setPageNum} totalRecords={totalCount} />
      {!!photos.length && (
        <Gallery
          photos={[...photos]}
          direction="column"
          columns={columns}
          renderImage={imageRenderer}
          setComponentWidth={true}
        />
      )}
      <div className="loading-msg" id="msg-loading-more">
        <SyncLoader color="#36d7b7" size={20} loading={loadNext} />
      </div>
    </>
  );
};

export default GalleryContainer;
