import "./UserSideBar.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Alert from "react-bootstrap/Alert";
import React, { useEffect, useState } from "react";
import axiosInstance from "../utility/axios";
import { Button, Modal } from "react-bootstrap";

const UserSideBar = ({
  selectedTab,
  editDetails,
  show,
  mode,
  currUser,
  handleClose,
  countries,
  roles,
  refresh,
  setNotificationData,
}) => {
  const [user, setUser] = useState({
    address: "",
    phone: "",
    alternate_phone: "",
    communityId: undefined,
    subCommunityId: undefined,
    countryId: undefined,
    country_code: "",
    email: "",
    username: "",
    role_level: undefined,
  });

  const [msg, setMsg] = useState({
    message: [],
    variant: "danger",
  });

  const [subCommunities, setSubCommunities] = useState([]);
  const [communities, setCommunities] = useState([]);
  const [showModel, setShowModel] = useState(false);

  useEffect(() => {
    if (show && !editDetails?.user) {
      setUser({
        ...user,
        countryId: currUser.countryId ? currUser.countryId : undefined,
        communityId: currUser.communityId ? currUser.communityId : undefined,
        subCommunityId: currUser.subCommunityId
          ? currUser.subCommunityId
          : undefined,
      });
    }
  }, [show]);

  useEffect(() => {
    if (editDetails?.user) setUser(editDetails?.user);
  }, [editDetails]);

  useEffect(() => {
    axiosInstance
      .get(
        `/user/hub/list?country_id=${user.countryId}&community_id=${user.communityId}`
      )
      .then((res) => {
        if (res.status === 200) {
          setSubCommunities(res.data);
        }
      });
  }, [user.communityId, user.countryId]);

  useEffect(() => {
    axiosInstance
      .get(`/user/hub/main/list?country_id=${user.countryId}`)
      .then((res) => {
        setCommunities(res.data);
      });
  }, [user.countryId]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setUser({ ...user, [id]: value });
  };

  const handleSubmit = () => {
    if (!user.id) {
      axiosInstance.post("/user/create", user).then((res) => {
        if (res.status === 200) {
          handleClose();
          refresh();
          setMsg({
            message: [],
            variant: "danger",
          });
          setUser({
            address: "",
            phone: "",
            alternate_phone: "",
            communityId: undefined,
            subCommunityId: undefined,
            countryId: undefined,
            country_code: "",
            email: "",
            username: "",
            role_level: undefined,
          });
        } else {
          if (res.data.message === "Validation error")
            setMsg({
              message: res.data.error?.details?.map((e) => e.message),
              variant: "danger",
            });
          else if (res.data.message === "DB Error")
            setMsg({ message: [...res.data.error], variant: "danger" });
          else setMsg({ message: [res.data.message], variant: "danger" });
        }
        setNotificationData([
          {
            msg: res.data.message,
            status: res.status,
          },
        ]);
      });
    } else {
      axiosInstance.patch(`/user/update/${user.id}`, user).then((res) => {
        if (res.status === 200) {
          handleClose();
          refresh();
          setMsg({
            message: [],
            variant: "danger",
          });
          setUser({
            address: "",
            phone: "",
            alternate_phone: "",
            communityId: undefined,
            subCommunityId: undefined,
            countryId: undefined,
            country_code: "",
            email: "",
            username: "",
            role_level: undefined,
          });
        } else {
          if (res.data.message === "Validation error")
            setMsg({
              message: res.data.error?.details?.map((e) => e.message),
              variant: "danger",
            });
          else if (res.data.message === "DB Error")
            setMsg({ message: [...res.data.error], variant: "danger" });
          else setMsg({ message: [res.data.message], variant: "danger" });
        }
        setNotificationData([
          {
            msg: res.data.message,
            status: res.status,
          },
        ]);
      });
    }
  };

  const DropdownSearch = React.forwardRef((props, ref) => {
    const { children, style, className, "aria-labelledby": labeledBy } = props;
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
          style={{ position: "sticky", top: "0px" }}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child?.props?.children.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  });

  return (
    <>
      <Modal
        show={showModel}
        onHide={() => {
          setShowModel(false);
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmation Required</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="alert alert-danger">
            Are you certain you wish to create/update user with email{" "}
            <mark>{user.email}</mark>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="default"
            onClick={() => {
              setShowModel(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              handleSubmit();
              setShowModel(false);
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <Offcanvas
        style={{ width: "450px" }}
        show={show}
        onHide={() => {
          handleClose();
          setUser({
            address: "",
            phone: "",
            alternate_phone: "",
            communityId: undefined,
            subCommunityId: undefined,
            countryId: undefined,
            country_code: "",
            email: "",
            username: "",
            role_level: undefined,
          });
          setMsg({
            message: [],
            variant: "danger",
          });
        }}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>User Form</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Name"
              aria-label="Username"
              aria-describedby="username"
              id="username"
              value={user.username}
              onChange={handleChange}
              disabled={mode === "preview"}
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Email"
              aria-label="Email"
              aria-describedby="email"
              id="email"
              value={user.email}
              onChange={handleChange}
              disabled={mode === "preview"}
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <Form.Control
              as="textarea"
              placeholder="Address"
              aria-label="Address"
              aria-describedby="Address"
              id="address"
              value={user.address}
              onChange={handleChange}
              disabled={mode === "preview"}
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <DropdownButton
              variant="outline-secondary"
              title={user.country_code || "Country Code"}
              id="input-group-dropdown-1"
              onSelect={(e) =>
                handleChange({ target: { id: "country_code", value: e } })
              }
              disabled={mode === "preview"}
            >
              <Dropdown.Item eventKey={"+91"}>+91</Dropdown.Item>
              <Dropdown.Item eventKey={"+31"}>+31</Dropdown.Item>
              <Dropdown.Item eventKey={"+66"}>+66</Dropdown.Item>
              <Dropdown.Item eventKey={"+880"}>+880</Dropdown.Item>
              <Dropdown.Item eventKey={"+977"}>+977</Dropdown.Item>
            </DropdownButton>
            <Form.Control
              placeholder="Phone"
              aria-label="Phone"
              aria-describedby="Phone"
              id="phone"
              value={user.phone}
              onChange={handleChange}
              disabled={mode === "preview"}
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Alternate Phone"
              aria-label="Alternate Phone"
              aria-describedby="Alternate Phone"
              id="alternate_phone"
              value={user.alternate_phone || ""}
              onChange={handleChange}
              disabled={mode === "preview"}
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <DropdownButton
              variant="success"
              title={
                user.role_level
                  ? roles.filter((role) => role.id == user.role_level)[0]?.name
                  : "Select Role"
              }
              id="input-group-dropdown-1"
              onSelect={(e) => {
                handleChange({ target: { id: "role_level", value: e } });
              }}
              disabled={mode === "preview"}
            >
              <Dropdown.Item eventKey={undefined}>Select None</Dropdown.Item>
              {roles.map((role, index) => (
                <Dropdown.Item eventKey={role.id} key={index}>
                  {role.name}
                </Dropdown.Item>
              ))}
            </DropdownButton>

            {user.role_level === "2" ? (
              ""
            ) : (
              <DropdownButton
                variant="outline-secondary"
                title={
                  user.countryId
                    ? countries.filter(
                        (country) => country.id == user.countryId
                      )[0]?.name
                    : "Select Country"
                }
                id="input-group-dropdown-1"
                onSelect={(e) => {
                  handleChange({ target: { id: "countryId", value: e } });
                }}
                disabled={mode === "preview"}
              >
                {currUser?.countryId ? (
                  ""
                ) : (
                  <>
                    <Dropdown.Item eventKey={undefined}>
                      Select None
                    </Dropdown.Item>
                    {countries.map((country, index) => (
                      <Dropdown.Item eventKey={country.id} key={index}>
                        {country.name}
                      </Dropdown.Item>
                    ))}
                  </>
                )}
              </DropdownButton>
            )}
          </InputGroup>

          {user.role_level === "2" ? (
            ""
          ) : (
            <div>
              <Alert key="info" variant="info">
                Search for Community. If community is not present please
                register it first.
              </Alert>
              <InputGroup style={{ width: "fit-content" }}>
                <DropdownButton
                  variant="outline-secondary"
                  title={
                    (user.communityId
                      ? communities.filter(
                          (community) => community.id == user.communityId
                        )[0]?.name
                      : "Select Main CSO") || "Select Main CSO"
                  }
                  id="input-group-dropdown-1"
                  onSelect={(e) => {
                    handleChange({ target: { id: "communityId", value: e } });
                  }}
                  disabled={mode === "preview"}
                >
                  {currUser?.communityId ? (
                    ""
                  ) : (
                    <>
                      <Dropdown.Item eventKey={undefined}>
                        Select None
                      </Dropdown.Item>
                      {communities.map((community, index) => (
                        <Dropdown.Item eventKey={community.id} key={index}>
                          {community.name}
                        </Dropdown.Item>
                      ))}
                    </>
                  )}
                </DropdownButton>
                {/* hiding subcommunity for admin */}
                {user.role_level == "3" ? (
                  ""
                ) : (
                  <Dropdown
                    onSelect={(e) => {
                      handleChange({
                        target: { id: "subCommunityId", value: e },
                      });
                    }}
                  >
                    <Dropdown.Toggle
                      variant="outline-secondary"
                      id="input-group-dropdown-1"
                      disabled={mode === "preview"}
                    >
                      {(user.subCommunityId
                        ? subCommunities.filter(
                            (community) => community.id == user.subCommunityId
                          )[0]?.name
                        : "Select CSO") || "Select CSO"}
                    </Dropdown.Toggle>
                    {currUser?.subCommunityId ? (
                      ""
                    ) : (
                      <Dropdown.Menu as={DropdownSearch}>
                        <Dropdown.Item eventKey={undefined}>
                          Select None
                        </Dropdown.Item>
                        {subCommunities.map((community, index) => (
                          <Dropdown.Item eventKey={community.id} key={index}>
                            {community.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    )}
                  </Dropdown>
                )}
              </InputGroup>
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              className="btn btn-primary mb-3"
              onClick={() => {
                setShowModel(true);
              }}
              disabled={mode === "preview"}
            >
              Save
            </button>
          </div>

          {msg.message.length > 0 &&
            msg.message.map((m, index) => (
              <Alert key={index} variant={msg.variant}>
                {m}
              </Alert>
            ))}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default UserSideBar;
