import React from "react";
import "./PreviewCard.css";

const PreviewCard = ({
  title,
  description,
  img,
  category,
  date,
  tags,
  author,
  showBtn,
  link,
}) => {
  return (
    <div id="card-preview">
      <div className="card shadow-sm">
        <div className="d-flex">
          <img loading="lazy" src={img} alt="News Image" />
        </div>
        <div className="card-body">
          <h4 className="panel-youth">
            <a href="" style={{ pointerEvents: "none" }}>
              {title}
            </a>
          </h4>
          {date && (
            <div className="posted-date">
              <i className="fa-solid fa-calendar-days"></i>
              <span>Posted On: {date}</span>
            </div>
          )}
          {category && (
            <div className="rural-indastr">
              Category:<span>{category}</span>
            </div>
          )}
          <div
            className="card-text ck-content"
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          ></div>
          <div className="eng-btn">
            {tags && tags.map((tag) => <h5 key={tag}>{tag}</h5>)}
          </div>
          {author && (
            <div className="Instructor">
              <div className="Jose">
                <p>
                  <span>Author:</span>
                </p>
                <p>- {author}</p>
              </div>
              <a className="btn btn-pink" style={{ pointerEvents: "none" }}>
                Read More
              </a>
            </div>
          )}
          {showBtn && (
            <a
              className="btn btn-pink"
              style={{ pointerEvents: "none", width: "100%" }}
            >
              Read More
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default PreviewCard;
