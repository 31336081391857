import React, { useEffect, useState, useRef, useContext } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Table } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import axiosInstance from "../utility/axios";
import StatusCard from "../components/StatusCard";
import Loader from "../components/Loader";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import InputGroup from "react-bootstrap/InputGroup";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import "./Analytics.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { userContext } from "./Context";

const counter_skeleton = {
  blog: { title: "Blogs", total: 0 },
  article: { title: "Articles", total: 0 },
  case_study: { title: "Case Stories", total: 0 },
  campaign: { title: "Campaigns", total: 0 },
  event: { title: "Events", total: 0 },
  webinar: { title: "Webinars", total: 0 },
  news: { title: "News", total: 0 },
  video: { title: "Videos", total: 0 },
};

const options = {
  credits: false,
  colors: ["#01BAF2", "#71BF45", "#FAA74B", "#B37CD2"],
  chart: {
    type: "pie",
    height: 500,
    width: null,
    borderRadius: "1em",
  },
  title: { text: "" },
  accessibility: {
    point: {
      valueSuffix: "%",
    },
  },
  tooltip: {
    pointFormat: "{point.name}: <b>{point.y}</b>",
  },
  legend: {
    layout: "vertical",
    align: "left",
    verticalAlign: "middle",
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: true,
        format: "{point.name}: {y}",
        crop: false,
        overflow: "allow",
        distance: 10,
      },
      showInLegend: false,
      size: 80,
      shadow: false,
    },
  },
  series: [
    {
      data: [],
    },
  ],
};

const CARD_MAP = [
  {
    id: 1,
    title: "Dashboard Users",
    type: "user",
    iclass: "fa-solid fa-users fa-3x",
  },
  {
    id: 2,
    title: "Website Users",
    type: "public_users",
    iclass: "fa-solid fa-users fa-3x",
  },
  {
    id: 3,
    title: "Gallery Images",
    type: "gallery",
    iclass: "fa-solid fa-image fa-3x",
  },
  { id: 4, title: "Videos", type: "videos", iclass: "fa-solid fa-video fa-3x" },
  {
    id: 5,
    title: "Events",
    type: "event",
    iclass: "fa-regular fa-calendar-check fa-3x",
  },
  {
    id: 6,
    title: "Webinars",
    type: "webinar",
    iclass: "fa-regular fa-calendar-days fa-3x",
  },
  {
    id: 7,
    title: "Campaigns",
    type: "campaigns",
    iclass: "fa-solid fa-bullhorn fa-3x",
  },
  {
    id: 8,
    title: "Blogs",
    type: "blog",
    iclass: "fa-brands fa-blogger-b fa-3x",
  },
  {
    id: 9,
    title: "Articles",
    type: "article",
    iclass: "fa-solid fa-newspaper fa-3x",
  },
  {
    id: 10,
    title: "Courses",
    type: "courses",
    iclass: "fa-solid fa-book fa-3x",
  },
  { id: 11, title: "Tools", type: "tools", iclass: "fa-solid fa-file fa-3x" },
  {
    id: 12,
    title: "Case Stories",
    type: "case_study",
    iclass: "fa-solid fa-newspaper fa-3x",
  },
  {
    id: 13,
    title: "Research Documents",
    type: "document",
    iclass: "fa-solid fa-file fa-3x",
  },
  {
    id: 14,
    title: "Reports",
    type: "report",
    iclass: "fa-solid fa-file fa-3x",
  },
  {
    id: 15,
    title: "Queries",
    type: "queries",
    iclass: "fa-solid fa-question fa-3x",
  },
  {
    id: 16,
    title: "Subscriptions",
    type: "newsletter",
    iclass: "fa-solid fa-envelope fa-3x",
  },
];

const firebaseDateFormatter = (inputDate) => {
  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, "0");
  const day = String(inputDate.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

const formatDate = (date) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};

const Analytics = () => {
  const { user } = useContext(userContext);
  const [loading, setLoading] = useState(false);
  const cardColors = [
    "",
    "#cac817",
    "#06a60b",
    "#b80000",
    "#0070c0",
    "#003a63",
    "#cac817",
    "#17ca7b",
    "#f16a9b",
    "#06a60b",
    "#b80000",
    "#0070c0",
    "#003a63",
    "#003a63",
    "#17ca7b",
    "#f16a9b",
    "#06a60b",
    "#17ca7b",
  ];
  const [statusCards, setStatusCards] = useState(counter_skeleton);
  const [storagePieOptions, setStoragePieOptions] = useState({
    credits: false,
    colors: [
      "#01BAF2",
      "#71BF45",
      "#FAA74B",
      "#B37CD2",
      "#FF5733",
      "#27AE60",
      "#E74C3C",
      "#9B59B6",
      "#3498DB",
    ],
    chart: {
      type: "pie",
      height: 300,
      width: 300,
      borderRadius: "1em",
    },
    title: { text: "" },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    tooltip: {
      pointFormat: "{point.name}: <b>{point.y} MB</b>",
    },
    legend: {
      labelFormat: "{name}: <br/> <b>{y} MB</b>",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
        size: 110,
        shadow: false,
      },
    },
    series: [],
  });
  const [selectedCountry, setSelectedCountry] = useState(undefined);
  const [countries, setCountries] = useState([]);
  const [totalStorage, setTotalStorage] = useState(0);
  const [countryPieOptions, setCountryPieOptions] = useState({ ...options });
  const [countryStatus, setCountryStatus] = useState({
    totalUsers: 0,
  });

  const [datePicker, setDatePicker] = useState([
    {
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [customAnalytics, setCustomAnalytics] = useState({
    graph_options_active_users: { ...options },
    graph_options_engagement_time: { ...options },
  });
  const [dimension, setDimension] = useState("country");
  const targetBox = useRef(null);
  const [usersLineOptions, setUsersLineOptions] = useState({
    credits: false,
    chart: {
      type: "line",
      backgroundColor: "white",
      height: "500px",
    },
    title: { text: "Country Wise Users" },
    xAxis: {
      type: "datetime",
      title: {
        text: "Date",
      },
      minRange: 1,
      gridLineWidth: "3px",
    },
    yAxis: {
      title: {
        text: "Number of Users",
      },
      minorGridLineWidth: 0,
    },
    plotOptions: {
      line: {
        shadow: false,
      },
    },
    series: [],
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageLocationdata, setPageLocationData] = useState([]);

  const [counts, setCounts] = useState({
    blog: 0,
    article: 0,
    case_study: 0,
    tools: 0,
    document: 0,
    report: 0,
    event: 0,
    webinar: 0,
    campaigns: 0,
    videos: 0,
    news: 0,
    gallery: 0,
    queries: 0,
    user: 0,
    courses: 0,
    newsletter: 0,
    public_users: 0,
    subCommunity: 0,
  });

  const handleCustomAnalytics = () => {
    const query = `?start_date=${firebaseDateFormatter(
      datePicker[0].startDate
    )}&end_date=${firebaseDateFormatter(
      datePicker[0].endDate
    )}&dimension=${dimension}`;
    axiosInstance
      .get(`/analytics/custom${query}`)
      .then((res) => {
        if (res.status === 200) {
          setCustomAnalytics({
            graph_options_active_users: {
              ...customAnalytics.graph_options_active_users,
              title: { text: `Average Active Users / ${dimension}` },
              xAxis: {
                type: "category",
              },
              yAxis: {
                title: {
                  text: "Number of Users",
                },
              },
              legend: {
                enabled: false,
              },
              chart: {
                type: "column",
                height: 350,
                width: null,
                borderRadius: "1em",
              },
              plotOptions: {
                column: {
                  shadow: false,
                },
              },
              series: [
                { name: "chart", colorByPoint: true, data: res.data.users },
              ],
            },
            graph_options_engagement_time: {
              ...customAnalytics.graph_options_engagement_time,
              title: { text: `Average Engagement Users / ${dimension}` },
              xAxis: {
                type: "category",
              },
              yAxis: {
                title: {
                  text: "Average Engagement Duration",
                },
              },
              legend: {
                enabled: false,
              },
              chart: {
                type: "column",
                height: 350,
                width: null,
                borderRadius: "1em",
              },
              plotOptions: {
                column: {
                  shadow: false,
                },
              },
              series: [
                {
                  name: "chart",
                  colorByPoint: true,
                  data: res.data.engagementDuration,
                },
              ],
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOverallAnalytics = () => {
    const query = `?start_date=${firebaseDateFormatter(
      datePicker[0].startDate
    )}&end_date=${firebaseDateFormatter(datePicker[0].endDate)}`;
    axiosInstance
      .get(`/analytics/overall${query}`)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setCountryPieOptions({
            ...countryPieOptions,
            chart: {
              type: "pie",
              // height: 350,
              // width: 300,
              borderRadius: "1em",
            },
            series: [
              {
                name: "users",
                colorByPoint: true,
                data: res.data.country?.map((v) => {
                  return { name: v.country, y: Number(v.activeUsers) };
                }),
              },
            ],
          });
          setCountryStatus({
            totalUsers: res.data.totalUsers,
          });
          Object.keys(res.data.active_user).forEach((country) => {
            res.data.active_user[country].sort((a, b) => a[0] - b[0]);
          });
          const seriesData = Object.entries(res.data.active_user).map(
            ([country, data]) => ({
              lineWidth: 3,
              name: country,
              data: data,
            })
          );
          setUsersLineOptions({
            ...usersLineOptions,

            series: [...seriesData],
          });
          if (res.data.totalPages <= 10) {
            let temp = [];
            for (let i = 0; i <= 9; i++) {
              temp.push(["", ""]); // Add the value to the end of the array
            }
            Object.entries(res.data.page).forEach((v, i) => {
              temp[i] = v;
            });
            setPageLocationData(temp);
          } else {
            setPageLocationData(Object.entries(res.data.page).slice(0, 10));
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // axiosInstance
    //   .get("/analytics/resources/")
    //   .then((res) => {
    //     if (res.status === 200 && res.data.db_data) {
    //       const data = res.data.db_data;
    //       let temp = Object.assign({}, counter_skeleton);
    //       console.log(temp, data);
    //       Object.keys(counter_skeleton).forEach((key) => {
    //         console.log(key, temp[key].total, "====");
    //         temp[key].total = data[key].db_data_stage.total;
    //         temp[key].graph_country = {
    //           ...options,
    //           chart: {
    //             type: "pie",
    //             height: 200,
    //             width: 300,
    //             borderRadius: "1em",
    //           },
    //           title: { text: `${counter_skeleton[key].title} / Country` },
    //           series: [
    //             {
    //               name: "country",
    //               colorByPoint: true,
    //               innerSize: "50%",
    //               data: [...data[key].db_data_country],
    //             },
    //           ],
    //         };
    //         temp[key].graph_stage = {
    //           ...options,
    //           title: { text: `${counter_skeleton[key].title} / Stage` },
    //           series: [
    //             {
    //               name: "stage",
    //               colorByPoint: true,
    //               innerSize: "50%",
    //               data: [...data[key].db_data_stage.data],
    //             },
    //           ],
    //         };
    //       });
    //       setStatusCards(temp);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    axiosInstance
      .get("/analytics/storage/")
      .then((res) => {
        if (res.status === 200) {
          const data = res.data?.storage;
          let calcTotalStorage = 0;
          setStoragePieOptions({
            ...storagePieOptions,
            series: [
              {
                name: "Registrations",
                colorByPoint: true,
                data: Object.keys(data).map((key) => {
                  calcTotalStorage += data[key];
                  return {
                    name: key,
                    y: Number((data[key] / (1024 * 1024)).toFixed(2)),
                  };
                }),
              },
            ],
          });
          setTotalStorage(calcTotalStorage);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    handleOverallAnalytics();
    handleCustomAnalytics();
  }, []);

  useEffect(() => {
    handleCustomAnalytics();
  }, [dimension]);

  useEffect(() => {
    axiosInstance.get("/user/country/list").then((res) => {
      setCountries(res.data);
    });
  }, []);

  useEffect(() => {
    let query = "";
    if (user.role < 3 && selectedCountry) {
      query += `&country_id=${selectedCountry.id}`;
    } else if (user.role >= 3) {
      query += `&country_id=${user.countryId}`;
    }
    axiosInstance.get(`/analytics/get-counts?${query}`).then((res) => {
      if (res.status === 200) {
        setCounts((prevCounts) => ({ ...prevCounts, ...res.data.counts }));
      }
    });
  }, [selectedCountry]);

  return (
    <section className="user-section" id="user-section">
      {loading && <Loader />}
      <div className="user-content">
        <h2 className="dashboard_title">OVOC HUB Platform</h2>
        <div className="filter-inputs">
          <div>
            {user.role < 3 && (
              <InputGroup className="mx-3" style={{ width: "fit-content" }}>
                <DropdownButton
                  variant="outline-secondary"
                  title={
                    selectedCountry ? selectedCountry["name"] : "Select Country"
                  }
                  id="input-group-dropdown-1"
                  onSelect={(e) => {
                    setSelectedCountry(JSON.parse(e));
                  }}
                >
                  <Dropdown.Item eventKey={undefined}>
                    Select None
                  </Dropdown.Item>
                  {countries.map((country, index) => (
                    <Dropdown.Item
                      eventKey={JSON.stringify(country)}
                      key={index}
                    >
                      {country.name}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </InputGroup>
            )}
          </div>
          <div id="date-picker">
            <OverlayTrigger
              placement="bottom"
              trigger="click"
              rootClose
              overlay={
                <Tooltip id="overlay-date" style={{}}>
                  <DateRangePicker
                    onChange={(item) => setDatePicker([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={1}
                    ranges={datePicker}
                    direction="vertical"
                    scroll={{ enabled: true }}
                  />
                  <button
                    type="button"
                    className="btn btn-success"
                    style={{
                      margin: ".5em",
                      color: "white",
                      background: "rgb(62, 207, 142)",
                      border: "none",
                    }}
                    onClick={() => {
                      handleCustomAnalytics();
                      handleOverallAnalytics();
                      document.body.click();
                      setLoading(true);
                    }}
                  >
                    Apply
                  </button>
                </Tooltip>
              }
            >
              <i
                className="bx bxs-calendar bx-md"
                ref={targetBox}
                style={{ color: "lavender", cursor: "pointer" }}
              ></i>
            </OverlayTrigger>
          </div>
        </div>

        <div className="analytics-card-container">
          {CARD_MAP.map((card) => (
            <div
              className="card"
              key={card.id}
              style={{ backgroundColor: cardColors[card.id] }}
            >
              <div className="card-content">
                <div
                  className="card-body "
                  style={{ padding: "40px 20px 10px 20px" }}
                >
                  <div className="media d-flex justify-content-between">
                    <div className="align-self-center">
                      <i style={{ color: "white" }} className={card.iclass}></i>
                    </div>
                    <div className="media-body text-right">
                      <h3>{counts[card.type]}</h3>
                      {/* <span>{card.title}</span> */}
                      <p
                        style={{
                          color: "rgb(255, 255, 255)",
                          width: "100%",
                          fontSize: "0.9rem",
                          textAlign: "end",
                        }}
                      >
                        {card.title}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="chart d-flex row">
          <div className="col-lg-7" style={{ marginBottom: "1rem" }}>
            <HighchartsReact
              id="chart-container-users"
              highcharts={Highcharts}
              options={usersLineOptions}
            />{" "}
          </div>

          <div
            className="col-lg-5"
            // style={{
            //   width: "30%",
            //   // display: "flex",
            //   // flexDirection: "column",
            //   // alignItems: "flex-start",
            //   // // padding: "25px",
            //   // width: "350px",
            // }}
          >
            <HighchartsReact
              id="chart-container"
              highcharts={Highcharts}
              options={countryPieOptions}
            />
            <div
              style={{
                transform: "translateY(-83px)",
                width: "100%",
              }}
            >
              <StatusCard
                count={countryStatus.totalUsers}
                title={"Total Unique Site Visitors"}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          <div className="dimensions">
            <div className="dimension-dates">
              <p>From: {formatDate(datePicker[0]?.startDate)}</p>

              <p>To: {formatDate(datePicker[0]?.endDate)}</p>
            </div>
            <div className="dimension-inputs">
              <p>Filter By:</p>
              <div
                className="status-card"
                onClick={() => setDimension("country")}
              >
                <div className="status-card__info">
                  <span>Country</span>
                </div>
              </div>
              <div
                className="status-card"
                onClick={() => setDimension("mobileDeviceBranding")}
              >
                <div className="status-card__info">
                  <span>Mobile device Brand</span>
                </div>
              </div>
              <div
                className="status-card"
                onClick={() => setDimension("browser")}
              >
                <div className="status-card__info">
                  <span>Browser</span>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              margin: "2em",
              gap: "2em",
            }}
          >
            <div className="chart">
              <HighchartsReact
                id="chart-container"
                highcharts={Highcharts}
                options={customAnalytics.graph_options_engagement_time}
              />
            </div>
            <div className="chart">
              <HighchartsReact
                id="chart-container"
                highcharts={Highcharts}
                options={customAnalytics.graph_options_active_users}
              />
            </div>
          </div>
        </div>

        <div className="overview-container">
          <div style={{ margin: "1em" }}>
            <Table bordered responsive>
              <thead>
                <tr>
                  <th className="table-head">PAGE NAME</th>
                  <th
                    style={{
                      backgroundColor: "#3863A0",
                      color: "white",
                    }}
                  >
                    PAGE VIEWS
                  </th>
                </tr>
              </thead>
              <tbody>
                {pageLocationdata.map((value, index) => (
                  <tr key={index} className="table_hover">
                    <td>{value[0]}</td>
                    <td>{value[1]}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="storage">
            <section id="my-cloud">
              <h4>File Storage Usage</h4>
              <footer>
                <div className="stat">
                  <div>
                    {(totalStorage / (1024 * 1024 * 1024)).toFixed(2)}GB
                  </div>
                  <div>5GB</div>
                </div>
                <div className="chart">
                  <div></div>
                  <div
                    style={{
                      width:
                        (totalStorage / (1024 * 1024 * 1024) / 5) * 100 + "%",
                    }}
                  ></div>
                </div>
              </footer>
            </section>
            <section className="details">
              <footer>
                <div className="chart">
                  <HighchartsReact
                    id="chart-container"
                    highcharts={Highcharts}
                    options={storagePieOptions}
                  />
                </div>
              </footer>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Analytics;
