import React, { createContext, useContext, useEffect, useState } from "react";
import { FILTERS } from "./constants";
import axiosInstance from "./axios";
const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [countryMap, setCountryMap] = useState({});

  useEffect(() => {
    axiosInstance.get("/user/country/list").then((res) => {
      const countries = res.data || [];
      const result = {};

      for (const key in FILTERS) {
        const regex = new RegExp(FILTERS[key], "i"); // Case-insensitive regex
        const matchingObject = countries?.find((obj) => regex.test(obj.name));
        if (matchingObject) {
          result[matchingObject.id] = key;
        }
      }
      setCountryMap(result);
    });
  }, []);

  return (
    <LanguageContext.Provider value={{ countryMap }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  return useContext(LanguageContext);
};
