import React, { useState, useEffect, useContext } from "react";
import Pagination from "./Pagination";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";
import Dropdown from "react-bootstrap/Dropdown";
import DeleteConfirmation from "./DeleteModal";
import DropdownButton from "react-bootstrap/DropdownButton";
import axiosInstance from "../utility/axios";
import { userContext } from "../pages/Context";
import NoDataPresent from "./NoDataPresent";

const generateStartDate = (daysAgo) => {
  const today = new Date();
  const startDate = new Date(today);
  startDate.setDate(today.getDate() - daysAgo);

  return startDate.toISOString();
};

const dateFilters = {
  last7days: { date: generateStartDate(7), title: "Last 7 days" },
  last30days: { date: generateStartDate(30), title: "Last 30 days" },
  last90days: { date: generateStartDate(90), title: "Last 90 days" },
};

const formatDateAndTime = (utcTimestamp) => {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const userOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: userTimezone,
  };

  return new Intl.DateTimeFormat("en-US", userOptions).format(
    new Date(utcTimestamp)
  );
};

const WebsiteUsers = ({ countries, setNotificationData, subCommunities }) => {
  const { user } = useContext(userContext);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [filters, setFilters] = useState(undefined);

  const fetchData = () => {
    let query = `?search=${search}&offset=${pagination.offset}&limit=${pagination.limit}`;
    if (user.role < 3 && selectedCountry) {
      query += `&country_id=${selectedCountry?.id}`;
    } else if (user.role >= 3) {
      query += `&country_id=${user.countryId}`;
    }
    if (filters) query += `&date_filters=${dateFilters[filters]?.date}`;
    axiosInstance.get(`/user/website/getall${query}`).then((res) => {
      if (res.status === 200) {
        setUsers(res.data.data.users);
        setTotalRecords(res.data.data.count);
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, [pagination, selectedCountry, filters]);

  useEffect(() => {
    if (deleteId) {
      setShowDeleteConfirmation(true);
    }
  }, [deleteId]);

  const deleteUser = (user) => {
    axiosInstance
      .delete(`/user/website/delete/${user.id}/${user.uid}`)
      .then((res) => {
        setNotificationData([
          {
            msg: res.data.message,
            status: res.status,
          },
        ]);
        fetchData();
      });
  };

  return (
    <>
      <DeleteConfirmation
        showModal={showDeleteConfirmation}
        hideModal={() => {
          setShowDeleteConfirmation(false);
          setDeleteId(undefined);
        }}
        confirmModal={() => {
          deleteUser(deleteId);
          setShowDeleteConfirmation(false);
        }}
        message={`Are you sure you want to delete ${deleteId?.name}?`}
      />
      <div
        className="input-group mb-4 table-filters"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div style={{ display: "flex" }}>
          <input
            type="search"
            placeholder="Search by name"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            aria-describedby="button-addon5"
            className="form-control"
            style={{ borderRadius: ".375rem 0px 0px .375rem" }}
          />
          <div className="input-group-append">
            <button
              id="button-addon5"
              type="button"
              onClick={() => fetchData()}
              className="btn btn-primary color3863A0"
              style={{
                borderRadius: "2px",
                height: "100%",
              }}
            >
              <i className="bx bx-search"></i>
            </button>
          </div>
          {user.role < 3 && (
            <InputGroup className="mx-3" style={{ width: "fit-content" }}>
              <DropdownButton
                variant="outline-secondary"
                title={
                  selectedCountry ? selectedCountry["name"] : "Select Country"
                }
                id="input-group-dropdown-1"
                onSelect={(e) => {
                  setSelectedCountry(JSON.parse(e));
                }}
              >
                <Dropdown.Item eventKey={undefined}>Select None</Dropdown.Item>
                {countries.map((country, index) => (
                  <Dropdown.Item eventKey={JSON.stringify(country)} key={index}>
                    {country.name}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </InputGroup>
          )}
          <InputGroup className="mx-3" style={{ width: "fit-content" }}>
            <DropdownButton
              variant="outline-secondary"
              title={dateFilters[filters]?.title || "Select Days"}
              id="input-group-dropdown-1"
              onSelect={(e) => {
                setFilters(e);
              }}
            >
              <Dropdown.Item eventKey={undefined}>Select None</Dropdown.Item>
              <Dropdown.Item eventKey={"last7days"}>Last 7 days</Dropdown.Item>
              <Dropdown.Item eventKey={"last30days"}>
                Last 30 days
              </Dropdown.Item>
              <Dropdown.Item eventKey={"last90days"}>
                Last 90 days
              </Dropdown.Item>
            </DropdownButton>
          </InputGroup>
        </div>
      </div>
      {users.length === 0 ? (
        <NoDataPresent />
      ) : (
        <>
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th className="table-head">#</th>
                <th className="table-head">Name</th>
                <th className="table-head">Email</th>
                <th className="table-head">Country</th>
                <th className="table-head">Organisation</th>
                <th className="table-head">Group Type</th>
                <th className="table-head">CSO</th>
                <th className="table-head">Last login</th>
                <th className="table-head">Delete</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr key={index}>
                  <td>{pagination.offset + index + 1}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>
                    {user.country || "Other - " + user.other_country_name}
                  </td>
                  <td>{user.organisation + " - " + user.organisation_name}</td>
                  <td>{user.group_type}</td>
                  <td>{user.subCommunity || user.other_CSO}</td>
                  <td>
                    {user.last_login_date &&
                      formatDateAndTime(user.last_login_date)}
                  </td>

                  <td>
                    <i
                      className="fa-solid fa-trash-can fa-1x"
                      onClick={() => setDeleteId(user)}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            setPagination={setPagination}
            totalRecords={totalRecords}
          />
        </>
      )}
    </>
  );
};

export default WebsiteUsers;
