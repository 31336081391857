import React from "react";
import { Alert } from "react-bootstrap";

export default function TrashNote() {
  return (
    <div style={{ marginBottom: "2rem" }}>
      <Alert variant="info" style={{ marginTop: "1em", paddingLeft: "1rem" }}>
        <p>Note:-</p>
        <p>
          {" "}
          All content in the trash can be restored within 7 days of deletion.
        </p>
        <p> After this period, it will be permanently deleted.</p>
      </Alert>
    </div>
  );
}
