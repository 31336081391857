import { React } from "react";
import Calendar from "./Calendar";
import News from "./News";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const OvocInAction = () => {
  return (
    <section className="user-section" id="user-section">
      <div className="user-content">
        <Tabs
          defaultActiveKey="events-webinar"
          id="about-tabs"
          className="mb-3"
        >
          <Tab eventKey="events-webinar" title="Events/Webinar">
            <Calendar />
          </Tab>
          <Tab eventKey="news" title="News & Updates">
            <News />
          </Tab>
        </Tabs>
      </div>
    </section>
  );
};

export default OvocInAction;
