import React from "react";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function AllIssues({ data, formatDate }) {
  return (
    <div>
      {data.map((item, index) => (
        <Link to={`/home/issues/${item.id}`} key={index} className="link-style">
          <Row className="issue_row pt-3 pb-2 mx-2" key={item.id}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <div className="issue-heading">
                  {!item.isClosed ? (
                    <svg
                      className="octicon octicon-issue-opened open"
                      viewBox="0 0 16 16"
                      version="1.1"
                      width="16"
                      height="16"
                      aria-hidden="true"
                    >
                      <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"></path>
                      <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0ZM1.5 8a6.5 6.5 0 1 0 13 0 6.5 6.5 0 0 0-13 0Z"></path>
                    </svg>
                  ) : (
                    <svg
                      className="octicon octicon-issue-closed closed"
                      viewBox="0 0 16 16"
                      version="1.1"
                      width="16"
                      height="16"
                      aria-hidden="true"
                    >
                      <path d="M11.28 6.78a.75.75 0 0 0-1.06-1.06L7.25 8.69 5.78 7.22a.75.75 0 0 0-1.06 1.06l2 2a.75.75 0 0 0 1.06 0l3.5-3.5Z"></path>
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0Zm-1.5 0a6.5 6.5 0 1 0-13 0 6.5 6.5 0 0 0 13 0Z"></path>
                    </svg>
                  )}

                  <h4>{item.title}</h4>
                </div>
                <div className="sub_text">created by user: {item.userName}</div>
              </div>
              <div className="sub_text">{formatDate(item.createdAt)}</div>
            </div>
          </Row>
        </Link>
      ))}
    </div>
  );
}
