import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import axiosInstance from "../utility/axios";
import { userContext } from "./Context";
import Pagination from "../components/Pagination";
import VideoPreview from "../components/VideoPreview";
import Notification from "../components/Notification";
import DeleteConfirmation from "../components/DeleteModal";
import NoDataPresent from "../components/NoDataPresent";
import TrashNote from "../components/TrashNote";

export default function TrashVideos() {
  const [allVideos, setAllVideos] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [video, setVideo] = useState({});
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
  });
  const [notificationData, setNotificationData] = useState([]);

  const { user } = useContext(userContext);

  const handleDelete = (id) => {
    axiosInstance
      .delete(`/trash/delete-video/${id}?indicator=2`)
      .then(async (response) => {
        if (response.status === 200) {
          getAllTrashVideos();
        } else {
          // TODO: alert
        }
        setNotificationData([
          {
            msg: response.data.message,
            status: response.status,
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const restoreVideo = (id) => {
    axiosInstance.get(`/trash/restore-videos/${id}?indicator=0`).then((res) => {
      if (res.status === 200) {
        setNotificationData([
          {
            msg: "Resource is being restored as draft.You can publish it live from the respective section",
            status: res.status,
          },
        ]);
      } else {
        setNotificationData([
          {
            msg: res.data.message,
            status: res.status,
          },
        ]);
      }

      getAllTrashVideos();
    });
  };

  const getAllTrashVideos = () => {
    let query = `search=&offset=${pagination.offset}&limit=${pagination.limit}`;
    if (user.role < 3 /*&& selectedCountry*/) {
      //   query += `&country_id=${selectedCountry.id}`;
    } else if (user.role >= 3) {
      query += `&country_id=${user.countryId}`;
    }
    axiosInstance
      .get(`/trash/get/trash-videos?${query}`)
      .then((res) => {
        setAllVideos(res.data);
        setTotalRecords(res.data.count || 0);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getAllTrashVideos();
  }, [pagination]);
  return (
    <div className="vedio_wrapper">
      <Notification
        notificationData={notificationData}
        setNotificationData={setNotificationData}
      />
      <DeleteConfirmation
        showModal={showModal}
        hideModal={() => {
          setShowModal(false);
        }}
        confirmModal={() => {
          handleDelete(video.id);
          setShowModal(false);
        }}
        message={`Are you sure you want to delete ${video?.title}?`}
      />
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Video Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal_body">
          <VideoPreview form={video} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <TrashNote />
      <h2>Deleted Videos</h2>
      <div className="table_wrapper">
        {totalRecords > 0 ? (
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th className="table-head">#</th>
                <th className="table-head">Title</th>
                <th className="table-head">Deleted By</th>
                <th className="table-head">View</th>
                <th className="table-head">Restore</th>
                <th className="table-head">Delete</th>
              </tr>
            </thead>
            <tbody>
              {allVideos?.videos?.map((video, index) => (
                <tr key={index}>
                  <td>{pagination.offset + index + 1}</td>
                  <td>{video?.title}</td>
                  <td>{video?.modifiedBy}</td>
                  <td>
                    <i
                      className="fa-solid fa-box-open fa-1x"
                      onClick={() => {
                        setVideo(video);
                        setShow(true);
                      }}
                    ></i>
                  </td>
                  <td>
                    <i
                      class="fa-solid fa-arrow-rotate-left fa-xl"
                      onClick={() => {
                        restoreVideo(video.id);
                      }}
                    ></i>
                  </td>
                  <td>
                    {" "}
                    <i
                      className="fa-solid fa-trash-can fa-1x"
                      onClick={() => {
                        setShowModal(true);
                        setVideo(video);
                      }}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <NoDataPresent />
        )}
      </div>
      <Pagination setPagination={setPagination} totalRecords={totalRecords} />
    </div>
  );
}
