import React from "react";
import { HashLoader } from "react-spinners";

const Loader = () => {
  return (
    <div className="loader-overlay">
      <div className="loader">
        <HashLoader color="#0072ce" loading={true} />
      </div>
    </div>
  );
};

export default Loader;
