import React, { useState, useEffect, useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";
import axiosInstance from "../utility/axios";
import InputGroup from "react-bootstrap/InputGroup";
import Alert from "react-bootstrap/Alert";
import Badge from "react-bootstrap/Badge";
import Card from "react-bootstrap/Card";
import { userContext } from "../pages/Context";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import "./EditVideos.css";
import { signOut } from "firebase/auth";
import { auth } from "../firebase-config";

const EditVideos = ({
  form,
  setForm,
  setShow,
  handleSubmit,
  handleChange,
  setShowVideos,
  setEditVideos,
  handleVideoChange,
  removeSelectedFile,
  video_category,
  fileError,
  msg,
  setMsg,
  setNotificationData,
  updateTable,
  uploadProgress,
  setUploadProgress,
  handleImageChange,
}) => {
  const { user } = useContext(userContext);

  const [comment, setComment] = useState("");
  const [countries, setCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState(
    form.countryIds || []
  );

  useEffect(() => {
    axiosInstance.get("/user/country/list").then((res) => {
      setCountries(res.data);
    });
  }, []);

  useEffect(() => {
    if (
      form.countryIds.find((country) => country.id === user.countryId) ||
      !user.countryId
    ) {
      setSelectedCountries(form.countryIds);
    } else {
      setSelectedCountries([
        ...form.countryIds,
        { id: user.countryId, name: user.country },
      ]);
    }
  }, [form.countryIds]);

  const handleComment = async (status) => {
    // status = 1 rejected, status = 3 approved, status = 2 No update [General comment]
    let isApproved = 2;
    if (status === "approve") {
      isApproved = 3;
    } else if (status === "reject") {
      isApproved = 1;
    }
    await axiosInstance
      .patch(`/videos/approve/${form.id}`, {
        status: isApproved,
        comment,
      })
      .then((res) => {
        setNotificationData([
          {
            msg: res.data.message,
            status: res.status,
          },
        ]);
        setShowVideos(false);
        setEditVideos("");
        setMsg({
          message: [],
          variant: "danger",
        });
        updateTable();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDropdownSelect = (e, selectedCountry) => {
    if (user.countryId !== selectedCountry.id) {
      setSelectedCountries((prevSelected) => {
        // Check if the country is already selected
        const isSelected = prevSelected.filter(
          (country) => country.id === selectedCountry.id
        );
        // If the country is already selected, remove it from the selected list
        if (isSelected.length) {
          return prevSelected.filter(
            (country) => country.id !== selectedCountry.id
          );
        } else {
          // If the country is not selected, add it to the selected list
          return [...prevSelected, selectedCountry];
        }
      });
    }
  };

  const uploadVideoToFirebase = (e, uploadUrl) => {
    return new Promise(async (resolve, reject) => {
      const file = e.target.files[0];
      try {
        const storage = getStorage();
        const storageRef = ref(storage, uploadUrl);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
            switch (snapshot.state) {
              case "paused":
                // console.log("Upload is paused");
                break;
              case "running":
                // console.log("Upload is running");
                break;
            }
          },
          (error) => {
            console.log("Upload error", error);
            reject(error);
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              // console.log("File available at", downloadURL);
              resolve(downloadURL); // Resolve the promise with the downloadURL
            } catch (error) {
              // console.log("Error getting download URL", error);
              reject(error);
            }
          }
        );
      } catch (error) {
        console.log("Video upload error", error);
        reject(error);
      }
      signOut(auth);
    });
  };

  useEffect(() => {
    handleChange({
      target: { name: "countryIds", value: selectedCountries },
    });
  }, [selectedCountries]);

  return (
    <div className="BlogsContainer">
      <div className="resource-top mx-4">
        <Col className="blog-heading pt-2 pb-2" xs={8}>
          <h1 className="heading">Edit Video</h1>
        </Col>
        <Col className="button-grp">
          <button className="preview-button" onClick={() => setShow(true)}>
            <img
              loading="lazy"
              src="https://cdn-icons-png.flaticon.com/512/6329/6329893.png"
              alt="Preview Icon"
              className="preview-icon"
            />
            Preview
          </button>
          <button className="save-button" onClick={handleSubmit}>
            Save
          </button>
          <button
            style={{ backgroundColor: "grey" }}
            className="save-button"
            onClick={() => {
              setShowVideos(false);
              setEditVideos("");
              if (form.videoFile) {
                removeSelectedFile("videoFile");
              }
              setMsg({
                message: [],
                variant: "danger",
              });
            }}
          >
            Back
          </button>
        </Col>
      </div>

      <div className="resource-inputs pt-4">
        <Col md={12} lg={8}>
          <div className="p-3 pt-0">
            <Row>
              <Container className="rounded editor-container p-4 m-1">
                <h2 className="sub-heading">Title</h2>

                <input
                  type="text"
                  className="form-control"
                  id="blogTitle"
                  name="title"
                  placeholder=""
                  value={form.title}
                  onChange={handleChange}
                  disabled={
                    user.role > 3 &&
                    (form.publishStage === 2 ||
                      (form.authorId && user.id !== form.authorId))
                  }
                />
              </Container>
            </Row>

            <Row>
              <div className="container m-1">
                <Form.Check
                  type="checkbox"
                  id="default-checkbox"
                  label="Do you want to share External YouTube URL?"
                  checked={form.isExternal}
                  onChange={() =>
                    handleChange({
                      target: {
                        name: "isExternal",
                        value: !form.isExternal,
                      },
                    })
                  }
                  disabled={
                    user.role > 3 &&
                    (form.publishStage === 2 ||
                      (form.authorId && user.id !== form.authorId))
                  }
                />
              </div>
            </Row>
            {!form.isExternal ? (
              <Row>
                <Container className="rounded editor-container p-4 m-1">
                  <Row>
                    <Col className="left-container text-center" xs={3}>
                      <p>Upload Video*</p>
                    </Col>
                    <Col className="text-center me-5">
                      <Row>
                        <div className="image-uploader">
                          <label htmlFor="videoFile" className="upload-label">
                            <div className="upload-icon">
                              <img
                                src="https://cdn-icons-png.flaticon.com/512/4210/4210796.png"
                                alt="Upload Icon"
                              />
                            </div>
                            <span className="upload-text">
                              {form.videoFile || form.videoFileId
                                ? typeof form.videoFile === "string"
                                  ? `${form.videoFileName}`
                                  : form.videoFile.name
                                : "Choose from files"}
                            </span>{" "}
                            {(form.videoFile || form.videoFileId) && (
                              <button
                                style={{
                                  padding: "0px .5em",
                                  marginLeft: "1em",
                                }}
                                className="remove-button"
                                type="button"
                                onClick={() => removeSelectedFile("videoFile")}
                                disabled={
                                  user.role > 3 &&
                                  (form.publishStage === 2 ||
                                    (form.authorId &&
                                      user.id !== form.authorId))
                                }
                              >
                                X
                              </button>
                            )}
                          </label>

                          <input
                            type="file"
                            id="videoFile"
                            accept="video/*"
                            multiple={false}
                            name="videoFile"
                            style={{ display: "none" }}
                            onChange={(e) =>
                              handleVideoChange(e, uploadVideoToFirebase)
                            }
                            disabled={
                              user.role > 3 &&
                              (form.publishStage === 2 ||
                                (form.authorId && user.id !== form.authorId))
                            }
                          />
                        </div>
                      </Row>
                      <Row className="progress-container">
                        <div className="progress-bar">
                          <div
                            className="progress-fill"
                            style={{ width: `${uploadProgress}%` }}
                          ></div>
                        </div>
                        <div className="progress-text">
                          {uploadProgress !== 0 &&
                            `${uploadProgress.toFixed(2)}%`}
                        </div>
                        <div>
                          {fileError && fileError?.videoFile && (
                            <Alert variant="danger">
                              {fileError?.videoFile}
                            </Alert>
                          )}
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </Row>
            ) : (
              <Row>
                <Container className="rounded editor-container p-4 m-1">
                  <h2 className="sub-heading">Enter Video link</h2>
                  <input
                    type="text"
                    className="form-control"
                    name="videoFileURL"
                    placeholder=""
                    value={form.videoFileURL}
                    onChange={handleChange}
                    disabled={
                      user.role > 3 &&
                      (form.publishStage === 2 ||
                        (form.authorId && user.id !== form.authorId))
                    }
                  />
                </Container>
              </Row>
            )}
            <Row>
              <Container className="rounded editor-container p-4 m-1">
                <Row>
                  <Col className="left-container text-center" xs={3}>
                    <p>Upload Thumbnail*</p>
                  </Col>
                  <Col className="text-center ">
                    <div className="image-uploader">
                      <label htmlFor="thumbnail" className="upload-label">
                        <div className="upload-icon">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/512/569/569800.png"
                            alt="Upload Icon"
                          />
                        </div>
                        <span className="upload-text">
                          {form.thumbnail
                            ? typeof form.thumbnail === "string"
                              ? "File selected"
                              : form.thumbnail.name
                            : "Choose from files"}
                        </span>{" "}
                        {form.thumbnail && (
                          <button
                            style={{
                              padding: "0px .5em",
                              marginLeft: "1em",
                              zIndex: "999",
                            }}
                            className="remove-button"
                            type="button"
                            onClick={() => setForm({ ...form, thumbnail: "" })}
                          >
                            X
                          </button>
                        )}
                      </label>

                      <input
                        type="file"
                        id="thumbnail"
                        accept=".jpg,.jpeg,.png"
                        multiple={false}
                        name="thumbnail"
                        style={{ display: "none" }}
                        onChange={handleImageChange}
                        disabled={
                          user.role > 3 &&
                          (form.publishStage === 2 ||
                            (form.authorId && user.id !== form.authorId))
                        }
                      />
                    </div>
                    <div>
                      {fileError && fileError?.thumbnail && (
                        <Alert variant="danger">{fileError?.thumbnail}</Alert>
                      )}
                    </div>
                  </Col>
                </Row>
              </Container>
            </Row>
            <Row>
              <Container className="rounded editor-container p-4 m-1">
                <h2 className="sub-heading">Write Description</h2>
                <textarea
                  type="text"
                  className="form-control"
                  name="description"
                  placeholder=""
                  value={form.description}
                  onChange={handleChange}
                  rows={5}
                  disabled={
                    user.role > 3 &&
                    (form.publishStage === 2 ||
                      (form.authorId && user.id !== form.authorId))
                  }
                />
              </Container>
            </Row>
          </div>
        </Col>
        <Col>
          <Container className="pt-4 editor-container">
            <div className="p-1">
              <h2 className="sub-heading">Country</h2>
              {user.role > 3 &&
              (form.publishStage === 2 ||
                (form.authorId && user.id !== form.authorId)) ? (
                <>
                  {selectedCountries.map((country) => (
                    <Badge
                      bg="dark"
                      text="light"
                      className="p-2 m-1"
                      key={country.id}
                    >
                      {country.name}
                    </Badge>
                  ))}
                </>
              ) : (
                <DropdownButton
                  variant="outline-secondary"
                  title={"Select Country"}
                  id="input-group-dropdown-1"
                >
                  <Form.Check
                    type="checkbox"
                    label="Select All"
                    style={{ margin: "0px 20px" }}
                    checked={selectedCountries.length === countries.length}
                    onChange={() => {
                      setSelectedCountries(
                        selectedCountries.length === countries.length
                          ? []
                          : countries
                      );
                    }}
                  />
                  {countries.map((country, index) => (
                    <Form.Check
                      type="checkbox"
                      label={country.name}
                      style={{ margin: "0px 20px" }}
                      checked={selectedCountries.some(
                        (selected) => selected?.id === country?.id
                      )}
                      onChange={(e) => {
                        handleDropdownSelect(e, country);
                      }}
                      key={index}
                    />
                  ))}
                </DropdownButton>
              )}
            </div>
            <div className="p-1">
              <h2 className="sub-heading">Category</h2>
              <InputGroup className="" style={{ width: "fit-content" }}>
                <DropdownButton
                  variant="outline-secondary"
                  title={form.category ? form.category : "Select Category"}
                  id="input-group-dropdown-1"
                  onSelect={(e) => {
                    handleChange({ target: { name: "category", value: e } });
                  }}
                  disabled={
                    user.role > 3 &&
                    (form.publishStage === 2 ||
                      (form.authorId && user.id !== form.authorId))
                  }
                >
                  {video_category.map((videoCategory, index) => (
                    <Dropdown.Item eventKey={videoCategory} key={index}>
                      {videoCategory}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </InputGroup>
            </div>

            <div className="p-1 ">
              <h2 className="sub-heading">Post Date</h2>

              <input
                type="date"
                className="form-control"
                id="blogDate"
                name="date"
                placeholder=""
                // min={new Date().toISOString().split("T")[0]}
                value={form.date}
                onChange={handleChange}
                disabled={
                  user.role > 3 &&
                  (form.publishStage === 2 ||
                    (form.authorId && user.id !== form.authorId))
                }
              />
            </div>

            <div>
              {msg.message.length > 0 &&
                msg.message.map((m, index) => (
                  <Alert key={index} variant={msg.variant}>
                    {m}
                  </Alert>
                ))}
            </div>

            <div
              style={{
                display: form.publishStage === 2 ? "flex" : "none",
              }}
            >
              <Card border="primary" style={{ margin: "auto" }}>
                <Card.Header>
                  {user.id === form.authorId
                    ? "Please Wait!!!"
                    : "Do you Approve this News?"}
                </Card.Header>
                <Card.Body>
                  <Card.Text as="div">
                    {user.id === form.authorId ? (
                      "News is pending for approval"
                    ) : (
                      <>
                        <textarea
                          placeholder="Comment"
                          style={{ width: "100%" }}
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        ></textarea>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "15px",
                          }}
                        >
                          <button
                            className="btn btn-success"
                            type=""
                            onClick={() => handleComment("approve")}
                          >
                            Approve
                          </button>
                          <button
                            className="btn btn-danger"
                            type=""
                            onClick={() => handleComment("reject")}
                          >
                            Reject
                          </button>
                        </div>{" "}
                      </>
                    )}
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </Container>
        </Col>
      </div>
    </div>
  );
};

export default EditVideos;
