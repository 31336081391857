import axios from "axios";

const axiosInstance = axios.create();
axiosInstance.defaults.headers = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  Expires: "0",
};

axiosInstance.interceptors.request.use((config) => {
  config.url =
    (process.env.REACT_APP_API || "http://localhost:8000") + config.url;
  config.withCredentials = true;
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      // window.location.href = "/login";
    }

    return response;
  },
  (err) => {
    try {
      if (err.code === "ERR_NETWORK") {
        throw Error("Server Down");
      } else if (err.response?.status === 401) {
        // window.location.href = "/login";
        return err.response;
      } else {
        return err.response;
      }
    } catch {
      console.log("Server Down");
      console.log(err);
      // window.location.href = "/login";
      return err;
    }
  }
);

export default axiosInstance;
