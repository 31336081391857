import { React, useState, useEffect, useContext } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./About.css";
import axiosInstance from "../utility/axios";
import Notification from "../components/Notification";
import { userContext } from "../pages/Context";
import { useLanguage } from "../utility/LanguageContext";

const titles = {
  aboutTitle: {
    en: "About OVOC Project",
    bangla: "OVOC প্রকল্প সম্পর্কে",
    nep: "OVOC परियोजना बारे",
    thai: "เกี่ยวกับโครงการ OVOC",
  },
  objectivesTitle: {
    en: "Specific Objectives",
    bangla: "নির্দিষ্ট উদ্দেশ্য",
    nep: "विशिष्ट उद्देश्यहरू",
    thai: "วัตถุประสงค์เฉพาะ",
  },
};

const Content = ({ lang }) => {
  const [notificationData, setNotificationData] = useState([]);
  const [preview, setPreview] = useState({
    OVOCProject: true,
    firstObjective: true,
    secondObjective: true,
    thirdObjective: true,
  });
  const [isSaved, setIsSaved] = useState({
    OVOCProject: true,
    firstObjective: true,
    secondObjective: true,
    thirdObjective: true,
  });
  const [about, setAbout] = useState({
    OVOCProject: {
      en: "",
      bangla: "",
      nep: "",
      thai: "",
    },
    firstObjective: {
      en: {
        title: "",
        content: "",
      },
      bangla: {
        title: "",
        content: "",
      },
      nep: {
        title: "",
        content: "",
      },
      thai: {
        title: "",
        content: "",
      },
    },
    secondObjective: {
      en: {
        title: "",
        content: "",
      },
      bangla: {
        title: "",
        content: "",
      },
      nep: {
        title: "",
        content: "",
      },
      thai: {
        title: "",
        content: "",
      },
    },
    thirdObjective: {
      en: {
        title: "",
        content: "",
      },
      bangla: {
        title: "",
        content: "",
      },
      nep: {
        title: "",
        content: "",
      },
      thai: {
        title: "",
        content: "",
      },
    },
  });

  const handleChange = (event, editor, key) => {
    if (key === "OVOCProject") {
      setAbout({
        ...about,
        [key]: { ...about[key], [lang]: editor.getData() },
      });
    } else {
      setAbout({
        ...about,
        [key]: {
          ...about[key],
          [lang]: {
            ...about[key][lang],
            content: editor.getData(),
          },
        },
      });
    }
    if (isSaved[key] === true) {
      setIsSaved({ ...isSaved, [key]: false });
    }
  };

  const handleObjectiveChange = (e, key) => {
    setAbout({
      ...about,
      [key]: {
        ...about[key],
        [lang]: {
          ...about[key][lang],
          title: e.target.value,
        },
      },
    });
    if (isSaved[key] === true) {
      setIsSaved({ ...isSaved, [key]: false });
    }
  };

  const handleSave = (key) => {
    axiosInstance
      .post(`/siteData/updateAbout`, {
        lang,
        key,
        value: about[key][lang],
      })
      .then((response) => {
        if (response.status === 200) {
          getAbout();
          setIsSaved({ ...isSaved, [key]: true });
          setPreview({ ...preview, [key]: true });
        } else {
          // TODO
        }
        setNotificationData([
          {
            msg: response.data.message,
            status: response.status,
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleObjectiveSave = (key) => {
    axiosInstance
      .post(`/siteData/updateObjectives`, {
        lang,
        key,
        title: about[key][lang].title,
        content: about[key][lang].content,
      })
      .then((response) => {
        if (response.status === 200) {
          getAbout();
          setIsSaved({ ...isSaved, [key]: true });
          setPreview({ ...preview, [key]: true });
        } else {
          // TODO
        }
        setNotificationData([
          {
            msg: response.data.message,
            status: response.status,
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAbout = () => {
    axiosInstance
      .get(`/siteData/getAbout`)
      .then((response) => {
        if (response.status === 200) {
          setAbout({ ...response.data?.response });
        } else {
          // TODO
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAbout();
  }, []);

  return (
    <>
      <Notification
        notificationData={notificationData}
        setNotificationData={setNotificationData}
      />
      <main className="about-us-dashboard">
        {lang && (
          <div className="container mb-0 pb-1">
            <div className="about-caption-top" style={{ textAlign: "justify" }}>
              <div className="objectiw">
                <div className="specific">
                  <h4>{titles.aboutTitle[lang]}</h4>
                </div>
              </div>
              <div>
                <div className="actions edit-btn mt-2">
                  <button
                    id="edit"
                    className="lx-btn"
                    onClick={() =>
                      setPreview({
                        ...preview,
                        OVOCProject: !preview.OVOCProject,
                      })
                    }
                  >
                    {preview.OVOCProject ? (
                      <i className="fa-solid fa-pen-to-square"></i>
                    ) : (
                      <i className="fa-regular fa-eye"></i>
                    )}
                    &nbsp;&nbsp;{preview.OVOCProject ? "Edit" : "Preview"}
                  </button>
                  <button
                    id="save"
                    className="lx-btn mx-3"
                    onClick={() => handleSave("OVOCProject")}
                  >
                    <i className="fas fa-save"></i>&nbsp;&nbsp;Save
                  </button>
                </div>

                {!isSaved.OVOCProject && (
                  <Alert variant="danger">
                    <p>
                      Please save before moving forward else data might erase.
                    </p>
                  </Alert>
                )}
                {!preview.OVOCProject ? (
                  <CKEditor
                    editor={Editor}
                    data={about?.OVOCProject[lang]}
                    onChange={(event, editor) => {
                      handleChange(event, editor, "OVOCProject");
                    }}
                    onReady={(editor) => {
                      editor.ui.view.toolbar.items.get(
                        11
                      ).buttonView.isVisible = false;
                      // editor.ui.view.toolbar.items.get(16).buttonView.isVisible = false;
                      editor.ui.view.toolbar.items.get(31).isVisible = false;
                      editor.ui.view.toolbar.items.get(
                        32
                      ).buttonView.isVisible = false;
                      editor.ui.view.toolbar.items.get(
                        33
                      ).buttonView.isVisible = false;
                      editor.ui.view.toolbar.items.get(
                        34
                      ).buttonView.isVisible = false;
                      editor.ui.view.toolbar.items.get(
                        35
                      ).buttonView.isVisible = false;
                    }}
                  />
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: about?.OVOCProject[lang],
                    }}
                    className="ck-content"
                  ></div>
                )}
              </div>
            </div>

            <div className="objectiw">
              <div className="specific">
                <h4>{titles.objectivesTitle[lang]}</h4>
              </div>
              <div className="d-flex flex-wrap flex-row justify-content-around">
                <div className="m-1">
                  <div className="actions edit-btn mt-2">
                    <button
                      id="edit"
                      className="lx-btn"
                      onClick={() =>
                        setPreview({
                          ...preview,
                          firstObjective: !preview.firstObjective,
                        })
                      }
                    >
                      {preview.firstObjective ? "Edit" : "Preview"}
                    </button>
                    <button
                      id="save"
                      className="lx-btn mx-3"
                      onClick={() => {
                        handleObjectiveSave("firstObjective");
                      }}
                    >
                      Save
                    </button>
                  </div>

                  <div className="card" style={{ textAlign: "justify" }}>
                    {!isSaved.firstObjective && (
                      <Alert variant="danger">
                        <p>
                          Please save before moving forward else data might
                          erase.
                        </p>
                      </Alert>
                    )}
                    {!preview.firstObjective ? (
                      <>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Enter title"
                            value={about?.firstObjective[lang]?.title}
                            onChange={(e) =>
                              handleObjectiveChange(
                                e,
                                "firstObjective",
                                "title"
                              )
                            }
                          />
                        </Form.Group>
                        <CKEditor
                          editor={Editor}
                          data={about?.firstObjective[lang].content}
                          onChange={(event, editor) => {
                            handleChange(event, editor, "firstObjective");
                          }}
                          onReady={(editor) => {
                            editor.ui.view.toolbar.items.get(
                              11
                            ).buttonView.isVisible = false;
                            // editor.ui.view.toolbar.items.get(16).buttonView.isVisible = false;
                            editor.ui.view.toolbar.items.get(
                              31
                            ).isVisible = false;
                            editor.ui.view.toolbar.items.get(
                              32
                            ).buttonView.isVisible = false;
                            editor.ui.view.toolbar.items.get(
                              33
                            ).buttonView.isVisible = false;
                            editor.ui.view.toolbar.items.get(
                              34
                            ).buttonView.isVisible = false;
                            editor.ui.view.toolbar.items.get(
                              35
                            ).buttonView.isVisible = false;
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <h4>{about?.firstObjective[lang].title}</h4>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: about?.firstObjective[lang].content,
                          }}
                          className="ck-content"
                        ></div>
                      </>
                    )}
                  </div>
                </div>
                <div className="m-1">
                  <div className="actions edit-btn mt-2">
                    <button
                      id="edit"
                      className="lx-btn"
                      onClick={() =>
                        setPreview({
                          ...preview,
                          secondObjective: !preview.secondObjective,
                        })
                      }
                    >
                      {preview.secondObjective ? "Edit" : "Preview"}
                    </button>
                    <button
                      id="save"
                      className="lx-btn mx-3"
                      onClick={() => {
                        handleObjectiveSave("secondObjective");
                      }}
                    >
                      Save
                    </button>
                  </div>
                  <div className="card" style={{ textAlign: "justify" }}>
                    {!isSaved.secondObjective && (
                      <Alert variant="danger">
                        <p>
                          Please save before moving forward else data might
                          erase.
                        </p>
                      </Alert>
                    )}
                    {!preview.secondObjective ? (
                      <>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Enter title"
                            value={about?.secondObjective[lang].title}
                            onChange={(e) =>
                              handleObjectiveChange(
                                e,
                                "secondObjective",
                                "title"
                              )
                            }
                          />
                        </Form.Group>
                        <CKEditor
                          editor={Editor}
                          data={about?.secondObjective[lang].content}
                          onChange={(event, editor) => {
                            handleChange(event, editor, "secondObjective");
                          }}
                          onReady={(editor) => {
                            editor.ui.view.toolbar.items.get(
                              11
                            ).buttonView.isVisible = false;
                            // editor.ui.view.toolbar.items.get(16).buttonView.isVisible = false;
                            editor.ui.view.toolbar.items.get(
                              31
                            ).isVisible = false;
                            editor.ui.view.toolbar.items.get(
                              32
                            ).buttonView.isVisible = false;
                            editor.ui.view.toolbar.items.get(
                              33
                            ).buttonView.isVisible = false;
                            editor.ui.view.toolbar.items.get(
                              34
                            ).buttonView.isVisible = false;
                            editor.ui.view.toolbar.items.get(
                              35
                            ).buttonView.isVisible = false;
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <h4> {about?.secondObjective[lang].title}</h4>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: about?.secondObjective[lang].content,
                          }}
                          className="ck-content"
                        ></div>
                      </>
                    )}
                  </div>
                </div>
                <div className="m-1">
                  <div className="actions edit-btn mt-2">
                    <button
                      id="edit"
                      className="lx-btn"
                      onClick={() =>
                        setPreview({
                          ...preview,
                          thirdObjective: !preview.thirdObjective,
                        })
                      }
                    >
                      {preview.thirdObjective ? "Edit" : "Preview"}
                    </button>
                    <button
                      id="save"
                      className="lx-btn mx-3"
                      onClick={() => {
                        handleObjectiveSave("thirdObjective");
                      }}
                    >
                      Save
                    </button>
                  </div>
                  <div className="card" style={{ textAlign: "justify" }}>
                    {!isSaved.thirdObjective && (
                      <Alert variant="danger">
                        <p>
                          Please save before moving forward else data might
                          erase.
                        </p>
                      </Alert>
                    )}
                    {!preview.thirdObjective ? (
                      <>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Enter title"
                            value={about?.thirdObjective[lang].title}
                            onChange={(e) =>
                              handleObjectiveChange(
                                e,
                                "thirdObjective",
                                "title"
                              )
                            }
                          />
                        </Form.Group>
                        <CKEditor
                          editor={Editor}
                          data={about?.thirdObjective[lang].content}
                          onChange={(event, editor) => {
                            handleChange(event, editor, "thirdObjective");
                          }}
                          onReady={(editor) => {
                            editor.ui.view.toolbar.items.get(
                              11
                            ).buttonView.isVisible = false;
                            // editor.ui.view.toolbar.items.get(16).buttonView.isVisible = false;
                            editor.ui.view.toolbar.items.get(
                              31
                            ).isVisible = false;
                            editor.ui.view.toolbar.items.get(
                              32
                            ).buttonView.isVisible = false;
                            editor.ui.view.toolbar.items.get(
                              33
                            ).buttonView.isVisible = false;
                            editor.ui.view.toolbar.items.get(
                              34
                            ).buttonView.isVisible = false;
                            editor.ui.view.toolbar.items.get(
                              35
                            ).buttonView.isVisible = false;
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <h4>{about?.thirdObjective[lang].title}</h4>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: about?.thirdObjective[lang].content,
                          }}
                          className="ck-content"
                        ></div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4"></div>
          </div>
        )}
      </main>
    </>
  );
};

const About = () => {
  const { user } = useContext(userContext);
  const { countryMap } = useLanguage();
  const [lang, setLang] = useState(undefined);
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    axiosInstance.get("/user/country/list").then((res) => {
      setCountries(res.data);
    });
  }, []);

  useEffect(() => {
    setLang(user.role < 3 ? "en" : countryMap[user?.countryId]);
  }, [user, countryMap]);

  return (
    <section className="user-section" id="user-section">
      <div className="user-content">
        <Tabs
          defaultActiveKey={user.role < 3 ? "en" : countryMap[user.countryId]}
          id="about-tabs"
          className="mb-3"
          onSelect={(k) => setLang(k)}
        >
          {countries?.map((country, index) => {
            if (user.role < 3 || user.countryId === country.id) {
              return (
                <Tab eventKey={countryMap[country.id]} title={country.name}>
                  <Content lang={lang} />
                </Tab>
              );
            }
          })}
        </Tabs>
      </div>
    </section>
  );
};

export default About;
