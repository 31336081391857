import "./EventSideBar.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Alert from "react-bootstrap/Alert";
import React, { useEffect, useState, useContext } from "react";
import { userContext } from "../pages/Context";
import axiosInstance from "../utility/axios";
import Compressor from "compressorjs";
import Notification from "../components/Notification";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import moment from "moment";
import Card from "react-bootstrap/Card";

const categories = [
  "Knowledge Session",
  "Workshop",
  "Celebration",
  "General Meetup",
];

const EventSideBar = ({ details, show, handleClose, refresh, countries }) => {
  const { user } = useContext(userContext);
  const [event, setEvent] = useState({
    title: "",
    category: undefined,
    start: "",
    end: "",
    bannerImage: "",
    bannerImageId: "",
    description: "",
    isOffline: false,
    place: "",
    website: "",
    phone: "",
    email: "",
    host1Name: "",
    host1Image: "",
    host1Role: "",
    host2Name: "",
    host2Image: "",
    host2Role: "",
    host3Name: "",
    host3Image: "",
    host3Role: "",
    host4Name: "",
    host4Image: "",
    host4Role: "",
  });

  const [msg, setMsg] = useState({
    message: [],
    variant: "danger",
  });

  const [fileError, setFileError] = useState("");
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (Object.keys(details).length) {
      if (details?.event) setEvent(details?.event);

      if (details?.countries) setSelectedCountries(details?.countries);
    } else {
      if (user.countryId)
        setSelectedCountries([{ id: user.countryId, name: user.country }]);
      setEvent({
        title: "",
        category: undefined,
        start: "",
        end: "",
        bannerImage: "",
        bannerImageId: "",
        description: "",
        isOffline: false,
        place: "",
        website: "",
        phone: "",
        email: "",
        host1Name: "",
        host1Image: "",
        host1Role: "",
        host2Name: "",
        host2Image: "",
        host2Role: "",
        host3Name: "",
        host3Image: "",
        host3Role: "",
        host4Name: "",
        host4Image: "",
        host4Role: "",
      });
    }
  }, [details]);

  const handleChange = (e) => {
    if (e.target.id === "isOffline") {
      setEvent({ ...event, [e.target.id]: e.target.checked });
    } else {
      setEvent({ ...event, [e.target.id]: e.target.value });
    }
  };

  const handleContentChange = (e, editor) => {
    setEvent((prev) => ({ ...prev, description: editor.getData() }));
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file && file.type.match("image.*") && file.size <= 5000000) {
      setEvent({ ...event, [e.target.name]: file });
      setFileError({ ...fileError, [e.target.name]: "" });
      try {
        const compressedBlob = await new Promise((resolve, reject) => {
          new Compressor(file, {
            quality: 0.6,
            success(result) {
              resolve(result);
            },
            error(err) {
              reject(err);
            },
          });
        });

        const compressedFile = new File([compressedBlob], file.name, {
          type: file.type,
        });
        setForm({ ...form, [e.target.name]: compressedFile });
      } catch (error) {
        console.log(error.message);
      }
    } else {
      setFileError({
        ...fileError,
        [e.target.name]: "File can have max size of 5MB",
      });
    }
  };

  const removeSelectedFile = (name) => {
    const fileInput = document.getElementById(name);
    fileInput.value = "";

    setEvent({ ...event, [name]: "" });
    setFileError({ ...fileError, [name]: "" });
  };

  const handleDropdownSelect = (e, selectedCountry) => {
    if (user.countryId !== selectedCountry.id) {
      setSelectedCountries((prevSelected) => {
        // Check if the country is already selected
        const isSelected = prevSelected.filter(
          (country) => country.id === selectedCountry.id
        );
        // If the country is already selected, remove it from the selected list
        if (isSelected.length) {
          return prevSelected.filter(
            (country) => country.id !== selectedCountry.id
          );
        } else {
          // If the country is not selected, add it to the selected list
          return [...prevSelected, selectedCountry];
        }
      });
    }
  };

  const handleSubmit = () => {
    const { publishStage, authorId, ...form } = event;
    const formData = {
      ...form,
      start: moment(form.start).utc().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      end: moment(form.end).utc().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      countryIds: JSON.stringify(
        selectedCountries.map((country) => country.id)
      ),
    };

    if (!event.id) {
      axiosInstance
        .post("/event/create", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            handleClose();
            refresh();
          } else {
            if (res.data.message === "Validation error")
              setMsg({
                message: res.data.error?.details?.map((e) => e.message),
                variant: "danger",
              });
            else if (res.data.message === "DB Error")
              setMsg({ message: [...res.data.error], variant: "danger" });
            else setMsg({ message: [res.data.message], variant: "danger" });
          }
          setNotificationData([
            {
              msg: res.data.message,
              status: res.status,
            },
          ]);
        });
    } else {
      axiosInstance
        .patch(`/event/edit/${event.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setMsg({
              message: [],
              variant: "danger",
            });
            handleClose();
            refresh();
          } else {
            if (res.data.message === "Validation error")
              setMsg({
                message: res.data.error?.details?.map((e) => e.message),
                variant: "danger",
              });
            else if (res.data.message === "DB Error")
              setMsg({ message: [...res.data.error], variant: "danger" });
            else setMsg({ message: [res.data.message], variant: "danger" });
          }
          setNotificationData([
            {
              msg: res.data.message,
              status: res.status,
            },
          ]);
        });
    }
  };

  const handleComment = async (status) => {
    // status = 1 rejected, status = 3 approved
    let isApproved = undefined;
    if (status === "approve") {
      isApproved = 3;
    } else if (status === "reject") {
      isApproved = 1;
    }
    await axiosInstance
      .patch(`/event/approve/${event.id}`, {
        status: isApproved,
        comment,
      })
      .then((res) => {
        setNotificationData([
          {
            msg: res.data.message,
            status: res.status,
          },
        ]);
      })
      .catch((err) => {
        setNotificationData([
          {
            msg: res.data.message,
            status: res.status,
          },
        ]);
      });
    setComment("");
    handleClose();
    refresh();
  };

  return (
    <>
      <Notification
        notificationData={notificationData}
        setNotificationData={setNotificationData}
      />
      <Offcanvas
        show={show}
        onHide={() => {
          handleClose();
          setEvent({
            title: "",
            category: undefined,
            start: "",
            end: "",
            bannerImage: "",
            bannerImageId: "",
            description: "",
            isOffline: false,
            place: "",
          });
          setMsg({
            message: [],
            variant: "danger",
          });
          setSelectedCountries([]);
        }}
        placement="end"
        style={{ width: window.innerWidth > 950 ? "55%" : "80%" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Calendar Form</Offcanvas.Title>
        </Offcanvas.Header>
        <div
          style={{
            position: "absolute",
            zIndex: "100",
            height: "100%",
            width: "100%",
            display: event.publishStage === 2 ? "flex" : "none",
          }}
        >
          <Card border="primary" style={{ width: "18rem", margin: "auto" }}>
            <Card.Header>
              {user.id === event.authorId
                ? "Please Wait!!!"
                : "Do you Approve this Event?"}
            </Card.Header>
            <Card.Body>
              <Card.Text>
                {user.id === event.authorId ? (
                  "Event is pending for approval"
                ) : (
                  <>
                    <textarea
                      placeholder="Comment"
                      style={{ width: "100%" }}
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    ></textarea>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "15px",
                      }}
                    >
                      <button
                        className="btn btn-success"
                        type=""
                        onClick={() => handleComment("approve")}
                      >
                        Approve
                      </button>
                      <button
                        className="btn btn-danger"
                        type=""
                        onClick={() => handleComment("reject")}
                      >
                        Reject
                      </button>
                    </div>{" "}
                  </>
                )}
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <Offcanvas.Body
          style={{ filter: event.publishStage === 2 ? "blur(5px)" : "" }}
        >
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="title"
              aria-label="Title"
              aria-describedby="Title"
              id="title"
              value={event.title}
              onChange={handleChange}
              disabled={
                user.role > 3 &&
                (event.publishStage === 2 ||
                  (event.authorId && user.id !== event.authorId))
              }
            />
          </InputGroup>

          <InputGroup
            className="mb-3"
            style={{ justifyContent: "space-between" }}
          >
            <div>
              <label htmlFor="start">Start</label>
              <input
                type="datetime-local"
                name="start-date"
                value={
                  event.start &&
                  moment(event.start).local().format("YYYY-MM-DDTHH:mm")
                }
                id="start"
                // min={moment().local().format("YYYY-MM-DDTHH:mm")}
                onChange={handleChange}
                disabled={
                  user.role > 3 &&
                  (event.publishStage === 2 ||
                    (event.authorId && user.id !== event.authorId))
                }
                style={{
                  height: "2.3rem",
                  fontSize: "1em",
                }}
              />
            </div>
            <div>
              <label htmlFor="end">End </label>
              <input
                className=""
                type="datetime-local"
                name="end-date"
                value={
                  event.end &&
                  moment(event.end).local().format("YYYY-MM-DDTHH:mm")
                }
                id="end"
                min={event.start}
                onChange={handleChange}
                disabled={
                  user.role > 3 &&
                  (event.publishStage === 2 ||
                    (event.authorId && user.id !== event.authorId))
                }
                style={{
                  height: "2.3rem",
                  fontSize: "1em",
                }}
              />
            </div>
          </InputGroup>

          <InputGroup className="mb-3">
            <DropdownButton
              variant="outline-secondary"
              title={
                event.calendar_type
                  ? _.capitalize(event.calendar_type)
                  : "Calendar Type"
              }
              id="input-group-dropdown-1"
              onSelect={(e) => {
                handleChange({ target: { id: "calendar_type", value: e } });
              }}
              disabled={
                user.role > 3 &&
                (event.publishStage === 2 ||
                  (event.authorId && user.id !== event.authorId))
              }
            >
              <Dropdown.Item eventKey={"event"}>Event</Dropdown.Item>
              <Dropdown.Item eventKey={"webinar"}>Webinar</Dropdown.Item>
            </DropdownButton>
            <DropdownButton
              variant="outline-secondary"
              title={event.category || "Category"}
              id="input-group-dropdown-1"
              onSelect={(e) =>
                handleChange({ target: { id: "category", value: e } })
              }
              disabled={
                user.role > 3 &&
                (event.publishStage === 2 ||
                  (event.authorId && user.id !== event.authorId))
              }
            >
              {categories.map((e, i) => (
                <Dropdown.Item eventKey={e} key={i}>
                  {e}
                </Dropdown.Item>
              ))}
            </DropdownButton>
            <DropdownButton
              variant="outline-secondary"
              title={"Select Country"}
              id="input-group-dropdown-1"
              disabled={
                user.role > 3 &&
                (event.publishStage === 2 ||
                  (event.authorId && user.id !== event.authorId))
              }
            >
              <Form.Check
                type="checkbox"
                label="Select All"
                style={{ margin: "0px 20px" }}
                checked={selectedCountries.length === countries.length}
                onChange={() => {
                  setSelectedCountries(
                    selectedCountries.length === countries.length
                      ? []
                      : countries
                  );
                }}
              />
              {countries.map((country, index) => (
                <Form.Check
                  type="checkbox"
                  label={country.name}
                  style={{ margin: "0px 20px" }}
                  checked={selectedCountries.some(
                    (selected) => selected?.id === country?.id
                  )}
                  onChange={(e) => {
                    handleDropdownSelect(e, country);
                  }}
                  key={index}
                />
              ))}
            </DropdownButton>
          </InputGroup>

          <InputGroup className="mb-3">
            <label htmlFor="bannerImage" style={{ width: "100%" }}>
              Banner Image{" "}
            </label>{" "}
            <br />
            <div>
              <div style={{ display: "flex" }}>
                {event.bannerImage && (
                  <button
                    style={{
                      padding: "0px .5em",
                      marginRight: ".5em",
                    }}
                    className="remove-button"
                    type="button"
                    onClick={() => removeSelectedFile("bannerImage")}
                  >
                    X
                  </button>
                )}
                <input
                  type="file"
                  name="bannerImage"
                  id="bannerImage"
                  onChange={handleImageChange}
                  accept="image/*"
                  disabled={
                    user.role > 3 &&
                    (event.publishStage === 2 ||
                      (event.authorId && user.id !== event.authorId))
                  }
                />
              </div>
              <div
                style={{
                  minHeight: "7em",
                  maxWidth: "13em",
                  backgroundColor: "grey",
                }}
              >
                <img
                  loading="lazy"
                  src={
                    typeof event.bannerImage === "object"
                      ? URL.createObjectURL(event.bannerImage)
                      : event.bannerImage
                  }
                  alt=""
                  style={{ width: "100%", objectFit: "contain" }}
                />
              </div>
              {!!fileError.bannerImage && (
                <Alert variant={"danger"}>{fileError.bannerImage}</Alert>
              )}
            </div>
          </InputGroup>

          <InputGroup className="mb-3 editor-container">
            <label htmlFor="description">Description </label>
            <CKEditor
              id="description"
              editor={Editor}
              data={event.description || ""}
              onChange={handleContentChange}
              onReady={(editor) => {
                editor.ui.view.toolbar.items.get(
                  11
                ).buttonView.isVisible = false;
                // editor.ui.view.toolbar.items.get(16).buttonView.isVisible = false;
                editor.ui.view.toolbar.items.get(31).isVisible = false;
                editor.ui.view.toolbar.items.get(
                  32
                ).buttonView.isVisible = false;
                editor.ui.view.toolbar.items.get(
                  33
                ).buttonView.isVisible = false;
                editor.ui.view.toolbar.items.get(
                  34
                ).buttonView.isVisible = false;
                editor.ui.view.toolbar.items.get(
                  35
                ).buttonView.isVisible = false;
              }}
              onBlur={(event, editor) => {}}
              onFocus={(event, editor) => {}}
              disabled={
                user.role > 3 &&
                (event.publishStage === 2 ||
                  (event.authorId && user.id !== event.authorId))
              }
            />
          </InputGroup>

          {event.calendar_type === "event" && (
            <InputGroup className="mb-3">
              <Form.Check
                type="checkbox"
                id={"isOffline"}
                label="Is Event Offline"
                checked={event.isOffline}
                value={event.isOffline}
                onChange={handleChange}
                disabled={
                  user.role > 3 &&
                  (event.publishStage === 2 ||
                    (event.authorId && user.id !== event.authorId))
                }
              />
            </InputGroup>
          )}

          <InputGroup className="mb-3">
            <label htmlFor="place" style={{ width: "100%" }}>
              {event.calendar_type === "webinar"
                ? "Registration Link"
                : event.isOffline
                ? "Place"
                : "Registration Link"}
            </label>
            <Form.Control
              placeholder={
                event.calendar_type === "webinar"
                  ? "Registration Link"
                  : event.isOffline
                  ? "Place"
                  : "Registration Link"
              }
              aria-label="Place"
              aria-describedby="Place"
              id="place"
              value={event.place}
              onChange={handleChange}
              disabled={
                user.role > 3 &&
                (event.publishStage === 2 ||
                  (event.authorId && user.id !== event.authorId))
              }
            />
          </InputGroup>

          <div id="preview">
            <div className="right-event-detail">
              <h4>Detail Event</h4>
              <div className="event-date-r">
                <div>
                  <img
                    loading="lazy"
                    src="/img/new-calender.png"
                    alt="Event Calendar"
                  />
                </div>
                <div>
                  <p>
                    Start Date & Time
                    <span>
                      {event.start &&
                        moment(event.start).format("MMMM Do YYYY, h:mm A")}
                    </span>
                  </p>
                </div>
              </div>
              <div className="event-date-r">
                <div>
                  <img
                    loading="lazy"
                    src="/img/new-calender.png"
                    alt="Event Calendar"
                  />
                </div>
                <div>
                  <p>
                    End Date & Time
                    <span>
                      {event.end &&
                        moment(event.end).format("MMMM Do YYYY, h:mm A")}
                    </span>
                  </p>
                </div>
              </div>
              <div className="event-date-r">
                <div>
                  <img
                    loading="lazy"
                    src="/img/location.png"
                    alt="Event Venue"
                  />
                </div>
                <div>
                  {event.isOffline ? (
                    <>
                      <p>
                        Event Venue:<span>{event.place}</span>
                      </p>
                    </>
                  ) : (
                    <>
                      <p>
                        Event Registration Link:{" "}
                        <span>
                          {" "}
                          <a
                            href={event.place}
                            style={{ pointerEvents: "none" }}
                          >
                            {event.place}
                          </a>
                        </span>
                      </p>
                    </>
                  )}
                </div>
              </div>
              <div className="may-dum">
                <div className="organizer-event">
                  <h4>Organizer</h4>
                  <p>
                    <span>Phone:</span>{" "}
                    <input
                      type="text"
                      id="phone"
                      value={event.phone}
                      onChange={handleChange}
                      placeholder="contact"
                      disabled={
                        user.role > 3 &&
                        (event.publishStage === 2 ||
                          (event.authorId && user.id !== event.authorId))
                      }
                    />
                  </p>
                  <p>
                    <span>Email:</span>{" "}
                    <input
                      type="text"
                      id="email"
                      placeholder="Email"
                      value={event.email}
                      onChange={handleChange}
                      disabled={
                        user.role > 3 &&
                        (event.publishStage === 2 ||
                          (event.authorId && user.id !== event.authorId))
                      }
                    />
                  </p>
                  <p>
                    <span>Website:</span>{" "}
                    <input
                      type="text"
                      id="website"
                      placeholder="Website"
                      value={event.website}
                      onChange={handleChange}
                      disabled={
                        user.role > 3 &&
                        (event.publishStage === 2 ||
                          (event.authorId && user.id !== event.authorId))
                      }
                    />
                  </p>
                </div>
              </div>
              <div className="jesus-hoiland">
                <h4>Event Host</h4>
                <div className="event-date-r" id="host-speak">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {event.host1Image && (
                      <button
                        style={{
                          padding: "0px .5em",
                          marginRight: ".5em",
                        }}
                        className="remove-button"
                        type="button"
                        onClick={() => removeSelectedFile("host1Image")}
                      >
                        X
                      </button>
                    )}
                    <input
                      type="file"
                      name="host1Image"
                      id="host1Image"
                      onChange={handleImageChange}
                      disabled={
                        user.role > 3 &&
                        (event.publishStage === 2 ||
                          (event.authorId && user.id !== event.authorId))
                      }
                    />

                    <img
                      loading="lazy"
                      className="host-event"
                      src={
                        typeof event.host1Image === "string" &&
                        event.host1Image?.length
                          ? process.env.REACT_APP_BUCKET_URL + event.host1Image
                          : typeof event.host1Image === "object" &&
                            event.host1Image !== null
                          ? URL.createObjectURL(event.host1Image)
                          : "https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png"
                      }
                      alt="Event Host"
                      style={{
                        minWidth: "50px",
                        maxWidth: "50px",
                        aspectRatio: 1,
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div>
                    <p>
                      <input
                        type="text"
                        id="host1Name"
                        value={event.host1Name}
                        placeholder="name"
                        onChange={handleChange}
                        disabled={
                          user.role > 3 &&
                          (event.publishStage === 2 ||
                            (event.authorId && user.id !== event.authorId))
                        }
                      />{" "}
                    </p>
                    {/* <span>Host and Speaker</span> */}
                    <p>
                      <input
                        type="text"
                        id="host1Role"
                        value={event.host1Role}
                        onChange={handleChange}
                        placeholder="role"
                        disabled={
                          user.role > 3 &&
                          (event.publishStage === 2 ||
                            (event.authorId && user.id !== event.authorId))
                        }
                      />
                    </p>
                  </div>
                </div>
                <div className="event-date-r" id="host-speak">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {event.host2Image && (
                      <button
                        style={{
                          padding: "0px .5em",
                          marginRight: ".5em",
                        }}
                        className="remove-button"
                        type="button"
                        onClick={() => removeSelectedFile("host2Image")}
                      >
                        X
                      </button>
                    )}
                    <input
                      type="file"
                      name="host2Image"
                      id="host2Image"
                      onChange={handleImageChange}
                      disabled={
                        user.role > 3 &&
                        (event.publishStage === 2 ||
                          (event.authorId && user.id !== event.authorId))
                      }
                    />
                    <img
                      loading="lazy"
                      className="host-event"
                      src={
                        typeof event.host2Image === "string" &&
                        event.host2Image?.length
                          ? process.env.REACT_APP_BUCKET_URL + event.host2Image
                          : typeof event.host2Image === "object" &&
                            event.host2Image !== null
                          ? URL.createObjectURL(event.host2Image)
                          : "https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png"
                      }
                      alt="Event Host"
                      style={{
                        minWidth: "50px",
                        maxWidth: "50px",
                        aspectRatio: 1,
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div>
                    <p>
                      <input
                        type="text"
                        id="host2Name"
                        value={event.host2Name}
                        placeholder="name"
                        onChange={handleChange}
                        disabled={
                          user.role > 3 &&
                          (event.publishStage === 2 ||
                            (event.authorId && user.id !== event.authorId))
                        }
                      />{" "}
                    </p>
                    {/* <span>Host and Speaker</span> */}
                    <p>
                      <input
                        type="text"
                        id="host2Role"
                        value={event.host2Role}
                        onChange={handleChange}
                        placeholder="role"
                        disabled={
                          user.role > 3 &&
                          (event.publishStage === 2 ||
                            (event.authorId && user.id !== event.authorId))
                        }
                      />
                    </p>
                  </div>
                </div>
                <div className="event-date-r" id="host-speak">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {event.host3Image && (
                      <button
                        style={{
                          padding: "0px .5em",
                          marginRight: ".5em",
                        }}
                        className="remove-button"
                        type="button"
                        onClick={() => removeSelectedFile("host3Image")}
                      >
                        X
                      </button>
                    )}
                    <input
                      type="file"
                      name="host3Image"
                      id="host3Image"
                      onChange={handleImageChange}
                      disabled={
                        user.role > 3 &&
                        (event.publishStage === 2 ||
                          (event.authorId && user.id !== event.authorId))
                      }
                    />
                    <img
                      loading="lazy"
                      className="host-event"
                      src={
                        typeof event.host3Image === "string" &&
                        event.host3Image?.length
                          ? process.env.REACT_APP_BUCKET_URL + event.host3Image
                          : typeof event.host3Image === "object" &&
                            event.host3Image !== null
                          ? URL.createObjectURL(event.host3Image)
                          : "https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png"
                      }
                      alt="Event Host"
                      style={{
                        minWidth: "50px",
                        maxWidth: "50px",
                        aspectRatio: 1,
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div>
                    <p>
                      <input
                        type="text"
                        id="host3Name"
                        value={event.host3Name}
                        placeholder="name"
                        onChange={handleChange}
                        disabled={
                          user.role > 3 &&
                          (event.publishStage === 2 ||
                            (event.authorId && user.id !== event.authorId))
                        }
                      />{" "}
                    </p>
                    {/* <span>Host and Speaker</span> */}
                    <p>
                      <input
                        type="text"
                        id="host3Role"
                        value={event.host3Role}
                        onChange={handleChange}
                        placeholder="role"
                        disabled={
                          user.role > 3 &&
                          (event.publishStage === 2 ||
                            (event.authorId && user.id !== event.authorId))
                        }
                      />
                    </p>
                  </div>
                </div>
                <div className="event-date-r" id="host-speak">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {event.host4Image && (
                      <button
                        style={{
                          padding: "0px .5em",
                          marginRight: ".5em",
                        }}
                        className="remove-button"
                        type="button"
                        onClick={() => removeSelectedFile("host4Image")}
                      >
                        X
                      </button>
                    )}
                    <input
                      type="file"
                      name="host4Image"
                      id="host4Image"
                      onChange={handleImageChange}
                      disabled={
                        user.role > 3 &&
                        (event.publishStage === 2 ||
                          (event.authorId && user.id !== event.authorId))
                      }
                    />
                    <img
                      loading="lazy"
                      className="host-event"
                      src={
                        typeof event.host4Image === "string" &&
                        event.host4Image?.length
                          ? process.env.REACT_APP_BUCKET_URL + event.host4Image
                          : typeof event.host4Image === "object" &&
                            event.host4Image !== null
                          ? URL.createObjectURL(event.host4Image)
                          : "https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png"
                      }
                      alt="Event Host"
                      style={{
                        minWidth: "50px",
                        maxWidth: "50px",
                        aspectRatio: 1,
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div>
                    <p>
                      <input
                        type="text"
                        id="host4Name"
                        value={event.host4Name}
                        placeholder="name"
                        onChange={handleChange}
                        disabled={
                          user.role > 3 &&
                          (event.publishStage === 2 ||
                            (event.authorId && user.id !== event.authorId))
                        }
                      />{" "}
                    </p>
                    {/* <span>Host and Speaker</span> */}
                    <p>
                      <input
                        type="text"
                        id="host4Role"
                        value={event.host4Role}
                        onChange={handleChange}
                        placeholder="role"
                        disabled={
                          user.role > 3 &&
                          (event.publishStage === 2 ||
                            (event.authorId && user.id !== event.authorId))
                        }
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button className="btn btn-primary mb-3" onClick={handleSubmit}>
              Save
            </button>
          </div>

          {msg.message.length > 0 &&
            msg.message.map((m, index) => (
              <Alert key={index} variant={msg.variant}>
                {m}
              </Alert>
            ))}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default EventSideBar;
