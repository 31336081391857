import React, { useState, useEffect, useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";
import axiosInstance from "../utility/axios";
import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup";
import Alert from "react-bootstrap/Alert";
import Badge from "react-bootstrap/Badge";
import { userContext } from "../pages/Context";

const EditCourse = ({
  form,
  setShow,
  handleSubmit,
  handleChange,
  handleContentChange,
  handleImageChange,
  setshowCourse,
  setEditCourse,
  removeSelectedFile,
  course_category,
  msg,
  setMsg,
  setNotificationData,
  updateTable,
  setFileError,
  fileError,
}) => {
  const { user } = useContext(userContext);
  const [comment, setComment] = useState("");
  const [countries, setCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState(
    form.countryIds || []
  );
  useEffect(() => {
    axiosInstance.get("/user/country/list").then((res) => {
      setCountries(res.data);
    });
  }, []);

  useEffect(() => {
    if (
      form.countryIds.find((country) => country.id === user.countryId) ||
      !user.countryId
    ) {
      setSelectedCountries(form.countryIds);
    } else {
      setSelectedCountries([
        ...form.countryIds,
        { id: user.countryId, name: user.country },
      ]);
    }
  }, [form.countryIds]);

  const handleComment = async (status) => {
    // status = 1 rejected, status = 3 approved, status = 2 No update [General comment]
    let isApproved = 2;
    if (status === "approve") {
      isApproved = 3;
    } else if (status === "reject") {
      isApproved = 1;
    }
    await axiosInstance
      .patch(`/course/approve/${form.id}`, {
        status: isApproved,
        comment,
      })
      .then((res) => {
        setNotificationData([
          {
            msg: res.data.message,
            status: res.status,
          },
        ]);
        setshowCourse(false);
        setEditCourse("");
        setMsg({
          message: [],
          variant: "danger",
        });
        updateTable();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDropdownSelect = (e, selectedCountry) => {
    if (user.countryId !== selectedCountry.id) {
      setSelectedCountries((prevSelected) => {
        // Check if the country is already selected
        const isSelected = prevSelected.filter(
          (country) => country.id === selectedCountry.id
        );
        // If the country is already selected, remove it from the selected list
        if (isSelected.length) {
          return prevSelected.filter(
            (country) => country.id !== selectedCountry.id
          );
        } else {
          // If the country is not selected, add it to the selected list
          return [...prevSelected, selectedCountry];
        }
      });
    }
  };

  useEffect(() => {
    handleChange({
      target: { name: "countryIds", value: selectedCountries },
    });
  }, [selectedCountries]);

  return (
    <div className="BlogsContainer">
      <div className="resource-top mx-4">
        <Col className="blog-heading pt-2 pb-2" xs={8}>
          <h1 className="heading">Edit Course</h1>
        </Col>
        <Col className="button-grp">
          <button className="preview-button" onClick={() => setShow(true)}>
            <img
              loading="lazy"
              src="https://cdn-icons-png.flaticon.com/512/6329/6329893.png"
              alt="Preview Icon"
              className="preview-icon"
            />
            Preview
          </button>
          <button className="save-button" onClick={handleSubmit}>
            Save
          </button>
          <button
            style={{ backgroundColor: "grey" }}
            className="save-button"
            onClick={() => {
              setshowCourse(false);
              setEditCourse("");
              setMsg({
                message: [],
                variant: "danger",
              });
            }}
          >
            Back
          </button>
        </Col>
      </div>
      <div className="resource-inputs pt-4">
        <Col md={12} lg={8}>
          <div className="p-3 pt-0">
            <Row>
              <Container className="rounded editor-container p-4 m-1">
                <h2 className="sub-heading">Title</h2>

                <input
                  type="text"
                  className="form-control"
                  id="blogTitle"
                  name="title"
                  placeholder=""
                  value={form.title}
                  onChange={handleChange}
                  disabled={
                    user.role > 3 &&
                    (form.publishStage === 2 ||
                      (form.authorId && user.id !== form.authorId))
                  }
                />
              </Container>
            </Row>
            <Row>
              <Container className="rounded editor-container p-2 pt-4 pb-4 mt-2 mb-2">
                <Row>
                  <Col className="left-container text-center" xs={3}>
                    <p>Upload Banner Image*</p>
                  </Col>
                  <Col className="text-center ">
                    <div className="image-uploader">
                      <label htmlFor="bannerImage" className="upload-label">
                        <div className="upload-icon">
                          <img
                            loading="lazy"
                            src="https://cdn-icons-png.flaticon.com/512/569/569800.png"
                            alt="Upload Icon"
                          />
                        </div>
                        <span className="upload-text">
                          {form.bannerImage || form.bannerImageName
                            ? typeof form.bannerImage === "string"
                              ? form.bannerImageName
                              : form.bannerImage.name
                            : "Choose from files"}
                        </span>{" "}
                        {form.bannerImage && (
                          <button
                            style={{
                              padding: "0px .5em",
                              marginLeft: "1em",
                            }}
                            className="remove-button"
                            type="button"
                            onClick={() => removeSelectedFile("bannerImage")}
                          >
                            X
                          </button>
                        )}
                      </label>

                      <input
                        type="file"
                        id="bannerImage"
                        accept=".jpg,.jpeg,.png"
                        multiple={false}
                        name="bannerImage"
                        style={{ display: "none" }}
                        onChange={handleImageChange}
                        disabled={
                          user.role > 3 &&
                          (form.publishStage === 2 ||
                            (form.authorId && user.id !== form.authorId))
                        }
                      />
                    </div>
                    {fileError && fileError?.bannerImage && (
                      <Alert variant="danger">{fileError?.bannerImage}</Alert>
                    )}
                  </Col>
                </Row>
              </Container>
            </Row>
            <Row>
              <Container className="rounded editor-container p-4 m-1  ">
                <h2 className="sub-heading">Instructor</h2>
                <input
                  type="text"
                  className="form-control"
                  id="instructor"
                  name="instructor"
                  placeholder=""
                  value={form.instructor}
                  onChange={handleChange}
                  disabled={
                    user.role > 3 &&
                    (form.publishStage === 2 ||
                      (form.authorId && user.id !== form.authorId))
                  }
                />
              </Container>
            </Row>
            <Row>
              <Container className="rounded editor-container p-4 m-1  ">
                <h2 className="sub-heading">Course Duration</h2>

                <input
                  type="text"
                  className="form-control"
                  id="duration"
                  name="duration"
                  placeholder=""
                  value={form.duration}
                  onChange={handleChange}
                  disabled={
                    user.role > 3 &&
                    (form.publishStage === 2 ||
                      (form.authorId && user.id !== form.authorId))
                  }
                />
              </Container>
            </Row>
            <Row>
              <Container className="rounded editor-container p-4 m-1  ">
                <h2 className="sub-heading">Course Link</h2>

                <input
                  type="text"
                  className="form-control"
                  id="courseLink"
                  name="courseLink"
                  placeholder=""
                  value={form.courseLink}
                  onChange={handleChange}
                  disabled={
                    user.role > 3 &&
                    (form.publishStage === 2 ||
                      (form.authorId && user.id !== form.authorId))
                  }
                />
              </Container>
            </Row>
            <Row>
              <Container className="rounded editor-container p-4 m-1">
                <h2 className="sub-heading">Description</h2>
                <CKEditor
                  editor={Editor}
                  data={form.description}
                  onReady={(editor) => {}}
                  onChange={handleContentChange}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                  disabled={
                    user.role > 3 &&
                    (form.publishStage === 2 ||
                      (form.authorId && user.id !== form.authorId))
                  }
                />
              </Container>
            </Row>
          </div>
        </Col>
        <Col>
          <Container className="pt-4 editor-container">
            <div className="p-1">
              <h2 className="sub-heading">Country</h2>

              {user.role > 3 &&
              (form.publishStage === 2 ||
                (form.authorId && user.id !== form.authorId)) ? (
                <>
                  {selectedCountries.map((country) => (
                    <Badge
                      bg="dark"
                      text="light"
                      className="p-2 m-1"
                      key={country.id}
                    >
                      {country.name}
                    </Badge>
                  ))}
                </>
              ) : (
                <DropdownButton
                  variant="outline-secondary"
                  title={"Select Country"}
                  id="input-group-dropdown-1"
                >
                  <Form.Check
                    type="checkbox"
                    label="Select All"
                    style={{ margin: "0px 20px" }}
                    checked={selectedCountries.length === countries.length}
                    onChange={() => {
                      setSelectedCountries(
                        selectedCountries.length === countries.length
                          ? []
                          : countries
                      );
                    }}
                  />
                  {countries.map((country, index) => (
                    <Form.Check
                      key={index}
                      type="checkbox"
                      label={country.name}
                      style={{ margin: "0px 20px" }}
                      checked={selectedCountries.some(
                        (selected) => selected?.id === country?.id
                      )}
                      onChange={(e) => {
                        handleDropdownSelect(e, country);
                      }}
                    />
                  ))}
                </DropdownButton>
              )}
            </div>
            <div className="p-1">
              <h2 className="sub-heading">Category</h2>
              <InputGroup className="" style={{ width: "fit-content" }}>
                <DropdownButton
                  variant="outline-secondary"
                  title={form.category ? form.category : "Select Category"}
                  id="input-group-dropdown-1"
                  onSelect={(e) => {
                    handleChange({ target: { name: "category", value: e } });
                  }}
                  disabled={
                    user.role > 3 &&
                    (form.publishStage === 2 ||
                      (form.authorId && user.id !== form.authorId))
                  }
                >
                  {course_category.map((courseCategory, index) => (
                    <Dropdown.Item eventKey={courseCategory} key={index}>
                      {courseCategory}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </InputGroup>
            </div>
            <div className="p-1">
              <h2 className="sub-heading">Post Date</h2>
              <input
                type="date"
                className="form-control"
                id="blogDate"
                name="date"
                placeholder=""
                // min={new Date().toISOString().split("T")[0]}
                value={form.date}
                onChange={handleChange}
                disabled={
                  user.role > 3 &&
                  (form.publishStage === 2 ||
                    (form.authorId && user.id !== form.authorId))
                }
              />
            </div>
            <div>
              {msg.message.length > 0 &&
                msg.message.map((m, index) => (
                  <Alert key={index} variant={msg.variant}>
                    {m}
                  </Alert>
                ))}
            </div>
            <div
              style={{
                display: form.publishStage === 2 ? "flex" : "none",
              }}
            >
              <Card border="primary" style={{ margin: "auto" }}>
                <Card.Header>
                  {user.id === form.authorId
                    ? "Please Wait!!!"
                    : `Do you Approve this Course?`}
                </Card.Header>
                <Card.Body>
                  <Card.Text as="div">
                    {user.id === form.authorId ? (
                      `Course is pending for approval`
                    ) : (
                      <>
                        <textarea
                          placeholder="Comment"
                          style={{ width: "100%" }}
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        ></textarea>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "15px",
                          }}
                        >
                          <button
                            className="btn btn-success"
                            type=""
                            onClick={() => handleComment("approve")}
                          >
                            Approve
                          </button>
                          <button
                            className="btn btn-danger"
                            type=""
                            onClick={() => handleComment("reject")}
                          >
                            Reject
                          </button>
                        </div>{" "}
                      </>
                    )}
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </Container>
        </Col>
      </div>
    </div>
  );
};

export default EditCourse;
