import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import UserSideBar from "../components/UserSideBar";
import axiosInstance from "../utility/axios";
import UserTable from "../components/UserTable";
import HubTable from "../components/HubTable";
import { userContext } from "./Context";
import "./UserBoard.css";
import { useContext, useEffect, useState } from "react";
import HubSideBar from "../components/HubSideBar";
import WebsiteUsers from "../components/WebsiteUsers";
import Notification from "../components/Notification";
import MainHubTable from "../components/MainHubTable";
import CCRequest from "../components/CCRequest";
import UnverifiedUsers from "../components/UnverifiedUsers";

const UserBoard = () => {
  const [showUserSidebar, setShowUserSidebar] = useState({
    show: false,
    mode: "",
  });
  const [showHubSidebar, setShowHubSidebar] = useState({
    show: false,
    mode: "",
  });
  const { user } = useContext(userContext);
  const [users, setUsers] = useState([]);
  const [hubs, setHubs] = useState([]);
  const [editDetails, setEditDetails] = useState({});
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedTab, setSelectedTab] = useState("users");
  const [selectedCountry, setSelectedCountry] = useState(undefined);
  const [selectedCommunity, setSelectedCommunity] = useState(undefined);
  const [selectedRole, setSelectedRole] = useState(undefined);

  const [countries, setCountries] = useState([]);
  const [communities, setCommunities] = useState([]);
  const [subCommunities, setSubCommunities] = useState([]);
  const [roles, setRoles] = useState([]);
  const [notificationData, setNotificationData] = useState([]);

  useEffect(() => {
    if (selectedTab === "users") getUsers();
    if (selectedTab === "hubs") getHubs();
  }, [pagination, selectedCommunity, selectedCountry, selectedRole]);

  useEffect(() => {
    setPagination({
      offset: 0,
      limit: 10,
    });
    setSearch("");
    setShowUserSidebar({
      show: false,
      mode: "",
    });
    setShowHubSidebar({
      show: false,
      mode: "",
    });
    if (selectedTab === "users" && !users.length) getUsers();
    if (selectedTab === "hubs" && !hubs.length) getHubs();
  }, [selectedTab]);

  useEffect(() => {
    axiosInstance.get("/user/hub/main/list").then((res) => {
      setCommunities(res.data);
    });
    axiosInstance.get("/user/hub/list").then((res) => {
      setSubCommunities(res.data);
    });
    axiosInstance.get("/user/country/list").then((res) => {
      setCountries(res.data);
    });
    axiosInstance.get("/user/roles/list").then((res) => {
      setRoles(res.data);
    });
  }, []);

  const getUsers = () => {
    let query = `search=${search}&offset=${pagination.offset}&limit=${pagination.limit}`;
    if (selectedRole) query += `&role_level=${selectedRole.id}`;
    if (selectedCommunity) query += `&community_id=${selectedCommunity.id}`;
    if (selectedCountry) query += `&country_id=${selectedCountry.id}`;
    axiosInstance.post(`/user/get/all?${query}`).then((res) => {
      if (res.status === 200) {
        if (res.data.users.length && typeof res.data.users === "object") {
          setUsers([...res.data.users]);
          setTotalRecords(res.data.count || 0);
        } else {
          setUsers([]);
        }
      }
    });
  };

  const getHubs = () => {
    let query = `search=${search}&offset=${pagination.offset}&limit=${pagination.limit}`;
    if (selectedCountry) query += `&country_id=${selectedCountry.id}`;
    axiosInstance.get(`/user/hub/getall?${query}`).then((res) => {
      if (res.status === 200) {
        if (
          res.data.communities.length &&
          typeof res.data.communities === "object"
        ) {
          setHubs([...res.data.communities]);
          setTotalRecords(res.data.count || 0);
        } else {
          setHubs([]);
        }
      }
    });
  };

  const getUserData = async (id, mode) => {
    await axiosInstance.get(`/user/get/${id}`).then((res) => {
      setEditDetails({ ...res.data });
      setShowUserSidebar({
        show: true,
        mode,
      });
    });
  };

  const deleteUser = async (id) => {
    await axiosInstance.delete(`/user/delete/${id}`).then((res) => {
      setNotificationData([
        {
          msg: res.data.message,
          status: res.status,
        },
      ]);
      getUsers();
    });
  };

  const getHubData = async (id, mode) => {
    await axiosInstance.get(`/user/hub/get/${id}`).then((res) => {
      setEditDetails({ ...res.data });
      setShowHubSidebar({
        show: true,
        mode,
      });
    });
  };

  const deleteHub = async (id) => {
    await axiosInstance.delete(`/user/hub/delete/${id}`).then((res) => {
      setNotificationData([
        {
          msg: res.data.message,
          status: res.status,
        },
      ]);
      getHubs();
    });
  };

  return (
    <section className="user-section" id="user-section">
      <div className="user-content">
        <Notification
          notificationData={notificationData}
          setNotificationData={setNotificationData}
        />
        <Tabs
          defaultActiveKey="users"
          id="uncontrolled-tab-example"
          className="mb-3"
          onSelect={(v) => {
            setSelectedTab(v);
          }}
        >
          <Tab eventKey="users" title="Dashboard Users">
            <UserTable
              tab="users"
              users={users}
              getUserData={getUserData}
              deleteUser={deleteUser}
              search={search}
              setSearch={setSearch}
              getUsers={getUsers}
              totalRecords={totalRecords}
              pagination={pagination}
              setPagination={setPagination}
              selectedCommunity={selectedCommunity}
              selectedRole={selectedRole}
              selectedCountry={selectedCountry}
              setSelectedCommunity={setSelectedCommunity}
              setSelectedCountry={setSelectedCountry}
              setSelectedRole={setSelectedRole}
              setEditDetails={setEditDetails}
              countries={countries}
              communities={communities}
              subCommunities={subCommunities}
              roles={roles}
              setNotificationData={setNotificationData}
            />
          </Tab>
          <Tab eventKey="mhubs" title="Main CSOs">
            <MainHubTable
              search={search}
              setSearch={setSearch}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              countries={countries}
            />
          </Tab>
          <Tab eventKey="hubs" title="Registered CSOs">
            <HubTable
              tab="hubs"
              hubs={hubs}
              getHubData={getHubData}
              deleteHub={deleteHub}
              search={search}
              setSearch={setSearch}
              setEditDetails={setEditDetails}
              getHubs={getHubs}
              totalRecords={totalRecords}
              pagination={pagination}
              setPagination={setPagination}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              countries={countries}
              setNotificationData={setNotificationData}
            />
          </Tab>
          {user.role <= 3 ? (
            <Tab eventKey="wusers" title="Website Users">
              <WebsiteUsers
                countries={countries}
                subCommunities={subCommunities}
                setNotificationData={setNotificationData}
              />
            </Tab>
          ) : (
            ""
          )}
          {user.role <= 3 ? (
            <Tab eventKey="unVerifiedUsers" title="Unverified Users">
              <UnverifiedUsers
                countries={countries}
                subCommunities={subCommunities}
                setNotificationData={setNotificationData}
              />
            </Tab>
          ) : (
            ""
          )}
          {user.role <= 4 ? (
            <Tab eventKey="userRequest" title="Content Creator Request">
              <CCRequest countries={countries} />
            </Tab>
          ) : (
            ""
          )}
        </Tabs>
      </div>
      <UserSideBar
        show={showUserSidebar.show}
        mode={showUserSidebar.mode}
        currUser={user}
        handleClose={() => {
          setShowUserSidebar({
            show: false,
            mode: "",
          });
          setEditDetails({});
        }}
        selectedTab={selectedTab}
        editDetails={editDetails}
        countries={countries}
        communities={communities}
        roles={roles}
        refresh={() => {
          getUsers();
          setEditDetails({});
        }}
        setNotificationData={setNotificationData}
      />
      <HubSideBar
        show={showHubSidebar.show}
        mode={showHubSidebar.mode}
        handleClose={() => {
          setShowHubSidebar({
            show: false,
            mode: "",
          });
          setEditDetails({});
        }}
        selectedTab={selectedTab}
        editDetails={editDetails}
        countries={countries}
        refresh={() => {
          getHubs();
          setEditDetails({});
        }}
        setNotificationData={setNotificationData}
      />
    </section>
  );
};

export default UserBoard;
