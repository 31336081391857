import "./EventSideBar.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import DropdownButton from "react-bootstrap/DropdownButton";
import Alert from "react-bootstrap/Alert";
import React, { useEffect, useState, useContext } from "react";
import { userContext } from "../pages/Context";
import axiosInstance from "../utility/axios";
import Notification from "./Notification";

const GroupSideBar = ({ details, show, handleClose, refresh, countries }) => {
  const { user } = useContext(userContext);
  const [group, setGroup] = useState({
    title: "",
    description: "",
  });

  const [msg, setMsg] = useState({
    message: [],
    variant: "danger",
  });

  const [selectedCountries, setSelectedCountries] = useState([]);
  const [notificationData, setNotificationData] = useState([]);

  useEffect(() => {
    if (Object.keys(details).length) {
      if (details?.chatGroup) setGroup(details?.chatGroup);

      if (details?.chatGroup.countries)
        setSelectedCountries(details?.chatGroup.countries);
    } else {
      if (user.countryId)
        setSelectedCountries([{ id: user.countryId, name: user.country }]);
    }
  }, [details]);

  const handleChange = (e) => {
    setGroup({ ...group, [e.target.id]: e.target.value });
  };

  const handleDropdownSelect = (e, selectedCountry) => {
    if (user.countryId !== selectedCountry.id) {
      setSelectedCountries((prevSelected) => {
        // Check if the country is already selected
        const isSelected = prevSelected.filter(
          (country) => country.id === selectedCountry.id
        );
        // If the country is already selected, remove it from the selected list
        if (isSelected.length) {
          return prevSelected.filter(
            (country) => country.id !== selectedCountry.id
          );
        } else {
          // If the country is not selected, add it to the selected list
          return [...prevSelected, selectedCountry];
        }
      });
    }
  };

  const handleSubmit = () => {
    const { ...form } = group;
    const formData = {
      ...form,
      countryIds: JSON.stringify(
        selectedCountries.map((country) => country.id)
      ),
    };
    if (!group.id) {
      axiosInstance
        .post("/chat-group/create", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            handleClose();
            refresh();
            setGroup({
              title: "",
              description: "",
            });
            setMsg({
              message: [],
              variant: "danger",
            });
          } else {
            if (res.data.message === "Validation error")
              setMsg({
                message: res.data.error?.details?.map((e) => e.message),
                variant: "danger",
              });
            else if (res.data.message === "DB Error")
              setMsg({ message: [...res.data.error], variant: "danger" });
            else setMsg({ message: [res.data.message], variant: "danger" });
          }
          setNotificationData([
            {
              msg: res.data.message,
              status: res.status,
            },
          ]);
        });
    } else {
      axiosInstance
        .patch(`/chat-group/edit/${group.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setMsg({
              message: [],
              variant: "danger",
            });
            handleClose();
            refresh();
            setGroup({
              title: "",
              description: "",
            });
          } else {
            if (res.data.message === "Validation error")
              setMsg({
                message: res.data.error?.details?.map((e) => e.message),
                variant: "danger",
              });
            else if (res.data.message === "DB Error")
              setMsg({ message: [...res.data.error], variant: "danger" });
            else setMsg({ message: [res.data.message], variant: "danger" });
          }
          setNotificationData([
            {
              msg: res.data.message,
              status: res.status,
            },
          ]);
        });
    }
  };

  return (
    <>
      <Notification
        notificationData={notificationData}
        setNotificationData={setNotificationData}
      />
      <Offcanvas
        show={show}
        onHide={() => {
          handleClose();
          setGroup({
            title: "",
            description: "",
          });
          setMsg({
            message: [],
            variant: "danger",
          });
          setSelectedCountries([]);
        }}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Group Form</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="title"
              aria-label="Title"
              aria-describedby="Title"
              id="title"
              value={group.title}
              onChange={handleChange}
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Enter Description"
              aria-label="description"
              aria-describedby="description"
              id="description"
              value={group.description}
              onChange={handleChange}
            ></Form.Control>
          </InputGroup>
          <InputGroup className="mb-3">
            <DropdownButton
              variant="outline-secondary"
              title={"Select Country"}
              id="input-group-dropdown-1"
            >
              <Form.Check
                type="checkbox"
                label="Select All"
                style={{ margin: "0px 20px" }}
                checked={selectedCountries.length === countries.length}
                onChange={() => {
                  setSelectedCountries(
                    selectedCountries.length === countries.length
                      ? []
                      : countries
                  );
                }}
              />
              {countries.map((country, index) => (
                <Form.Check
                  type="checkbox"
                  label={country.name}
                  style={{ margin: "0px 20px" }}
                  checked={selectedCountries.some(
                    (selected) => selected?.id === country?.id
                  )}
                  onChange={(e) => {
                    handleDropdownSelect(e, country);
                  }}
                  key={index}
                />
              ))}
            </DropdownButton>
          </InputGroup>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button className="btn btn-primary mb-3" onClick={handleSubmit}>
              Save
            </button>
          </div>

          {msg.message.length > 0 &&
            msg.message.map((m, index) => (
              <Alert key={index} variant={msg.variant}>
                {m}
              </Alert>
            ))}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default GroupSideBar;
